<div class="alert">
  <div *ngIf="data.title" class="header">
    <h2>{{ data.title | translate }}</h2>
  </div>
  <div *ngIf="data.content" class="body">
    <div [innerHtml]="data.content | translate"></div>
  </div>
  <div *ngIf="data.non_field_errors" class="body">
    <div [innerHtml]="data.non_field_errors | translate"></div>
  </div>
  <div *ngIf="data.buttons && data.buttons.length" class="buttons">
    <button (click)="confirm(button.type, button.response)"
            *ngFor="let button of data.buttons"
            [ngClass]="button.class ? button.class : ''"
            class="btn">
      {{ button.text | translate }}
    </button>
  </div>
</div>
