import { Injectable }   from '@angular/core';
import { ApiEndpoints } from '@app/app.constants';
import { HttpService }  from '@services/http.service';
import { Observable }   from 'rxjs';
import { map }          from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BuyStatusService {

  constructor(
    private _http: HttpService,
  ) {
  }

  updateUserShoppingStatus(status: BuyStatus): Observable<ShoppingStatusPayload> {
    const payload: ShoppingStatusPayload = { purchase_status: status };
    return this._http.put(ApiEndpoints.assessmentCompany, payload).pipe(map((response: ShoppingStatusPayload) => response));
  }
}

export enum BuyStatus {
  new = 'new',
  interested_prices = 'interested_prices',
  interested_buying = 'interested_buying',
  bought = 'bought'
}

export interface ShoppingStatusPayload {
  purchase_status: BuyStatus
}
