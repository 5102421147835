import { Routes }                             from '@angular/router';
import { AutologinGuard }                     from '@b2b/route.autologin/guards/autologin.guard';
import { PlatformsAutologinComponent }        from '@b2b/route.autologin/platforms-autologin.component';
import { RouteForceLoginComponent }           from '@b2b/route.forcelogin/route-force-login.component';
import { LoginTokenComponent }                from '@b2b/route.login_token/login-token.component';
import { RouteRegistrationConfirmComponent }  from '@b2b/route.registation-confirm/route-registration-confirm.component';
import { RouteResetPasswordConfirmComponent } from '@b2b/route.reset-password-confirm/route-reset-password-confirm.component';
import { AccessPlatformsGuard }               from '@common/guards/access-platforms.guard';
import { AuthTokenGuard }                     from '@common/guards/auth-token.guard';
import { LayoutBlankComponent }               from '@common/layouts/cpts/layout-blank/layout-blank.component';
import { LayoutHeroBannerComponent }          from '@common/layouts/cpts/layout-hero-banner/layout-hero-banner.component';
import { LayoutComponent }                    from '@common/layouts/cpts/layout/layout.component';
import { B2BAppType }                         from '@services/platforms-access.service';
import { B2BComponent }                       from './b2b.component';

export const B2B_ROUTES: Routes = [
  {
    path: 'business',
    component: B2BComponent,
    children: [
      {
        path: 'freelancers',
        component: LayoutComponent,
        canActivate: [AuthTokenGuard, AccessPlatformsGuard],
        canActivateChild: [AuthTokenGuard, AccessPlatformsGuard],
        data: {
          type: B2BAppType[B2BAppType.freelancers]
        },
        // canActivateChild: [ LoggedInGuard ],
        // component: LayoutDefaultComponent,
        // canActivateChild: [AuthTokenGuard, AccessFreelancersPlatformGuard, AccessPlatformsGuard],
        loadChildren:
          () => import('./+recruiters/recruiters.module').then(module => module.RecruitersModule)
      },
      {
        path: 'user',
        component: LayoutBlankComponent,
        children: [
          {
            path: 'password-reset-confirm/:uid/:token',
            component: LayoutHeroBannerComponent,
            children: [
              {
                path: '',
                component: RouteResetPasswordConfirmComponent,
                data: {name: 'resetPasswordConfirm'}
              },
            ]
          },
          {
            path: 'login-token/:token',
            component: LoginTokenComponent,
          },
        ]
      },
      {
        path: 'autologin/:platform',
        component: LayoutBlankComponent,
        children: [
          {
            path: '',
            component: PlatformsAutologinComponent,
            canActivate: [AutologinGuard]
          },
        ]
      },
      {
        path: 'register/confirm/:token',
        component: RouteRegistrationConfirmComponent,
      },
    ]
  },
  {
    path: 'app/forcelogin/:authToken',
    component: RouteForceLoginComponent,
  },
  {
    path: 'app',
    component: B2BComponent,
    data: {
      type: B2BAppType[B2BAppType.assessment]
    },
    canActivate: [AuthTokenGuard, AccessPlatformsGuard],
    canActivateChild: [AuthTokenGuard, AccessPlatformsGuard]
  }
];
