import { CommonModule }                      from '@angular/common';
import { ModuleWithProviders, NgModule }     from '@angular/core';
import { FormsModule }                       from '@angular/forms';
import { FormTestFeedbackComponent }         from '@common/cpts/form-test-feedback/form-test-feedback.component';
import { ModalTestFeedbackComponent }        from '@common/cpts/modal-test-feedback/modal-test-feedback.component';
import { GlobalComponentsModule }            from '@common/global-components/global-components.module';
import { GlobalDirectivesModule }            from '@common/global-directives/global-directives.module';
import { TranslateModule }                   from '@ngx-translate/core';
import { TRANSLATE_MODULE_PROVIDERS }        from '@services/i18n.service';
import { AuthModule }                        from './auth/auth.module';
import { ACertificateComponent }             from './cpts/a-certificate/a-certificate.component';
import { FormAlertComponent }                from './cpts/form-alert.component';
import { JobStatusComponent }                from './cpts/job-status/job-status.component';
import { MaterialAlertComponent }            from './cpts/material-alert.component';
import { ModalComponent }                    from './cpts/modal.component';
import { ParticipationCertificateComponent } from './cpts/participation-certificate/participation-certificate.component';
import { QuizInfoCardsComponent }            from './cpts/quiz-info-cards/quiz-info-cards.component';
import { QuizQuestionComponent }             from './cpts/quiz-question/quiz-question.component';
import { ModalDirective }                    from './directives/modal.directive';
import { ProgressBarDirective }              from './directives/progress-bar.directive';
import { SectionFullPageDirective }          from './directives/section-full-page.directive';
import { HelpersModule }                     from './helpers/helpers.module';
import { LayoutsModule }                     from './layouts/layouts.module';
import { MaterialModule }                    from './material/material.module';

const CORE_SERVICES = [
  // UtilsService,
  // InternationalizationService,
  // AuthService,
  // UserRoutesService,
  // HttpService,
  // PlatformsAccessService,
  // SiteConfigService,
  // CurrencyService,
  // ProfileService,
  // MaterialSnackBarService,
  // PathResolveService,
  // ModalService,
  // ModalAlertService,
  // CheckCompanySlugGuard,
  // AccessPlatformsGuard
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    AuthModule,
    HelpersModule,
    // UserModule,
    MaterialModule,
    FormsModule,
    GlobalDirectivesModule,
    GlobalComponentsModule,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    AuthModule,
    HelpersModule,
    // UserModule,
    MaterialModule,
    FormsModule,
    LayoutsModule,
    ProgressBarDirective,
    FormAlertComponent,
    ModalComponent,
    ModalDirective,
    SectionFullPageDirective,
    ParticipationCertificateComponent,
    ACertificateComponent,
    QuizQuestionComponent,
    JobStatusComponent,
  ],
  providers: [
    ...CORE_SERVICES,
    TRANSLATE_MODULE_PROVIDERS,
  ],
  declarations: [
    SectionFullPageDirective,
    MaterialAlertComponent,
    ProgressBarDirective,
    FormAlertComponent,
    ModalComponent,
    ModalDirective,
    ModalTestFeedbackComponent,
    FormTestFeedbackComponent,
    ACertificateComponent,
    ParticipationCertificateComponent,
    QuizInfoCardsComponent,
    QuizQuestionComponent,
    JobStatusComponent
  ],
  entryComponents: [
    ModalComponent,
    MaterialAlertComponent,
    FormAlertComponent
  ]
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ...CORE_SERVICES,
        TRANSLATE_MODULE_PROVIDERS,
      ]
    };
  }
}
