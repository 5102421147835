import { Injectable }      from '@angular/core';
import { AuthService }     from '@common/auth/services/auth.service';
import { LanguageService } from '@services/i18n.service';
import { Cookie }          from 'ng2-cookies';

export const GLOBAL_GTM_ID_EN = 'GTM-NPJXNTF';
export const GLOBAL_GTM_ID_FR = 'GTM-WK3K7W6';

const GTM_DEBUG_FORCE_ENABLE_COOKIE = 'GTM_FORCE_ENABLE';

@Injectable({
  providedIn: 'root'
})
export class GtmService {

  private _insertingScript = false;

  constructor(
    private _authService: AuthService,
    private _languageService: LanguageService
  ) {}

  public init() {
    if (this._isProduction && !this._isAdmin) {
      this._insertGtmScript();
    }
  }

  private _insertGtmScript(gtmId = GLOBAL_GTM_ID_EN) {
    if (this._isGtmScriptInserted()) {
      return;
    }
    this._insertingScript = true;

    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      'gtm.start':
        new Date().getTime(), event: 'gtm.js'
    });
    const firstScript = (document as any).getElementsByTagName('script')[0] as HTMLScriptElement;
    const script = (document as any).createElement('script') as HTMLScriptElement;

    script.async = true;
    gtmId = this._languageService.currentLang === 'fr' ? GLOBAL_GTM_ID_FR :  GLOBAL_GTM_ID_EN;
    script.src =
      'https://www.googletagmanager.com/gtm.js?id=' + gtmId;
    script.id = gtmId;

    this._isGtmScriptInserted();

    firstScript.parentNode.insertBefore(script, firstScript);
    this._insertingScript = false;
  }

  private _isGtmScriptInserted(gtmId = GLOBAL_GTM_ID_EN) {
    gtmId = this._languageService.currentLang === 'fr' ? GLOBAL_GTM_ID_FR :  GLOBAL_GTM_ID_EN;
    const script = document.getElementById(gtmId) as HTMLScriptElement;
    return script != null;
  }

  // Helpers
  // ==============================

  private get _isProduction() {
    if (Cookie.check(GTM_DEBUG_FORCE_ENABLE_COOKIE)) {
      return true;
    }
    return window.location.href.indexOf('pentalog-freelancers.com') !== -1;
  }

  private get _isAdmin() {
    return this._authService.currentUserDetails ?
      this._authService.currentUserDetails.is_admin : false;
  }
}
