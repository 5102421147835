import { CommonModule }              from '@angular/common';
import { NgModule }                  from '@angular/core';
import { HighlightMultipleTextPipe } from '@common/pipes/highlight-multiple-text.pipe';
import { ObjectPropertiesPipe }      from '@common/pipes/object-properties.pipe';
import { FilterByPropertyPipe }      from './filter-by-property.pipe';
import { NumberRoundPipe }           from './number-round.pipe';
import { TimeFormatPipe } from './time-format.pipe';


@NgModule({
  declarations: [
    ObjectPropertiesPipe,
    HighlightMultipleTextPipe,
    FilterByPropertyPipe,
    NumberRoundPipe,
    TimeFormatPipe,
  ],
  exports: [
    ObjectPropertiesPipe,
    HighlightMultipleTextPipe,
    FilterByPropertyPipe,
    NumberRoundPipe,
    TimeFormatPipe,
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule {
}
