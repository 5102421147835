import { Component, OnInit }         from '@angular/core';
import { Currency, CurrencyService } from '@services/currency.service';
import { UtilsService }              from '@services/utils.service';

@Component({
  selector: 'app-currency-switcher',
  templateUrl: './currency-switcher.component.html',
  styleUrls: ['./currency-switcher.component.scss']
})
export class CurrencySwitcherComponent implements OnInit {

  public currencies: Currency[] = this.currencyService.currencies;

  constructor(
    public currencyService: CurrencyService,
    public utilsService: UtilsService
  ) {
  }

  public ngOnInit(): void {
    this.currencyService.setCurrencySwitcherValue();
    this.currencyService.setCurrencyLocalStorage();
  }

  public updateCurrency(currency: Currency) {
    this.currencyService.setCurrencySwitcherValue(currency.id);
    this.currencyService.setCurrencyLocalStorage(currency.id);
    this.currencyService.currencyChanges.next(currency);
  }

}
