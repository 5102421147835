import { Injectable }     from '@angular/core';
import { ApiEndpoints }   from '@app/app.constants';
import { TutorialModule } from '@b2b/@common/modules/tutorial/tutorial.module';
import { AuthService }    from '@common/auth/services/auth.service';
import { HttpService }    from '@services/http.service';
import { Observable }     from 'rxjs';

@Injectable({
  providedIn: TutorialModule
})
export class SlideshowGuideService {

  constructor(
    public HTTP: HttpService,
    public userService: AuthService
  ) {

  }

  public getSlides(category): Observable<any> {
    // const authOptions = this.userService.authRequestOptions;

    return this.HTTP.get(ApiEndpoints.common.carousel + `?category=${category}`);
  }
}
