<div class="signup-terms-modal">
  <div class="signup-terms-modal__header">
    <h1 class="signup-terms-modal__heading" mat-dialog-title>
      <ng-container *ngIf="!isB2B">{{'SIGNUP_FORM_MODAL_TITLE_B2C' | translate}}</ng-container>
      <ng-container *ngIf="isB2B">{{'SIGNUP_FORM_MODAL_TITLE_B2B' | translate}}</ng-container>
    </h1>
  </div>

  <div class="signup-terms-modal__content">
    <form [formGroup]="termsForm" class="signum-terms-form custom-form mat-form" novalidate>
      <div class="form-group">
        <div
          class="form-control-checkbox no-margin">
          <input
            formControlName="terms"
            id="modal_terms"
            required
            type="checkbox">
          <label appCheckbox for="modal_terms">
            <span
              *ngIf="isB2C"
              [innerHTML]="'SIGNUP_FORM_LABEL_TERMS_AND_COND' | translate">
            </span>
            <span
              *ngIf="isB2F"
              [innerHTML]="'SIGNUP_FORM_LABEL_TERMS_AND_COND_FREELANCE' | translate">
            </span>
            <span
              *ngIf="isB2B"
              [innerHTML]="'SIGNUP_FORM_LABEL_TERMS_AND_COND_B2B' | translate">
            </span>
          </label>
        </div>
      </div>

      <div class="form-group">
        <div class="form-control-checkbox no-margin">
          <input formControlName="newsletter" id="modal-use-personal-data" type="checkbox">
          <label checkbox for="modal-use-personal-data">
            <span [innerHTML]="'SIGNUP_FORM_LABEL_USE_PERSONAL_DATA' | translate"></span>
          </label>
        </div>
      </div>

      <div *ngIf="isB2B" class="form-group">
        <div class="form-control-select">
          <select
            (ngModelChange)="onAccessTypeChange($event)"
            [(ngModel)]="selectedApplicationTypeOption"
            class="form-control"
            formControlName="type"
            required>
            <option
              *ngFor="let type of formAvailableApplicationTypes.b2bTypes"
              [ngValue]="type"
              [selected]="type.val === selectedApplicationTypeOption.val">
              {{ type.title | translate }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </div>

  <div class="signup-terms-modal__footer">
    <div class="row">
      <button
        (click)="onSubmit($event)"
        [attr.disabled]="!terms.value || (isB2B && !selectedApplicationTypeOption.val) ? 'disabled' : null"
        class="btn"
        type="button">
        {{('SIGNUP_FORM_MODAL_LABEL_CONTINUE' | translate) | uppercase }}
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </div>
</div>
