import { CommonModule }                       from '@angular/common';
import { NgModule }                           from '@angular/core';
import { MatIconModule }                      from '@angular/material/icon';
import { TranslateModule }                    from '@ngx-translate/core';
import { LicenseUpgradePromptModalComponent } from './cpts/license-upgrade-prompt-modal.component';


@NgModule({
  declarations: [LicenseUpgradePromptModalComponent],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule
  ],
  exports: [LicenseUpgradePromptModalComponent],
  entryComponents: [LicenseUpgradePromptModalComponent]
})
export class LicenseModule {
}
