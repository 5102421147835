import { Component, HostListener, Input, OnInit } from '@angular/core';
import { SLIDE_IN_OUT_ANIMATION }                 from '@common/core/core.animations';
import { ModalService, ModalStatus }              from '@services/modal.service';
import { Observable, Observer }                   from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.scss'],
  animations: [SLIDE_IN_OUT_ANIMATION]
})

export class ModalComponent implements OnInit {
  @Input() public modalId: string;
  @Input() public width = 400;
  @Input() public title: string;
  @Input() public showCloseIcon = true;

  public isVisible: boolean;
  public status: ModalStatus;

  constructor(
    public modalService: ModalService
  ) {
    // noop
  }

  public ngOnInit() {
    this.modalService.initModal(this);
  }

  public show(): Observable<boolean> {
    this.isVisible = true;

    return new Observable<boolean>(
      (observer: Observer<boolean>) => {
        setTimeout(() => {
          observer.next(true);
          observer.complete();
        }, 300);
      }
    );
  }

  public hide(): Observable<boolean> {
    this.isVisible = false;

    return new Observable<boolean>(
      (observer: Observer<boolean>) => {
        setTimeout(() => {
          observer.next(true);
          observer.complete();
        }, 300);
      }
    );
  }

  public close(): void {
    this.modalService.close(this.modalId);
  }

  public onWrapperClick(event: any) {
    if (event.target.className !== 'modal-container') {
      return;
    }

    this.close();
  }

  @HostListener('keyup', ['$event'])
  public onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.close();
    }
  }

}
