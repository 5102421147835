import { CommonModule }                  from '@angular/common';
import { NgModule }                      from '@angular/core';
import { RouterModule }                  from '@angular/router';
import { TutorialModule }                from '@b2b/@common/modules/tutorial/tutorial.module';
import { GlobalComponentsModule }        from '@common/global-components/global-components.module';
import { GlobalDirectivesModule }        from '@common/global-directives/global-directives.module';
import { PipesModule }                   from '@common/pipes/pipes.module';
import { TranslateModule }               from '@ngx-translate/core';
import { LocalizeRouterModule }          from 'localize-router';
import { MaterialModule }                from '../material/material.module';
import { CurrencySwitcherComponent }     from './cpts/currency-switcher/currency-switcher.component';
import { FooterComponent }               from './cpts/footer/footer.component';
import { HeaderAndSidebarLeftComponent } from './cpts/header-and-sidebar-left/header-and-sidebar-left.component';
import { BlockBrandingCommonComponent }  from './cpts/header/cpts/block-branding-common.component';
import { BlockButtonsComponent }         from './cpts/header/cpts/block-buttons.component';
import { BlockMenuHeaderComponent }      from './cpts/header/cpts/block-menu-header.component';
import { BlockMenuMobileComponent }      from './cpts/header/cpts/block-menu-mobile.component';
import { BlockNavHeaderComponent }       from './cpts/header/cpts/block-nav-header.component';
import { BlockNavToggleComponent }       from './cpts/header/cpts/block-nav-toggle.component';
import { HeaderComponent }               from './cpts/header/header.component';
import { LanguageSwitcherComponent }     from './cpts/language-switcher/language-switcher.component';
import { LayoutBizComponent }            from './cpts/layout-biz/layout-biz.component';
import { LayoutBlankComponent }          from './cpts/layout-blank/layout-blank.component';
import { LayoutComponent }               from './cpts/layout/layout.component';
import { LoaderComponent }               from './cpts/loader/loader.component';
import { B2bSidebarComponent }           from './cpts/sidebar/cpts/b2b-sidebar.component';
import { B2cSidebarComponent }           from './cpts/sidebar/cpts/b2c-sidebar.component';
import { SidebarComponent }              from './cpts/sidebar/sidebar.component';
import { PageHeaderComponent }           from './cpts/b2c/page-header/page-header.component';
import { PageFooterComponent }           from './cpts/b2c/page-footer/page-footer.component';
import { BlockBrandingComponent }        from './cpts/b2c/block-branding/block-branding.component';
import { BlockMainNavComponent }         from './cpts/b2c/block-main-nav/block-main-nav.component';
import { BlockMenuAuthenticatedComponent } from './cpts/b2c/block-menu-authenticated/block-menu-authenticated.component';
import { PublicMenuComponent }           from './cpts/b2c/public-menu/public-menu.component';
import { LayoutHeroBannerComponent }     from './cpts/layout-hero-banner/layout-hero-banner.component';
import { JobLayoutComponent }            from './cpts/b2c/job-layout/job-layout.component';
import { B2cJobHeaderComponent }         from './cpts/b2c/b2c-job-header/b2c-job-header.component';
import { B2bSidebarAppSwitcherComponent } from './cpts/sidebar/cpts/b2b-sidebar-app-switcher/b2b-sidebar-app-switcher.component';

export const LAYOUTS_MODULE_IMPORTS = [
  CommonModule,
  RouterModule,
  TranslateModule,
  LocalizeRouterModule,
  MaterialModule
];

export const LAYOUTS_MODULE_COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  HeaderAndSidebarLeftComponent,
  SidebarComponent,
  BlockBrandingCommonComponent,
  BlockNavToggleComponent,
  LayoutComponent,
  LanguageSwitcherComponent,
  LoaderComponent,
  B2bSidebarComponent,
  B2cSidebarComponent,
  CurrencySwitcherComponent,
  BlockNavHeaderComponent,
  BlockButtonsComponent,
  BlockMenuHeaderComponent,
  BlockMenuMobileComponent,
  B2bSidebarAppSwitcherComponent,
];

@NgModule({
  declarations: [
    ...LAYOUTS_MODULE_COMPONENTS,
    LayoutBizComponent,
    LayoutBlankComponent,
    PageHeaderComponent,
    PageFooterComponent,
    BlockBrandingComponent,
    BlockMainNavComponent,
    BlockMenuAuthenticatedComponent,
    PublicMenuComponent,
    LayoutHeroBannerComponent,
    JobLayoutComponent,
    B2cJobHeaderComponent,
    B2bSidebarAppSwitcherComponent,
  ],
  imports: [
    ...LAYOUTS_MODULE_IMPORTS,
    PipesModule,
    GlobalComponentsModule,
    GlobalDirectivesModule,
    TutorialModule,
  ],
  exports: [
    ...LAYOUTS_MODULE_COMPONENTS,
  ]
})
export class LayoutsModule {
}
