import { LocationStrategy }                                                                                 from '@angular/common';
import { Injectable, EventEmitter }                                                                         from '@angular/core';
import { MatDialog }                                                                                        from '@angular/material/dialog';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, NavigationExtras, NavigationStart, Router } from '@angular/router';
import { B2bUserDetails }                                                                                   from '@common/auth/classes/b2b-user-details';
import { UserDetails }                                                                                      from '@common/auth/classes/user-details';
import { Cookie }                                                                                           from 'ng2-cookies';
import { Observable, Subject }                                                                              from 'rxjs';
import { filter, map }                                                                                      from 'rxjs/operators';
import { LanguageService }                                                                                  from './i18n.service';
import { RouteMetaService }                                                                                 from './route-meta.service';
import { SiteConfigService }                                                                                from './site-config.service';
import { UtilsService }                                                                                     from './utils.service';


export enum NavigationState {
  Pending,
  Done
}

declare var ga: any;

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private readonly mobileMenuEmitter: EventEmitter<boolean>;

  public state: NavigationState;
  public pending: boolean;
  public isFront: boolean;
  public isModal: boolean;
  public activatedRouteSnapshot: ActivatedRouteSnapshot;

  public events: Subject<NavigationState>;

  constructor(
    private _location: LocationStrategy,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _languageService: LanguageService,
    private _siteConfigService: SiteConfigService,
    private _routeMetaService: RouteMetaService,
    private _utilsService: UtilsService,
    private _matDialog: MatDialog
  ) {
    this.events = new Subject();
    this.mobileMenuEmitter = new EventEmitter<boolean>();

    this._initMainRouterEventListener();
  }

  public openMobileMenu() {
    this.mobileMenuEmitter.emit(true);
  }

  public closeMobileMenu() {
    this.mobileMenuEmitter.emit(false);
  }

  public mobileMenuOpened() {
    return this.mobileMenuEmitter;
  }

  public activeRoute(): Observable<any> {
    return this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this._activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(filter((route) => route.outlet === 'primary'))
      .pipe(map((route) => route.snapshot));
  }

  public navigateByUrl(url: string, extras?: NavigationExtras, options: any = {}): Promise<boolean> {
    if (!options.skipClosingModals) {
      this._matDialog.closeAll();
    }

    let translatedUrl = url;
    if (!this.urlHasLanguageParam(url)) {
      translatedUrl = this._languageService.translateUrl(url);
    }
    return this._router.navigate([translatedUrl || 'en'], extras);
  }

  public setURLParamsForB2BAssessment(userDetails: UserDetails & B2bUserDetails, existingUrlString): string {
    // todo: future - use a implementation of URLParams that has .append API
    const hasLicenseInfo = !!userDetails.license_info;
    const hasLicenseChanges = hasLicenseInfo && userDetails.license_info.license_type_changed;
    const licenseName = hasLicenseInfo ? this.cleanStringAsQueryParam(userDetails.license_info.name) : null;
    const licenseId = hasLicenseInfo &&
      (userDetails.license_info.license_type_id !== null ? userDetails.license_info.license_type_id.toString() : false);

    if (userDetails && hasLicenseChanges) {
      const separator = this.getSeparator(existingUrlString);
      const newUrlString = separator + licenseName + '=' + licenseId;
      existingUrlString = existingUrlString + newUrlString;
    }

    const hasCompanyStats = userDetails && userDetails.company_stats;
    const hasSentFirstCampaign = hasCompanyStats && userDetails.company_stats.first_campaign_sent;

    if (userDetails && hasSentFirstCampaign) {
      const separator = this.getSeparator(existingUrlString);
      const newUrlString = separator + 'firstCampaignSent=true';
      existingUrlString = existingUrlString + newUrlString;
    }

    const hasReachedFirstLevel = hasCompanyStats && userDetails.company_stats.first_level_reached;

    if (userDetails && hasReachedFirstLevel) {
      const separator = this.getSeparator(existingUrlString);
      const newUrlString = separator + 'firstLevelReached=true';
      existingUrlString = existingUrlString + newUrlString;
    }

    return existingUrlString;
  }

  public setURLParamsForB2BFreelancers(userDetails: UserDetails & B2bUserDetails, existingUrlString): string {
    existingUrlString = existingUrlString.replace('?', '');
    let params = this.unserialize(existingUrlString);
    params = {
      source: 'site',
      ...params,
      type: 'company'
    };
    const paramsAsString = this.serialize(params);
    const separator = this.getSeparator(paramsAsString);

    return separator + paramsAsString;
  }

  public cleanStringAsQueryParam(str: string = '') {
    return str.toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '-')
      .replace(/^-+|-+$/g, '');
  }

  public serialize(params): string {
    const finalString = [];
    for (const p in params) {
      if (params.hasOwnProperty(p)) {
        finalString.push(encodeURIComponent(p) + '=' + encodeURIComponent(params[p]));
      }
    }
    return finalString.join('&');
  }

  public unserialize(str: string) {
    if (str.length === 0) {
      return {};
    }
    return JSON
      .parse('{"' + decodeURI(str)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') + '"}');
  }

  public getSeparator(str: string) {
    return str.indexOf('?') >= 0 ? '&' : '?';
  }

  public navigateToUrl(url: string, extras?: NavigationExtras): Promise<boolean> {
    let translatedUrl = url;
    if (!this.urlHasLanguageParam(url)) {
      translatedUrl = this._languageService.translateUrl(url);
    }

    return this._router.navigate([translatedUrl], extras);
  }

  public urlHasLanguageParam(url: string) {

    if (url === '/') {
      return false;
    }

    url = url.split('?')[0];

    const urlSegments = url.split('/');
    const defaultLang = Cookie.get('django_language') || this._languageService.locales[0];

    return defaultLang.indexOf(urlSegments[1]) >= 0;
  }

  public urlHasModalParam(url: string) {
    return url.indexOf('(modal:') >= 0;
  }

  public isPathActive(pathName: string, exactMatch?: boolean): boolean {
    pathName = this._languageService.translateUrl(pathName);

    if (exactMatch && window.location.pathname === pathName) {
      return true;
    } else if (!exactMatch && window.location.pathname.indexOf(pathName) === 0) {
      return true;
    }

    return false;
  }

  public getUrlAndParams(url) {
    const data = {
      url: '/',
      params: {}
    };
    url = decodeURIComponent(url);
    data.url = url.split('?')[0];
    if (url.split('?')[1]) {
      url.split('?')[1].split('&').forEach((query) => {
        data.params[query.split('=')[0]] = query.split('=')[1];
      });
    }
    return data;
  }

  public replaceHistoryWithUrl(url) {
    if (this.checkUrlHasSameHost(url)) {
      console.warn('Replace History', url);
      try {
        window.history.replaceState(null, null, url);
      } catch (err) {
        console.warn(err);
      }
    }
  }

  public checkUrlHasSameHost(url) {
    const a = document.createElement('a');
    a.href = url;
    return (a.host === window.location.host);
  }

  private _initMainRouterEventListener() {
    this.activeRoute().subscribe(
      (route: ActivatedRouteSnapshot) => {
        this._checkIfIsFrontPage(route);
        this._siteConfigService.initSettings();

        this.activatedRouteSnapshot = route;
      });

    this._router.events
      .pipe(filter(() => this._checkIfPrimaryOutlet()))
      .pipe(filter((event) => (event instanceof NavigationStart) || (event instanceof NavigationEnd)))
      .subscribe((event: any) => {
        if (this._utilsService.appType() !== 'admin') {
          this._sendToAnalytics(event);
        }

        if (!(event instanceof NavigationStart)) {
          return;
        }

        const url = event.url;
        if (this.urlHasModalParam(url) || this.urlHasLanguageParam(url)) {
          return;
        }

        this.redirectToLanguageParameterizedUrl(url);
      });
  }

  private redirectToLanguageParameterizedUrl(url: string, skipLocationChange: boolean = true) {
    const defaultLang = Cookie.get('django_language') || this._languageService.locales[0];

    let redirectPath = '/' + defaultLang + url;
    let queryParamsStringified = '';
    let queryParams = {};

    const redirectPathParts = redirectPath.split('?');
    try {
      if (redirectPathParts.length > 1 && redirectPathParts[1].length > 0) {
        queryParamsStringified = '{"' + decodeURI(redirectPathParts[1].replace(/&/g, '","').replace(/=/g, '":"')) + '"}';
        queryParams = JSON.parse(queryParamsStringified);
        redirectPath = redirectPathParts[0];
      }
    } catch (e) {
      console.warn(e);
    }

    this._routeMetaService.addRedirectTags(redirectPath);

    // Redirect to correct route without changing the state, which we will update manually later on successful navigation.
    // Skipping location change because there could be additional redirects apart from this.
    this._router.navigate([redirectPath], {queryParams, skipLocationChange}).then(() => {
      // Once router navigated successfully- substitute the old state which didn't contain language parameter
      this._location.replaceState(this._router.routerState.snapshot.url, '', '', '');
    });
  }

  private _sendToAnalytics(event) {
    if (typeof ga === 'function' && event.urlAfterRedirects) {
      /* ga('set', 'page', event.urlAfterRedirects);
       ga('send', 'pageview'); */
    }
  }

  private _toggleNavigationState(event: NavigationStart | NavigationEnd) {
    const navigationLog = [];

    this.state = null;

    if (event instanceof NavigationStart) {
      navigationLog.push('NavigationStart');

      setTimeout(() => {
        navigationLog.push('NavigationState');
        // navigationLog.push(this.state);
        if (this.state == null) {
          this.state = NavigationState.Pending;
          this.pending = true;

          this.events.next(this.state);

          setTimeout(() => {
            if (this.pending === true) {
              this.state = NavigationState.Done;
              this.pending = false;

              throw (navigationLog);
            }
          }, 3000);
        }
      }, 100);
    }

    if (event instanceof NavigationEnd) {
      navigationLog.push('NavigationEnd');
      this.state = NavigationState.Done;
      this.pending = false;

      this.events.next(this.state);

      // scroll to top if route is not modal
      if (this._router.url.indexOf('(modal:') < 0) {
        if (!this.isModal) {
          window.scroll(0, 0);
        } else {
          this.isModal = false;
        }
      } else {
        this.isModal = true;
      }

      // scroll to element if anchor fragment is present
      const tree = this._router.parseUrl(this._router.url);
      if (tree.fragment) {
        const element: any = document.querySelector(tree.fragment);
        if (element) {
          element.scrollIntoView(element);
        }
      }
    }
  }

  private _checkIfPrimaryOutlet(): boolean {
    let route = this._activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }

    return route.outlet === 'primary';
  }

  private _checkIfIsFrontPage(route: ActivatedRouteSnapshot): void {
    this.isFront = route.data.name === 'home';
  }

}
