import { Component, OnInit } from '@angular/core';
import { ActivatedRoute }    from '@angular/router';
import { B2bUserType }       from '@common/auth/enums/b2b-user-type.enum';
import { AuthService }       from '@common/auth/services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public isFreelancer = window.location.href.indexOf('user') > -1;
  public isAssessment = window.location.href.indexOf('freelancers') === -1 &&
    window.location.href.indexOf('business') === -1 && !this.isFreelancer;

  public data: any = {};
  public showSidebar = false;

  constructor(
    public authService: AuthService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.data.type = B2bUserType[B2bUserType.assessment];
    this.activatedRoute.data.subscribe((data) => {
      if (data.type) {
        this.data.type = data.type;
      }
    });
    this.showSidebar = this.authService.isAuthenticatedB2C || this.authService.isAuthenticatedB2B;
  }

}
