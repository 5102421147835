import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Params, Router }           from '@angular/router';
import { AuthService }                              from '@common/auth/services/auth.service';
import { UserRoutesService }                        from '@common/auth/services/user-routes.service';
import { LanguageService }                          from '@services/i18n.service';
import { MaterialAlertService }                     from '@services/material-alert.service';
import { ModalAlertService }                        from '@services/modal-alert.service';
import { NavigationService }                        from '@services/navigation.service';
import { PlatformsAccessService }                   from '@services/platforms-access.service';
import { RouteMeta, RouteMetaService }              from '@services/route-meta.service';

@Component({
  selector: 'app-route-reset-password-confirm',
  templateUrl: './route-reset-password-confirm.component.html',
  styleUrls: ['./route-reset-password-confirm.component.scss']
})
export class RouteResetPasswordConfirmComponent implements OnInit {

  public uid: string;
  public token: string;

  public redirectTo = '';
  public queryParams: Params;
  public projectIdForRedirect: number;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public modalAlertService: ModalAlertService,
    public navigationService: NavigationService,
    public languageService: LanguageService,
    public authService: AuthService,
    public routeMetaService: RouteMetaService,
    public element: ElementRef,
    public renderer: Renderer2,
    public platformsAccessService: PlatformsAccessService,
    public materialAlert: MaterialAlertService,
    public userRoutesService: UserRoutesService
  ) {
  }

  public ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams.project_id) {
      this.projectIdForRedirect = this.activatedRoute.snapshot.queryParams.project_id;
      this.router.navigate([], {
        queryParams: {
          source: 'project',
          type: 'company'
        }
      });
    }
    this.uid = this.activatedRoute.snapshot.params.uid;
    this.token = this.activatedRoute.snapshot.params.token;
    this.redirectTo = this.activatedRoute.snapshot.queryParams.redirectTo;
    this.queryParams = this.activatedRoute.snapshot.queryParams;

    this.activatedRoute.data.subscribe((data: any) => {
      this._setRouteMeta();
    });

    this._loadOverrideStyle();
  }

  public async onFormResetPasswordSuccess(event: any) {
    this.authService.refreshUserState();
    await this.materialAlert.open({
      buttons: [{
        type: 'accept',
        text: 'OK',
        response: true
      }],
      title: 'PAGE_RESET_PASSWORD_CONFIRM_ALERT_TITLE',
      content: 'PAGE_RESET_PASSWORD_CONFIRM_ALERT_MESSAGE',
      closeAfter: false
    }, {blur: false});

    this.authService.authTokenB2B = event.key;
    this.userRoutesService.handleB2BLogin();
  }

  private _setRouteMeta() {
    this.routeMetaService.getRouteMeta('password-reset-confirm/:uid/:token')
      .subscribe((routeMeta: RouteMeta) => {
        this.routeMetaService.setRouteMeta(routeMeta);
      });
  }

  private _loadOverrideStyle() {
    const overrideStyle = require('./route-reset-password-confirm.component.override.scss');
    const styleElement = this.renderer.createElement('style');
    styleElement.innerHTML = overrideStyle;

    this.renderer.appendChild(this.element.nativeElement, styleElement);
  }

}
