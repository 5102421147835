<div class="color-overlay">
  <div #mainWrapper [class.is-loading]="isLoading" class="main-wrapper">
    <div class="logo">
      <a
        (click)="goToHomePage()"
        [attr.title]="'GLOBAL_LABEL_BRANDING_PENTALOG' | translate">
        <img
          [attr.alt]="siteConfigService?.settings ? siteConfigService?.settings[utilsService.appType()]?.website.name : ''"
          class="logo"
          src="/assets/img/logos/pentalog.svg"
        />
      </a>
    </div>
    <div
      class="signup-content">
<!--      <h5 *ngIf="!data.access_type">{{ 'SIGNUP_MESSAGE_01' | translate }}</h5>-->
<!--      <div-->
<!--        *ngIf="!data.access_type"-->
<!--        class="radio-buttons">-->
<!--        <a-->
<!--          [class.selected]="isB2C"-->
<!--          [queryParams]="{ type: B2cUserType[B2cUserType.coder] }"-->
<!--          [routerLink]="routeWithRedirectB2C('/auth/signup') | localize"-->
<!--          data-id="coder-account-btn"-->
<!--          class="radio-element"-->
<!--          queryParamsHandling="merge">-->
<!--          <mat-icon *ngIf="!isB2C">blur_circular</mat-icon>-->
<!--          <mat-icon *ngIf="isB2C">check_circle_outline</mat-icon>-->
<!--          <span class="large">{{ 'SIGNUP_I_AM_A_CODER' | translate }},</span>-->
<!--          <span class="small">{{ 'SIGNUP_ASSESS_MYSELF' | translate }}</span>-->
<!--        </a>-->
<!--        <a-->
<!--          [class.selected]="isB2F"-->
<!--          [queryParams]="{ type: B2cUserType[B2cUserType.freelancer] }"-->
<!--          [routerLink]="routeWithRedirectB2C('/auth/signup') | localize"-->
<!--          data-id="freelancer-account-btn"-->
<!--          class="radio-element"-->
<!--          queryParamsHandling="merge">-->
<!--          <mat-icon *ngIf="!isB2F">blur_circular</mat-icon>-->
<!--          <mat-icon *ngIf="isB2F">check_circle_outline</mat-icon>-->
<!--          <span class="large">{{ 'SIGNUP_I_AM_A_FREELANCER' | translate }},</span>-->
<!--          <span class="small">{{ 'SIGNUP_FREELANCER' | translate }}</span>-->
<!--        </a>-->
<!--        <a-->
<!--          *ngIf="!noB2BSignup"-->
<!--          [class.selected]="isB2B"-->
<!--          [routerLink]="routeWithRedirect('/auth/business/signup') | localize"-->
<!--          data-id="business-account-btn"-->
<!--          class="radio-element">-->
<!--          <mat-icon *ngIf="!isB2B">blur_circular</mat-icon>-->
<!--          <mat-icon *ngIf="isB2B">check_circle_outline</mat-icon>-->
<!--          <span class="large">{{ 'SIGNUP_I_AM_A_COMPANY' | translate }},</span>-->
<!--          <span class="small">{{ 'SIGNUP_ASSESS_CANDIDATES' | translate }}</span>-->
<!--        </a>-->
<!--      </div>-->
      <div *ngIf="hasChosenType || !noB2BSignup" class="signup-with-content">
        <h6
          *ngIf="utilsService.appType() === 'b2c'">
          {{ 'SIGNUP_TEXT_HEADER' | translate }}
        </h6>
        <h6
          *ngIf="utilsService.appType() === 'b2b'">
          {{ 'SIGNUP_TEXT_HEADER_B2B' | translate }}
        </h6>
<!--        <app-social-auth-buttons-->
<!--          (signInAttempt)="onSocialSignInAttempt($event)"-->
<!--          (signInSuccess)="onSocialSignUpSuccess()"-->
<!--          [appendConnectParams]="true"-->
<!--          [params]="buildSocialAuthSignupApiParams"-->
<!--          [validateAttempts]="true">-->
<!--        </app-social-auth-buttons>-->
        <div
          class="signup-form">
          <form
            (ngSubmit)="createAccount($event)"
            [formGroup]="SignUpFormGroup"
            class="sign-up-form"
            novalidate>
            <div class="md-dialog-content">
              <div class="modal-cards-container">
                <div class="modal-card">
                  <div
                    *ngIf="genericErrorMessages"
                    class="generic-error">
                    {{ genericErrorMessages }}
                  </div>
                  <div *ngIf="isB2B" class="form-group inline-controls">
                    <div class="inline-controls">
                      <div class="form-control-text">
                        <span class="input-with-icon">
                          <mat-icon>account_box</mat-icon>
                          <input
                            [attr.placeholder]="'GLOBAL_LABEL_FIRST_NAME' | translate"
                            [minlength]="2"
                            class="form-control"
                            formControlName="first_name"
                            name="first_name"
                            required
                            type="text"
                          >
                        </span>
                        <div
                          *ngIf="first_name.errors && first_name.touched"
                          class="alert alert-danger">
                          <span
                            *ngIf="first_name.errors.required"
                            class="form-control-error">
                            {{ 'GLOBAL_ERROR_FIRST_NAME_REQUIRED' | translate }}
                          </span>
                          <span
                            *ngIf="first_name.errors.minlength"
                            class="form-control-error">
                            {{ 'GLOBAL_ERROR_FIELD_MIN_LENGTH' | translate: {minlength: 2} }}
                          </span>
                          <span
                            *ngIf="first_name.errors.invalid"
                            class="form-control-error">
                            {{ first_name.errors.invalid }}
                          </span>
                        </div>
                      </div>
                      <div class="form-control-text">
                        <span class="input-with-icon">
                          <mat-icon>account_box</mat-icon>
                          <input
                            [attr.placeholder]="'GLOBAL_LABEL_LAST_NAME' | translate"
                            [minlength]="2"
                            class="form-control"
                            formControlName="last_name"
                            name="last_name"
                            required
                            type="text"
                          >
                        </span>
                        <div
                          *ngIf="last_name.errors && last_name.touched"
                          class="alert alert-danger">
                          <span
                            *ngIf="last_name.errors.required"
                            class="form-control-error">
                            {{ 'GLOBAL_ERROR_LAST_NAME_REQUIRED' | translate }}
                          </span>
                          <span
                            *ngIf="last_name.errors.minlength"
                            class="form-control-error">
                            {{ 'GLOBAL_ERROR_FIELD_MIN_LENGTH' | translate: {minlength: 2} }}
                          </span>
                          <span
                            *ngIf="last_name.errors.invalid"
                            class="form-control-error">
                            {{ last_name.errors.invalid }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <!--                    <input-validations [form]="SignUpFormGroup" name="first_name" localePrefix="profile"></input-validations>-->
                    <!--                    <input-validations [form]="SignUpFormGroup" name="last_name" localePrefix="profile"></input-validations>-->
                  </div>
                  <div class="form-group">
                    <div class="form-control-text">
                      <span class="input-with-icon">
                        <mat-icon>email</mat-icon>
                        <input
                          [attr.placeholder]="'GLOBAL_LABEL_EMAIL' | translate"
                          appValidatorEmail
                          class="form-control"
                          formControlName="email"
                          name="email"
                          data-id="signup-email"
                          required
                          type="email"
                        >
                      </span>
                    </div>
                    <div
                      *ngIf="email.errors && email.touched"
                      class="alert alert-danger">
                      <span
                        *ngIf="email.errors.required"
                        class="form-control-error">
                        {{ 'GLOBAL_ERROR_EMAIL_REQUIRED' | translate }}
                      </span>
                      <span
                        *ngIf="email.errors.validEmail"
                        class="form-control-error">
                        {{ 'GLOBAL_ERROR_EMAIL_INVALID' | translate }}
                      </span>
                      <span
                        *ngIf="email.errors.duplicate"
                        class="form-control-error">
                        {{ email.errors.duplicate }}
                      </span>
                    </div>
                  </div>
                  <div
                    class="form-group">
                    <div class="form-control-text">
                      <span class="input-with-icon">
                        <mat-icon>lock</mat-icon>
                        <input
                          [attr.placeholder]="'GLOBAL_LABEL_PASSWORD' | translate"
                          class="form-control"
                          formControlName="password1"
                          minlength="8"
                          data-id="signup-password"
                          required reverse="true"
                          type="password"
                        >
                      </span>
                    </div>
                    <div
                      *ngIf="password1.errors && password1.touched"
                      class="alert alert-danger">
                      <span
                        *ngIf="password1.errors.required"
                        class="form-control-error">
                          {{ 'GLOBAL_ERROR_PASSWORD_REQUIRED' | translate }}
                      </span>
                      <span
                        *ngIf="password1.errors.minlength"
                        class="form-control-error">
                          {{ 'GLOBAL_ERROR_FIELD_MIN_LENGTH' | translate: {minlength: 8} }}
                      </span>
                      <span
                        *ngIf="(password1.errors.strong || password1.errors.minlength) && !password1.errors.required"
                        class="form-control-error">
                            {{ 'GLOBAL_ERROR_PASSWORD_VALIDATOR' | translate }}
                      </span>
                      <span
                        *ngIf="password1.errors.invalid"
                        class="form-control-error">
                          <span
                            *ngIf="password1.errors.invalid.isCommonText">
                            {{ password1.errors.invalid.isCommonText }}
                          </span>
                          <span
                            *ngIf="password1.errors.invalid.isNumericText">
                              {{ password1.errors.invalid.isNumericText }}
                          </span>
                      </span>
                    </div>
                  </div>
                  <div
                    *ngIf="!noB2BSignup"
                    class="form-group">
                    <div
                      class="form-control-text">
                      <div
                        class="form-control-text">
                        <span
                          class="input-with-icon">
                          <mat-icon>business</mat-icon>
                          <input
                            [attr.placeholder]="'GLOBAL_LABEL_COMPANY' | translate"
                            class="form-control"
                            formControlName="company_name"
                            name="company_name"
                            pattern="^[^<_>%$]*$"
                            required
                            type="text"
                          >
                        </span>
                      </div>
                    </div>
                    <div
                      *ngIf="company_name.errors && company_name.touched"
                      class="alert alert-danger">
                      <span
                        *ngIf="company_name.errors.required"
                        class="form-control-error">
                        {{ 'GLOBAL_ERROR_COMPANY_REQUIRED' | translate }}
                      </span>
                      <span
                        *ngIf="company_name.errors.pattern"
                        class="form-control-error">
                        "{{company_name.errors.pattern.actualValue}}" -
                        {{ 'GLOBAL_ERROR_COMPANY_NAME_INVALID_CHARACTERS' | translate }}
                      </span>
                    </div>
                  </div>
                  <div
                    *ngIf="!noB2BSignup"
                    class="form-group">
                    <div
                      class="form-control-text">
                      <span class="input-with-icon">
                        <mat-icon>phone</mat-icon>
                        <input
                          [attr.placeholder]="'GLOBAL_LABEL_PHONE_NUMBER' | translate"
                          class="form-control"
                          formControlName="phone" minlength="10"
                          name="phone"
                          pattern="^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$"
                          required
                          type="text"
                        >
                      </span>
                    </div>
                    <div
                      *ngIf="phone.errors && phone.touched"
                      class="alert alert-danger">
                      <span
                        *ngIf="phone.errors.pattern"
                        class="form-control-error">
                        {{ 'GLOBAL_ERROR_PHONE_INVALID' | translate }} <br/>
                      </span>
                      <span
                        *ngIf="phone.errors.required"
                        class="form-control-error">
                        {{ 'GLOBAL_ERROR_PHONE_NUMBER_REQUIRED' | translate }}
                      </span>
                      <span
                        *ngIf="phone.errors.minlength"
                        class="form-control-error">
                        {{ 'GLOBAL_ERROR_FIELD_MIN_LENGTH' | translate: {minlength: 10} }}
                      </span>
                    </div>
                  </div>
<!--                  <div *ngIf="!data.access_type && isB2B" class="form-group">-->
<!--                    <div class="form-control-select">-->
<!--                      <select-->
<!--                        (ngModelChange)="onAccessTypeChange($event)"-->
<!--                        [ngModel]="selectedApplicationTypeOption"-->
<!--                        class="form-control"-->
<!--                        formControlName="type"-->
<!--                        required>-->
<!--                        <option-->
<!--                          *ngFor="let type of applicationTypeOptions.b2bTypes"-->
<!--                          [ngValue]="type"-->
<!--                          [selected]="type.val === selectedApplicationTypeOption.val">-->
<!--                          {{ type.title | translate }}-->
<!--                        </option>-->
<!--                      </select>-->
<!--                    </div>-->
<!--                    <div-->
<!--                      *ngIf="typeRequired"-->
<!--                      class="alert alert-danger">-->
<!--                      <span-->
<!--                        class="form-control-error">-->
<!--                        {{ 'GLOBAL_ERROR_TYPE_REQUIRED' | translate }}-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
              </div>
            </div>
            <div class="md-dialog-actions">

              <div class="modal-actions">

                <div class="modal-card">
                  <!-- Privacy Policy -->
                  <div class="form-group">
                    <div
                      [class.invalid]="privacy_policy?.errors && checkPrivacyPolicy"
                      class="form-control-checkbox no-margin animated-error">
                      <input
                        formControlName="privacy_policy"
                        id="privacy_policy"
                        required
                        type="checkbox">
                      <label
                        checkbox
                        for="privacy_policy">
                        <span
                          [innerHTML]="'SIGNUP_FORM_LABEL_PRIVACY_P5_FREELANCERS' | translate">
                        </span>
                      </label>
                    </div>
                    <div
                      *ngIf="privacy_policy?.errors && checkPrivacyPolicy"
                      [class.invalid]="privacy_policy?.errors && checkPrivacyPolicy"
                      class="alert alert-danger no-absolute terms animated-error">
                        <span
                          *ngIf="privacy_policy.errors.required"
                          class="form-control-error">
                          {{ 'GLOBAL_ERROR_PRIVACY' | translate }}
                        </span>
                    </div>
                  </div>
                  <!-- Terms -->
                  <div class="form-group">
                    <div
                      [class.invalid]="terms?.errors && checkTermsAndConditions"
                      class="form-control-checkbox no-margin animated-error">
                      <input
                        formControlName="terms"
                        id="terms"
                        required
                        type="checkbox">
                      <label
                        checkbox
                        for="terms">
                        <span
                          *ngIf="isB2C"
                          [innerHTML]="'SIGNUP_FORM_LABEL_TERMS_AND_COND' | translate">
                        </span>
                        <span
                          *ngIf="isB2F"
                          [innerHTML]="'SIGNUP_FORM_LABEL_TERMS_AND_COND_FREELANCE' | translate">
                        </span>
                        <span
                          *ngIf="isB2B"
                          [innerHTML]="'SIGNUP_FORM_LABEL_TERMS_AND_COND_B2B' | translate">
                        </span>
                      </label>
                    </div>
                    <div
                      *ngIf="terms?.errors && checkTermsAndConditions"
                      [class.invalid]="terms?.errors && checkTermsAndConditions"
                      class="alert alert-danger no-absolute terms animated-error">
                        <span
                          *ngIf="terms.errors.required"
                          class="form-control-error">
                          {{ 'GLOBAL_ERROR_TERMS' | translate }}
                        </span>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-control-checkbox no-margin">
                      <input formControlName="newsletter" id="use-personal-data" type="checkbox">
                      <label checkbox for="use-personal-data">
                        <span [innerHTML]="'SIGNUP_FORM_LABEL_USE_PERSONAL_DATA_PENTA' | translate"></span>
                      </label>
                    </div>
                  </div>

                </div>

                <div class="modal-card">
                  <button
                    [class.ng-pending]="sendingForm"
                    [disabled]="sendingForm"
                    class="btn btn-submit"
                    type="submit">
                    {{ 'GLOBAL_CREATE_THE_ACCOUNT' | translate }}
                    <mat-icon>done</mat-icon>
                  </button>
                  <p
                    class="go-to-login">
                    <a [routerLink]="'/auth/login' | localize"
                       queryParamsHandling="preserve">
                      {{ 'SIGNUP_ALREADY_HAVE_AN_ACCOUNT' | translate }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
  <div class="clearfix"></div>
</div>
