import { HttpClient, HttpHandler, HttpParams } from '@angular/common/http';
import { Injectable }                          from '@angular/core';
// import { RequestOptions } from 'http';
import { Params }                              from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class HttpService extends HttpClient {
  static window: any;

  constructor(
    handler: HttpHandler,
  ) {
    super(handler);
  }

  public resolveRequestOptionsSearch(
    queryParams: Params
  ) {
    let requestOptions = new HttpParams({fromString: ''});
    Object.keys(queryParams).forEach((key) => {
      requestOptions = requestOptions.append(key, queryParams[key]);
    });
    return requestOptions;
  }
}
