import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ApiEndpoints} from '@app/app.constants';
import * as _emojiRegex from 'emoji-regex';
import {Observable, of} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';

export interface HandleStorage {
  get: () => any;
  add: (data: any) => any;
  remove: () => any;
}

export enum FreelancersFilterTextTypes {
  profile = 'profile',
  competence = 'competence',
  city = 'city',
  country = 'country'
}

export enum EndpointType {
  b2b = 0,
  b2c
}

export enum JobStatuses {
  new,
  proposed_by_sv,
  accepted_by_candidate,
  canceled_by_candidate,
  accepted_by_candidate_pending_validation,
  accepted_by_client,
  rejected_by_client,
  accepted_by_both,
  contract_signed_by_candidate,
  contract_signed_by_recruiter,
  job_canceled,
  interview_planned,
  hired,
}

export enum B2CHumanlyReadableStatus {
  proposed_by_sv = 'B2C_MISSION_STATUS_PROPOSED_BY_SV',
  accepted_by_candidate = 'B2C_MISSION_STATUS_ACCEPTED_BY_CANDIDATE',
  canceled_by_candidate = 'B2C_MISSION_STATUS_REJECTED_BY_CANDIDATE',
  accepted_by_client = 'B2C_MISSION_STATUS_ACCEPTED_BY_CLIENT',
  accepted_by_candidate_pending_validation = 'B2C_MISSION_STATUS_ACCEPTED_BY_CANDIDATE',
  rejected_by_client = 'B2C_MISSION_STATUS_REJECTED_BY_CLIENT',
  accepted_by_both = 'B2C_MISSION_STATUS_ACCEPTED_BY_BOTH',
  contract_signed_by_candidate = 'B2C_MISSION_STATUS_SIGNED_BY_CANDIDATE',
  contract_signed_by_recruiter = 'B2C_MISSION_STATUS_SIGNED_BY_RECRUITER',
  job_canceled = 'B2C_MISSION_STATUS_JOB_CANCELED',
  interview_planned = 'B2C_MISSION_STATUS_JOB_INTERVIEW_PLANNED',
}

const emojiRegex = _emojiRegex;

@Injectable({
  providedIn: 'root'
})
export class UtilsService {


  // @ts-ignore
  public emojiValidator: RegExp = new RegExp(emojiRegex());
  public highlightDomain = false;
  public highlightTechnology = false;

  public stringsToBeHighlighted: string[] = [];

  public URLS = {
    job: 'job/apply/wp'
  };

  public charCodes = {
    uppercaseA: 65,
    uppercaseZ: 90,
    lowercaseA: 97,
    lowercaseZ: 122,
    number0: 48,
    number9: 57,
    unitSeparator: 31,
  };

  /**
   * Alternative: URLSearchParams - but not supported by any version of ie
   * @param name - param from url like "code"
   * @returns // {string}
   */
  public validators = {
    textWithNumbers: new RegExp(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/),
    anythingButCode: new RegExp(/^[^=<>:]*$/),
    text: new RegExp(/^[a-zA-Z ]*$/),
    files: '.png,.jpeg,.jpg,.pdf,.gif',
    // tslint:disable:max-line-length
    email: new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
    phone: new RegExp([
      /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})/,
      /(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/
    ].map((r: any) => r.source).join('')),
    phoneMinlength: 10,
    numbers: new RegExp(/^[0-9]+$/),
    linkedIn: new RegExp(/^(http(s)?:\/\/)?(www\.)?(linkedin\.com\/).*$/),
    // cv_accept: this.userCvService.validators.accept,
    cv_accept: '.rtf,.docx,.pdf',
    avatar: {
      accept: '.jpg,.png,.jpeg',
      pattern: /.jpg|.png|.jpeg/g
    }
  };

  public defaultConfigDate = {
    day: 15,
  };

  public socialSignupBodyParams: any = {};

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public matDialog: MatDialog,
    public http: HttpClient
  ) {

  }

  public isB2BRecruiters(href = window.location.href) {
    return href.indexOf('freelancers') !== -1 && href.indexOf('business') !== -1;
  }

  activeRoute() {
    const filterNavigationEnd = tap((event) => event instanceof NavigationEnd);
    const activatedRouteObj = tap(() => this.activatedRoute);
    const firstChild = map((route: ActivatedRoute) => {
      while (route.firstChild) {
        route = route.firstChild;
      }
      return route;
    });
    const filterPrimaryRoute = filter((route: ActivatedRoute) => route.outlet === 'primary');
    const mapSnapshot = map((route: ActivatedRoute) => route.snapshot);
    return this.router.events.pipe(
      filterNavigationEnd,
      activatedRouteObj,
      firstChild,
      filterPrimaryRoute,
      mapSnapshot
    );
  }

  public getUrlParameter(urlSearch: string, name: string): string {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(urlSearch);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  getFileExtension(filename) {
    return filename.split('.').pop();
  }

  /**
   * @param url -> main url
   * @param params -> query params
   */

  public createUrl(url: string, params: object): string {
    const parts = [];
    Object.keys(params).map((param) => {
      parts.push(encodeURIComponent(param) + '=' + encodeURIComponent(params[param]));
    });
    return url + '?' + parts.join('&');
  }

  public getWebSiteBaseUrl(): string {
    const fullUrl = window.location;
    return fullUrl.protocol + '//' + fullUrl.host + '/';
  }

  public getQueryParamsStrippedUrl(url: string): string {
    const urlParts = url.split('?');
    if (urlParts.length > 1 && urlParts[1].length > 0) {
      return urlParts[0];
    }
    return url;
  }

  public validateEmail(email: string): boolean {

    const EMAIL_REGEXP = new RegExp(
      ['^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)',
        '|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])',
        '|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'].join('')
    );
    return EMAIL_REGEXP.test(email);
  }

  public appType(pathname = window.location.pathname) {
    const urlSegments = pathname.substr(1).split('/');
    const isB2b = urlSegments.filter((item) => {
      return (item === 'business' || item === 'app');
    }).length;
    const isAdmin = urlSegments.filter((item) => {
      return item === 'biz';
    }).length;

    if (isB2b) {
      return 'b2b';
    } else if (isAdmin) {
      return 'admin';
    }

    return 'b2c';
  }

  public hasAccessToRoute(type, url) {
    switch (type) {
      case 'b2c':
        return this.appTypeFromUrl(url) === 'b2c' || !url;
      case 'b2b':
        return this.appTypeFromUrl(url) === 'b2b' || !url;
    }
  }

  public appTypeFromUrl(url: string = window.location.pathname) {
    const urlSegments = url.substr(1).split('/');
    const isB2b = urlSegments.filter((item) => {
      return (item === 'business' || item === 'app');
    }).length;
    if (isB2b) {
      return 'b2b';
    }
    return 'b2c';
  }

  public getQueryParams(search = window.location.search): HttpParams {

    const searchQuery = search.charAt(0) === '?' ? search.slice(1) : search;
    return new HttpParams({
      fromString: searchQuery
    });
  }

  public getQueryParamsAsObject(str = this.getQueryParams().toString()): {} {
    const obj = {};

    const segments = str.split('&');
    segments.forEach((segment) => {
      const part = segment.split('=');
      obj[part[0]] = part[1];
    });

    return obj;
  }

  public createQueryParams(queryParams): HttpParams {
    const searchParams = new HttpParams({fromObject: queryParams});
    return searchParams;
  }

  public getQueryParam(key, search = window.location.search) {
    const params = this.getQueryParams(search);
    return params.get(key);
  }

  public replaceQueryString(queryParams: HttpParams): void {
    const queryParamsString = '?' + queryParams.toString();
    let params;
    if (window.location.search !== queryParamsString) {
      params = queryParams.toString().charAt(0) === '?'
        ? queryParams.toString()
        : '?' + queryParams.toString();
      window.history.replaceState({url: window.location.pathname}, '',
        window.location.pathname + params);
    }
  }

  public scrollToElement(element, offset: number = 150) {
    const position = element.getBoundingClientRect();
    return window.scrollTo({
      top: position.top + window.scrollY - offset,
      behavior: 'smooth'
    });
  }

  public setSocialSignupBodyParam(param, value) {
    this.socialSignupBodyParams[param] = value;
  }

  public deleteSocialSignupBodyParams() {
    this.socialSignupBodyParams = {};
  }

  public protectFromXSS(text) {
    return typeof text === 'string'
      ? text
        .replace(/\&/g, '&amp;')
        .replace(/\</g, '&lt;')
        .replace(/\>/g, '&gt;')
        .replace(/\"/g, '&quot;')
        .replace(/\'/g, '&apos;')
      : text;
  }

  public convertTagsReverse(content): string {
    return content.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  }

  public localStorage(key: string): HandleStorage {
    return {
      get: () => this._getFromLocalStorage(key),
      add: (data) => this._addToLocalStorage(key, data),
      remove: () => this._removeFromLocalStorage(key)
    };
  }

  public sessionStorage(key: string): HandleStorage {
    return {
      get: () => this._getFromSessionStorage(key),
      add: (data) => this._addToSessionStorage(key, data),
      remove: () => this._removeFromSessionStorage(key)
    };
  }

  public randomDataSet(dataSetSize, minValue, maxValue) {
    return new Array(dataSetSize).fill(0).map((n) => {
      return Math.random() * (maxValue - minValue) + minValue;
    });
  }

  public extractCompetencesSearchStrings(items: any[]) {
    this.stringsToBeHighlighted = items.map((item) => item.name);
  }

  public getEndpoint(url: string, type: string = 'b2c') {
    const stringToSearch = 'api';
    const stringToSearchIndex = url.indexOf(stringToSearch);
    const stringToSearchLastIndex = stringToSearchIndex + stringToSearch.length;
    const isB2BRequest = type === 'b2b' && stringToSearchIndex > -1;
    const firstFragment = url.slice(0, stringToSearchLastIndex);
    const lastFragment = url.slice(stringToSearchLastIndex);
    const dynamicFragment = isB2BRequest ? '/b2b' : '';

    return firstFragment + dynamicFragment + lastFragment;
  }

  public serialize(params): string {
    const finalString = [];
    for (const p in params) {
      if (params.hasOwnProperty(p)) {
        finalString.push(encodeURIComponent(p) + '=' + encodeURIComponent(params[p]));
      }
    }
    return finalString.join('&');
  }

  public parseUrlQueryParameters(url: string = ''): any {
    const urlParts = url.split('?');
    let urlParams = {};

    try {
      if (urlParts.length > 1 && urlParts[1].length > 0) {
        const sanitizedQueryPart = urlParts[1].replace(/&/g, '","').replace(/=/g, '":"');
        const decodedQueryParamsJSON = '{"' + decodeURI(sanitizedQueryPart) + '"}';
        urlParams = JSON.parse(decodedQueryParamsJSON);
      }
    } catch (e) {
      console.warn(e);
    }

    return urlParams;
  }

  public getAzureAutocompleteCompetencesTagsName(queryParams): number[] {
    const names = [];
    if (queryParams.competences_tag_names) {
      const competenceList = queryParams.competences_tag_names.split(',');
      if (competenceList && competenceList.length > 0) {
        competenceList.forEach((str) => {
          const objFromStr = this.parseAzureAutocompleteParam(str, FreelancersFilterTextTypes.competence);
          names.push(objFromStr.text);
        });
      }
    }
    return names;
  }

  public parseAzureAutocompleteParam(queryParamStringified: string, type: FreelancersFilterTextTypes) {
    const destructuredParam = queryParamStringified.split('$');
    return {
      type,
      id: decodeURIComponent(destructuredParam[0]),
      text: decodeURIComponent(destructuredParam[1]),
    };
  }

  public getCountries() {
    return this.http.get(ApiEndpoints.common.countries)
      .pipe(map((response: CountryInfo[]) => response));
  }

  public getCities(countryId: number, searchParams: HttpParams = new HttpParams({fromString: ''})) {
    if (countryId) {
      if (countryId) {
        searchParams = searchParams.set('country', countryId.toString());
      }
      return this.http.get(ApiEndpoints.common.cities, {params: searchParams})
        .pipe(map((response: CityInfo[]) => response));
    } else {
      return of([]);
    }
  }

  public getLocationAutocompleteSuggestions(locationPart, queryParams: any = {}) {

    if(locationPart !== 'all'){
      let query = '?query=' + locationPart;
      Object.keys(queryParams).forEach((key) => {
        query += `&${key}=${queryParams[key]}`;
      });
      return this.http.get(ApiEndpoints.common.locationAutocomplete + query)
        .pipe(map((response: CountryInfo[] & CityInfo[]) => response));
    } else {
      return this.http.get(ApiEndpoints.common.locationAutocomplete)
      .pipe(map((response: CountryInfo[] & CityInfo[]) => response));
    }
  }

  public getCountriesAutocomplete(locationPart, queryParams: any = {}) {
    let query = '?name__istartswith=' + locationPart;
    Object.keys(queryParams).forEach((key) => {
      query += `&${key}=${queryParams[key]}`;
    });

    return this.http.get(ApiEndpoints.common.countries + query)
      .pipe(map((response: any[]) => response));
  }

  public getJobTitlesForCompaniesAutoComplete(query) {
    const str = '?query=' + query;
    return this.http.get(ApiEndpoints.common.jobTitleB2BAutocomplete + str)
      .pipe(map((response: any[]) => response));
  }

  public getTechnologyAutocompleteSuggestions(technologyPart, queryParams: any = {}) {
    let query = '?query=' + encodeURIComponent(technologyPart);
    Object.keys(queryParams).forEach((key) => {
      query += `&${key}=${queryParams[key]}`;
    });

    if(technologyPart !== 'all'){
      return this.http.get(ApiEndpoints.experience.stackAutocomplete + query)
      .pipe(map((response: any[]) => response));
    } else {
      return this.http.get(ApiEndpoints.experience.stackAutocomplete)
      .pipe(map((response: any[]) => response));
    }


  }

  public resolveRequest(url: string, searchParams?: HttpParams): Observable<any> {
    return this.http.get(url, {params: searchParams})
      .pipe(map((response: any) => response));
  }

  // public checkUrlHasSameHost(url, host = window.location.host) {
  //   const a = document.createElement('a');
  //   a.href = url;
  //   return (a.hostname === host);
  // }

  public isDate(date) {
    const dateWrapper = new Date(date);
    return date ? !isNaN(dateWrapper.getDate()) : false;
  }

  public isNumber(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    const isFuncKeys = charCode > this.charCodes.unitSeparator;
    const isOperationKeys = charCode < this.charCodes.number0;
    const isSeparationKeys = charCode > this.charCodes.number9;
    const numberZeroToNine = isOperationKeys || isSeparationKeys;
    return !(isFuncKeys && (numberZeroToNine));
  }

  public isChar(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    const isFuncKeys = charCode > this.charCodes.unitSeparator;
    const isCharKeys = charCode < this.charCodes.uppercaseA;
    const otherThanLetterKeys = charCode > this.charCodes.uppercaseZ;
    const isNavigationAndLetterKeys = charCode < this.charCodes.lowercaseA;
    const otherThanLowercaseLetterKeys = charCode > this.charCodes.lowercaseZ;
    const uppercaseAtoZKeys = isCharKeys || otherThanLetterKeys;
    const lowercaseAtoZKeys = isNavigationAndLetterKeys || otherThanLowercaseLetterKeys;

    return !(isFuncKeys && (uppercaseAtoZKeys) && (lowercaseAtoZKeys));
  }

  public validateURL(url: string): boolean {
    const URL_REGEXP = new RegExp(
      ['^(http[s]?:\\/\\/){1}(www\\.){0,1}[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&=]*)?'].join('')
    );
    return URL_REGEXP.test(url);
  }

  public getTagsAutocomplete(query: string): Observable<any> {
    let searchParams: HttpParams = new HttpParams({fromString: ''});
    searchParams = searchParams.set('query', query);

    // return this.resolveRequest(ApiEndpoints.tagsAutocomplete, searchParams);
    return this.http.get(ApiEndpoints.tagsAutocomplete, {params: searchParams});
  }

  public getNotificationChoices(): Observable<any[]> {
    return this.resolveRequest(ApiEndpoints.notificationChoices, null);
  }

  public hasEmojis(input) {
    const reg = this.emojiValidator.exec(input);
    return reg != null && reg.length > 0 && !!reg[0];
  }

  public closeAllDialogs() {
    this.matDialog.closeAll();
  }

  private _removeFromLocalStorage(key: string): void {
    if (!key) {
      return;
    }
    localStorage.removeItem(key);
  }

  private _addToLocalStorage(key: string, data: any) {
    if (!key) {
      return;
    }
    if ((!!data) && (data.constructor === Object)) {
      data = JSON.stringify(data);
    }
    localStorage.setItem(key, data);
  }

  private _getFromLocalStorage(key: string): any {
    if (!key) {
      return;
    }
    const data = localStorage.getItem(key);
    try {
      return JSON.parse(data);
    } catch (error) {
      return data;
    }
  }

  private _removeFromSessionStorage(key: string): void {
    if (!key) {
      return;
    }
    sessionStorage.removeItem(key);
  }

  private _addToSessionStorage(key: string, data: any) {
    if (!key) {
      return;
    }
    if ((!!data) && (data.constructor === Object)) {
      data = JSON.stringify(data);
    }
    sessionStorage.setItem(key, data);
  }

  private _getFromSessionStorage(key: string): any {
    if (!key) {
      return;
    }
    const data = sessionStorage.getItem(key);
    try {
      return JSON.parse(data);
    } catch (error) {
      return data;
    }
  }

}

export interface StudiesItem {
  university: string;
  degree: string;
  start_date: string;
  end_date: string;
  _editMode?: boolean;
  id?: number;
  description?: string;
}

export interface ExperienceItem {
  position: string;
  company: string;
  start_date: string;
  end_date: string;
  description: string;
  competences: string[];
  _editMode?: boolean;
  id?: number;
}

export interface StackItemGroup {
  competence_family_id: number;
  competences: string[];
}

export interface StackFamilyItem {
  id: number;
  name: string;
}

export interface StackItem {
  id: number;
  name: string;
  text?: string;
  type?: string;
}

export interface UserStackItem {
  competence_family_id: number;
  competences: string[];
  competence_family: string;
}

export interface SuggestedTest {
  id: number;
  type: string;
  title: string;
  domain: string;
  domain_slug: string;
  quiz_slug: string;
  coding_language: any;
  finished_results_count: number;
  average_score: number[];
  _link: string;
}

export interface CountryInfo {
  id: number;
  name: string;
}

export interface CityInfo {
  id: number;
  name: string;
}

export enum TestTypeEnum {
  quiz,
  algo
}
