import { Component, OnInit }              from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService }                    from '@common/auth/services/auth.service';
import { FADE_OUT_ANIMATION }             from '@common/core/core.animations';
import { LanguageService }                from '@services/i18n.service';
import { UtilsService }                   from '@services/utils.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [FADE_OUT_ANIMATION]
})
export class AppComponent implements OnInit {
  title = 'SkillValue';
  routeName = '';
  appLoading = true;

  constructor(
    public authService: AuthService,
    public utils: UtilsService,
    public language: LanguageService,
    public router: Router
  ) {
  }

  async ngOnInit() {
    this.saveActivatedRoute();
    // await this._initUserData();
  }

  public onActivate() {
    this.appLoading = false;
  }

  private saveActivatedRoute() {
    this.utils.activeRoute()
      .subscribe((route: ActivatedRouteSnapshot) => {
        this.routeName = route.data.name;
      });
  }

  private async _initUserData() {
    const type = this.utils.appType();
    await this.authService.getUserDetails(type).toPromise();
  }
}
