import { Injectable }                    from '@angular/core';
import { environment }                   from '@app/app.constants';
import { AuthEndpoints }                 from '@common/auth/auth.constants';
import { EndpointService }               from '@services/endpoint.service';
import { HttpError, HttpErrorService }   from '@services/http-error.service';
import { HttpService }                   from '@services/http.service';
import { LanguageService }               from '@services/i18n.service';
import { LoaderService, LoaderState }    from '@services/loader-service.service';
import { MaterialAlertService }          from '@services/material-alert.service';
import { UtilsService }                  from '@services/utils.service';
import { Observable, of, throwError }    from 'rxjs';
import { catchError, finalize, flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LinkedinService {

  private redirectURI: string;

  constructor(
    protected _http: HttpService,
    protected _httpErrorService: HttpErrorService,
    protected _utils: UtilsService,
    protected _langService: LanguageService,
    protected _loaderService: LoaderService,
    protected _EndpointService: EndpointService,
    protected utilsService: UtilsService,
    protected _materialAlert: MaterialAlertService
  ) {
  }

  public signIn(params, justAuthorise?: boolean): Observable<any> {
    // console.log(params);
    return this.linkedinGetCodeWindow()
      .pipe(flatMap((code: string) => {
        if (!code) {
          return throwError(this._langService.getValue('SOCIAL_AUTHORIZATION_ERROR'));
        }

        const correctEndpoint = this._EndpointService.checkIfRouteIs('signup') ? AuthEndpoints.linkedinAuth : AuthEndpoints.linkedinLogin;
        const appType = this.utilsService.appType();
        const apiEndpointUserLogin = this._EndpointService.getEndpoint(correctEndpoint, appType);

        const body = {
          code,
          redirect_uri: this.redirectURI,
          return_login_cookie: !justAuthorise,
          ...this.utilsService.socialSignupBodyParams,
          ...params
        };
        // authRequestOptions.body = undefined;
        return this._http.post(apiEndpointUserLogin, body, {params});
      }))
      .pipe(catchError((error) => {
        if (typeof error === 'string') {
          return throwError({detail: error});
        }

        const httpError: HttpError = this._httpErrorService.parseError(error);
        if (httpError.status === 400) {
          httpError.detail = httpError.json.non_field_errors;

          this._loaderService.setState(LoaderState.Done);
          this._materialAlert.open({
            buttons: [{
              type: 'accept',
              text: 'OK',
              response: true
            }],
            title: 'GLOBAL_WARNING',
            content: httpError.detail ? httpError.detail[0] : 'LINKEDIN_ERROR_NON_EXISTING_ACCOUNT',
            closeAfter: false
          }, {blur: false});
        }

        return throwError(httpError);
      }))
      .pipe(finalize(() => this._loaderService.setState(LoaderState.Done)));
  }

  public authorise() {
    const requestOptions = {withCredentials: true};
    const authorise = true;
    return this.signIn(requestOptions, authorise);
  }

  private linkedinGetCodeWindow(): Observable<string> {
    this.redirectURI = this._utils.getWebSiteBaseUrl() + this._langService.currentLang + '/auth';
    const url = this._utils.createUrl('https://www.linkedin.com/oauth/v2/authorization', {
      response_type: 'code',
      client_id: environment.linkedin.client_id,
      redirect_uri: this.redirectURI,
      scope: 'r_liteprofile r_emailaddress',
      state: environment.linkedin.state
    });
    let timer = 0;
    const data = window.open(url, '', 'width=700,height=600');

    try {
      if (!data) {
        throwError('popup_not_opened');
      }
    } catch (e) {
      this._loaderService.setState(LoaderState.Done);
      this._materialAlert.open({
        buttons: [{
          type: 'accept',
          text: 'OK',
          response: true
        }],
        title: 'There was an error reaching LinkedIn',
        content: 'The LinkedIn window was blocked by the browser.',
        closeAfter: false
      }, {blur: false});
      console.warn(e, 'error');
      return of(null);
    }
    this._loaderService.setState(LoaderState.Pending);
    return new Observable((observer) => {
      const checkUrlInterval = setInterval(() => {
        timer += 100;
        if (data && data.closed) {
          observer.next('');
          observer.complete();
          clearInterval(checkUrlInterval);
        }
        try {
          const countdown = window.location.href.indexOf('skillvalue.com') ? 20000 : 40000;
          if (timer >= countdown) {
            throw new Error('timeout');
          }
          if (data.location.href !== 'about:blank' && data.location.href !== url) {
            const code = this._utils.getUrlParameter(data.location.search, 'code');
            const error = this._utils.getUrlParameter(data.location.search, 'error');
            data.onload = () => data.close();
            if (error) {
              observer.error(error);
            } else {
              data.close();
              observer.next(code);
            }
            observer.complete();
            clearInterval(checkUrlInterval);
          }
        } catch (e) {
          const corsError = !((e.message.indexOf('Blocked a frame with origin') === -1) &&
            (e.message.indexOf('Permission denied') === -1));

          if (!corsError) {
            data.close();
            clearInterval(checkUrlInterval);
            observer.error(e.message);
            observer.complete();
            this._loaderService.setState(LoaderState.Done);
            this._materialAlert.open({
              buttons: [{
                type: 'accept',
                text: 'OK',
                response: true
              }],
              title: 'GLOBAL_WARNING',
              content: 'GLOBAL_LINKEDIN_ERROR',
              closeAfter: false
            }, {blur: false});
            console.warn(e, 'error');
          }
          // security error (CORS)
        }
      }, 100);
    });
  }
}
