import { Injectable }                            from '@angular/core';
import { AuthEndpoints }                         from '@common/auth/auth.constants';
import { EndpointService }                       from '@services/endpoint.service';
import { HttpError, HttpErrorService }           from '@services/http-error.service';
import { HttpService }                           from '@services/http.service';
import { LoaderService, LoaderState }            from '@services/loader-service.service';
import { SiteConfigService, SocialSiteSettings } from '@services/site-config.service';
import { UtilsService }                          from '@services/utils.service';
import { Observable, Observer, throwError }      from 'rxjs';
import { catchError, finalize }                  from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

declare let FB: any;
declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  constructor(
    protected _http: HttpService,
    protected _loaderService: LoaderService,
    protected _httpErrorService: HttpErrorService,
    protected siteConfigService: SiteConfigService,
    protected _EndpointService: EndpointService,
    protected utilsService: UtilsService,
  ) {
    this._init();
  }

  public _init(): void {
    this.siteConfigService.siteConfigSocialSettingsLoaded.subscribe((settings: SocialSiteSettings) => {
      const facebookAppId = settings.facebook;
      this.initFacebook(facebookAppId);
    });
  }

  public signIn(params): Observable<Response> {
    return new Observable<Response>(
      (observer: Observer<Response>) => {

        FB.getLoginStatus((facebookLoginStatus: FacebookLoginStatus) => {

          const checkFacebookAuth: Observable<FacebookAuthResponse> =
            new Observable<FacebookAuthResponse>(
              (facebookObserver: Observer<FacebookAuthResponse>) => {

                if (!facebookLoginStatus.authResponse) {
                  FB.login((facebookLoginResponse: FacebookLoginResponse) => {
                    if (facebookLoginResponse.authResponse) {
                      facebookObserver.next(facebookLoginResponse.authResponse);
                      facebookObserver.complete();
                    }
                  }, {
                    auth_type: 'reauthorize',
                    scope: 'email'
                  });
                } else {
                  facebookObserver.next(facebookLoginStatus.authResponse);
                  facebookObserver.complete();
                }

              }
            );

          checkFacebookAuth.subscribe((facebookAuthResponse: FacebookAuthResponse) => {
            this._loaderService.setState(LoaderState.Pending);

            this._restAuthentication(facebookAuthResponse.accessToken, params)
              .pipe(finalize(() => this._loaderService.setState(LoaderState.Done)))
              .subscribe(
                (response: Response) => {

                  observer.next(response);
                  observer.complete();
                },
                (error: Response) => {
                  FB.api('/me/permissions', 'delete');
                  FB.logout(() => {
                    FB.Auth.setAuthResponse(null, 'unknown');
                  });

                  observer.error(error);
                  observer.complete();
                });
          });

        });

      }
    );
  }

  public initFacebook(facebookAppId: string) {
    if (!window.FB) {
      const scriptElement = document.createElement('script');

      scriptElement.setAttribute('defer', '');
      document.body.appendChild(scriptElement);

      scriptElement.onload = () => {
        FB.init({
          appId: facebookAppId,
          version: 'v11.0',
          status: true,
          cookie: true,
          xfbml: true
        });
      };

      scriptElement.src = '//connect.facebook.net/en_US/sdk.js';
    }
  }

  protected _restAuthentication(accessToken: string, params): Observable<any> {
    const body = {
      access_token: accessToken,
      ...this.utilsService.socialSignupBodyParams,
      ...params
    };
    // authRequestOptions.body = undefined;
    const correctEndpoint = this._EndpointService.checkIfRouteIs('signup') ? AuthEndpoints.facebookAuth : AuthEndpoints.facebookLogin;
    const appType = this.utilsService.appType();
    const facebookAuthEndpoint = this._EndpointService.getEndpoint(correctEndpoint, appType);
    return this._http.post(facebookAuthEndpoint, body, {params})
      .pipe(catchError((error: HttpErrorResponse) => {
        const httpError: HttpError = this._httpErrorService.parseError(error);
        if (httpError.status === 400) {
          httpError.detail = httpError.json.non_field_errors;
        }

        return throwError(httpError);
      }));
  }

}

export interface FacebookAuthResponse {
  accessToken: string;
  expiresIn: number;
  signedRequest: string;
  userID: string;
}

export interface FacebookLoginStatus {
  status: string;
  authResponse: FacebookAuthResponse;
}

export interface FacebookLoginResponse {
  authResponse: FacebookAuthResponse;
  status: string;
}
