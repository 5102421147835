import { Component, OnInit, Input }     from '@angular/core';
import { LanguageService }       from '@services/i18n.service';
import { LocalizeRouterService } from 'localize-router';

export enum LanguageName {
  // english = 'en',
  // french = 'fr',
  en = 'en',
  fr = 'fr',
}

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit {

  @Input() public isTouchDevice = false;

  constructor(
    public internationalizationService: LanguageService,
    public localizeRouterService: LocalizeRouterService
  ) {
  }

  ngOnInit() {
  }

  switchToLanguage(str: string) {
    this.internationalizationService.switchLanguage(LanguageName[str], {
      queryParamsHandling: 'preserve',
      preserveFragment: true
    }, false);
  }

}
