<main>
  <section
    class="page-block page-hero"
    style="background-image: url(/assets/img/pages/home/hero-bg.jpg);"
    appSectionFullPage>
    <div class="page-block-container">
      <div class="page-block-overlay"></div>
      <div class="page-block-content">
        <div class="form-container">
          <div class="form-header">
            <div [ngSwitch]="status">
              <h1 *ngSwitchCase="'confirming'">
                {{ 'SIGNUP_CONFIRMING_ACCOUNT' | translate }}
              </h1>
              <h1 *ngSwitchCase="'redirecting'">
                {{ 'SIGNUP_REDIRECTING_TO_APP' | translate }}
              </h1>
              <div *ngSwitchCase="'error'">
                <h1>
                  {{ 'SIGNUP_CONFIRM_ERROR_TITLE' | translate }}
                </h1>
                {{ message }}
                <a
                  href="/companies/{{languageService.currentLang}}/business"
                  class="btn">
                  {{ 'MENU_HOME' | translate }}
                </a>
              </div>
              <div *ngSwitchCase="'success'">
                <h1>
                  {{ 'SIGNUP_CONFIRMATION_SUCCESS_TITLE' | translate }}
                </h1>
                {{ 'SIGNUP_CONFIRMATION_SUCCESS_MESSAGE' | translate }}
                <a
                  (click)="goToApplicationB2B"
                  class="btn">{{ 'SIGNUP_GO_TO_APPLICATION' | translate }}
                </a>
              </div>
            </div>
            <div
              class="is-loading"
              *ngIf="status === 'confirming'">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
