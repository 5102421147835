import { Injectable }  from '@angular/core';
import { Resolve } from '@angular/router';
import { UtilsService } from '@services/utils.service';
import { MarketoTrackingCookiesService } from '@services/marketo-tracking-cookies.service';

@Injectable({
  providedIn: 'root'
})
export class AuthMarketoCookiesResolver implements Resolve<boolean> {

  constructor(
    private utilsService: UtilsService,
    private marketoTrackingCookiesService: MarketoTrackingCookiesService,
  ) {
    // noop
  }

  public resolve(): boolean {
    this.marketoTrackingCookiesService.updateMarketoTrackingCookies(this.utilsService.getQueryParams());
    return true;
  }
}
