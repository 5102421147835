import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCheckbox]'
})
export class CheckboxDirective {

  constructor(
    public element: ElementRef
  ) {
  }

  @HostListener('keyup', ['$event'])
  public onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Space') { // space keycode
      this.element.nativeElement.click();
    }
  }

}
