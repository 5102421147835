export const environment = {
  production: false,
  domain: 'pentalogfreelancers',
  baseUrl: 'https://webservice-xtest.skillvalue.com/',
  facebook: {
    appId: '575514446135319'
  },
  google: {
    client_id: '38436270735-htr4hu012504oc332bankke97747upqi.apps.googleusercontent.com',
    recaptchaKey: '6LfptiAUAAAAAIV-msQXWveaPXgM8_FBaKzLFU5L'
  },
  linkedin: {
    client_id: '787yahprmg83we',
    state: 987654321
  },
  github: {
    client_id: '9066bd8bb390405c9c29',
  },
  marketo: {
    key: '232-EET-259'
  },
  socketServer: 'websocket-xtest.skillvalue.com',
  stripePublishableAPIKey: 'pk_test_agoXl9ODO5mSXrCdHw0b5BUu00PxQg4WOm',
};
