import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router }                                             from '@angular/router';
import { AppDomain }                                          from '@app/app.constants';
import { AuthService }                                        from '@common/auth/services/auth.service';

import { LanguageService }        from '@services/i18n.service';
import { NavigationService }      from '@services/navigation.service';
import { PlatformsAccessService } from '@services/platforms-access.service';
import { Cookie }                 from 'ng2-cookies';
import { Subscription }           from 'rxjs';

@Component({
  selector: 'app-block-menu-mobile',
  templateUrl: './block-menu-mobile.component.html',
  styleUrls: ['./block-menu-mobile.component.scss']
})
export class BlockMenuMobileComponent implements OnInit, OnDestroy {
  @Output() public menuItemClicked = new EventEmitter();

  public isAdmin = false;
  public isDropdownOpen = false;
  public getUserSubscription: Subscription;

  constructor(
    public router: Router,
    public languageService: LanguageService,
    public navigationService: NavigationService,
    public language: LanguageService,
    public authService: AuthService,
    public _access: PlatformsAccessService
  ) {
  }

  get isCurrentlyVisitingFreelancers() {
    return window.location.href.indexOf('freelancers') > -1 && window.location.href.indexOf('business') > -1;
  }

  get isCurrentlyVisitingAssessment() {
    return (window.location.href.indexOf('app') > -1 || window.location.href.indexOf('business') > -1)
      && !this.isCurrentlyVisitingFreelancers;
  }

  public async ngOnInit() {
    this.authService.getUserDetails().subscribe();
    this.getUserSubscription = this.authService.getUserDetails().subscribe((data: any) => {
      this.isAdmin = data.is_company_admin;
    });
    await this.authService.getUserDetails().toPromise();
  }

  async setAccessPlatform(platformType) {
    if (platformType === 'access_assessment') {
      await this._access.createAccessPlatform({access: platformType}).toPromise().then((data) => {
        this.router.navigate(
          [`/${this.languageService.currentLang}/app/${this.authService.currentUserDetails.company_slug}`]
        );
      })
    }

    if (platformType === 'access_freelancers') {
      await this._access.createAccessPlatform({access: platformType}).toPromise().then((data) => {
        this.router.navigate(
          [`/${this.languageService.currentLang}/business/freelancers/dashboard`]
        );
      })
    }
  }

  public async onSignOutClick(event: any) {
    event.preventDefault();

    Cookie.delete('laravel_session');
    const cookies = Cookie.getAll();
    for (const cookie of cookies) {
      if (cookie.startWith('remember_web_')) {
        Cookie.delete(cookie);
      }
    }

    await this.authService.signOutB2B().toPromise();
    // if (window.location.href.indexOf('xlocal') > -1) {
    //   window.location.href = '/';
    // }
    if (window.location.href.indexOf(AppDomain) >= 0) {
      window.location.href = `/companies/${this.language.currentLang}/business/`;
    }

  }

  public handleDropdownClick() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  public onMenuItemClicked(event: any) {
    this.menuItemClicked.emit(event);
  }

  ngOnDestroy(): void {
    this.getUserSubscription.unsubscribe();
  }
}
