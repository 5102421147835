import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup }                   from '@angular/forms';
import { ActivatedRoute }                           from '@angular/router';
import { AuthService }                              from '@common/auth/services/auth.service';
import { FormAlertCode, FormHelper, FormState }     from '@common/helpers/form-helper';
import { HttpError }                                from '@services/http-error.service';
import { LanguageService }                          from '@services/i18n.service';
import { NavigationService }                        from '@services/navigation.service';
import { RouteMeta, RouteMetaService }              from '@services/route-meta.service';
import { SiteConfigService }                        from '@services/site-config.service';
import { UtilsService }                             from '@services/utils.service';
import { finalize }                                 from 'rxjs/operators';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {
  public formHelper: FormHelper;

  public resetPasswordMode = false;
  public passwordHasBeenReset = false;
  //
  @ViewChild('emailField') private _inputElement: ElementRef;

  constructor(
    public navigationService: NavigationService,
    public siteConfigService: SiteConfigService,
    public authService: AuthService,
    public utilsService: UtilsService,
    public languageService: LanguageService,
    private routeMetaService: RouteMetaService,
    private activatedRoute: ActivatedRoute,
  ) {}

  public ngOnInit() {
    this._initForm();
    this.activatedRoute.params.subscribe((params) => this._setRouteMeta());
  }

  public resetPassword(event: Event) {
    event.preventDefault();
    this.formHelper.setFormState(FormState.Submitted);
    this.formHelper.clearAlert();
    this.formHelper.initFormValidation();

    if (this.formHelper.formValid) {
      const emailValue: string = this.formHelper.getFieldValue('email');

      this.formHelper.setFormState(FormState.Pending);
      this.authService.resetPassword(emailValue)
        .pipe(finalize(() => this.formHelper.setFormState(FormState.Done)))
        .subscribe(
          (response: any) => {
            this.formHelper.resetForm();
            this.passwordHasBeenReset = true;
            this.resetPasswordMode = false;
          },
          (error: HttpError) => {
            this.formHelper.setAlert(FormAlertCode.Danger, error.detail);
          }
        );
    }
  }

  public goToLogin() {
    this.navigationService.navigateByUrl('/auth/login');
  }

  private _setRouteMeta() {
    this.routeMetaService.getRouteMeta('auth/recover-password')
      .subscribe((routeMeta: RouteMeta) => {
        this.routeMetaService.setRouteMeta(routeMeta);
      });
  }

  private _initForm(): void {
    this.formHelper = new FormHelper(
      new FormGroup({
        email: new FormControl(''),
        password: new FormControl('')
      })
    );
  }

  public goToHomePage() {
    window.location.href = this.languageService.currentLang;
  }
}
