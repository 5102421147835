import { CommonModule }                      from '@angular/common';
import { NgModule }                          from '@angular/core';
import { GlobalDirectivesModule }            from '@common/global-directives/global-directives.module';
import { SharedModule }                      from '@common/shared.module';
import { TranslateModule }                   from '@ngx-translate/core';
import { AuthModule }                        from '../auth/auth.module';
import { UserService }                       from './services/user.service';
import { FormResetPasswordComponent }        from './cpts/form-reset-password/form-reset-password.component';
import { FormResetPasswordConfirmComponent } from './cpts/form-reset-password-confirm/form-reset-password-confirm.component';
import { ModalResetPasswordComponent }       from './cpts/modal-reset-password/modal-reset-password.component';

@NgModule({
  declarations: [FormResetPasswordComponent, FormResetPasswordConfirmComponent, ModalResetPasswordComponent],
  imports: [
    CommonModule,
    AuthModule,
    // TranslateModule,
    SharedModule,
    GlobalDirectivesModule
  ],
  exports: [
    FormResetPasswordConfirmComponent
  ],
  providers: [
    UserService
  ]
})
export class UserModule {
}
