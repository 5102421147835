import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef }        from '@angular/material/dialog';
import { SlideshowGuideService }                from '@b2b/@common/modules/tutorial/slideshow-guide.service';
import { finalize }                             from 'rxjs/operators';

@Component({
  selector: 'app-slideshow-guide-youtube',
  templateUrl: './slideshow-guide-youtube.component.html',
  styleUrls: ['./slideshow-guide-youtube.component.scss']
})
export class SlideshowGuideYoutubeComponent implements OnInit, OnDestroy {

  public subscriber: any;
  youtubeVideoId = '';
  guideTitle = '';
  guideInfo = '';

  constructor(
    public slideshowGuideService: SlideshowGuideService,
    public dialogRef: MatDialogRef<SlideshowGuideYoutubeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.getTutorialInfo();
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  getTutorialInfo() {
    this.subscriber = this.slideshowGuideService.getSlides('b2b_assessment_video')
      .pipe(finalize(() => {
        this.subscriber.unsubscribe();
      }))
      .subscribe((result) => {
        this.guideTitle =  result[0].title;
        this.guideInfo =  result[0].description;
        this.youtubeVideoId = result[0].video_link.split('v=')[1];
      });
  }

  close(): void {
    this.dialogRef.close();
  }
}
