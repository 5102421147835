import { HttpClient }                                                from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output }            from '@angular/core';
import { FormControl, FormGroup, Validators }                        from '@angular/forms';
import { MatSnackBar }                                               from '@angular/material/snack-bar';
import { UserDetails }                                               from '@common/auth/classes/user-details';
import { AuthService }                                               from '@common/auth/services/auth.service';
import { FormAlertCode, FormHelper, FormState }                      from '@common/helpers/form-helper';
import { LanguageService }                                           from '@services/i18n.service';
import { CountryInfo, UtilsService }                                 from '@services/utils.service';
import { Observable, throwError }                                    from 'rxjs';
import { catchError, finalize, first, flatMap, map, startWith, tap } from 'rxjs/operators';

export enum CompanyFormLocation {
  default = 'default',
  checkout = 'checkout'
}

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.scss']
})
export class CompanyEditComponent implements OnInit {
  @Input() public data: UserDetails;
  @Input() public formLocation = CompanyFormLocation.default;
  @Input() events: Observable<void>;
  @Output() public onSaveCompleted: EventEmitter<any>;
  @Output() public onCancel: EventEmitter<any>;
  @Output() public onCountryChange: EventEmitter<CountryInfo>;
  @Output() public onError: EventEmitter<any>;

  companyFormEnum = CompanyFormLocation;

  public formHelper: FormHelper;
  public countryList: CountryInfo[];
  public filteredCountryList: Observable<CountryInfo[]>;
  public companyLogoPreview: string;
  public pending: boolean;

  constructor(
    public matSnackBar: MatSnackBar,
    public languageService: LanguageService,
    // public commonService: CommonService,
    public authService: AuthService,
    public http: HttpClient,
    // public httpErrorService: HttpErrorService,
    public utilsService: UtilsService
  ) {
    this.onSaveCompleted = new EventEmitter();
    this.onCancel = new EventEmitter();
    this.onCountryChange = new EventEmitter();
    this.onError = new EventEmitter();
  }

  public ngOnInit() {
    this._initForm();

    this.utilsService.getCountries().pipe(first()).toPromise().then((countries: CountryInfo[]) => {
      this.countryList = countries;

      this.filteredCountryList = this.formHelper.getField('country').valueChanges
        .pipe(startWith(''))
        .pipe(map((value: string) => this.filterCountry(value)));
    });

    if (this.formLocation === CompanyFormLocation.checkout) {
      this.events.subscribe(() => {
        this.onSubmit();
      });
    }
  }

  public emitSelectedCountry(country: CountryInfo) {
    this.onCountryChange.emit(country);
  }

  public onCancelClick(event: any) {
    this.onCancel.emit(event);
  }

  public onSubmit() {
    this.formHelper.setFormState(FormState.Submitted);
    this.formHelper.clearAlert();
    this.formHelper.initFormValidation();

    if (this.formHelper.formValid) {
      this.pending = true;

      const formData = this._buildFormData();

      this.authService.saveUserDetails(formData)
        .pipe(flatMap(() => this.authService.getUserDetails({type: 'b2b'})))
        .pipe(tap((response: UserDetails) => {
          this.authService.currentUserDetails = response;
        }))
        .pipe(catchError((error: any) => {
          return throwError(error);
        }))
        .pipe(finalize(() => this.pending = false))
        .subscribe(() => {
            this.matSnackBar.open(
              this.languageService.getValue('B2B_COMPANY_DETAILS_MESSAGE_SAVE_SUCCESSFULL'),
              null,
              {
                duration: 3000,
                panelClass: ['success']
              });

            this.formHelper.formGroup.markAsPristine();
            this.onSaveCompleted.emit();
          },
          (error) => {
            if (error.status === 400) {
              if (error.json.company_name) {
                this.formHelper.getField('name').setErrors({invalid: error.json.company_name[0]});
              }

              if (error.json.company_logo) {
                this.formHelper.getField('logo').setErrors({invalid: error.json.company_logo[0]});
              }

              if (error.json.company_country_id) {
                this.formHelper.getField('country').setErrors({invalid: error.json.company_country_id[0]});
              }

              if (error.json.company_website) {
                this.formHelper.getField('website').setErrors({invalid: error.json.company_website[0]});
              }

              if (error.json.company_address) {
                this.formHelper.getField('address').setErrors({invalid: error.json.company_address[0]});
              }

              if (error.detail) {
                this.formHelper.setAlert(FormAlertCode.Danger, error.detail);
              }
            } else {
              this.formHelper.setAlert(FormAlertCode.Danger, error.detail);
            }
          }
        );
    } else {
      this.onError.emit();
    }
  }

  public onCompanyLogoChange(event: any) {
    const imageFile = event.target.files[0];
    if (!imageFile) {
      return false;
    }

    this.companyLogoPreview = imageFile.name;
    this.formHelper.setFieldValue('logo', imageFile);
    this.formHelper.getField('logo').markAsDirty();
  }

  public filterCountry(value: string): CountryInfo[] {
    return this.countryList.filter((item: CountryInfo) => {
      if (typeof value === 'string') {
        return item.name.toLowerCase().indexOf(value.toLowerCase()) === 0;
      }
    });
  }

  public fieldCountryDisplay(country: CountryInfo): string {
    return country ? country.name : '';
  }

  onBlurCountry(event: any) {
    setTimeout(()=> {
      if(event.target.value){
        const sendTypedCountry = this.filterCountry(event.target.value);
        this.formHelper.setFieldValue('country', sendTypedCountry[0]);
        this.onCountryChange.emit(sendTypedCountry[0]);
      }
    }, 500);
  }

  private _initForm(): void {
    const country = this.data && this.data.company_country_id
      ? {id: this.data.company_country_id, name: this.data.company_country}
      : '';

    if (this.formLocation === CompanyFormLocation.checkout) {
      this.formHelper = new FormHelper(
        new FormGroup({
          name: new FormControl(this.data.company_name, [Validators.required]),
          address: new FormControl(this.data.company_address || '', [Validators.required]),
          country: new FormControl(country, [Validators.required]),
          company_city: new FormControl(this.data.company_city || '', [Validators.required]),
          company_vat_number: new FormControl(this.data.company_vat_number || '', [Validators.required, this._validateNoWhitespace]),
        })
      );
    }

    if (this.formLocation === CompanyFormLocation.default) {
      this.formHelper = new FormHelper(
        new FormGroup({
          name: new FormControl(this.data.company_name),
          website: new FormControl(this.data.company_website || '',
            (control: FormControl) => this._validateWebsite(control)),
          address: new FormControl(this.data.company_address || ''),
          activity: new FormControl(this.data.field_of_activity || ''),
          country: new FormControl(
            country,
            (control: FormControl) => this._validateCountry(control)
          ),
          company_city: new FormControl(this.data.company_city || ''),
          logo: new FormControl('', (control: FormControl) => this._validateCompanyLogo(control)),
          company_vat_number: new FormControl(
            this.data.company_vat_number || '',
            (control: FormControl) => this._validateNoWhitespace(control)),
        })
      );
    }
  }

  private _buildFormData(): FormData {
    const country: CountryInfo = this.formHelper.getFieldValue('country');
    const formData: FormData = new FormData();

    if (this.formLocation === CompanyFormLocation.checkout) {
      formData.append('company_name', this.formHelper.getFieldValue('name'));
      formData.append('company_address', this.formHelper.getFieldValue('address'));
      formData.append('company_city', this.formHelper.getFieldValue('company_city'));
      formData.append('company_country_id', country ? country.id.toString() : null);
      formData.append('company_vat_number', this.formHelper.getFieldValue('company_vat_number'));
    } else {
      formData.append('company_name', this.formHelper.getFieldValue('name'));
      formData.append('company_website', this.formHelper.getFieldValue('website'));
      formData.append('company_address', this.formHelper.getFieldValue('address'));
      formData.append('field_of_activity', this.formHelper.getFieldValue('activity'));
      formData.append('company_country_id', country ? country.id.toString() : null);
      formData.append('company_city', this.formHelper.getFieldValue('company_city'));
      formData.append('company_vat_number', this.formHelper.getFieldValue('company_vat_number'));

      const companyLogo = this.formHelper.getFieldValue('logo');
      if (companyLogo) {
        formData.append('company_logo', companyLogo);
      }
    }

    return formData;
  }

  private _validateWebsite(control: FormControl) {
    const website = control.value;
    if (website !== '' && website !== null && this.utilsService.validateURL(website)) {
      return null;
    } else {
      return {
        invalid: this.languageService.getValue('GLOBAL_ERROR_WEBSITE_REQUIRED')
      };
    }
  }

  private _validateCountry(control: FormControl) {
    const country: CountryInfo = control.value;
    if (control.value === '' || (country !== null && country.name)) {
      return null;
    }
    return {
      invalid: this.languageService.getValue('B2B_GLOBAL_ERROR_INVALID_COUNTRY')
    };
  }

  private _validateCompanyLogo(control: FormControl) {
    if (!control.value) {
      return null;
    }

    const imageFile = control.value;
    if (imageFile.type.indexOf('image/') < 0) {
      return {
        invalid: this.languageService.getValue('B2B_COMPANY_ERROR_INVALID_LOGO')
      };
    }

    return null;
  }

  private _validateNoWhitespace(control: FormControl) {
    const isWhitespace = control.value.indexOf(' ') >= 0;
    const isValid = !isWhitespace;
    const validResponse = { whitespace: true };
    return isValid ? null : validResponse;
  }
}
