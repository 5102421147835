import { Component, OnInit }      from '@angular/core';
import { ActivatedRoute }         from '@angular/router';
import { AppDomain }              from '@app/app.constants';
import { B2bUserDetails }         from '@common/auth/classes/b2b-user-details';
import { UserDetails }            from '@common/auth/classes/user-details';
import { AuthService }            from '@common/auth/services/auth.service';
import { UserRoutesService }      from '@common/auth/services/user-routes.service';
import { LanguageService }        from '@services/i18n.service';
import { NavigationService }      from '@services/navigation.service';
import { PlatformsAccessService } from '@services/platforms-access.service';
import { Cookie }                 from 'ng2-cookies';

@Component({
  selector: 'app-route-force-login',
  templateUrl: './route-force-login.component.html',
})
export class RouteForceLoginComponent implements OnInit {
  /*
   * Given an auth token from URL, set it un current b2b user service.
   * Then, redirect to b2b dashboard.
   */
  constructor(
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public navigationService: NavigationService,
    public languageService: LanguageService,
    public platformAccessService: PlatformsAccessService,
    public userRoutesService: UserRoutesService
  ) {
  }

  public ngOnInit() {
    this.activatedRoute.params.subscribe((params: any) => {
      this.authService.authTokenB2B = params.authToken;

      this.authService.getUserDetails({type: 'b2b'}).subscribe(
        (user: UserDetails & B2bUserDetails) => {
          this.authService.currentUserDetails = user;
          if (!user.platforms_access) {
            return;
          }

          const now = new Date();
          const exp = new Date(now.getFullYear() + 5, now.getMonth(), now.getDate());
          Cookie.set('force_login', 'true', exp, '/');

          this.userRoutesService.handleB2BLogin();
        },
        (error: any) => {
          if (error.status !== 401) {
            // this.navigationService.._language;
            if (window.location.href.indexOf(AppDomain) >= 0) {
              window.location.href = `/companies/${this.languageService.currentLang}/business/`;
            }
          }
          return false;
        });
    });

  }
}
