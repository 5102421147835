import { CommonModule }                    from '@angular/common';
import { NgModule }                        from '@angular/core';
import { BlockLearningMaterialsComponent } from '@common/global-components/cpts/learning/block-learning-materials.component';
import { ModalAlertComponent }             from '@common/global-components/cpts/modal-alert/modal-alert.component';
import { MaterialModule }                  from '@common/material/material.module';
import { TranslateModule }                 from '@ngx-translate/core';
import { InputValidationsComponent }       from './cpts/input-validations/input-validations.component';
import { MatAutocompleteComponent }        from './cpts/mat-autocomplete/mat-autocomplete.component';
import { StackAutocompleteComponent }      from './cpts/stack-autocomplete/stack-autocomplete.component';
import { CalloutComponent } from './cpts/callout/callout.component';


@NgModule({
  declarations: [
    InputValidationsComponent,
    ModalAlertComponent,
    BlockLearningMaterialsComponent,
    StackAutocompleteComponent,
    MatAutocompleteComponent,
    CalloutComponent,
  ],
  exports: [
    InputValidationsComponent,
    ModalAlertComponent,
    BlockLearningMaterialsComponent,
    StackAutocompleteComponent,
    MatAutocompleteComponent,
    MatAutocompleteComponent,
    CalloutComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule
  ]
})
export class GlobalComponentsModule {
}
