<div
  [attr.lang]="languageService.currentLang" [ngClass]="routeName" class="router-outlet-container-wrapper">
  <div
    class="router-outlet-container">
    <div
      [@fadeOutAnimation]
      *ngIf="appLoading"
      class="router-outlet-loader">
    </div>
    <router-outlet></router-outlet>
  </div>
  <router-outlet name="modal"></router-outlet>
  <app-loader></app-loader>
</div>
