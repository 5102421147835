import { Injectable }                     from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { LanguageService }                from './i18n.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialSnackBarService {
  constructor(
    private matSnackBar: MatSnackBar,
    private languageService: LanguageService
  ) {

  }

  public open(translation_key, label = null, configs: MatSnackBarConfig = {}) {
    const translated = this.languageService.getValue(translation_key);
    if (translation_key === translated) {
      // Missing translation key.
    }
    return this.matSnackBar.open(translated, label, configs);
  }

  public openTop(translation_key, configs: MatSnackBarConfig = {}, label = '') {
    configs.verticalPosition = configs.verticalPosition || 'top';
    configs.duration = configs.duration || 5000;
    configs.panelClass = configs.panelClass || ['success'];
    this.open(translation_key, label, configs);
  }

  public openBottom(translation_key, configs: MatSnackBarConfig = {}, label = '') {
    configs.verticalPosition = configs.verticalPosition || 'bottom';
    configs.duration = configs.duration || 5000;
    configs.panelClass = configs.panelClass || ['success'];
    this.open(translation_key, label, configs);
  }

  public close() {
    return this.matSnackBar.dismiss();
  }
}
