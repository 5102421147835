import { Component, Inject }             from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QueryParamsHandling }           from '@angular/router';
import { CompanyAccountsService }        from '@b2b/@common/modules/company-accounts/services/company-accounts.service';
import { AuthService }                   from '@common/auth/services/auth.service';
import { FormAlertCode }                 from '@common/helpers/form-helper';

@Component({
  selector: 'app-see-token-modal',
  templateUrl: './see-token-modal.component.html',
  styleUrls: ['./see-token-modal.component.scss']
})
export class SeeTokenModalComponent {

  passwordForToken = '';
  passwordNull = false;
  passwordInvalid = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<SeeTokenModalComponent>,
    public authService: AuthService,
    public _companyAccountsService: CompanyAccountsService,
  ) {
  }

  public close(response: boolean) {
    this.matDialogRef.close(false);
  }

  public async checkPassword() {
    if(this.passwordForToken.length !== 0) {
      this.passwordNull = false;

      try {
        await this._companyAccountsService.requestCompanyAssessmentToken(this.passwordForToken).then((data: {api_key: string;}) => {
          this.matDialogRef.close(data.api_key);
        })
      } catch (error) {
        if (error.status === 400) {
          this.passwordInvalid = true
        }
      }
    } else {
      this.passwordNull = true;
    }
  }

  public clearValidators() {
    this.passwordNull = false;
    this.passwordInvalid = false;
  }
}
