import { NgModule }             from '@angular/core';
import { RouterModule }         from '@angular/router';
import { LocalizeRouterModule } from 'localize-router';
import { B2B_ROUTES }           from './b2b.routes';


@NgModule({
  imports: [
    RouterModule.forChild(B2B_ROUTES),
    LocalizeRouterModule.forChild(B2B_ROUTES)
  ],
  exports: [RouterModule, LocalizeRouterModule]
})
export class B2bRoutingModule {
}
