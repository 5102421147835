<div class="color-overlay">
  <div class="main-wrapper">
    <div class="logo">
      <a
        (click)="goToHomePage()"
        [attr.title]="'GLOBAL_LABEL_BRANDING_PENTALOG' | translate">
        <img
          [attr.alt]="siteConfigService?.settings ? siteConfigService?.settings[utilsService.appType()]?.website.name : ''"
          class="logo"
          src="/assets/img/logos/pentalog.svg"
        />
      </a>
    </div>
    <div
      [class.is-loading]="STATUS_LOADING"
      class="login-content">
      <h4
        class="thin">{{ 'LOGIN_WITH_PENTALOG' | translate }}
      </h4>
      <div *ngIf="isB2C">
        <span>{{ 'LOGIN_ERROR_B2C_PENTALOG' | translate }}
          <a (click)="goToSV()">skillvalue.com</a>
        </span>
      </div>
      <form
        (ngSubmit)="signIn($event)"
        [formGroup]="SignInFormGroup"
        *ngIf="!isB2C"
        class="sign-in-form"
        novalidate>
<!--        <app-social-auth-buttons-->
<!--          (signInSuccess)="_onSignInSuccess($event)">-->
<!--        </app-social-auth-buttons>-->
        <div class="form-group">
          <div>
            <span class="input-with-icon">
              <mat-icon>email</mat-icon>
              <!--              <i class="fa fa-envelope"></i>-->
              <input
                #emailField
                [attr.placeholder]="'' + 'GLOBAL_LABEL_EMAIL' | translate"
                appValidatorEmail
                class="form-control"
                formControlName="email"
                name="email"
                required
                tabindex="1"
                type="email"
                data-id="login-email"
              >
            </span>
          </div>
          <div
            *ngIf="email.errors && email.dirty"
            class="alert alert-danger">
            <span
              *ngIf="email.errors.required"
              class="form-control-error">
              {{ 'GLOBAL_ERROR_EMAIL_REQUIRED' | translate }}
            </span>
            <span
              *ngIf="email.errors.validEmail"
              class="form-control-error">
              {{ 'GLOBAL_ERROR_EMAIL_INVALID' | translate }}
            </span>
          </div>
        </div>
        <div class="form-group">
          <div
            class="form-control-text"
          >
            <span class="input-with-icon">
              <mat-icon>lock</mat-icon>
              <!--              <i class="fa fa-lock"></i>-->
              <input
                #passwordField
                [attr.placeholder]="'GLOBAL_LABEL_PASSWORD' | translate"
                class="form-control"
                formControlName="password"
                required
                tabindex="2"
                type="password"
                data-id="login-password">
            </span>
          </div>
          <div
            *ngIf="password.errors && password.dirty"
            class="alert alert-danger">
            <span
              *ngIf="password.errors.required"
              class="form-control-error">
                {{ 'GLOBAL_ERROR_PASSWORD_REQUIRED' | translate }}
            </span>
            <span
              *ngIf="password.errors.generic"
              class="form-control-error">
              {{ 'GENERIC_CANNOT_LOGIN' | translate }}
            </span>
            <span
              *ngIf="password.errors.spam"
              class="form-control-error">
              {{ 'USERS_TOO_MANY_FAILED_LOGINS_FE' | translate }}
            </span>
          </div>
          <div>
            <a
              [routerLink]="'/auth/recover-password' | localize"
              class="forgot-password-link">
              {{ 'LOGIN_FORM_FORGOT_PASSWORD' | translate }}
            </a>
          </div>
        </div>
        <div class="form-actions">
          <button
            class="btn btn-submit"
            type="submit"
          >
            {{ 'GLOBAL_BUTTON_SIGNIN' | translate }}
          </button>
        </div>
      </form>
    </div>
    <p class="signup-text" *ngIf="!isB2C">
      {{ 'LOGIN_DONT_HAVE_AN_ACCOUNT' | translate }}
      <!--      <a [routerLink]="routeWithRedirect('/' + languageService.currentLang + '/auth/signup')"-->
      <a [queryParams]="{ type: B2cUserType[B2cUserType.coder] }"
         [routerLink]="'/' + languageService.currentLang + '/auth/signup'"
         data-id="signup-link"
         queryParamsHandling="merge">
        {{ 'GLOBAL_BUTTON_SIGNUP' | translate }}
      </a>
    </p>
  </div>
  <div class="clearfix"></div>
</div>
