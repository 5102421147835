<form [formGroup]="formHelper.formGroup" (ngSubmit)="onSubmit($event)" class="reset-password-form"
      [class.is-loading]="isLoading" novalidate>

  <app-form-alert [formGroupAlert]="formHelper.formGroupAlert"></app-form-alert>

  <div class="form-group">
    <div class="form-control-text" [class.ng-valid]="formHelper.fieldValid('password1')"
         [class.ng-invalid]="formHelper.fieldInvalid('password1')">
      <input type="password" formControlName="password1" class="form-control" tabindex="40"
             [attr.placeholder]="'GLOBAL_LABEL_PASSWORD' | translate"
             required minlength="8" appEqualTo equalTo="password2" reverse="true">
    </div>

    <div *ngIf="formHelper.fieldInvalid('password1')">
      <span *ngIf="formHelper.fieldHasError('password1', 'required')" class="form-control-error">
        {{ 'GLOBAL_ERROR_PASSWORD_REQUIRED' | translate }}</span>
      <span *ngIf="formHelper.fieldHasError('password1', 'minlength')" class="form-control-error">
        {{ 'GLOBAL_ERROR_FIELD_MIN_LENGTH' | translate: { minlength: 8 } }}</span>
      <span *ngIf="formHelper.fieldHasError('password1', 'invalid')" class="form-control-error">
        {{ formHelper.getField('password1').getError('invalid') }}</span>
    </div>

  </div>

  <div class="form-group">
    <div
      class="form-control-text"
      [class.ng-valid]="formHelper.fieldValid('password2')"
      [class.ng-invalid]="formHelper.fieldInvalid('password2')">
      <input
        type="password"
        formControlName="password2"
        class="form-control"
        tabindex="50"
        [attr.placeholder]="'GLOBAL_LABEL_CONFIRM_PASSWORD' | translate" required
        appEqualTo
        equalTo="password1">
    </div>

    <div *ngIf="formHelper.fieldInvalid('password2')">
      <span *ngIf="formHelper.fieldHasError('password2', 'required')" class="form-control-error">
        {{ 'GLOBAL_ERROR_PASSWORD_REQUIRED' | translate }}</span>
      <span *ngIf="formHelper.fieldHasError('password2', 'equalTo')" class="form-control-error">
        {{ 'GLOBAL_ERROR_PASSWORD_NO_MATCH' | translate }}</span>
      <span *ngIf="formHelper.fieldHasError('password2', 'invalid')" class="form-control-error">
        {{ formHelper.getField('password2').getError('invalid') }}</span>
    </div>
  </div>

  <div class="form-actions">
    <button
      type="submit"
      class="btn"
      [class.ng-pending]="formHelper.formPending"
      [disabled]="formHelper.formPending">
      {{ 'RESET_PASSWORD_CONFIRM_BUTTON_RESET' | translate }}
    </button>
  </div>

</form>
