import { Injectable }                  from '@angular/core';
import { QueryParamsHandling }         from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { share }                       from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageHeaderService {

  private _backLinkSource = new BehaviorSubject<PageHeaderBackLink>(null);
  public readonly backLink$: Observable<PageHeaderBackLink> = this._backLinkSource.asObservable().pipe(share());
  private _titleSource = new BehaviorSubject<PageHeaderTitle>(null);
  public readonly title$: Observable<PageHeaderTitle> = this._titleSource.asObservable().pipe(share());
  private _buttonSource = new BehaviorSubject<PageHeaderButton>(null);
  public readonly button$: Observable<PageHeaderButton> = this._buttonSource.asObservable().pipe(share());

  constructor() {
  }

  public get title(): PageHeaderTitle | null {
    return this._titleSource.getValue();
  }

  public set title(title: PageHeaderTitle | null) {
    this._titleSource.next(title);
    if (title != null) {
      this.backLink = null;
    }
  }

  public get backLink(): PageHeaderBackLink | null {
    return this._backLinkSource.getValue();
  }

  public set backLink(backLink: PageHeaderBackLink | null) {
    this._backLinkSource.next(backLink);
    if (backLink != null) {
      this.title = null;
    }
  }

  public get button(): PageHeaderButton | null {
    return this._buttonSource.getValue();
  }

  public set button(button: PageHeaderButton | null) {
    this._buttonSource.next(button);
  }

  public reset() {
    this.title = null;
    this.backLink = null;
    this.button = null;
  }
}

export interface PageHeaderBackLink {
  label: string;
  routerLink?: any[];
  queryParamsHandling?: QueryParamsHandling;
  preserveQueryParams?: boolean;
  skipLocationChange?: boolean;
  onClick?: ($event) => any;
}

export interface PageHeaderTitle {
  label: string;
}

export interface PageHeaderButton {
  label: string;
  icon?: string;
  classes?: string[];
  onClick?: ($event) => any;
}
