import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-and-sidebar-left',
  templateUrl: './header-and-sidebar-left.component.html',
  styleUrls: ['./header-and-sidebar-left.component.scss']
})
export class HeaderAndSidebarLeftComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
