import { Component, OnInit }                              from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router }             from '@angular/router';
import { LicenseService }                                            from '@b2b/@common/modules/license/services/license.service';
import { AuthenticationEvent, AuthenticationEventType, AuthService } from '@common/auth/services/auth.service';
import { FADE_OUT_ANIMATION }                                        from '@common/core/core.animations';
import { ConnectionMonitorService }                                  from '@services/connection-monitor.service';
import { LanguageService }                                           from '@services/i18n.service';
import { NavigationService }                                         from '@services/navigation.service';
import { SiteConfigService }                                         from '@services/site-config.service';
import { GtmService }                                                from '@services/gtm.service';
import { from }                                                      from 'rxjs';
import { filter }                                                    from 'rxjs/operators';
import { Cookie }                                                    from 'ng2-cookies';

@Component({
  selector: 'app-b2b',
  templateUrl: './b2b.component.html',
  styleUrls: ['./b2b.component.scss'],
  animations: [FADE_OUT_ANIMATION]
})
export class B2BComponent implements OnInit {
  public routeName: string;
  public appLoading = true;

  constructor(
    public navigationService: NavigationService,
    public languageService: LanguageService,
    public siteConfigService: SiteConfigService,
    public gtmService: GtmService,
    public router: Router,
    public licenseRenewal: LicenseService,
    public connectionMonitorService: ConnectionMonitorService,
    public authService: AuthService
  ) {

    this._showApplicationLoader();
    // Main router outlet loader
    this.authService.events.subscribe((event: AuthenticationEvent) => {
      if (event.type === AuthenticationEventType.StateUpdated) {
        this.authService.setGoogleTagManagerDatalayer();
      }
    });
  }

  public ngOnInit() {
    this.connectionMonitorService.init();
    this.navigationService.activeRoute().subscribe((route: ActivatedRouteSnapshot) => {
      this.routeName = route.data.name;
      this._initGoogleTagManager();
    });
  }

  private _showApplicationLoader() {
    const routerSubscriber = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.appLoading = false;
        routerSubscriber.unsubscribe();
      });
  }

  private _initGoogleTagManager() {
    if (!this.isForcedB2BLogin) {
      this.gtmService.init();
    }
  }

  private get isForcedB2BLogin() {
    return Cookie.get('force_login');
  }

}
