import { FormControl } from '@angular/forms';

export interface ValidationResult {
  [key: string]: boolean;
}

export class PasswordValidator {

  public static strong(control: FormControl): ValidationResult {
    const hasNumber = /\d/.test(control.value);
    const hasUpper = /[A-Z]/.test(control.value);
    const hasLower = /[a-z]/.test(control.value);
    const hasSpecialChar = /[!@#$%^&*+._?]/.test(control.value);

    // console.log('Upp, Low, Num, SpecialChar \n', hasNumber, hasUpper, hasLower, hasSpecialChar);
    const valid = hasUpper && hasLower && (hasNumber || hasSpecialChar);
    if (!valid) {
      // return what´s not valid
      return {strong: true};
    }
    return null;
  }
}
