import { Directive, ElementRef, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MediaQueries }                                                      from '@app/app.constants';

@Directive({
  selector: '[appPageHeader]'
})
export class PageHeaderDirective implements OnDestroy, OnInit {

  private _initialHeaderHeight;

  constructor(
    private _element: ElementRef,
    private _renderer: Renderer2
  ) {
  }

  public ngOnInit() {
    this._checkSticky();
  }

  public ngOnDestroy() {
    this._renderer.setStyle(document.body, 'padding-top', null);
  }

  @HostListener('window:scroll', ['$event'])
  public onWindowScroll(event: any) {
    this._checkSticky();
  }

  @HostListener('window:resize', ['$event'])
  public onWindowResize(event: any) {
    this._checkSticky();
  }

  private _checkSticky() {
    const nativeElement = this._element.nativeElement;

    if ((window.innerWidth >= MediaQueries.screenDesktop) && (window.pageYOffset > 50)) {
      if (!this._initialHeaderHeight) {
        this._initialHeaderHeight = nativeElement.offsetHeight + 'px';
      }

      if (!nativeElement.classList.contains('absolute')) {
        this._renderer.setStyle(document.body, 'padding-top', this._initialHeaderHeight);
      }

      this._renderer.addClass(nativeElement, 'is-sticky');
    } else {
      this._renderer.removeStyle(document.body, 'padding-top');
      this._renderer.removeClass(nativeElement, 'is-sticky');

      if (window.pageYOffset > 50) {
        this._renderer.addClass(nativeElement, 'is-mobile-sticky');
      } else {
        this._renderer.removeClass(nativeElement, 'is-mobile-sticky');
      }
    }
  }

}
