import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FADE_IN_OUT_ANIMATION }                  from '@common/core/core.animations';
import { FormAlert }                              from '@common/helpers/form-helper';

@Component({
  selector: 'app-form-alert',
  templateUrl: './form-alert.component.html',
  styleUrls: ['./form-alert.component.scss'],
  animations: [FADE_IN_OUT_ANIMATION]
})

export class FormAlertComponent {
  @Input() public formGroupAlert: FormAlert;
  @Input() public linkText: string = null;

  @Output() public onLinkClick = new EventEmitter();
}

