import { Injectable }             from '@angular/core';
import { MatDialog }              from '@angular/material/dialog';
import { AppDomain }              from '@app/app.constants';
import { first }                  from 'rxjs/operators';
import { MaterialAlertComponent } from '../cpts/material-alert.component';


@Injectable({
  providedIn: 'root'
})
export class MaterialAlertService {

  private options = {
    width: '600px',
    disableClose: true,
    redirectUrl: '/',
    blur: false,
    mainContainerClass: 'main-container',
    data: {
      title: 'GLOBAL_ALERT_TITLE_MODAL',
      content: 'GLOBAL_ALERT_CONTENT_MODAL',
      non_field_errors: '',
      buttons: [{
        type: 'accept',
        text: 'GLOBAL_LABEL_GO_NEXT_BUTTON_MODAL',
        response: true
      }, {
        type: 'dismiss',
        text: 'GLOBAL_LABEL_DISMISS_BUTTON_MODAL',
        response: false
      }],
      closeAfter: 1 // seconds
    }
  };
  private _layoutButtons = {
    yes: {
      type: 'accept',
      text: 'GLOBAL_LABEL_YES',
      class: 'success',
      response: true
    },
    continue: {
      type: 'accept',
      text: 'GLOBAL_LABEL_CONTINUE',
      class: 'success',
      response: true
    },
    confirm: {
      type: 'accept',
      text: 'GLOBAL_LABEL_CONFIRM',
      class: 'success',
      response: true
    },
    no: {
      type: 'accept',
      text: 'GLOBAL_LABEL_NO',
      class: 'cancel',
      response: false
    },
    dismiss: {
      type: 'accept',
      text: 'GLOBAL_LABEL_DISMISS',
      class: 'cancel',
      response: false
    },
    cancel: {
      type: 'accept',
      text: 'GLOBAL_LABEL_CANCEL',
      class: 'cancel',
      response: false
    },
    invertCancel: {
      type: 'accept',
      text: 'GLOBAL_LABEL_CANCEL',
      class: 'success',
      response: false
    },
    invertConfirm: {
      type: 'accept',
      text: 'GLOBAL_LABEL_CONFIRM',
      class: 'cancel',
      response: true
    },
  };

  constructor(
    private _matDialog: MatDialog
  ) {
  }

  public message(message: string = 'GLOBAL_ALERT_TITLE_MODAL') {
    return this.open({
      buttons: [],
      title: '',
      content: message,
      closeAfter: false,
    }, {blur: false});
  }

  public messageAndButton(message: string = '') {
    return this.open({
      buttons: [{
        type: 'dismiss',
        text: 'GLOBAL_LABEL_OK',
        response: false
      }],
      content: message,
      closeAfter: false,
    }, {blur: false, redirectUrl: '/'});
  }

  public confirmWithTitleAndMessage(title = '', content = '') {
    return this.open({
      buttons: [{
        type: 'dismiss',
        text: 'GLOBAL_LABEL_OK',
        response: false
      }],
      title,
      content,
      closeAfter: false,
    }, {blur: false, redirectUrl: '/'});
  }

  public open(data: any = {}, options: any = {}) {
    Object.assign(this.options.data, data);
    Object.assign(this.options, options);
    return this._createModal();
  }

  public closeAll() {
    return new Promise((resolve) => {
      this._matDialog.afterAllClosed.pipe(first()).toPromise().then(() => resolve());
      this._matDialog.closeAll();
    });
  }

  public confirm(title, content, options: any = {}) {
    options.buttons = options.buttons || ['yes', 'no'];
    const displayButtons = [];
    options.buttons.map((button) => {
      if (typeof button === 'string') {
        displayButtons.push(this._layoutButtons[button]);
      } else {
        displayButtons.push(button);
      }
    });
    options.redirectUrl = (options.redirectUrl !== undefined && options.redirectUrl !== null) ? options.redirectUrl : '/';
    return this.open({
      buttons: displayButtons,
      title,
      content,
      data: options.data,
      closeAfter: options.closeAfter || false,
    }, {
      blur: options.blur || false,
      width: options.width || 420,
      redirectUrl: options.redirectUrl,
      disableClose: options.disableClose,
      panelClass: options.panelClass,
    });
  }

  public confirmWithComponent(component, options: any = {}) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        try {
          this._addBlur();
          const modal = await this._matDialog
            .open(component, options)
            .afterClosed()
            .toPromise();
          this._removeBlur();
          return resolve(modal);
        } catch (e) {
          this._removeBlur();
        }
      });
    });
  }

  private _createModal() {
    return new Promise(async (resolve) => {
      // setTimeout(async () => {

      // try {

      this._addBlur();
      const data = await this._matDialog
        .open(MaterialAlertComponent, this.options)
        .afterClosed()
        .toPromise();

      this._removeBlur();

      if (data && data.response === false && this.options.redirectUrl && data.type === 'dismiss') {
        if (window.location.href.indexOf(AppDomain) >= 0) {
          return window.location.href = this.options.redirectUrl;
        }

      }
      return resolve(data.response);
      //
      // } catch (e) {
      //
      //   this._removeBlur();
      // }
      // }); // workaround  #5268 -> material issue
    });
  }

  private _addBlur() {
    const mainContainer = document.getElementsByClassName(this.options.mainContainerClass);
    if (this.options.blur && mainContainer[0]) {
      mainContainer[0].classList.add('blurContainer');
    }
  }

  private _removeBlur() {
    const mainContainer = document.getElementsByClassName(this.options.mainContainerClass);
    if (this.options.blur && mainContainer[0]) {
      mainContainer[0].classList.remove('blurContainer');
    }
  }

}
