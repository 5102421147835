import { AbstractControl, FormGroup } from '@angular/forms';

export interface FormAlert {
  code: FormAlertCode;
  description: string;
  cssClass: string;
}

export enum FormAlertCode {
  Danger,
  Success,
  Warning,
  Info
}

export enum FormState {
  Submitted,
  Pending,
  Done
}

export class FormHelper {

  public formGroup: FormGroup;
  public formGroupAlert: FormAlert;

  private _formState: FormState;

  constructor(formGroup: FormGroup) {
    this.formGroup = formGroup;
  }

  public get formPending(): boolean {
    return this._formState === FormState.Pending;
  }

  public get formValid(): boolean {
    return this.formGroup.valid;
  }

  public setFormState(formState: FormState) {
    this._formState = formState;
  }

  public getFormState(): FormState {
    return this._formState;
  }

  public initFormValidation(): void {
    this.formGroup.markAsDirty();
    this.formGroup.markAsTouched();
    this.formGroup.updateValueAndValidity();

    Object.keys(this.formGroup.controls).forEach(
      (name) => {
        if (this.formGroup.controls[name]) {
          this.formGroup.controls[name].markAsDirty();
          this.formGroup.controls[name].markAsTouched();
          this.formGroup.controls[name].updateValueAndValidity();
        }
      });
  }

  public fieldInvalid(path: Array<string | number> | string): boolean {
    const formControl = this.formGroup.get(path);
    return formControl.invalid && (formControl.dirty || formControl.touched);
  }

  public fieldValid(path: Array<string | number> | string): boolean {
    const formControl = this.formGroup.get(path);
    return formControl.valid && (formControl.dirty || formControl.touched);
  }

  public fieldHasError(path: Array<string | number> | string, error: string): any {
    return this.fieldInvalid(path) ? this.formGroup.get(path).hasError(error) : null;
  }

  public fieldInEditMode(path: Array<string | number> | string, error: string): any {
    return this.formGroup.get(path).hasError(error);
  }

  public getFieldValue(path: Array<string | number> | string): any {
    return this.formGroup.get(path).value;
  }

  public getField(path: Array<string | number> | string): AbstractControl {
    return this.formGroup.get(path);
  }

  public setFieldValue(key: string, value: any): void {
    const fieldValue = {};
    fieldValue[key] = value;

    this.formGroup.patchValue(fieldValue);
  }

  public setAlert(alertCode: FormAlertCode, alertDescription: string) {
    this.formGroupAlert = {
      code: alertCode,
      description: alertDescription,
      cssClass: 'info'
    };

    switch (alertCode) {
      case FormAlertCode.Danger:
        this.formGroupAlert.cssClass = 'danger';
        break;
      case FormAlertCode.Success:
        this.formGroupAlert.cssClass = 'success';
        break;
      case FormAlertCode.Warning:
        this.formGroupAlert.cssClass = 'warning';
        break;
      default:
    }
  }

  public clearAlert() {
    this.formGroupAlert = null;
  }

  public resetForm() {
    this.formGroup.reset();
  }

}
