import { Injectable }                                                        from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppDomain }                                                         from '@app/app.constants';
import { LanguageService }                                                   from '@services/i18n.service';
import { NavigationService }                                                 from '@services/navigation.service';
import { Observable }                                                        from 'rxjs';

const B2B_FREELANCERS_SIGNUP_REQUIRED_REGEX = /^.+(freelancers\/candidate-profiles\/\d+)$/;

@Injectable({
  providedIn: 'root'
})
export class AuthTokenGuard implements CanActivate {
  constructor(
    public navigationService: NavigationService,
    public languageService: LanguageService,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isAuthenticated = !!localStorage.getItem('auth_token');
    if (isAuthenticated) {
      return true;
    }
    if (window.location.href.indexOf(AppDomain) >= 0) {

      const currentLang = this.languageService.currentLang;
      let currentUrl = state.url;
      if (currentUrl.substr(-1, 1) === '/') {
        currentUrl = currentUrl.substr(0, currentUrl.length - 1);
      }
      const isSignupBeforeViewCvRequired = B2B_FREELANCERS_SIGNUP_REQUIRED_REGEX.test(currentUrl);

      if (window.location.href.indexOf(AppDomain) >= 0) {
        if (isSignupBeforeViewCvRequired) {
          // const freelancerId = currentUrl.split('/').pop();
          window.location.href = '/' + currentLang + '/auth/business/signup/?redirectTo=' + encodeURIComponent(currentUrl);
        } else {
          // this.navigationService.navigateByUrl(currentLang + '/auth/login/', {
          //   queryParams: {
          //     redirectTo: encodeURIComponent(currentUrl)
          //   }
          // });
          window.location.href = '/' + currentLang + '/auth/login/?redirectTo=' + encodeURIComponent(currentUrl);
        }
      }
    }

    return false;
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(childRoute, state);
  }

}
