import { Component }     from '@angular/core';
import { LoaderService } from '@services/loader-service.service';

import { UtilsService } from '@services/utils.service';

@Component({
  selector: 'app-loader',
  templateUrl: 'loader.component.html',
  styleUrls: ['loader.component.scss']
})
export class LoaderComponent {
  loading = false; // true
  constructor(
    public loaderService: LoaderService,
    public navigationService: UtilsService
  ) {
    // this.loaderService.isLoading.subscribe((v) => {
    //   this.loading = v;
    // });
  }

}
