<div class="alert upgrade-prompt">
  <a
    class="btn-close-dialog"
    (click)="close(false)">
    <mat-icon>close</mat-icon>
  </a>
  <div
    class="body">
    <h2 *ngIf="data.subscription">{{'B2B_ASSESSMENT_UPGRADE_PROMPT_SUBSCRIPTION_MODE_TITLE' | translate}}</h2>
    <h2 *ngIf="data.credits">{{'B2B_ASSESSMENT_UPGRADE_PROMPT_CREDITS_MODE_TITLE' | translate}}</h2>
    <p *ngIf="data.subscription && this.data.days_remaining > 1">
      {{'B2B_ASSESSMENT_UPGRADE_PROMPT_SUBSCRIPTION_MODE_BODY' | translate: {days_remaining: this.data.days_remaining} }}
    </p>
    <p *ngIf="data.subscription && this.data.days_remaining === 0">
      {{'B2B_ASSESSMENT_UPGRADE_PROMPT_SUBSCRIPTION_MODE_BODY_TODAY' | translate: {days_remaining: this.data.days_remaining} }}
    </p>
    <p *ngIf="data.subscription && this.data.days_remaining === 1">
      {{'B2B_ASSESSMENT_UPGRADE_PROMPT_SUBSCRIPTION_MODE_BODY_TOMMORROW' | translate: {days_remaining: this.data.days_remaining} }}
    </p>
    <p *ngIf="data.credits">
      {{'B2B_ASSESSMENT_UPGRADE_PROMPT_CREDITS_MODE_BODY' | translate: {credits_remaining: this.data.credits_remaining} }}
    </p>
    <div class="buttons">
      <button
        class="btn"
        (click)="close(true)">
        <mat-icon>check</mat-icon>
        {{ "B2B_ASSESSMENT_UPGRADE_PROMPT_CONFIRM" | translate }}
      </button>
      <button
        class="btn"
        (click)="close(false)">
        <mat-icon>clear</mat-icon>
        {{ "B2B_ASSESSMENT_UPGRADE_PROMPT_DISMISS" | translate }}
      </button>
    </div>
  </div>
</div>
