import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CompanyProfileModule} from '@b2b/@common/modules/company-profile/company-profile.module';
import {LicenseModule} from '@b2b/@common/modules/license/license.module';
import {MaterialModule} from '@common/material/material.module';
import {PipesModule} from '@common/pipes/pipes.module';
import {TranslateModule} from '@ngx-translate/core';
import {B2bSidebarFiltersInlineComponent} from './cpts/filters/b2b-sidebar-filters-inline.component';
import {B2bSidebarFiltersComponent} from './cpts/filters/b2b-sidebar-filters.component';
import {StarComponent} from './cpts/star/star.component';
import {B2BTopBannerComponent} from './cpts/top-banner/b2b-top-banner.component';
import {LocalizeRouterModule} from 'localize-router';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [
    B2bSidebarFiltersComponent,
    B2bSidebarFiltersInlineComponent,
    B2BTopBannerComponent,
    StarComponent,
  ],
  exports: [
    CompanyProfileModule,
    LicenseModule,
    B2bSidebarFiltersComponent,
    B2bSidebarFiltersInlineComponent,
    B2BTopBannerComponent,
    StarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MaterialModule,
    CompanyProfileModule,
    ReactiveFormsModule,
    PipesModule,
    LicenseModule,
    LocalizeRouterModule,
    RouterModule
  ]
})
export class CommonB2bModule {
}
