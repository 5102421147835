<main>
  <section class="page-block page-hero" appSectionFullPage>
    <div class="page-block-container">
      <div class="page-block-overlay"></div>
      <div class="page-block-content">
        <div class="form-container">
          <div class="form-header">
            <h1>{{ 'PAGE_RESET_PASSWORD_CONFIRM_MESSAGE' | translate }}</h1>
          </div>
          <app-form-reset-password-confirm
            [uid]="uid"
            [token]="token"
            (onSuccess)="onFormResetPasswordSuccess($event)">
          </app-form-reset-password-confirm>
        </div>
      </div>
    </div>
  </section>
</main>
