import { CommonModule }               from '@angular/common';
import { NgModule }                   from '@angular/core';
import { SignupTermsModalComponent }  from '@common/auth/cpts/signup-terms-modal/signup-terms-modal.component';
import { TranslateModule }            from '@ngx-translate/core';
import { SocialAuthButtonsComponent } from './cpts/social-auth-buttons/social-auth-buttons.component';


@NgModule({
  declarations: [SocialAuthButtonsComponent],
  exports: [
    SocialAuthButtonsComponent
  ],
  entryComponents: [SignupTermsModalComponent],
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class SocialModule {
}
