import { Location }                                                                          from '@angular/common';
import { HttpClient, HttpParams }                                                            from '@angular/common/http';
import { EventEmitter, Injectable }                                                          from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationExtras, Params, Router }                 from '@angular/router';
import { ApiEndpoints }                                                                      from '@app/app.constants';
import { LangChangeEvent, TranslateLoader, TranslateService }                                from '@ngx-translate/core';
import { TranslateHttpLoader }                                                               from '@ngx-translate/http-loader';
import { LocalizeParser, LocalizeRouterService, LocalizeRouterSettings, ManualParserLoader } from 'localize-router';
import { Cookie }                                                                            from 'ng2-cookies';
import { Observable, Subscription }                                                          from 'rxjs';
import { filter }                                                                            from 'rxjs/operators';
import { LoaderService, LoaderState }                                                        from './loader-service.service';
import { UtilsService }                                                                      from './utils.service';

export interface LanguageItem {
  label?: string;
  value: any;
  styleClass?: string;
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  public currentLangChanged = new EventEmitter();
  public preventDefault = false;
  public availableLanguages: LanguageItem[];
  public prevLang: string;

  public navigationEndEventListener: Subscription;
  public navigationCancelEventListener: Subscription;

  constructor(
    private parser: LocalizeParser,
    private _translateService: TranslateService,
    private _localizeRouterService: LocalizeRouterService,
    private _loaderService: LoaderService,
    private _utilsService: UtilsService,
    private _router: Router,
    private _location: Location
  ) {
    this._initLanguageChangeEventListener();
    this._initAvailableLanguages();
  }

  public get locales(): string[] {
    return this._localizeRouterService.parser.locales;
  }

  public get newLanguage(): string {
    return (window as any).newLanguage;
  }

  public set newLanguage(lang: string) {
    (window as any).newLanguage = lang;
  }

  public get currentLang(): string {
    return this._localizeRouterService.parser.currentLang || 'en';
  }

  public set currentLang(lang: string) {
    if (lang === this.currentLang) {
      return;
    }
    if (this.preventDefault) {
      this.currentLangChanged.emit(lang);

      return;
    }

    // Let HttpService & LanguageService to quickly catch the changes made to language selection.
    this.newLanguage = lang;
    const navigationExtras: NavigationExtras = {
      queryParams: this.getCurrentQueryParameters()
    };

    this.prevLang = this.currentLang;

    // this._localizeRouterService.changeLanguage(lang, navigationExtras, true);
    this._localizeRouterService.changeLanguage(lang);
    this._loaderService.setState(LoaderState.Pending);

    this.subscribeNavigationEndEventListener();
    this.subscribeNavigationCancelEventListener(navigationExtras);
  }

  public getCurrentLanguage() {
    if ((window as any).newLanguage) {
      return (window as any).newLanguage;
    }

    const langRegex = /^\/(en|fr)\/.*$/g;
    const match = langRegex.exec(window.location.pathname);

    if (match && match.length >= 2) {
      return match[1];
    }

    return Cookie.get('django_language') || 'en';

  }

  public getValue(key: string, param?: any): any {
    return this._translateService.instant(key, param);
  }

  public getValueAsync(key: string, param?: any): Observable<any> {
    return this._translateService.get(key, param);
  }

  public translateUrl(url: string): string {
    return this._location.normalize(this._localizeRouterService.translateRoute(url).toString());
  }

  public subscribeNavigationEndEventListener() {
    this.navigationEndEventListener = this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
        this._loaderService.setState(LoaderState.Done);
        this.unsubscribeNavigationEndEventListener();
      });
  }

  public unsubscribeNavigationEndEventListener() {
    if (this.navigationEndEventListener) {
      this.navigationEndEventListener.unsubscribe();
      this.navigationEndEventListener = null;
    }
  }

  public subscribeNavigationCancelEventListener(navigationExtras: NavigationExtras) {
    this.navigationCancelEventListener = this._router.events
      .pipe(filter((event) => event instanceof NavigationCancel)).subscribe(() => {
        if (this.currentLang !== this.prevLang) {
          this.newLanguage = this.prevLang;
          // this._localizeRouterService.changeLanguage(this.prevLang, navigationExtras, true);
          this._localizeRouterService.changeLanguage(this.prevLang);
        }
        this._loaderService.setState(LoaderState.Done);
        this.unsubscribeNavigationCancelEventListener();
      });
  }

  public unsubscribeNavigationCancelEventListener() {
    if (this.navigationCancelEventListener) {
      this.navigationCancelEventListener.unsubscribe();
      this.navigationCancelEventListener = null;
    }
  }

  switchLanguage(language: string, options: NavigationExtras, useNavigateMethod: boolean) {
    if (language !== this.parser.currentLang) {
      const currentUrl: string = this._router.url;
      const newRoute: string = this.parser.translateRoute(currentUrl.replace(this.parser.currentLang, language));
      this.parser.translateRoutes(language).subscribe(() => {
        // reset the router config so that routes are dynamically loaded.
        this._router.resetConfig(this.parser.routes);

        const extras = Object.assign({skipLocationChange: false}, options);
        if (useNavigateMethod) {
          this._router.navigate([newRoute], extras);
        } else {
          this._router.navigateByUrl(newRoute, extras);
        }
      });
    }
  }

  private _initLanguageChangeEventListener() {
    this._translateService.onLangChange
      .subscribe((event: LangChangeEvent) => {
        // fix for ito
        Cookie.set('django_language', event.lang, 365, '/');

        // Let HttpService & LanguageService to quickly catch the changes made to language selection.
        this.newLanguage = event.lang;

        if (!this.preventDefault) {
          this.currentLangChanged.emit(event.lang);
        }
      });
  }

  private _initAvailableLanguages() {
    if (this._localizeRouterService.parser.locales) {
      this.availableLanguages = this._localizeRouterService.parser.locales.map((item: string) => {
        return {
          label: item,
          value: item
        };
      });
    }
  }


  // Helpers
  // ==============================

  private getCurrentQueryParameters(): Params {
    const currentQueryParams: Params = {};
    const searchParams: HttpParams = this._utilsService.getQueryParams();

    return currentQueryParams;
  }
}


export const TRANSLATE_MODULE_PROVIDERS = {
  provide: TranslateLoader,
  useFactory: (createTranslateLoader),
  deps: [HttpClient]
};

export const LOCALIZE_ROUTER_MODULE_PARSER = {
  parser: {
    provide: LocalizeParser,
    useFactory: (createManualParseLoader),
    deps: [TranslateService, Location, LocalizeRouterSettings]
  }
};

export const TRANSLATE_MODULE_LOADER = {
  loader: {
    provide: TranslateLoader,
    useFactory: createTranslateLoader,
    deps: [HttpClient]
  }
};

export function createManualParseLoader(translate, location, settings) {
  return new ManualParserLoader(translate, location, settings, ['en', 'fr'], 'ROUTES.');
}

export function createTranslateLoader(httpService: HttpClient) {
  return new TranslateHttpLoader(
    httpService,
    ApiEndpoints.multiLanguage.base, '/'
  );
}

