import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Cookie} from 'ng2-cookies';
import {from, Observable, throwError} from 'rxjs';
import {AuthService} from '../auth/services/auth.service';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorService} from '@services/http-error.service';
import {ApiEndpoints} from '@app/app.constants';
import {HttpService} from '@services/http.service';
import {UtilsService} from '@services/utils.service';
import {LanguageService} from '@services/i18n.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private http: HttpService,
    private httpErrorService: HttpErrorService,
    private utilsService: UtilsService,
    private languageService: LanguageService,
    private matSnackBar: MatSnackBar
  ) {
  }

  private static getCurrentLanguage() {
    if ((window as any).newLanguage) {
      return (window as any).newLanguage;
    }

    const langRegex = /^\/(en|fr)\/.*$/g;
    const match = langRegex.exec(window.location.pathname);

    if (match && match.length >= 2) {
      return match[1];
    }

    return Cookie.get('django_language') || 'en';
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const acceptLanguage = RequestInterceptor.getCurrentLanguage();
    // response.setHeader("Set-Cookie", "HttpOnly;Secure;SameSite=Strict");
    const clonedRequest = request.clone({
      withCredentials: true,
      setHeaders: {
        Accept: 'application/json',
        'Accept-Language': acceptLanguage,
        // 'Set-Cookie': 'HttpOnly;Secure;SameSite=Strict',
        Forced: this.authService.isForcedLogin,
        ...this.authService.authTokenB2B && {Authorization: this.authService.isNewLogin ? `Bearer ${this.authService.authTokenB2B}` : `Token ${this.authService.authTokenB2B}`},
      }
    });
    return next.handle(clonedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
          return from(this.handleTokenError(error, next, clonedRequest));
        } else {
          return throwError(error);
        }
      })
    ) as Observable<HttpEvent<any>>;
  }

  private handleTokenError(error: HttpErrorResponse, next: HttpHandler, clonedRequest: HttpRequest<any>) {
    if (error.error.detail.toLowerCase() === 'signature has expired') {
      const body = {
        refresh_token: localStorage.getItem(AuthService.refreshTokenB2BKey ?
          AuthService.refreshTokenB2BKey : AuthService.refreshTokenB2CKey)
      };
      if (!body.refresh_token) {
        body.refresh_token = localStorage.getItem(this.utilsService.appType() === 'b2b' ?
          AuthService.refreshTokenB2BKey : AuthService.refreshTokenB2CKey)
      }
      return this.http.post(ApiEndpoints.auth.refreshToken, body)
        .pipe(map(async (response: any) => {
          this.authService._handleAuthResponse(response);
          const authRequest: HttpRequest<any> = await new Promise((resolve) => {
            const req = clonedRequest.clone({
              setHeaders: {
                Authorization: 'Bearer ' + response.access_token,
              }
            });
            return resolve(req);
          });

          // const headersKey = 'headers';
          // // replace old auth token from header with the one received on refresh
          // clonedRequest.headers[headersKey].set('authorization', 'Bearer ' + response.access_token);
          // return next.handle(clonedRequest).toPromise();
          if (clonedRequest.url.indexOf('user') > -1) {
            this.authService.clearSession();
            window.location.href = `/${this.languageService.currentLang}/auth/login`;
          }
          return next.handle(authRequest).toPromise();
        }))
        .pipe(catchError((err: HttpErrorResponse) => {
          return throwError(this.httpErrorService.parseError(err));
        }));
    } else {
      if (!!error &&
        error.error.detail.toLowerCase() !== 'authentication credentials were not provided.' &&
        error.error.code !== 'invalid-license') {
        this.redirectToLogin();
      }
      return throwError(error);
    }
  }

  redirectToLogin() {
    this.matSnackBar.open(
      this.languageService.getValue('SESSION_EXPIRED'),
      null,
      {
        duration: 4000,
        panelClass: ['error']
      });
    this.authService.clearSession();
    window.location.href = `/${this.languageService.currentLang}/auth/login`;
  }
}
