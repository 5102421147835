import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EndpointService {

  // public getEndpointFor(url: string, type: number = 1) {
  //   const stringToSearch = 'api';
  //   const stringToSearchIndex = url.indexOf(stringToSearch);
  //   const stringToSearchLastIndex = stringToSearchIndex + stringToSearch.length;
  //   const isB2BRequest = EndpointType[EndpointType.b2b] === EndpointType[EndpointType[type]] && stringToSearchIndex > -1;
  //   const firstFragment = url.slice(0, stringToSearchLastIndex);
  //   const lastFragment = url.slice(stringToSearchLastIndex);
  //   const dynamicFragment = isB2BRequest ? '/b2b' : '';
  //
  //   return firstFragment + dynamicFragment + lastFragment;
  // }

  public getEndpoint(url: string = '', type: string = 'b2c') {
    const stringToSearch = 'api';
    const stringToSearchIndex = url.indexOf(stringToSearch);
    const stringToSearchLastIndex = stringToSearchIndex + stringToSearch.length;
    const isB2BRequest = type === 'b2b' && stringToSearchIndex > -1;
    const firstFragment = url.slice(0, stringToSearchLastIndex);
    const lastFragment = url.slice(stringToSearchLastIndex);
    const dynamicFragment = isB2BRequest ? '/b2b' : '';

    return firstFragment + dynamicFragment + lastFragment;
  }

  public checkIfRouteIs(pathname: string) {
    return window.location.pathname && window.location.pathname.indexOf(pathname) !== -1;
  }

}

export enum EndpointType {
  b2b = 0,
  b2c
}
