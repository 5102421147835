import { Injectable }                          from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthService }                         from '@common/auth/services/auth.service';
import { B2BAppType, PlatformsAccessService }  from '@services/platforms-access.service';

@Injectable({
  providedIn: 'root'
})
export class AccessPlatformsGuard implements CanActivate {
  constructor(
    public authService: AuthService,
    public platformsAccessService: PlatformsAccessService,
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot) {
    return this.authService.getUserDetails({type: 'b2b'}, false).toPromise().then(async (user: any) => {
      const platformType: B2BAppType = this.platformsAccessService.getB2BApplicationTypeFromRoute(route);
      await this.platformsAccessService.switchDefaultPlatform(platformType, user).toPromise();
      return true;
    }).catch(() => {
      return false;
    });
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot) {
    return this.canActivate(childRoute);
  }

}
