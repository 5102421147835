import { Component, Inject, OnInit }     from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService }                   from '@common/auth/services/auth.service';

@Component({
  selector: 'app-license-upgrade-prompt-modal',
  templateUrl: './license-upgrade-prompt-modal.component.html',
  styleUrls: ['./license-upgrade-prompt-modal.component.scss']
})
export class LicenseUpgradePromptModalComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<LicenseUpgradePromptModalComponent>,
    public authService: AuthService
  ) {
  }

  public close(response: boolean) {
    this.matDialogRef.close(response);
  }

}
