<main>
  <section class="page-block page-hero" style="background-image: url(/assets/img/pages/home/hero-bg.jpg);" appSectionFullPage>
    <div class="page-block-container">
      <div class="page-block-overlay"></div>
      <div class="page-block-content">
      </div>
    </div>
  </section>
</main>

