import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup }               from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef }        from '@angular/material/dialog';
import { B2cUserType }                          from '@common/auth/enums/b2c-user-type.enum';
import { UtilsService }                         from '@services/utils.service';
import { Subject }                              from 'rxjs';

export interface SignupTermsModalData {
  formData: any;
  type: string;
}

@Component({
  selector: 'app-signup-terms-modal',
  templateUrl: './signup-terms-modal.component.html',
  styleUrls: ['./signup-terms-modal.component.scss']
})
export class SignupTermsModalComponent implements OnInit, OnDestroy {

  public B2cUserType = B2cUserType;
  public appType: string;
  public isB2C: boolean;
  public isB2F: boolean;
  public isB2B: boolean;

  public termsForm: FormGroup;

  public formAvailableApplicationTypes: any = {
    b2bTypes: [
      {
        title: 'GLOBAL_LABEL_B2B_TYPE_LABEL',
        val: null
      }, {
        title: 'B2B_REGISTER_TYPE_ASSESMENT_LABEL',
        val: 'assessment'
      }, {
        title: 'B2B_REGISTER_TYPE_FREELANCER_LABEL',
        val: 'freelancers'
      }]
  };

  public selectedApplicationTypeOption = this.formAvailableApplicationTypes.b2bTypes[0];

  private _destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: SignupTermsModalData,
    private dialogRef: MatDialogRef<SignupTermsModalComponent>,
    private utilsService: UtilsService,
  ) {
  }

  get type() {
    return this.termsForm.get('type');
  }

  get terms() {
    return this.termsForm.get('terms');
  }

  get newsletter() {
    return this.termsForm.get('newsletter');
  }

  public ngOnInit(): void {
    this.setDefaults(this.dialogData.type);
    this.buildForm();
  }

  // Form
  // ==============================

  public ngOnDestroy(): void {
    this.closeChangeSubscriptions();
  }

  // Event Handlers
  // ==============================

  public closeChangeSubscriptions() {
    this._destroyed$.next();
    this._destroyed$.unsubscribe();
  }

  public setDefaults(type?: string) {
    this.appType = this.utilsService.appType();
    this.isB2C = this.appType === 'b2c' && type === B2cUserType[B2cUserType.coder];
    this.isB2F = this.appType === 'b2c' && type === B2cUserType[B2cUserType.freelancer];
    this.isB2B = this.appType === 'b2b';
  }

  // Form Fields
  // ==============================

  public buildForm() {
    const formData = this.dialogData.formData;

    if (formData && formData.type && formData.type.val) {
      this.selectedApplicationTypeOption = this.formAvailableApplicationTypes.b2bTypes.filter((item) => item.val === formData.type.val)[0];
    }

    this.termsForm = new FormGroup({
      type: new FormControl(this.selectedApplicationTypeOption),
      terms: new FormControl(this.dialogData.formData ? this.dialogData.formData.terms : false),
      newsletter: new FormControl(this.dialogData.formData ? this.dialogData.formData.newsletter : false)
    });
  }

  public onAccessTypeChange(event) {
    const switchedToAssessment = event && event.val === 'assessment';
    const switchedToFreelancers = event && event.val === 'freelancers';
    if (switchedToFreelancers || switchedToAssessment) {
      if (!this.formAvailableApplicationTypes.b2bTypes[0].val) {
        this.formAvailableApplicationTypes.b2bTypes.splice(0, 1);
      }
    }
  }

  public onSubmit(event) {
    this.dialogRef.close(this.termsForm.value);
  }
}
