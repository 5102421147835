import { Injectable }                from '@angular/core';
import { ApiEndpoints, environment } from '@app/app.constants';
import { AuthService }               from '@common/auth/services/auth.service';
import { HttpService }               from '@services/http.service';
import { loadStripe }                from '@stripe/stripe-js';
import { map }                       from 'rxjs/operators';


declare let window: any;

@Injectable({
  providedIn: 'root'
})

export class StripeService {

  constructor(
    private _authService: AuthService,
    private _http: HttpService,
  ) {
  }

  getSellableSubscriptions(): Promise<SellableSubscriptions> {
    return this._http
      .get(ApiEndpoints.license.sellableSubscriptions)
      .pipe(map((data: SellableSubscriptions) => {
        return data;
      }))
      .toPromise();
  }

  getSellableCreditPacks(): Promise<SellableCreditPacks> {
    return this._http
      .get(ApiEndpoints.license.sellableCreditPacks)
      .pipe(map((data: SellableCreditPacks) => {
        return data;
      })).toPromise();
  }

  buyProduct(productType, productId, currencySymbol, calculatedPrice, numberOfUsers, numberOfTests) {
    const successUrl = window.location.href.split('?')[0] + '?successPayment=true';
    const cancelUrl = window.location.href.split('?')[0] + '?successPayment=false';

    const productInfo: CheckoutProduct = {
      product_type: productType,
      product_id: productId,
      amount: calculatedPrice,
      number_of_users: numberOfUsers,
      number_of_tests: numberOfTests,
      currency: currencySymbol,
      success_url: successUrl,
      cancel_url: cancelUrl,
    };
    return new Promise((resolve, reject) => {
      this._http.post(ApiEndpoints.payments.buyProduct, productInfo, this._authService.authRequestOptions)
        .subscribe(async (data: any) => {
          const stripe = await loadStripe(environment.stripePublishableAPIKey);

          const {error} = await stripe.redirectToCheckout({
            sessionId: data.session_id
          });
          resolve(true);
        }, (error) => {
          reject(error);
        });
    });
  }
}

export interface SellableSubscriptions {
  count: number,
  next: string,
  previous: string,
  results: SellableItem[]
}

export interface SellableCreditPacks {
  count: number,
  next: string,
  previous: string,
  results: SellableItem[]
}

export interface SellableItem {
  id: number;
  name: string;
  description: string;
  unit: string;
  number_of_units: number;
  price_per_unit_eur: number;
  price_per_unit_usd: number;
  total_price_eur: number;
  total_price_usd: number;
  savings_eur: number;
  savings_usd: number;
  numberOfUsers: number,
  numberOfTests: number,
  calculated_price_eur: number,
  calculated_price_usd: number,
}

export interface CheckoutProduct {
  product_type: string;
  product_id: number;
  currency: string;
  success_url: string;
  cancel_url: string;
  amount: number,
  number_of_users: number,
  number_of_tests: number,
}
