import { Component }         from '@angular/core';
import { AuthService }       from '@common/auth/services/auth.service';
import { LanguageService }   from '@services/i18n.service';
import { NavigationService } from '@services/navigation.service';
import { SiteConfigService } from '@services/site-config.service';
import { UtilsService }      from '@services/utils.service';

@Component({
  selector: 'app-signup-confirm',
  templateUrl: './signup-confirm.component.html',
  styleUrls: ['./signup-confirm.component.scss']
})

export class SignupConfirmComponent {

  constructor(
    public siteConfigService: SiteConfigService,
    public utilsService: UtilsService,
    public navigationService: NavigationService,
    public authService: AuthService,
    public languageService: LanguageService
  ) {
    //
  }

  public goBackToApplication() {
    this.authService.goBackToApplication(this.utilsService.appType());
  }

}
