import { Component, OnInit } from '@angular/core';
import { AuthService }       from '@common/auth/services/auth.service';
import { LanguageService }   from '@services/i18n.service';

@Component({
  selector: 'app-b2c-sidebar',
  templateUrl: './b2c-sidebar.component.html',
  styleUrls: ['./b2c-sidebar.component.scss']
})
export class B2cSidebarComponent implements OnInit {
  disableTooltip = true;
  isFreelancer = true;

  constructor(
    public languageService: LanguageService,
    public authService: AuthService,
  ) {
  }

  public get algoplayLink() {
    return this.languageService.currentLang === 'fr' ?
      '/fr/exercices-de-programmation/' : '/en/programming-exercises/';
  }

  ngOnInit() {
    this.checkIsFreelancer();
  }

  checkIsFreelancer() {
    this.isFreelancer = this.authService.currentUserDetails.is_freelancer;
  }
}
