import {Component, OnInit} from '@angular/core';
import {LanguageService} from '@services/i18n.service';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '@common/auth/services/auth.service';
import {LoaderService, LoaderState} from '@services/loader-service.service';

@Component({
  selector: 'app-route.logout',
  templateUrl: './route.logout.component.html',
  styleUrls: ['./route.logout.component.scss']
})
export class RouteLogoutComponent implements OnInit {

  constructor(
    public languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private loaderService: LoaderService
  ) {
  }

  public get isB2b() {
    return document.cookie.indexOf(this.authService.authTokenB2BKeyFor3rdPartyApps) !== -1;
  }

  async ngOnInit() {
    this.loaderService.setState(LoaderState.Pending);

    if (this.isB2b) {
      await this.authService.signOutB2B();
    } else {
      await this.authService.signOutB2C();
    }
  }

}
