import { Injectable }              from '@angular/core';
import { MaterialSnackBarService } from '@services/material-snack-bar.service';
import { Observable }              from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConnectionMonitorService {
  public internetOnline = false;
  readonly connectionMonitor: Observable<boolean>;

  constructor(
    private _matSnackBarService: MaterialSnackBarService
  ) {
    this.connectionMonitor = new Observable((observer) => {
      window.addEventListener('offline', (e) => {
        observer.next(false);
      });
      window.addEventListener('online', (e) => {
        observer.next(true);
      });
    });
  }

  public init() {
    this.monitor().subscribe((status) => {
      this.internetOnline = status;
      if (!status) {
        const minutesInAnHour = 60;
        const secondsInAMinute = 60;
        const millisecondsInASecond = 1000;
        const hourInMs = minutesInAnHour * secondsInAMinute * millisecondsInASecond;
        this._matSnackBarService.openTop('GLOBAL_OFFLINE_INTERNET', {panelClass: ['error'], duration: hourInMs});
      }
      if (status) {
        this._matSnackBarService.close();
        this._matSnackBarService.openTop('GLOBAL_ONLINE_INTERNET', {panelClass: ['success'], duration: 5000});
      }
    });
  }

  private monitor(): Observable<boolean> {
    return this.connectionMonitor;
  }
}
