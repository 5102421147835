<div appPageHeader class="page-header-container">
  <router-outlet name="page-header"></router-outlet>
</div>

<div class="router-outlet-container">
  <div *ngIf="navigationService.pending" [@fadeOutAnimation] class="router-outlet-loader"></div>
  <router-outlet></router-outlet>
</div>

<div class="page-footer-container">
  <router-outlet name="page-footer"></router-outlet>
</div>
