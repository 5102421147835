import { Component, Inject }             from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-material-alert',
  templateUrl: './material-alert.component.html',
  styleUrls: ['./material-alert.component.scss']
})
export class MaterialAlertComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<MaterialAlertComponent>
  ) {

    if (Array.isArray(data.buttons) && (typeof data.buttons !== 'undefined' && !data.buttons.length) && data.closeAfter !== false) {
      setTimeout(() => {
        this._dialogRef.close({type: 'dismiss', response: false});
      }, data.closeAfter * 1000);
    }
  }

  public confirm(type: string = 'accept', response: any = true) {
    this._dialogRef.close({type, response});
  }

}
