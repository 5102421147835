<div class="see-token-modal">
  <a
    class="btn-close-dialog"
    (click)="close(false)">
    <mat-icon>close</mat-icon>
  </a>
  <div class="password-submit-container form-group">
    <div class="password-submit-container__message">
      {{'B2B_COMPANY_PROFILE_SEE_ASSESSMENT_TOKEN_PASSWORD_REQUEST_MESSAGE' | translate}}
    </div>
    <div class="password-submit-container__body">
      <input [(ngModel)]="passwordForToken" class="form-control" type="password"
             (keyup.enter)="checkPassword()"
             (keyup.escape)="close(false)"
             (input)="clearValidators()"
             placeholder="{{ 'GLOBAL_LABEL_PASSWORD' | translate }}">
      <button (click)="checkPassword()" class="btn btn-check-password">
        {{'LEGAL_FORM_GLOBAL_SUBMIT_BUTTON' | translate}}
      </button>
    </div>
    <div class="password-submit-container__errors">
      <span *ngIf="passwordNull" class="form-control-error">{{ 'GLOBAL_FIELD_WRONG_PASSWORD' | translate }}</span>
      <span *ngIf="passwordInvalid" class="form-control-error">{{ 'SIGNUP_FORM_ERROR_INVALID_DATA' | translate }}</span>
    </div>
  </div>
</div>
