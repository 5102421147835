<div class="decorative-rhombus">
  <div class="rhombus"></div>
</div>

<mat-icon class="btn-close" mat-dialog-close>close</mat-icon>
<div
  class="stripe-modal-container"
  mat-dialog-content
  [class.isLoading]="isLoading">
  <div class="loading-panel" *ngIf="isLoading">
    <div class="rotating-container">
      <mat-icon>rotate_right</mat-icon>
    </div>
  </div>
  <div class="stripe-modal-header">
    <div
      *ngIf="!showCheckout"
      [innerHTML]="'B2B_STRIPE_HEADER_CHOOSE_OPTION' | translate"
      class="stripe-modal-header--title">
    </div>
    <div
      *ngIf="showCheckout"
      [innerHTML]="'B2B_STRIPE_HEADER_REVIEW_INFO' | translate"
      class="stripe-modal-header--title">
    </div>
    <div class="current-currency">
      <div class="currency-switcher-text">
      </div>
      <app-currency-switcher
        class="currency-switcher-wrapper">
      </app-currency-switcher>
    </div>
  </div>
  <div class="stripe-modal-body">
    <div
      class="company-details-wrapper"
      [@fadeInAnimation]
      *ngIf="showCheckout">
      <div class="checkout-row">
        <div class="checkout-column-left">
          <h2>
            {{ 'B2B_STRIPE_HEADER_BILLING_INFO' | translate }}
            <span
              *ngIf="!isEditingCompanyDetails"
              [@fadeInAnimation]
              class="edit-wrapper">
              (<a
              class="edit-button"
              (click)="editCompany()">
                {{ 'B2B_GLOBAL_BUTTON_EDIT' | translate }}
              </a>)
            </span>
          </h2>
          <div
            [@fadeInAnimation]
            *ngIf="!isEditingCompanyDetails">
            <div class="billing-info-container">
              <div class="billing-info-row">
                <div class="billing-info-column-left">
                  {{ 'B2B_COMPANY_LABEL_NAME' | translate }}
                </div>
                <div class="billing-info-column-right">
                  {{authService.currentUserDetails.company_name}}
                </div>
              </div>
              <div class="billing-info-row">
                <div class="billing-info-column-left">
                  {{ 'B2B_COMPANY_LABEL_COUNTRY' | translate }}
                </div>
                <div class="billing-info-column-right">
                  {{authService.currentUserDetails.company_country}}
                </div>
              </div>
              <div class="billing-info-row">
                <div class="billing-info-column-left">
                  {{ 'B2B_COMPANY_LABEL_CITY' | translate }}
                </div>
                <div class="billing-info-column-right">
                  {{authService.currentUserDetails.company_city}}
                </div>
              </div>
              <div class="billing-info-row">
                <div class="billing-info-column-left">
                  {{ 'B2B_COMPANY_LABEL_ADDRESS' | translate }}
                </div>
                <div class="billing-info-column-right">
                  {{authService.currentUserDetails.company_address}}
                </div>
              </div>
              <div class="billing-info-row">
                <div class="billing-info-column-left">
                  {{ 'B2B_COMPANY_VAT_NUMBER' | translate }}
                </div>
                <div class="billing-info-column-right">
                  {{authService.currentUserDetails.company_vat_number}}
                </div>
              </div>
            </div>
          </div>
          <div
            [@fadeInAnimation]
            *ngIf="isEditingCompanyDetails">
            <app-company-edit
              [formLocation]="companyFormEnum.checkout"
              (onCancel)="onCompanyFormCancel()"
              (onSaveCompleted)="onCompanyFormSave()"
              (onCountryChange)="onCountryChange($event)"
              (onError)="onFormError()"
              [events]="eventsSubject"
              [data]="authService.currentUserDetails">
            </app-company-edit>
          </div>
        </div>
        <div class="checkout-column-right">
          <div class="summary-container">
            <h2>
              {{ 'B2B_STRIPE_ORDER_SUMMARY' | translate }}
              <span
                class="edit-wrapper">
                (<a
                class="edit-button"
                (click)="toggleCheckout()">
                  {{ 'B2B_GLOBAL_BUTTON_EDIT' | translate }}
                </a>)
            </span>
            </h2>
            <div class="summary-row">
              <div class="summary-column-left">
                {{'B2B_STRIPE_SELECTED_PRODUCT_NAME' | translate}}
              </div>
              <div class="summary-column-right">
                {{ selectedProduct.name }}
              </div>
            </div>
            <div class="summary-row">
              <div class="summary-column-left">
                {{'B2B_STRIPE_SELECTED_PRODUCT_DESCRIPTION' | translate}}
              </div>
              <div class="summary-column-right">
                {{selectedProduct.description}}
              </div>
            </div>
            <div class="summary-row">
              <div class="summary-column-left">
                {{'B2B_STRIPE_SELECTED_PRODUCT_PRICE' | translate}}
              </div>
              <div class="summary-column-right">
                {{currencyService.activeCurrencySymbol}}
                {{ selectedProduct['calculated_price_' + currencyService.activeCurrencyId.toLowerCase()] | number: '1.2-2'}}
              </div>
            </div>
            <div class="summary-row">
              <div class="summary-column-left">
                  <span [innerHTML]="'B2B_STRIPE_SELECTED_PRODUCT_VAT' | translate: {
                    vat_value:vatValue * 100 | number: '1.2-2'}"></span>
              </div>
              <div class="summary-column-right">
                {{currencyService.activeCurrencySymbol}}
                {{selectedProduct['calculated_price_' + currencyService.activeCurrencyId.toLowerCase()] * vatValue | number: '1.2-2'}}
              </div>
            </div>
            <div class="summary-row total">
              <div class="summary-column-left">
                <span>
                  {{'B2B_STRIPE_SELECTED_PRODUCT_TOTAL_PRICE' | translate}}
                </span>
              </div>
              <div class="summary-column-right">
                <span
                  class="item-price"
                  *ngIf="currencyService.activeCurrencyId">
                  {{currencyService.activeCurrencySymbol}}
                  {{
                  selectedProduct['calculated_price_' + currencyService.activeCurrencyId.toLowerCase()] +
                  selectedProduct['calculated_price_' + currencyService.activeCurrencyId.toLowerCase()] * vatValue | number: '1.2-2'
                  }}
                </span>
              </div>
            </div>
            <div class="summary-row payment">
              <button
                [disabled]="savingData"
                class="btn btn-primary"
                (click)="validate()">
                <span *ngIf="!savingData">
                  <mat-icon>check</mat-icon>
                  {{'B2B_STRIPE_SELECTED_PRODUCT_BUTTON_PROCEED_TO_PAYMENT' | translate}}
                </span>
                <span *ngIf="savingData">
                  {{'B2B_STRIPE_SELECTED_PRODUCT_BUTTON_PROCESSING' | translate}}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      [@fadeInAnimation]
      *ngIf="!showCheckout">
      <mat-tab-group
        [dynamicHeight]="true"
        [disableRipple]="true"
        animationDuration="300ms"
        [(selectedIndex)]="activeTabIndex">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon>local_florist</mat-icon>
            <span>{{'B2B_PAGE_IT_ASSESSMENT_PRICING_SUBSCRIPTION_TAB_TITLE' | translate}}</span>
          </ng-template>
          <div class="short-info">
            <ul>
              <li>
                <mat-icon>check</mat-icon>
                <span [innerHTML]="'B2B_STRIPE_FIRST_SUBSCRIPTIONS_BENEFIT' | translate "></span>
              </li>
              <li>
                <mat-icon>check</mat-icon>
                <span [innerHTML]="'B2B_STRIPE_SECOND_SUBSCRIPTIONS_BENEFIT' | translate "></span>
              </li>
            </ul>
            <div class="decorative-border"></div>
          </div>
          <div class="item-sellable-container item-subscriptions">
            <div *ngFor="let item of allSubscriptions; let index=index" class="item-box">
              <div class="item-title">
                {{ item.name }}
              </div>
              <div class="item-user-counter">
                <a (click)="decreaseUserCounter(
                index, item.number_of_units,
                item['total_price_' + currencyService.activeCurrencyId.toLowerCase()])"
                   [ngClass]="{'disabled': selectedSubscriptionOptions[index].minHit}">
                  <mat-icon>remove_circle_outline</mat-icon>
                </a>
                <div class="number-of-users">
                  <span>{{selectedSubscriptionOptions[index].numberOfUsers}}</span>
                  <span *ngIf="selectedSubscriptionOptions[index].numberOfUsers <= 1">
                    {{ 'B2B_COMPANY_ACCOUNTS_COLUMN_USER' | translate }}
                  </span>
                  <span *ngIf="selectedSubscriptionOptions[index].numberOfUsers > 1">
                    {{ 'B2B_COMPANY_ACCOUNTS_USERS' | translate }}
                  </span>
                </div>
                <a (click)="increaseUserCounter(
                index, item.number_of_units,
                item['total_price_' + currencyService.activeCurrencyId.toLowerCase()])"
                   [ngClass]="{'disabled': selectedSubscriptionOptions[index].maxHit}">
                  <mat-icon>add_circle_outline</mat-icon>
                </a>
              </div>
              <div class="item-price">
                <span *ngIf="selectedSubscriptionOptions[index].numberOfTests === null">Unlimited tests</span>
                <span *ngIf="selectedSubscriptionOptions[index].numberOfTests !== null">
                  {{ selectedSubscriptionOptions[index].numberOfTests }} tests
                </span>
              </div>
              <div
                class="item-price"
                *ngIf="currencyService.activeCurrencyId">
                {{currencyService.activeCurrencySymbol}}
                {{ selectedSubscriptionOptions[index]['calculated_price_' + currencyService.activeCurrencyId.toLowerCase()] | number: '1.2-2'}}
                <sup class="item-asterix">*</sup>
              </div>
              <div class="item-short-info">
                <!--                <span class="item-period-info">-->
                <!--                  {{ item.number_of_units }}-->
                <!--                  <span-->
                <!--                    *ngIf="languageService.currentLang === 'en'">{{ item.number_of_units === 1 ? 'month' : 'months'}}</span>-->
                <!--                  <span *ngIf="languageService.currentLang === 'fr'">mois</span>-->
                <!--                  {{'B2B_STRIPE_MEMBERSHIP_TEXT' | translate}}-->
                <!--                </span>-->
                <!--                <span-->
                <!--                  *ngIf="item.number_of_units === 1"-->
                <!--                  [innerHTML]="'B2B_STRIPE_ONE_MONTH_PAY' | translate: {-->
                <!--                    currency_symbol:currencyService.activeCurrencySymbol,-->
                <!--                    price_per_unit: item['price_per_unit_' + currencyService.activeCurrencyId.toLowerCase()] | number: '1.2-2'-->
                <!--                  }"-->
                <!--                  class="item-price-unit">-->
                <!--                </span>-->
                <span
                  *ngIf="item.number_of_units >= 12"
                  [innerHTML]="'B2B_STRIPE_MANY_MONTHS_PAY' | translate: {
                    currency_symbol:currencyService.activeCurrencySymbol,
                    price_per_unit: item['price_per_unit_' + currencyService.activeCurrencyId.toLowerCase()] | number: '1.2-2'
                  }" class="item-price-unit">
                </span>
                <span class="item-vat-not-included">
                  <sup class="item-asterix">*</sup>
                  {{'B2B_STRIPE_VAT_NOT_INCLUDED' | translate}}
                </span>
              </div>
              <div class="item-buy-button"
                   (click)="proceedToInformationConfirmation(
                   productTypeEnum.subscription,
                   item.id,
                   currencyService.activeCurrencyId.toLowerCase(),
                   index)">
                <span class="button-text">
                  {{'B2B_STRIPE_PURCHASE_BUTTON' | translate}}
                </span>
                <mat-icon>shopping_cart</mat-icon>
              </div>
              <div class="border-right"></div>
            </div>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon>spa</mat-icon>
            <span>{{'GLOBAL_LABEL_CREDITS' | translate}}</span>
          </ng-template>
          <div class="short-info">
            <ul>
              <li>
                <mat-icon>check</mat-icon>
                <span [innerHTML]="'B2B_STRIPE_FIRST_CREDITS_BENEFIT' | translate "></span>
              </li>
              <li>
                <mat-icon>check</mat-icon>
                <span [innerHTML]="'B2B_STRIPE_SECOND_CREDITS_BENEFIT' | translate "></span>
              </li>
              <li>
                <mat-icon>check</mat-icon>
                <span [innerHTML]="'B2B_STRIPE_THIRD_CREDITS_BENEFIT' | translate "></span>
              </li>
              <li>
                <mat-icon>check</mat-icon>
                <span [innerHTML]="'B2B_STRIPE_FOURTH_CREDITS_BENEFIT' | translate "></span>
              </li>
            </ul>
            <div class="decorative-border"></div>
          </div>
          <div class="item-sellable-container item-credits">
            <div
              *ngFor="let item of allCreditPacks; let i=index" class="item-box"
              (click)="proceedToInformationConfirmation(productTypeEnum.credits, item.id, currencyService.activeCurrencyId.toLowerCase())">
              <div class="item-title">
                {{ item.name }}
              </div>
              <div
                class="item-price"
                *ngIf="currencyService.activeCurrencyId">
                {{currencyService.activeCurrencySymbol}}
                {{ item['total_price_' + currencyService.activeCurrencyId.toLowerCase()] | number: '1.2-2'}}
                <sup class="item-asterix">*</sup>
              </div>
              <div class="item-short-info">
                <span
                  *ngIf="item.number_of_units !== 1"
                  [innerHTML]="'B2B_STRIPE_MANY_CREDITS_PAY' | translate: {
                    currency_symbol:currencyService.activeCurrencySymbol,
                    price_per_unit: item['price_per_unit_' + currencyService.activeCurrencyId.toLowerCase()] | number: '1.2-2'
                  }"
                  class="item-price-unit">
                </span>
                <span class="item-vat-not-included">
                <sup class="item-asterix">*</sup>{{'B2B_STRIPE_VAT_NOT_INCLUDED' | translate}}
              </span>
              </div>
              <div class="separator"></div>
              <div class="item-save-amount">
              <span *ngIf="item.savings_eur">
                <strong>
                  {{'B2B_STRIPE_SAVE' | translate}}
                  {{ item['savings_' + currencyService.activeCurrencyId.toLowerCase()]}}%
                </strong>
              </span>
              </div>
              <div class="item-buy-button">
                <span class="button-text">
                  {{'GLOBAL_BUY_NOW' | translate}}
                </span>
                <mat-icon>shopping_cart</mat-icon>
              </div>
              <div class="border-right"></div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>

      <div class="vertical-decorative">
        <div class="decorative-rhombus-text">
          <div class="rhombus"></div>
          <div class="line"></div>
          <div
            class="info-text"
            *ngIf="activeTabIndex===0">
            {{'B2B_STRIPE_SIDE_TEXT_PAYMENT_PLANS' | translate}}
          </div>
          <div
            class="info-text"
            *ngIf="activeTabIndex===1">
            {{'B2B_STRIPE_SIDE_TEXT_BUSINESS_INFO' | translate}}
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-info">
      <mat-icon>error_outline</mat-icon>
      <span [innerHTML]="'B2B_STRIPE_BOTTOM_INFO' | translate"></span>
    </div>
  </div>
</div>
