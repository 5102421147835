import { Injectable }          from '@angular/core';
import { ModalAlertComponent } from '@common/global-components/cpts/modal-alert/modal-alert.component';
import { ModalService }        from '@services/modal.service';
import { Subject }             from 'rxjs';

export enum ModalAlertType {
  Success,
  Info,
  Warning,
  Redirect,
  YesNo
}

export enum ModalAlertStatus {
  OK,
  Yes,
  No
}

@Injectable({
  providedIn: 'root'
})
export class ModalAlertService {

  public activeAlert: ModalAlertComponent;
  public events: Subject<ModalAlertStatus>;

  constructor(
    private _modalService: ModalService
  ) {
    this.events = new Subject();
  }

  public initAlert(alertComponent: ModalAlertComponent): void {
    this.activeAlert = alertComponent;
  }

  public open(title: string, content: string): Subject<ModalAlertStatus> {
    const activeModal = this._modalService.activeModal;
    if (activeModal) {
      activeModal.hide();
    }
    this.activeAlert.type = ModalAlertType.Info;
    this.activeAlert.title = title;
    this.activeAlert.content = content;

    this.activeAlert.show();

    return this.events;
  }

  public showSuccess(title: string, content: string): Subject<ModalAlertStatus> {
    this.open(title, content);

    this.activeAlert.type = ModalAlertType.Success;

    return this.events;
  }

  public showYesNo(title: string, content: string): Subject<ModalAlertStatus> {
    this.open(title, content);
    this.activeAlert.type = ModalAlertType.YesNo;

    return this.events;
  }

  public showInfo(title: string, content: string): Subject<ModalAlertStatus> {
    this.open(title, content);

    this.activeAlert.type = ModalAlertType.Info;

    return this.events;
  }

  public showRedirect(title: string, content: string): Subject<ModalAlertStatus> {
    this.open(title, content);

    this.activeAlert.type = ModalAlertType.Redirect;

    return this.events;
  }

  public showWarning(title: string, content: string): Subject<ModalAlertStatus> {
    this.open(title, content);
    this.activeAlert.type = ModalAlertType.Warning;

    return this.events;
  }

  public close(): void {
    if (this.activeAlert) {
      this.activeAlert.hide().subscribe(() => {
        const activeModal = this._modalService.activeModal;
        if (activeModal) {
          activeModal.show();
        }

        this.events.next(this.activeAlert.status);
      });
    }
  }

}
