<div (click)="onWrapperClick($event)" *ngIf="isVisible" [@slideInOutAnimation] [attr.id]="modalId"
     class="modal-container">
  <div [style.max-width.px]="width" appModal class="modal">
    <div (click)="close()" *ngIf="showCloseIcon" class="btn-close"><i aria-hidden="true" class="fa fa-times"></i></div>

    <div *ngIf="title" class="modal-header">
      <h2>{{ title }}</h2>
    </div>

    <div class="modal-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>

<div *ngIf="isVisible" class="modal-overlay"></div>
