import {HttpErrorResponse}                                                  from '@angular/common/http';
import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup}                                             from '@angular/forms';
import {ActivatedRoute, Params}                                             from '@angular/router';
import { AppDomain }                                                        from '@app/app.constants';
import {B2cUserType}                                                        from '@common/auth/enums/b2c-user-type.enum';
import {UserRoutesService}                                                  from '@common/auth/services/user-routes.service';
import {LanguageService}                                                    from '@services/i18n.service';
import {MaterialSnackBarService}                                            from '@services/material-snack-bar.service';
import {NavigationService}                                                  from '@services/navigation.service';
import {RouteMeta, RouteMetaService}                                        from '@services/route-meta.service';
import {SiteConfigService}                                                  from '@services/site-config.service';
import {UtilsService}                                                       from '@services/utils.service';
import {Subscription}                                                       from 'rxjs';
import {finalize}                                                           from 'rxjs/operators';
import {AuthenticationEventType, AuthService} from '../../services/auth.service';
import {RouterUrlService} from '@services/router-url.service';

@Component({
  selector: 'app-login-apim',
  templateUrl: './login-apim.component.html',
  styleUrls: ['./login-apim.component.scss'],
})
export class LoginApimComponent implements OnInit, AfterViewInit, OnDestroy {
  public B2cUserType = B2cUserType;
  public SignInFormGroup: FormGroup;

  public redirectTo: string;
  public isCallbackUri = false;
  public isB2c = false;
  public formData: any = {
    email: null,
  };
  public SignInForm: any;
  public STATUS_LOADING: boolean;
  public queryParams: Params;
  public params: Params;
  public activeSubscriptions: Subscription[] = [];
  @ViewChild('emailField') private _inputElement: ElementRef;
  public showLoader = false;
  public errorMessage: string;

  constructor(
    public navigationService: NavigationService,
    public siteConfigService: SiteConfigService,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public languageService: LanguageService,
    public userRoutesService: UserRoutesService,
    public utilsService: UtilsService,
    private routeMetaService: RouteMetaService,
    private matSnackBarService: MaterialSnackBarService,
    private routerUrlService: RouterUrlService
  ) {
  }

  get email() {
    return this.SignInFormGroup.get('email');
  }

  public ngOnInit() {
    if (window.location.href.indexOf('redirectTo') > 0) {
      this.routerUrlService.setRedirectUrl();
    }

    if (window.location.href.indexOf('redirectTo') > 0 &&
      (this.authService.isAuthenticatedB2C || this.authService.isAuthenticatedB2B)) {
      this.showLoader = true;
    }

    this.authService.isNewLogin = 'true';
    if (this.activatedRoute.snapshot.queryParams.loginRequired === 'true') {
      this.forceLogoutUser();
    } else {
      this.skipIfLoggedIn();
    }
    if (typeof this.activatedRoute.snapshot.queryParams.error_description !== 'undefined') {
      this.matSnackBarService.open(
        decodeURI(this.activatedRoute.snapshot.queryParams.error_description),
        null,
        {
          duration: 10000,
          panelClass: ['error']
        });

    }

    if (typeof this.activatedRoute.snapshot.queryParams.state !== 'undefined' &&
      typeof this.activatedRoute.snapshot.queryParams.code !== 'undefined') {
      this.isCallbackUri = true;
      this.STATUS_LOADING = true;
      this._doCallbackApim();
    } else {
      this._initSignupFormData();
      this._initSignUpForm();

      const paramsSubscription = this.activatedRoute.params.subscribe((params) => {
        this.params = params;
        if (params.redirectTo) {
          this.redirectTo = decodeURIComponent(params.redirectTo);
        }
      });

      const queryParamsSubscription = this.activatedRoute.queryParams.subscribe((params) => {
        this.queryParams = params;
        if (params.redirectTo) {
          this.redirectTo = decodeURIComponent(params.redirectTo);
        }
      });

      this._setRouteMeta();

      this.activeSubscriptions = [
        ...this.activeSubscriptions,
        ...[paramsSubscription, queryParamsSubscription]
      ];
    }

    this.showLoader = false;
  }

  public ngOnDestroy(): void {
    for (const subscription of this.activeSubscriptions) {
      subscription.unsubscribe();
    }
    this.activeSubscriptions = [];
  }

  goToSV() {
    window.location.href = this.authService.logoutFromADAndRedirectToSV;
  }

  public forceLogoutUser(): void {
    this.authService.currentUserDetails = null;
    this.authService.authTokenB2C = null;
    this.authService.authTokenB2B = null;
    this.authService.events.next({type: AuthenticationEventType.Logout});
  }

  public skipIfLoggedIn() {
    if (this.authService.isAuthenticatedB2C || this.authService.isAuthenticatedB2B) {
      const b2btoken = localStorage.getItem(AuthService.authTokenB2BKey);
      if (b2btoken) {
        this.authService.isB2B = true;
        this.authService.isB2C = false;
        this.authService.authTokenB2B = localStorage.getItem(AuthService.authTokenB2BKey);
        this.authService.authToken = this.authService.authTokenB2B;
        this.userRoutesService.handleB2BLogin();
      } else {
        this.authService.isB2B = false;
        this.authService.isB2C = true;
        this.userRoutesService.handleB2CLogin();
      }
    }
  }

  public _initSignupFormData() {
    if (this.activatedRoute.snapshot.queryParams.loginEmail) {
      this.formData.email = this.activatedRoute.snapshot.queryParams.loginEmail;
    }
  }

  public _initSignUpForm() {
    this._createForm();
    this._setValueChangesSubscription();
  }

  public ngAfterViewInit(): void {
    if (!this.formData.email) {
      this._inputElement.nativeElement.focus();
    }
  }

  public signIn(event: Event) {
    const isNecessaryToDisplayErrors = this.SignInFormGroup.pristine || this.SignInFormGroup.dirty;
    if (isNecessaryToDisplayErrors) {
      Object
        .keys(this.SignInFormGroup.controls)
        .forEach((formControlKey) => {
          if (typeof formControlKey !== 'undefined') {
            const hasAnyValuelessFields = this.SignInFormGroup.controls[formControlKey].value == null
              && this.SignInFormGroup.controls.hasOwnProperty(formControlKey);
            if (hasAnyValuelessFields) {
              this.SignInFormGroup.controls[formControlKey].markAsDirty();
            }
          }
        });
    }
    if (this.SignInFormGroup.valid) {
      this._doSignInApim();
    }
  }

  public _doCallbackApim() {
    this.STATUS_LOADING = true;
    this.authService.callbackApim(this.utilsService.getQueryParams())
      .pipe(finalize(() => {
        this.STATUS_LOADING = false;
      }))
      .subscribe(
        (response) => {
          if (response && response.type === 'b2c') {
            this.isB2c = true;
            this.authService.logoutFromADAndRedirectToSV = response.logout_url;
          } else {
            this._onSignInSuccess(response);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this._handleErrors(errorResponse);
          this.STATUS_LOADING = false;
        }
      );
  }

  public _doSignInApim() {
    const signUpRequestBody = {
      email: this.SignInForm.email,
      redirect_uri: (window.location.href.indexOf('localhost') >= 0 ? 'http://' : 'https://') +
        window.location.host + '/' + this.languageService.currentLang + '/auth/login/'
      // not accepted by Azure on local and xTest because we use http
      // redirect_uri: this.utilsService.getWebSiteBaseUrl() + this.languageService.currentLang + '/auth/login-apim/',
    };
    this.STATUS_LOADING = true;
    this.authService.signInApim(signUpRequestBody)
      .pipe(finalize(() => {
        this.STATUS_LOADING = false;
      }))
      .subscribe(
        (response) => {
          window.location.href = response.authorize_url;
        },
        (errorResponse: HttpErrorResponse) => {
          switch (errorResponse.status) {
            case 400:
              this.redirectToOldLogin();
              break;
            case 403:
              this.errorMessage = errorResponse.error.detail;
              break;
            case 404:
              this.redirectToRegister();
              break;
            default:
              break;
          }
          this._handleErrors(errorResponse);
          this.STATUS_LOADING = false;
        }
      );
  }

  public _handleErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error) {
      this._handleGenericError(errorResponse.error);
    }
  }

  public _onSignInSuccess(response) {
    this.STATUS_LOADING = true;
    const hasToGoToMyP5 = this.hasToGoToMyPentalogCVOnSuccess(response);
    const hasToGoToMVPFreelancerCV = this.hasToGoToFreelancerCVOnSuccess(response);
    const IsB2B = response.type === 'b2b';
    const hasAccessToRedirectUrl = this.userRoutesService.hasAccessToRedirectUrl();

    if (IsB2B && !hasAccessToRedirectUrl) {
      this.forceLogoutUser();
      this.userRoutesService.displayB2CAuthenticationRequired();
    }

    if (hasToGoToMyP5 || hasToGoToMVPFreelancerCV || IsB2B) {
      this.userRoutesService.handleB2BLogin();
    } else {
      this.userRoutesService.handleB2CLogin();
    }
  }

  public goToHomePage() {
    if (window.location.href.indexOf(AppDomain) >= 0) {
      window.location.href = this.languageService.currentLang;
    }
  }

  public _handleGenericError(error) {
    this.SignInFormGroup.get('email').setErrors(
      {
        generic: error.non_field_errors || error.detail
      });
  }

  public _createForm() {
    this.SignInFormGroup = new FormGroup({
      email: new FormControl(this.formData.email),
    });
  }

  public _setValueChangesSubscription() {
    const subscription = this.SignInFormGroup.valueChanges.subscribe((formWithLastValues) => this.onFormValueChanged(formWithLastValues));
    this.activeSubscriptions.push(subscription);
  }

  public onFormValueChanged(formWithLastValues?: any) {
    if (!this.SignInFormGroup) {
      return;
    }
    this.SignInForm = formWithLastValues;
  }

  private redirectToRegister() {
    this.navigationService.navigateByUrl(
      `/${this.languageService.currentLang}/auth/signup`,
      {
        queryParams: {
          type: B2cUserType[B2cUserType.coder]
        },
        queryParamsHandling: 'merge'
      }
    );
  }

  private redirectToOldLogin() {
    this.navigationService.navigateByUrl('/auth/login-2');
  }

  private _setRouteMeta() {
    const subscription = this.routeMetaService.getRouteMeta('auth/login')
      .subscribe((routeMeta: RouteMeta) => {
        this.routeMetaService.setRouteMeta(routeMeta);
      });
    this.activeSubscriptions.push(subscription);
  }

  private hasToGoToMyPentalogCVOnSuccess(response): boolean {
    const redirectTo = this.redirectTo;
    let hasToGoToMyP5 = redirectTo && redirectTo.indexOf('verify') > -1 && redirectTo.indexOf('cv') > -1;
    hasToGoToMyP5 = hasToGoToMyP5 && redirectTo.indexOf('freelancer_cv') === -1;
    return hasToGoToMyP5;
  }

  private hasToGoToFreelancerCVOnSuccess(response): boolean {
    const redirectTo = this.redirectTo;
    let hasToGoToMVP = redirectTo && redirectTo.indexOf('verify') > -1 && redirectTo.indexOf('freelancer_cv') > -1;
    hasToGoToMVP = hasToGoToMVP && response.type === 'b2b';
    return hasToGoToMVP;
  }
}
