<main>
  <section
    appSectionFullPage
    class="page-block page-hero"
    style="background-image: url(/assets/img/pages/home/hero-bg.jpg);">
    <div class="page-block-container">
      <div class="page-block-overlay"></div>
      <div class="page-block-content">
        <div class="page-hero-message">
          <h1>{{ 'PAGE_NOT_FOUND_SUBTITLE' | translate }}</h1>
          <p>{{ 'PAGE_NOT_FOUND_MESSAGE' | translate }}</p>
          <!--          <a; href="/companies/{{languageService.currentLang}}/business"; class="btn">-->
          <p *ngIf="path">You might want to go to the <a [routerLink]="path">"{{path}}" page</a></p>
          <a class="btn" href="/">
            {{ 'MENU_HOME' | translate }}</a>
        </div>
      </div>
    </div>
  </section>
</main>
