import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '@common/auth/services/auth.service';
import {PageHeaderService} from '@services/page-header.service';
import {Subscription} from 'rxjs';
import {share} from 'rxjs/operators';
import {LanguageService} from '@services/i18n.service';
import {B2BAppType, PlatformsAccessService} from '@services/platforms-access.service';
import {StripeModalService} from '@b2b/@common/modules/stripe/services/stripe-modal.service';
import {RouterUrlService} from '@services/router-url.service';

@Component({
  selector: 'app-block-nav-header',
  templateUrl: './block-nav-header.component.html',
  styleUrls: ['./block-nav-header.component.scss']
})
export class BlockNavHeaderComponent implements AfterViewInit, OnDestroy {

  public titleChangeSubscription: Subscription;
  public backLinkChangeSubscription: Subscription;

  constructor(
    public authService: AuthService,
    public cdr: ChangeDetectorRef,
    public pageHeaderService: PageHeaderService,
    public platformsAccessService: PlatformsAccessService,
    public languageService: LanguageService,
    public stripeModalService: StripeModalService,
    private routerUrlService: RouterUrlService,
    private router: Router
  ) {
  }

  public ngAfterViewInit(): void {
    this.subscribePageHeaderChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribePageHeaderChanges();
  }
  // Open price Modal from WP
  // ==============================

  private openStripeModalFromWP(url) {
    if (url.indexOf('pricing-modal-tab=subscriptions') > 0) {
      this.stripeModalService.buy(0);
    } else {
      if (url.indexOf('pricing-modal-tab=credits') > 0) {
        this.stripeModalService.buy(1);
      }
    }
  }

  public checkForWpParams() {
    const wpUrl = this.routerUrlService.getUrlFromWP(); // login with AD
    const url = window.location.href; // login-2

    if (wpUrl) {
      this.openStripeModalFromWP(wpUrl);
      this.routerUrlService.deleteUrlFromWP();
    } else {
      if (url) {
        this.openStripeModalFromWP(url);
      }
    }
  }

  // Event Handlers
  // ==============================

  async setAccessPlatform(platformType) {
    if (platformType === 'access_assessment') {
      window.open(`https://skillvalue.com/${this.languageService.currentLang}/auth/login`, '_blank');
    }

    if (platformType === 'access_freelancers') {
      await this.platformsAccessService.createAccessPlatform({access: platformType}).toPromise().then((data) => {
        this.router.navigate(
          [`/${this.languageService.currentLang}/business/freelancers/dashboard`]
        );
      })
    }

    if (platformType === 'access_outsourcing') {
      window.open('https://www.pentalog.com', '_blank');
    }
  }

  // Page Header
  // ==============================

  private subscribePageHeaderChanges() {
    this.checkForWpParams();
    this.titleChangeSubscription = this.pageHeaderService.title$.pipe(share()).subscribe((value) => {
      this.cdr.detectChanges();
    });

    this.backLinkChangeSubscription = this.pageHeaderService.backLink$.pipe(share()).subscribe((value) => {
      this.cdr.detectChanges();
    });
  }

  private unsubscribePageHeaderChanges() {
    [this.titleChangeSubscription, this.backLinkChangeSubscription].forEach((subscription: Subscription) => {
      if (subscription) {
        subscription.unsubscribe();
        subscription = null;
      }
    });
  }

  // Helpers
  // ==============================

  get isActiveB2BAssessmentTab() {
    return this.platformsAccessService.getB2BAppType() === B2BAppType[B2BAppType.assessment];
  }

  get isActiveB2BFreelancersTab() {
    return !this.isActiveB2BAssessmentTab;
  }
}
