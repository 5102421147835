import { CommonModule }              from '@angular/common';
import { NgModule }                  from '@angular/core';
import { ReactiveFormsModule }       from '@angular/forms';
import { AuthRoutingModule }         from '@common/auth/auth-routing.module';
import { LoginApimComponent }        from '@common/auth/cpts/login-apim/login-apim.component';
import { GlobalComponentsModule }    from '@common/global-components/global-components.module';
import { GlobalDirectivesModule }    from '@common/global-directives/global-directives.module';
import { SocialModule }              from '@common/social/social.module';
import { TranslateModule }           from '@ngx-translate/core';
import { MaterialModule }            from '../material/material.module';
import { LoginComponent }            from './cpts/login/login.component';
import { RecoverPasswordComponent }  from './cpts/recover-password/recover-password.component';
import { SignupConfirmComponent }    from './cpts/signup-confirm/signup-confirm.component';
import { SignupTermsModalComponent } from './cpts/signup-terms-modal/signup-terms-modal.component';
import { SignupCampaignComponent }   from '@common/auth/cpts/signup-campaign/signup-campaign.component';
import { SignupComponent }           from './cpts/signup/signup.component';
import { VerifyComponent }           from './cpts/verify/verify.component';
import { SignupCampaignB2BComponent } from '@common/auth/cpts/signup-campaign-b2b/signup-campaign-b2b.component';
import { GtmService }                from '@services/gtm.service';


@NgModule({
  declarations: [
    LoginComponent,
    LoginApimComponent,
    SignupComponent,
    SignupConfirmComponent,
    SignupCampaignComponent,
    SignupCampaignB2BComponent,
    RecoverPasswordComponent,
    VerifyComponent,
    SignupTermsModalComponent,
  ],
  imports: [
    // BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CommonModule,
    TranslateModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    MaterialModule,
    GlobalComponentsModule,
    SocialModule,
    GlobalDirectivesModule
  ],
  entryComponents: [SignupTermsModalComponent],
  providers: [
    // {provide: APP_BASE_HREF, useValue: '/'},
    // {
    //   provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
    // },
    // {
    //   provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor
    // }
  ]
})
export class AuthModule {
  constructor(gtmService: GtmService) {
    gtmService.init();
  }
}
