import { environment } from '../environments/environment';

export const ApiEndpointsBase = environment.baseUrl + 'api/';
export const ApiEndpointsB2BBase = environment.baseUrl + 'api/b2b/';
export const ApiEndpointsAzureBase = environment.baseUrl + 'api/azure/';
export const AppDomain = environment.domain;

// export const ApiEndpointsBase = environment.baseUrl + 'api/b2b/';
// export const ApiEndpointsBaseRoot = environment.baseUrl + 'api/';
// const rootURLforAlgoImages = () => {
//   if (!!window && window.location.href.indexOf('xlocal') > -1) {
//     return 'http://skillvalue.xtest/';
//   }
// };
export const ApiEndpoints: any = {
  delete_company: ApiEndpointsB2BBase + 'delete-company/',
  emailTemplates: ApiEndpointsB2BBase + 'company-email-templates/',
  companyAccounts: ApiEndpointsB2BBase + 'company-accounts/',
  companyAssessmentToken: ApiEndpointsB2BBase + 'api-token/',
  assessmentCompany: ApiEndpointsB2BBase +'assessment-company/',
  tagsAutocomplete: ApiEndpointsB2BBase + 'tagsearch/autocomplete/',
  notificationChoices: ApiEndpointsB2BBase + 'notification-choices/token/',
  grantFreelancersAccess: ApiEndpointsB2BBase + 'grant-freelancers-access/',
  thirdParty: {
    autologin: ApiEndpointsB2BBase + 'third-party/autologin/',
    magentoLogin: ApiEndpointsB2BBase + 'auth/old-app-autologin/'
  },
  auth: {
    base: ApiEndpointsBase + 'auth/',
    login: ApiEndpointsBase + 'auth/login/',
    loginApim: ApiEndpointsBase + 'auth/ad/authorization-url/',
    callbackApim: ApiEndpointsBase + 'auth/ad/callback/',
    logout: ApiEndpointsBase + 'auth/logout/',
    registration: ApiEndpointsBase + 'auth/registration/',
    registrationConfirm: ApiEndpointsBase + 'auth/registration/verify-email/',
    resendConfirmationEmail: ApiEndpointsBase + 'auth/resend-confirmation-email/',
    userDetails: ApiEndpointsBase + 'auth/user/',
    passwordReset: ApiEndpointsBase + 'auth/password/reset/',
    passwordResetConfirm: ApiEndpointsBase + 'auth/password/reset/confirm/',
    googleAuth: ApiEndpointsBase + 'auth/google/',
    facebookAuth: ApiEndpointsBase + 'auth/facebook/',
    linkedinAuth: ApiEndpointsBase + 'auth/linkedin/',
    githubAuth: ApiEndpointsBase + 'auth/github/',
    refreshToken: ApiEndpointsBase + 'auth/ad/refresh/'
  },
  legal: {
    base: ApiEndpointsBase + 'legal-info/',
    status: ApiEndpointsBase + 'legal-info/status/',
    company_details: ApiEndpointsBase + 'legal-info/company-details/',
    legal_representative: ApiEndpointsBase + 'legal-info/legal-representative/',
    legal_documents: ApiEndpointsBase + 'legal-info/legal-documents/',
    legal_documents_types: ApiEndpointsBase + 'legal-info/legal-documents-types/',
    legal_entity_form: ApiEndpointsBase + 'legal-info/legal-entity-form/',
    banking_details: ApiEndpointsBase + 'legal-info/banking-details/',
    shareholder: ApiEndpointsBase + 'legal-info/shareholder/',
  },
  license: {
    base: ApiEndpointsB2BBase + 'license/',
    licenseHistory: ApiEndpointsB2BBase + 'license/license-history/',
    sellableSubscriptions: ApiEndpointsB2BBase + 'license/sellable-subscriptions/',
    sellableCreditPacks: ApiEndpointsB2BBase + 'license/sellable-credit-packs/',
  },
  payments: {
    base: ApiEndpointsBase + 'payments/',
    buyProduct: ApiEndpointsBase + 'payments/buy-product/',
  },
  activity: {
    base: ApiEndpointsB2BBase + 'activity-logs/',
    logs: ApiEndpointsB2BBase + 'activity-logs/recent-logs/'
  },
  tests: {
    base: ApiEndpointsB2BBase + 'tests/',
    levels: ApiEndpointsB2BBase + 'tests/levels/',
    languages: ApiEndpointsB2BBase + 'tests/languages/',
    favorites: ApiEndpointsB2BBase + 'tests/favorites/',
    testTypes: ApiEndpointsB2BBase + 'tests/typetests/',
    topTests: ApiEndpointsB2BBase + 'tests/top/',
    topTestsCompany: ApiEndpointsB2BBase + 'tests/top-company/',
    codingLanguages: environment.baseUrl + 'api/algo/ws-tcc/programminglanguages/search/',
    domain: ApiEndpointsB2BBase + 'tests/domain/',
    testCatalog: ApiEndpointsB2BBase + 'tests/test-catalog/',
    test: ApiEndpointsB2BBase + 'tests/quiz/',
    competences: ApiEndpointsBase + 'smartquiz/questions-competences/',
    deleteSmartQuiz: ApiEndpointsBase + 'smartquiz/config/',
    deletedSmartQuiz: ApiEndpointsB2BBase + 'tests/deleted-smartquizes/',
  },
  freelance: {
    base: ApiEndpointsBase + 'freelance/',
    contact: ApiEndpointsBase + 'freelance/contact/',
    choices: ApiEndpointsBase + 'users/freelance-choices/',
  },
  common: {
    base: ApiEndpointsBase + 'common/',
    countries: ApiEndpointsBase + 'common/country/',
    cities: ApiEndpointsBase + 'common/city/',
    roles: ApiEndpointsBase + 'common/profile/',
    technologies: ApiEndpointsBase + 'common/technology/',
    profile: ApiEndpointsBase + 'common/profile/',
    domains: ApiEndpointsBase + 'common/activity-domain/',
    siteConfig: ApiEndpointsBase + 'common/siteconfig/',
    carousel: ApiEndpointsBase + 'i18n/frontend-image/',
    locationAutocomplete: ApiEndpointsBase + 'common/location-autocomplete/',
    jobTitleB2BAutocomplete: ApiEndpointsBase + 'common/jobtitle-autocomplete/',
  },
  campaign: {
    base: ApiEndpointsB2BBase + 'campaign/',
    list: ApiEndpointsB2BBase + 'campaign/campaign/',
    statuses: ApiEndpointsB2BBase + 'campaign/campaign-status/',
    tags: ApiEndpointsB2BBase + 'campaign/campaign-tags/',
    tokenReminder: ApiEndpointsB2BBase + 'campaign/token-reminder/',
    currentCampaigns: ApiEndpointsB2BBase + 'campaign/chart-campaign-status/',
    tokenCancel: ApiEndpointsB2BBase + 'campaign/token-cancel/',
    publicCampign: ApiEndpointsB2BBase + 'campaign/public',
    importCampaigns: ApiEndpointsB2BBase + 'campaign/import/'
  },
  users: {
    base: ApiEndpointsBase + 'users/',
    newsletter: ApiEndpointsBase + 'users/subscriber/',
    requestDeletion: ApiEndpointsBase + 'users/request-delete/',
    freelacerChoices: ApiEndpointsBase + 'users/freelance-choices/',
    profilePreview: ApiEndpointsBase + 'users/candidate-profile-preview/'
  },
  dashboard: {
    jobApplications: ApiEndpointsBase + 'jobs/job-application/',
    testResults: ApiEndpointsBase + 'tests/mytokens/'
  },
  quizzes: {
    base: ApiEndpointsBase + 'quizzes/',
    domain: ApiEndpointsBase + 'quizzes/domain/',
    quizList: ApiEndpointsBase + 'quizzes/quiz/',
    quizInfo: ApiEndpointsBase + 'quizzes/quiz/slug/',
    quizSampleQuestion: ApiEndpointsBase + 'quizzes/quiz/sample_question/',
    quizToken: ApiEndpointsBase + 'quizzes/quiztoken/',
  },
  reports: {
    base: ApiEndpointsB2BBase + 'reports/',
    quiz_token: ApiEndpointsB2BBase + 'reports/quiz-token/',
    smartquiz_token: ApiEndpointsB2BBase + 'reports/quiz-token/',
    algo_token: ApiEndpointsB2BBase + 'reports/algo-token/'
  },
  result: {
    catalog: ApiEndpointsB2BBase + 'results/result-catalog/',
    domain: ApiEndpointsB2BBase + 'results/domain/',
    type: ApiEndpointsB2BBase + 'results/type/',
    level: ApiEndpointsB2BBase + 'results/level/',
    status: ApiEndpointsB2BBase + 'results/status/',
    score: ApiEndpointsB2BBase + 'results/score-percentage/',
    export: ApiEndpointsB2BBase + 'results/export/',
    tags: ApiEndpointsB2BBase + 'results/candidates-tags/',
  },
  certificates: {
    base: ApiEndpointsBase + 'certificates/',
    certificate: ApiEndpointsBase + 'certificates/certificate/'
  },
  multiLanguage: {
    base: ApiEndpointsBase + 'i18n/'
  },
  seo: {
    base: ApiEndpointsBase + 'seo/',
    routeMeta: ApiEndpointsBase + 'seo/routeseo/'
  },
  static: environment.baseUrl + 'static/',
  images: {
    // algo: `${rootURLforAlgoImages()}algo-ws-images/exercises/`
  },
  userData: {
    cv: ApiEndpointsBase + 'experience/resume/',
    deleteAll: ApiEndpointsBase + 'experience/resume/delete_all/',
    suggestedTests: ApiEndpointsBase + 'tests/suggested/'
  },
  jobs: {
    wp_apply: ApiEndpointsBase + 'jobs/job/wp/',
    apply: ApiEndpointsBase + 'jobs/job/',
    job: ApiEndpointsBase + 'jobs/job/',
    jobB2B: ApiEndpointsBase + 'b2b/jobs/job/',
    jobApplication: ApiEndpointsBase + 'jobs/job-application/',
    related: ApiEndpointsBase + 'jobs/suggested/',
    rejectOffer: ApiEndpointsBase + 'jobs/job/{job_id}/reject-offer/',
    rejectWpOffer: ApiEndpointsBase + 'jobs/job/wp/{wp_job_id}/reject-offer/'
  },
  candidates: {
    base: ApiEndpointsB2BBase + 'candidates/',
    candidate: ApiEndpointsB2BBase + 'candidates/candidate/',
    candidatesTokenList: ApiEndpointsB2BBase + 'candidates/token-list/',
    candidatesTags: ApiEndpointsB2BBase + 'candidates/candidates-tags/',
    importCandidates: ApiEndpointsB2BBase + 'candidates/import/'
  },
  experience: {
    base: ApiEndpointsBase + 'experience/',
    stack: ApiEndpointsBase + 'experience/my-stack/',
    stackAutocomplete: ApiEndpointsBase + 'experience/competence/',
    stackAutocompleteOLD: ApiEndpointsBase + 'experience/competence/autocomplete/',
    resume: ApiEndpointsBase + 'experience/resume/',
    competence_family: ApiEndpointsBase + 'experience/competence-family/',
    linkedin: ApiEndpointsBase + 'experience/linkedin-cv-update/',
    mainTechnologies: ApiEndpointsBase + 'experience/my-main-competence/'
  },
  studies: {
    base: ApiEndpointsBase + 'studies/education/'
  },
  gtm: {
    freelancers: ApiEndpointsBase + 'gtm/flags/b2b-freelance/'
  },
  marketo: {
    sync: ApiEndpointsBase + 'marketo-sync/',
    syncLead: ApiEndpointsBase + 'marketo-sync/add-lead-to-list/',
    createOutsourceLead: ApiEndpointsBase + 'marketo-sync/marketo-create-pentalog-lead/',
    createFreelanceLead: ApiEndpointsBase + 'marketo-sync/marketo-create-sv-freelancers-lead/',
    accessPlatform : ApiEndpointsB2BBase + 'access-platform/',
  },
  b2b: {
    freelancers: {
      taggedCompetencesFilters: ApiEndpointsBase + 'freelancers/competence-tag-filters/',
      candidateProfiles: ApiEndpointsBase + 'freelancers/freelancer/',
      availabilityFilters: ApiEndpointsBase + 'freelancers/availability-filters/',
      rateFilters: ApiEndpointsB2BBase + 'freelancers/rate-filters/',
      seniorityFilters: ApiEndpointsB2BBase + 'freelancers/seniority-filters/',
      projects: ApiEndpointsB2BBase + 'jobs/job/',
      suggestedJobs: ApiEndpointsBase + 'jobs/job-application/',
      addJob: ApiEndpointsBase + 'jobs/',
      addJobApplicationInterviewDates: ApiEndpointsB2BBase + 'jobs/interview-date/',
      stackAutocomplete: ApiEndpointsBase + 'b2b/' + 'experience/competence/',
    },
  },
  azure: {
    base: ApiEndpointsBase + 'azure/',
    competenceAutocomplete: ApiEndpointsBase + 'azure/autocomplete/competences-tags/',
    locationAutocomplete: ApiEndpointsBase + 'azure/autocomplete/location/',
    profileAutocomplete: ApiEndpointsBase + 'azure/autocomplete/profile/',
    freelancersSearch: ApiEndpointsBase + 'azure/freelancers/search/',
    suggestedOursourcers: ApiEndpointsBase + 'azure/assessment-outsourcing-suggestions/',
    suggestedFreelancers: ApiEndpointsBase + 'azure/assessment-freelancers-suggestions/',
  },
  recruiters: {
    userDetails: ApiEndpointsB2BBase + 'auth/user/',
  },
  freelancers: {
    taggedCompetencesFilters: ApiEndpointsB2BBase + 'freelancers/competence-tag-filters/',
    candidateProfiles: ApiEndpointsB2BBase + 'freelancers/freelancer/',
    candidateSuggestedForJob: ApiEndpointsAzureBase + 'freelancers/job/',
    topProfilesFilters: ApiEndpointsB2BBase + 'freelancers/top-profiles/',
    topPercentageProfilesFilters: ApiEndpointsB2BBase + 'freelancers/top-svi/',
    availabilityFilters: ApiEndpointsB2BBase + 'freelancers/availability-filters/',
    rateFilters: ApiEndpointsB2BBase + 'freelancers/rate-filters/',
    seniorityFilters: ApiEndpointsB2BBase + 'freelancers/seniority-filters/',
    projects: ApiEndpointsB2BBase + 'jobs/job/',
    suggestedJobs: ApiEndpointsB2BBase + 'jobs/job-application/',
    addJob: ApiEndpointsB2BBase + 'jobs/',
    getJobApplicationInterviewDates: ApiEndpointsBase + 'jobs/interview-date/',
    stackAutocomplete: ApiEndpointsBase + 'experience/competence/',
  },
};

export { environment } from '../environments/environment';

export const MediaQueries: any = {
  screenPhone: 640,
  screenDesktop: 1024,
  screenLgDesktop: 1200,
  screenXlgDesktop: 1440
};
