import { NgModule }                          from '@angular/core';
import { RouterModule, Routes }              from '@angular/router';
import { LandingPageDefaultComponent }       from '@app/route.landing-page-default/landing-page-default.component';
import { TranslateModule }                   from '@ngx-translate/core';
import { LOCALIZE_ROUTER_MODULE_PARSER }     from '@services/i18n.service';
import { PathResolveService }                from '@services/path-resolve.service';
import { LocalizeRouterModule }              from 'localize-router';
import { RouteNotFoundComponent }            from './route.not-found/route-not-found.component';
import {RouteLogoutComponent} from '@app/route.logout/route.logout.component';

export const ROUTE_REDIRECTS = [
  {
    path: 'certificate/:token',
    redirectTo: 'user/certificate/:token',
    pathMatch: 'full'
  },
  {
    path: 'quizzes/test/:token',
    redirectTo: 'user/quizzes/test/:token',
    pathMatch: 'full'
  },
  {
    path: 'quizzes/test/:token/result',
    redirectTo: 'user/quizzes/test/:token/result',
    pathMatch: 'full'
  },
  {
    path: 'job/apply/wp/:wp_id',
    redirectTo: 'user/apply/wp/:wp_id'
  },
  {
    path: 'job/apply/wp/:wp_id/finish',
    redirectTo: 'user/apply/wp/:wp_id/finish'
  },
  {
    path: 'job/:id',
    redirectTo: 'user/job/:id'
  },
  {
    path: 'dashboard',
    pathMatch: 'full',
    redirectTo: 'user/dashboard'
  }
];

export const ROUTES: Routes = [
  {
    path: '**',
    resolve: {
      path: PathResolveService,
    },
    component: RouteNotFoundComponent
  },
  {
    path: '',
    component: LandingPageDefaultComponent,
    pathMatch: 'full'
  },
  {
    path: 'logout',
    component: RouteLogoutComponent
  },
  ...ROUTE_REDIRECTS,
  // {
  //   path: 'user',
  //   loadChildren: () => import('./b2c/b2c.module').then(module => module.B2CModule)
  // },
  {
    path: 'auth',
    data: {disableSupportService: true},
    loadChildren: () => import('./common/auth/auth.module').then(module => module.AuthModule),
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES,
     //  { enableTracing: true } // <-- debugging purposes only
    ),
    LocalizeRouterModule.forRoot(ROUTES, LOCALIZE_ROUTER_MODULE_PARSER),
    TranslateModule.forRoot()
  ],
  exports: [RouterModule, LocalizeRouterModule, TranslateModule]
})
export class AppRoutingModule {
}
