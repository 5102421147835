import { Directive }                      from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appValidatorEmail]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: ValidatorEmailDirective, multi: true}
  ]
})
export class ValidatorEmailDirective {

  public validate(formControl: AbstractControl): { [key: string]: any } {
    const EMAIL_REGEXP = new RegExp(
      ['^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)',
        '|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])',
        '|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'].join('')
    );

    if (formControl.value && !EMAIL_REGEXP.test(formControl.value)) {
      return {validEmail: true};
    }

    return null;
  }

}
