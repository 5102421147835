import { Injectable }                                               from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AppDomain }                                                from '@app/app.constants';
import { AuthService }                                              from '@common/auth/services/auth.service';
import { NavigationService }                                        from '@services/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class IsAuthenticatedB2CGuard implements CanActivate {

  constructor(
    private _navigationService: NavigationService,
    private _auth: AuthService
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) {
    const isAuthenticated = this._auth.isAuthenticatedB2C;
    if (!isAuthenticated) {
      if (window.location.href.indexOf(AppDomain) >= 0) {
        // this._navigationService.navigateByUrl('auth/login');
        // this._navigationService.navigateByUrl('auth/login');
        window.location.href = '/en/auth/login';
      }
    }
    return isAuthenticated;
  }
}
