import { CommonModule }            from '@angular/common';
import { NgModule }                from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GlobalComponentsModule }  from '@common/global-components/global-components.module';
import { LayoutsModule }           from '@common/layouts/layouts.module';
import { SharedModule }            from '@common/shared.module';
import { TrumbowygModule }         from 'ng2-lazy-trumbowyg';

import { BizAdminRoutingModule } from './biz-admin-routing.module';
import { BizAdminComponent }     from './biz-admin.component';


@NgModule({
  declarations: [BizAdminComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    TrumbowygModule.forRoot({plugins: ['colors', 'noembed', 'preformatted']}),
    SharedModule,
    GlobalComponentsModule,
    LayoutsModule,
    BizAdminRoutingModule,
  ]
})
export class BizAdminModule {
}
