import { NgModule }             from '@angular/core';
import { RouterModule }         from '@angular/router';
import { AUTH_ROUTES }          from '@common/auth/auth.routes';
import { TranslateModule }      from '@ngx-translate/core';
import { LocalizeRouterModule } from 'localize-router';

@NgModule({
  imports: [
    LocalizeRouterModule.forChild(AUTH_ROUTES),
    RouterModule.forChild(AUTH_ROUTES),
    TranslateModule.forChild()
  ],
  exports: [RouterModule, LocalizeRouterModule, TranslateModule]
})
export class AuthRoutingModule {
}
