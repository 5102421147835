import { Routes }                  from '@angular/router';
import { IsAuthenticatedB2CGuard } from '@common/auth/guards/is-authenticated-b2c.guard';

export const BIZADMIN_ROUTES: Routes = [
  {
    path: 'biz',
    canActivate: [IsAuthenticatedB2CGuard],
    children: [
      {
        path: 'candidate',
        loadChildren: () => import('./+candidates/biz-admin-candidates.module').then(module => module.BizAdminCandidatesModule)
      }
    ]
  }
];
