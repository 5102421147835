import { Component, OnInit } from '@angular/core';
import { AppDomain }         from '@app/app.constants';
import { AuthService }       from '@common/auth/services/auth.service';
import { LanguageService }   from '@services/i18n.service';
import { NavigationService } from '@services/navigation.service';
import { SiteConfigService } from '@services/site-config.service';
import { UtilsService }      from '@services/utils.service';

import * as jQuery from 'jquery';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public year: Date;
  public currentLang: string;
  public isAssessment = this.authService.isAuthenticatedB2B;

  constructor(
    public siteConfigService: SiteConfigService,
    public navigationService: NavigationService,
    public languageService: LanguageService,
    public utilsService: UtilsService,
    public authService: AuthService,
  ) {
  }

  public ngOnInit() {
    this.year = new Date();
    this.currentLang = this.languageService.currentLang;
    this.languageService.currentLangChanged.subscribe((lang: string) => {
      this.currentLang = lang;
    });
  }

  public callBackOnClick(event: any) {
    const form = document.getElementById('formContactUs');
    if (form && this.currentLang !== 'fr') {
      jQuery('html, body').animate({
        scrollTop: form.offsetTop - 80
      }, 1300);
    } else {

      if (window.location.href.indexOf(AppDomain) >= 0) {
        if (this.currentLang === 'fr') {
          window.location.href = '/companies/fr/recrutement-developpeurs/#contact-form';
        }
        window.location.href = '/companies/en/developer-recruitment/#contact-form';
      }
    }
  }

}
