import { Component, OnInit }    from '@angular/core';
import { ActivatedRoute }       from '@angular/router';
import { AppDomain }            from '@app/app.constants';
import { AutologinService }     from '@b2b/route.autologin/services/autologin.service';
import { LanguageService }      from '@services/i18n.service';
import { MaterialAlertService } from '@services/material-alert.service';
import { NavigationService }    from '@services/navigation.service';

@Component({
  selector: 'app-platforms-autologin',
  templateUrl: './platforms-autologin.component.html',
  styleUrls: ['./platforms-autologin.component.scss']
})
export class PlatformsAutologinComponent  implements OnInit {

  constructor(
    private _alertMessage: MaterialAlertService,
    private _activatedRoute: ActivatedRoute,
    private _autoLogin: AutologinService,
    private _navigationService: NavigationService,
    private _languageService: LanguageService
  ) {}

  public ngOnInit(): void {
    this._activatedRoute.params.subscribe(async (params) => {

      try {

        const platform =  require('../../common/access_map/' + params.platform)[params.platform];

        if (!platform.loginPath && window.location.href.indexOf(AppDomain) >= 0) {
          // return this._navigationService.navigateByUrl('/business');
          window.location.href = `/companies/${this._languageService.currentLang}/business/`;
          return;
        }

        this._alertMessage.open({
          buttons: [],
          title: '',
          content: 'GLOBAL_WAITING_FOR_AUTOLOGIN',
          closeAfter: false
        }, {blur: false});
        this._activatedRoute.queryParams.subscribe(async (queryParams) => {
          const qs = queryParams.cv ? '?cv=' + queryParams.cv : '';
          const data = await this._autoLogin.getToken(params.platform).toPromise();
          const url = data.base_url + platform.loginPath + data.token;
          const redirectUrl = url + qs;
          if (window.location.href.indexOf(AppDomain) >= 0) {
            window.location.href = redirectUrl;
          }
        });
      } catch (error) {

        await this._alertMessage.closeAll();
        await this._alertMessage.open({
          buttons: [{
            type: 'accept',
            text: 'GLOBAL_LABEL_REFRESH_BUTTON_MODAL',
            response: true
          }],
          title: '',
          content: 'GLOBAL_WAITING_FOR_AUTOLOGIN_ERROR',
          closeAfter: false
        }, {blur: false});

        window.location.reload();
      }

    });
  }

}
