import { CommonModule }                    from '@angular/common';
import { NgModule }                        from '@angular/core';
import { YouTubePlayerModule }             from '@angular/youtube-player';
import { MaterialModule }                  from '@common/material/material.module';
import { TranslateModule }                 from '@ngx-translate/core';
import { NgxSiemaModule }                  from 'ngx-siema';
import { SlideshowGuideCarouselComponent } from './cpts/slideshow-guide-carousel.component';
import { SlideshowGuideModalComponent }    from './cpts/slideshow-guide-modal.component';
import { SlideshowGuideYoutubeComponent }  from './cpts/slideshow-guide-youtube.component';


@NgModule({
  declarations: [
    SlideshowGuideCarouselComponent,
    SlideshowGuideModalComponent,
    SlideshowGuideYoutubeComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgxSiemaModule.forRoot(),
    TranslateModule,
    YouTubePlayerModule,
  ],
  exports: [
    SlideshowGuideModalComponent
  ],
  entryComponents: [
    SlideshowGuideCarouselComponent,
    SlideshowGuideYoutubeComponent,
  ]
})
export class TutorialModule {
}
