<ul class="currency-switcher">
  <li *ngFor="let currency of currencies">
    <a
      (click)="updateCurrency(currency)"
      [ngClass]="{
        'is-active' : currency.selected
      }"
      class="button-currency {{'currency-' + currency.id}}">
      {{currency.symbol}}
      <strong>{{currency.id}}</strong>
    </a>
  </li>
</ul>
