import { CommonModule }                     from '@angular/common';
import { NgModule }                         from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule }            from '@angular/material/autocomplete';
import { MatInputModule }                   from '@angular/material/input';
import { MatRadioModule }                   from '@angular/material/radio';
import { MatSnackBarModule }                from '@angular/material/snack-bar';
import { EmailTemplatesEditComponent }      from '@b2b/@common/modules/company-profile/cpts/email-templates-edit.component';
import { SharedModule }                     from '@common/shared.module';
import { TranslateModule }                  from '@ngx-translate/core';
import { TrumbowygModule }                  from 'ng2-lazy-trumbowyg';
// import { CompanyProfileRoutingModule } from './company-profile-routing.module';
import { CompanyProfileComponent }          from './company-profile.component';
import { CompanyDetailsComponent }          from './cpts/company-details.component';
import { CompanyEditComponent }             from './cpts/company-edit.component';
import { SeeTokenModalComponent }           from './cpts/see-token-modal.component';
import { UserAssessmentTokenComponent }     from './cpts/user-assessment-token.component';
import { UserDetailsComponent }             from './cpts/user-details.component';
import { UserEditComponent }                from './cpts/user-edit.component';
import { DeleteUsersIntervalComponent } from './cpts/delete-users-interval.component';


@NgModule({
  declarations: [
    CompanyProfileComponent,
    CompanyDetailsComponent,
    CompanyEditComponent,
    UserDetailsComponent,
    UserEditComponent,
    EmailTemplatesEditComponent,
    SeeTokenModalComponent,
    UserAssessmentTokenComponent,
    DeleteUsersIntervalComponent
  ],
  exports: [
    EmailTemplatesEditComponent,
    CompanyProfileComponent,
    CompanyEditComponent,
    CompanyDetailsComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatAutocompleteModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatSnackBarModule,
    SharedModule,
    TrumbowygModule.forRoot({plugins: ['colors', 'noembed', 'preformatted']})
    // CommonB2bModule,
    // CompanyProfileRoutingModule
  ],
  entryComponents: [
    SeeTokenModalComponent
  ]
})
export class CompanyProfileModule {
}
