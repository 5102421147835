import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup }                         from '@angular/forms';
import { AuthEndpoints }                                  from '@common/auth/auth.constants';
import { FormAlertCode, FormHelper, FormState }           from '@common/helpers/form-helper';
import { EndpointService }                                from '@services/endpoint.service';
import { HttpError, HttpErrorService }                    from '@services/http-error.service';
import { HttpService }                                    from '@services/http.service';
import { LanguageService }                                from '@services/i18n.service';
import { UtilsService }                                   from '@services/utils.service';
import { Observable, throwError }                         from 'rxjs';
import { catchError, finalize }                           from 'rxjs/operators';

@Component({
  selector: 'app-form-reset-password-confirm',
  templateUrl: './form-reset-password-confirm.component.html',
  styleUrls: ['./form-reset-password-confirm.component.scss']
})
export class FormResetPasswordConfirmComponent implements OnInit {
  @Input() public uid: string;
  @Input() public withCredentials: boolean;
  @Input() public token: string;

  @Output() public onSuccess = new EventEmitter<any>();

  public formHelper: FormHelper;
  public isLoading = true;

  constructor(
    public http: HttpService,
    public httpErrorService: HttpErrorService,
    public languageService: LanguageService,
    public _EndpointService: EndpointService,
    public utilsService: UtilsService
  ) {
    this.withCredentials = false;
    this.formHelper = new FormHelper(
      new FormGroup({
        password1: new FormControl(''),
        password2: new FormControl('')
      })
    );
  }

  public ngOnInit() {
    this.callResetApiToGetEmail();
  }

  public onSubmit(event: any) {
    event.preventDefault();

    this.formHelper.setFormState(FormState.Submitted);
    this.formHelper.clearAlert();
    this.formHelper.initFormValidation();

    if (this.formHelper.formValid) {
      const input = {
        uid: this.uid,
        new_password1: this.formHelper.getFieldValue('password1'),
        new_password2: this.formHelper.getFieldValue('password2'),
        token: this.token
      };

      this.formHelper.setFormState(FormState.Pending);
      this.resetPasswordConfirm(input)
        .pipe(finalize(() => this.formHelper.setFormState(FormState.Done)))
        .subscribe(
          (response: Response) => {
            this.onSuccess.emit(response);
          },
          (httpError: HttpError) => {
            if (httpError.status === 400) {
              const fieldErrors = httpError.json;
              let fieldErrorsCount = 0;

              if (fieldErrors.new_password1) {
                this.formHelper.formGroup.get('password1').setErrors({invalid: fieldErrors.new_password1});
                fieldErrorsCount++;
              }

              if (fieldErrors.new_password2) {
                this.formHelper.formGroup.get('password2').setErrors({invalid: fieldErrors.new_password2});
                fieldErrorsCount++;
              }

              if (fieldErrorsCount === 0) {
                this.formHelper.setAlert(FormAlertCode.Danger, this.languageService.getValue('RESET_PASSWORD_CONFIRM_INVALID_FORM'));
              }
            } else {
              this.formHelper.setAlert(FormAlertCode.Danger, httpError.detail);
            }
          }
        );
    }
  }

  public async callResetApiToGetEmail() {
    const input = {
      uid: this.uid,
      token: this.token
    };

    await this.resetPasswordConfirm(input)
      .subscribe(
        (response) => {
          if (response.email.length > 0) {
            const adInput = {
              email: response.email,
              redirect_uri: 'https://' + window.location.host + '/' + this.languageService.currentLang + '/auth/login/',
              flow: 'reset_pw'
            };

            this.activeDirectoryResetPasswordConfirm(adInput).subscribe(
              (data) => {
                // user is present in Active Directory so go Azure reset password form
                if (data.authorize_url.length > 0) {
                  window.location.href = data.authorize_url;
                }
              });

            setTimeout(() => {
              this.isLoading = false;
            }, 2000);
          }
        },
        (httpError: HttpError) => {
          if (httpError.status === 400) {
            const fieldErrorsCount = 0;

            if (fieldErrorsCount === 0) {
              this.formHelper.setAlert(FormAlertCode.Danger, this.languageService.getValue('RESET_PASSWORD_CONFIRM_INVALID_FORM'));
            }
          } else {
            this.formHelper.setAlert(FormAlertCode.Danger, httpError.detail);
          }
        }
      );
  }

  public resetPasswordConfirm(input: any): Observable<any> {
    // const requestOptions = this.withCredentials
    //   ? new RequestOptions({ withCredentials: true })
    //   : null;
    const passwordResetConfirmEndpoint = this._EndpointService.getEndpoint(AuthEndpoints.passwordResetConfirm,
      this.utilsService.appType());
    return this.http.post(passwordResetConfirmEndpoint, input)
      .pipe(catchError((error: any) => {
        if (error.detail) {
          return throwError(error);
        }

        return throwError(this.httpErrorService.parseError(error));
      }));
  }

  public activeDirectoryResetPasswordConfirm(input: any): Observable<any> {
    const passwordResetConfirmEndpoint = this._EndpointService.getEndpoint(AuthEndpoints.adPasswordResetConfirm);
    return this.http.post(passwordResetConfirmEndpoint, input)
      .pipe(catchError((error: any) => {
        if (error.detail) {
          return throwError(error);
        }

        return throwError(this.httpErrorService.parseError(error));
      }));
  }
}
