import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router }      from '@angular/router';
import { MediaQueries }                               from '@app/app.constants';
import { AuthService }                                from '@common/auth/services/auth.service';
import { FADE_OUT_ANIMATION }                         from '@common/core/core.animations';
import { NavigationService }                          from '@services/navigation.service';
import { Cookie }                                     from 'ng2-cookies';
import { Subject }                                    from 'rxjs';
import { filter, takeUntil }                          from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [ FADE_OUT_ANIMATION ]
})
export class LayoutComponent implements OnInit, OnDestroy {
  isTopBannerShown = false;
  isSidebarCollapsedMobile = false;
  showSidebar = false;
  isTestResultPage = false;
  showResolutionWarning = false;
  routeNavigationEnd$ = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));
  private unsubscribe$ = new Subject<void>();
  private paramKey = 'successPayment';
  successPayment = null;
  showStripeMessage = true;

  public isMobileMenuOpen = false;

  constructor(
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public navigationService: NavigationService,
    public router: Router
  ) {
    this.navigationService.mobileMenuOpened().subscribe(isOpened => this.isMobileMenuOpen = isOpened);
  }

  public get isSidebarCollapsed() {
    return Cookie.get('b2b_app_sidebar_collapsed') === 'true';
  }

  public set isSidebarCollapsed(value: boolean) {
    Cookie.set('b2b_app_sidebar_collapsed', value.toString(), null, '/');
  }

  ngOnInit() {
    this.routeNavigationEnd$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event) => {
        this.onRouteActivate();
      });
    this.onResize();
    this.stripeMessage();
    this.isB2BTestResultPage();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  stripeMessage() {
    this.successPayment = this.activatedRoute.snapshot.queryParams[this.paramKey];

    if(this.successPayment !== null && this.successPayment) {
      this._registerSuccessPayment();
    } else {
      this._registerFailedPayment();
    }

    setTimeout(function() {
      this.showStripeMessage = false;
    }.bind(this), 5000);
  }

  public showTopBanner(event: any) {
    this.isTopBannerShown = true;
  }

  public hideTopBanner(event: any) {
    this.isTopBannerShown = false;
  }

  isB2BTestResultPage() {
    this.isTestResultPage =  (window.location.href.indexOf('business') > -1 && window.location.href.indexOf('result') > -1) ||
      (this.authService.isAuthenticatedB2B && window.location.href.indexOf('user') > -1 &&
        window.location.href.indexOf('result') > -1);
  }

  public collapseSidebar(event: any) {
    this.isSidebarCollapsed = true;
  }

  public expandSidebar(event: any) {
    this.isSidebarCollapsed = false;
  }

  public onRouteActivate() {
    window.scroll(0, 0);
  }

  private _registerSuccessPayment() {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: 'B2B_Assessment_Dashboard_Paid',
      userType: 'b2b',
      source: 'dashboard'
    });
  }


  private _registerFailedPayment() {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: 'B2B_Assessment_Dashboard_Fail',
      userType: 'b2b',
      source: 'dashboard'
    });
  }

  @HostListener('window:resize')
  public onResize() {
    this.showResolutionWarning = window.innerWidth < MediaQueries.screenLgDesktop;
    this.isSidebarCollapsedMobile = window.innerWidth <= MediaQueries.screenDesktop;

    const isUserAuthenticated = this.authService.isAuthenticatedB2C || this.authService.isAuthenticatedB2B;
    this.showSidebar = window.innerWidth <= MediaQueries.screenDesktop ? false : isUserAuthenticated;
  }

}
