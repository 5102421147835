import {HttpParams}                               from '@angular/common/http';
import {Injectable}                               from '@angular/core';
import {ActivatedRoute, NavigationExtras, Params} from '@angular/router';
import { AppDomain }                              from '@app/app.constants';
import {B2bUserDetails}                           from '@common/auth/classes/b2b-user-details';
import {UserDetails}                              from '@common/auth/classes/user-details';
import {STEPNAMES}                                from '@common/auth/constants/stepnames';
import {B2cUserType}                              from '@common/auth/enums/b2c-user-type.enum';
import {UserActionType}                           from '@common/auth/enums/user-action-type.enum';
import {AuthService}                              from '@common/auth/services/auth.service';
import {LanguageService}                          from '@services/i18n.service';
import {MaterialSnackBarService}                  from '@services/material-snack-bar.service';
import {NavigationService}                        from '@services/navigation.service';
import {PlatformsAccessService}                   from '@services/platforms-access.service';
import {UtilsService} from '@services/utils.service';
import {RouterUrlService} from '@services/router-url.service';

@Injectable({
  providedIn: 'root'
})
export class UserRoutesService {

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private utilsService: UtilsService,
    private navigationService: NavigationService,
    private languageService: LanguageService,
    private platformsAccessService: PlatformsAccessService,
    private matSnackBarService: MaterialSnackBarService,
    private routerUrlService: RouterUrlService
  ) {
  }

  get queryParams(): Params {
    return this.activatedRoute.snapshot.queryParams;
  }

  get params(): Params {
    return this.activatedRoute.snapshot.params;
  }

  public async handleB2CLogin() {
    if (this.utilsService.hasAccessToRoute('b2c', this.queryParams.redirectTo)) {
      const type = 'b2c';
      await this.authService.getUserDetails({type})
        .subscribe(async (USER: any) => {
          const PARAMS: NavigationExtras = {
            queryParams: {
              type: this.userTypeB2CUser(USER),
              login_success: true,
              ...!this.isValidUserB2C(USER) && {invalid: true},
              ...this.queryParams.action && {action: this.queryParams.action}
            },
            queryParamsHandling: 'merge'
          };
          console.log(this.utilsService.hasAccessToRoute('b2c', this.queryParams.redirectTo));
          const redirectUrl = this.queryParams.redirectTo ? this.queryParams.redirectTo :
            decodeURIComponent(this.routerUrlService.getRedirectUrl());
          if (!this.isValidUserB2C(USER)) {
            if (this.queryParams.redirectTo) {
              PARAMS.queryParams.onContinue = this.queryParams.redirectTo +
                this.utilsService.createUrl('', PARAMS.queryParams);

              if (this.isFromJobs()) {
                return this._goToRedirect(USER, this.queryParams.redirectTo); // jobs
              }
            }
            return this._goToB2COnboarding(PARAMS);
          }

          if (this.queryParams.redirectTo) {
            return this._goToRedirect(USER, this.queryParams.redirectTo); // jobs, quizzes, algo
          }

          const defaultRoute = this.utilsService.createUrl(`${this.languageService.currentLang}/user/dashboard`, PARAMS.queryParams);

          return window.location.href = defaultRoute;
        });
    } else {
      this.matSnackBarService.openTop('GLOBAL_ERROR_PLEASE_LOGIN_WITH_B2B_ACCOUNT',
        {
          panelClass: ['error'], duration: 9000
        });
    }
  }

  public handleB2BLogin(parameters = this.queryParams || this.params) {
    if (this.hasAccessToRedirectUrl(parameters)) {
      const qpFromUrl = this.utilsService.parseUrlQueryParameters(window.location.href);
      if (!parameters.redirectTo && qpFromUrl.redirectTo != null) {
        parameters.redirectTo = decodeURIComponent(qpFromUrl.redirectTo);
      }
      if (parameters.redirectTo || this.routerUrlService.getRedirectUrl()) {
        const redirectParams = parameters.redirectTo ? parameters : this.routerUrlService.getRedirectUrl();
        this.routerUrlService.deleteRedirectUrl();
        this.goToB2BRedirect(redirectParams);
      } else {
        this.goToB2BApplication();
      }
    } else {
      this.displayB2CAuthenticationRequired();
    }
  }

  public hasAccessToRedirectUrl(parameters = this.queryParams || this.params): boolean {
    return this.utilsService.hasAccessToRoute('b2b', parameters.redirectTo);
  }

  public displayB2CAuthenticationRequired() {
    this.matSnackBarService.openTop('GLOBAL_ERROR_PLEASE_LOGIN_WITH_B2C_ACCOUNT',
      {
        panelClass: ['error'], duration: 9000
      });
  }

  public getRedirectForSignup(appType?: string, companyName?: string, companyType?: string) {
    const redirectToUrl = (this.queryParams.redirectTo)
      ? ('&onContinue=' +
        this.queryParams.redirectTo +
        ((this.queryParams.redirectTo.indexOf('?') !== -1) ? '&' : '?') + 'signup_success=true')
      : '';

    if (appType === 'b2b') {
      if (companyType === 'assessment') {
        return `${this.languageService.currentLang}/app/` + companyName + '?login_success=true';
      } else {
        return `${this.languageService.currentLang}/business/freelancers/dashboard`;
      }
    }

    const redirectUrl =
      `${this.languageService.currentLang}/user/profile/complete/${STEPNAMES.step1}` +
      `?signup_success=true${redirectToUrl}${this.getTypeStringFromURL()}`;

    return this.isFromJobs() ?
      `${this.queryParams.redirectTo}` +
      `&source=site&signup_success=true${this.getActionString()}${this.getTypeStringFromURL()}` :
      redirectUrl;
  }

  public getRedirectForCampaignSignup() {
    return `${this.languageService.currentLang}/user/dashboard/` +
      `?signup_success=true${this.getTypeStringFromURL()}`;
  }

  public isFromJobs(redirectUrl?): boolean {
    const QUERY_PARAMS: HttpParams = this.utilsService.getQueryParams();
    const REDIRECT_TO = QUERY_PARAMS.get('redirectTo') ? QUERY_PARAMS.get('redirectTo') : decodeURIComponent(redirectUrl);
    return decodeURIComponent(REDIRECT_TO).indexOf(this.utilsService.URLS.job) > -1;
  }

  public getTypeStringFromURL(): string {
    return window.location.href.indexOf(B2cUserType[B2cUserType.freelancer]) >= 0 && this.utilsService.appType() === 'b2c' ?
      '&type=' + B2cUserType[B2cUserType.freelancer] :
      '&type=' + B2cUserType[B2cUserType.coder];
  }

  public async redirectToApp(firstTimeSignup?: boolean, loggedIn?: boolean) {
    const user = await this.authService.getUserDetails(null, true).toPromise();

    const root = '/app/';
    const companySlug = user.company_slug;
    const signupQueryParams = {
      // ?source=site&signup_success=true&type=company
      queryParams: {
        ...user &&
        user.platforms_access &&
        user.platforms_access.freelancers &&
        {source: 'site'},
        signup_success: true,
        ...user &&
        user.platforms_access &&
        user.platforms_access.freelancers &&
        {type: 'company'}
      }
    };
    const loginQueryParams = {
      queryParams: {
        login_success: true
      }
    };

    let queryParams;

    if (firstTimeSignup) {
      queryParams = signupQueryParams;
      this.platformsAccessService.redirectAfterLogin(
        user,
        user.platforms_access.freelancers ? '?source=site&signup_success=true&type=company' : '?signup_success=true',
        firstTimeSignup);
    }
    if (loggedIn) {
      queryParams = loginQueryParams;
    }

    if (user.company_has_campaigns || firstTimeSignup) {
      this.navigationService.navigateByUrl(
        root + companySlug,
        queryParams
      );
    } else {
      this.navigationService.navigateByUrl(
        root + companySlug + '/tests',
        queryParams
      );
    }
  }

  private goToB2BApplication() {
    const isLogin = window.location.pathname.indexOf('login') > -1;
    const loginSuffix = isLogin ? '?login_success=true' : '?signup_success=true';
    this.authService.getUserDetails({type: 'b2b'}, true).toPromise().then((user: UserDetails & B2bUserDetails) => {
      this.authService.currentUserDetails = user;
      let url = '';
      if (user.default_platform_access === 'assessment') {
        url = this.navigationService.setURLParamsForB2BAssessment(user, loginSuffix);
      }
      if (user.default_platform_access === 'freelancers') {
        url = this.navigationService.setURLParamsForB2BFreelancers(user, loginSuffix);
      }
      this.platformsAccessService.redirectAfterLogin(this.authService.currentUserDetails, url);
    });
  }

  private goToB2BRedirect(parameters) {
    let redirectTo;
    if (parameters.redirectTo) {
      redirectTo = decodeURIComponent(parameters.redirectTo);
    } else {
      redirectTo = decodeURIComponent(parameters);
    }
    const separator = (redirectTo.indexOf('?') !== -1) ? '&' : '?';
    window.location.href = redirectTo + separator + 'login_success=true';
  }

  private getActionString(): string {
    return this.isFromJobs() ? '&action=' + UserActionType[UserActionType.application] : '';
  }

  private userTypeB2CUser(user): string {
    return user.is_freelancer ? B2cUserType[B2cUserType.freelancer] : B2cUserType[B2cUserType.coder];

  }

  private getTypeStringFromUser(user): string {
    return `&type=${this.userTypeB2CUser(user)}`;
  }

  private isValidUserB2C(user): boolean {
    return this.authService.isValidUserB2C(user);
  }

  private _goToRedirect(user, redirectUrl) {
    const separator = (redirectUrl.indexOf('?') !== -1) ? '&' : '?';
    const source = this.isFromJobs() ? separator + 'source=site' : separator;
    window.location.href = decodeURIComponent(redirectUrl) +
      source + '&login_success=true' + this.getActionString() + this.getTypeStringFromUser(user);
  }

  private _goToB2COnboarding(params: NavigationExtras) {
    const paramsClone = {...params};
    paramsClone.queryParams.redirectTo = null;
    paramsClone.queryParamsHandling = '';
    const newParams = this.utilsService.createQueryParams(paramsClone.queryParams);
    const stringifiedParams = newParams ? '?' + newParams.toString() : '';

    if (window.location.href.indexOf(AppDomain) >= 0) {
      window.location.href = `/${this.languageService.currentLang}/user/profile/complete/${STEPNAMES.step1}${stringifiedParams}`;
    }
    // this.navigationService.navigateByUrl(`user/profile/complete/${STEPNAMES.step1}`, paramsClone);
  }

}
