import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable }                       from '@angular/core';
import { environment }                      from '@app/app.constants';
import { AuthEndpoints }                    from '@common/auth/auth.constants';
import { EndpointService }                  from '@services/endpoint.service';
import { HttpError, HttpErrorService }      from '@services/http-error.service';
import { HttpService }                      from '@services/http.service';
import { LoaderService, LoaderState }       from '@services/loader-service.service';
import { UtilsService }                     from '@services/utils.service';
import { Observable, Observer, throwError } from 'rxjs';
import { catchError, finalize }             from 'rxjs/operators';

// declare var gapi: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleService {

  private GoogleAuth: any;

  constructor(
    protected _http: HttpService,
    protected _loaderService: LoaderService,
    protected _httpErrorService: HttpErrorService,
    protected utilsService: UtilsService,
    protected _EndpointService: EndpointService
  ) {
    if ((window as any).gapi && typeof (window as any).gapi.load === 'function') {
      this._initGapi();
    } else {
      // rare case, retry if not loaded
      let count = 0;
      const interval = setInterval(() => {
        if ((window as any).gapi && typeof (window as any).gapi.load === 'function' || count === 20) {
          this._initGapi();
          clearInterval(interval);
        }
        count++;
      }, 500);
    }
  }

  public signIn(params): Observable<any> {
    return new Observable<Response>(
      (observer: Observer<Response>) => {

        const checkGoogleAuth: Observable<GoogleAuthResponse> = new Observable<GoogleAuthResponse>(
          ((googleObserver: Observer<GoogleAuthResponse>) => {
            if (!this.GoogleAuth.isSignedIn.get()) {
              this.GoogleAuth.signIn().then(() => {
                googleObserver.next(this.GoogleAuth.currentUser.get().getAuthResponse());
                googleObserver.complete();
              });
            } else {
              googleObserver.next(this.GoogleAuth.currentUser.get().getAuthResponse());
              googleObserver.complete();
            }
          })
        );

        checkGoogleAuth.subscribe((googleAuthResponse: GoogleAuthResponse) => {
          this._loaderService.setState(LoaderState.Pending);

          this._restAuthentication(googleAuthResponse.access_token, params)
            .pipe(finalize(() => this._loaderService.setState(LoaderState.Done)))
            .subscribe(
              (response: Response) => {
                observer.next(response);
                observer.complete();
              },
              (error: HttpError) => {
                this.GoogleAuth.signOut();
                this.GoogleAuth.disconnect();

                observer.error(error);
                observer.complete();
              });
        });

      });
  }

  protected _restAuthentication(accessToken: string, params): Observable<any> {
    const body = {
      access_token: accessToken,
      ...this.utilsService.socialSignupBodyParams,
      ...params
    };

    const correctEndpoint = this._EndpointService.checkIfRouteIs('signup') ? AuthEndpoints.googleAuth : AuthEndpoints.googleLogin;
    const appType = this.utilsService.appType();
    const googleAuthEndpoint = this._EndpointService.getEndpoint(correctEndpoint, appType);

    return this._http.post(googleAuthEndpoint, body)
      .pipe(catchError((error: HttpErrorResponse) => {
        const httpError: HttpError = this._httpErrorService.parseError(error);
        if (httpError.status === 400) {
          httpError.detail = httpError.json.non_field_errors;
        }

        return throwError(httpError);
      }));
  }

  private _initGapi() {
    if ((window as any).gapi) {
      (window as any).gapi.load('auth2', () => {
        (window as any).gapi.auth2.init(
          {
            client_id: environment.google.client_id,
            cookiepolicy: 'single_host_origin',
            fetch_basic_profile: false,
            scope: 'https://www.googleapis.com/auth/userinfo.email'
          }).then((response: Response) => {
          this.GoogleAuth = response;
        });
      });
    }
  }

}

export interface GoogleAuthResponse {
  access_token: string;
  id_token: string;
  login_hint: string;
  scope: string;
  expires_in: string;
  first_issued_at: string;
  expires_at: string;
}
