import { Injectable }                                               from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AppDomain }                                                from '@app/app.constants';
import { AuthService }                                              from '@common/auth/services/auth.service';
import { LanguageService }                                          from '@services/i18n.service';
import { NavigationService }                                        from '@services/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class AutologinGuard implements CanActivate {

  constructor(
    private _navigationService: NavigationService,
    private _auth: AuthService,
    private _languageService: LanguageService
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) {

    return this._auth.getUserDetails({type: 'b2b'}).toPromise().then((user: any) => {

      const platform = route.params.platform || '';

      if (!user.platforms_access[platform]) {
        throw false;
      }
      return true;
    }).catch(() => {
      if (window.location.href.indexOf(AppDomain) >= 0) {
        window.location.href = `/companies/${this._languageService.currentLang}/business/`;
      }
      return false;
    });
  }
}
