import { Component, Renderer2 } from '@angular/core';
import { Router }               from '@angular/router';
import { MediaQueries }         from '@app/app.constants';
import { AuthService }          from '@common/auth/services/auth.service';
import { LanguageService }      from '@services/i18n.service';
import { NavigationService }    from '@services/navigation.service';


@Component({
  selector: 'app-block-nav-toggle',
  templateUrl: './block-nav-toggle.component.html',
  styleUrls: ['./block-nav-toggle.component.scss']
})
export class BlockNavToggleComponent {


  public isMobileActive: boolean;

  constructor(
    public router: Router,
    public renderer: Renderer2,
    public languageService: LanguageService,
    public authService: AuthService,
    public navigationService: NavigationService
  ) {
    this.isMobileActive = false;
    this.navigationService.activeRoute().subscribe(() => {
      this.navigationService.closeMobileMenu();
    });
    this.navigationService.mobileMenuOpened().subscribe(isOpened => this.isMobileActive = isOpened);
  }

  public onCloseClick(event: any) {
    this.navigationService.closeMobileMenu();
  }

  public toggleMainMenu(event: any) {
    if (this.isMobileActive) {
      this.navigationService.closeMobileMenu();
    } else {
      this.navigationService.openMobileMenu();
    }

    // if (window.innerWidth < MediaQueries.screenPhone) {
    //   if (this.isMobileActive) {
    //     this._disableDocumentScroll();
    //   } else {
    //     this._enableDocumentScroll();
    //   }
    // }
  }

  public onWindowResize(event: any) {
    if (window.innerWidth >= MediaQueries.screenDesktop) {
      this.navigationService.closeMobileMenu();
    }
  }

  private _enableDocumentScroll() {
    this.renderer.setStyle(document.body, 'overflow', '');
    this.renderer.setStyle(document.body, 'height', '');
  }

  private _disableDocumentScroll() {
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
    this.renderer.setStyle(document.body, 'height', '100%');
  }
}
