<div class="color-overlay">
  <div class="main-wrapper">
    <div class="logo">
      <a (click)="goToHomePage()"
        [attr.title]="'GLOBAL_LABEL_BRANDING_PENTALOG' | translate">
        <img
          [attr.alt]="siteConfigService?.settings ? siteConfigService?.settings[utilsService.appType()]?.website.name : ''"
          class="logo"
          src="/assets/img/logos/pentalog.svg"
        />
      </a>
    </div>
    <div class="login-content">
      <h4
        *ngIf="!resetPasswordMode && !passwordHasBeenReset"
        class="thin">{{ 'LOGIN_WITH_RECOVER_PASSWORD' | translate }}
      </h4>
      <h4
        *ngIf="resetPasswordMode"
        class="thin">{{ 'LOGIN_RESET_PASSWORD' | translate }}
      </h4>
      <form
        *ngIf="!passwordHasBeenReset"
        [formGroup]="formHelper.formGroup"
        class="sign-in-form"
        novalidate>
        <div class="form-group">
          <div
            [class.ng-invalid]="formHelper.fieldInvalid('email')"
            [class.ng-valid]="formHelper.fieldValid('email')"
            class="form-control-text">
            <span class="input-with-icon">
              <i class="fa fa-envelope"></i>
              <input
                #emailField
                [attr.placeholder]="'GLOBAL_LABEL_EMAIL' | translate"
                class="form-control"
                formControlName="email"
                required
                tabindex="1" type="text" validEmail>
            </span>
          </div>
          <div
            *ngIf="formHelper.fieldInvalid('email')">
            <span
              *ngIf="formHelper.fieldHasError('email', 'required')"
              class="form-control-error">
              {{ 'GLOBAL_ERROR_EMAIL_REQUIRED' | translate }}
            </span>
            <span
              *ngIf="formHelper.fieldHasError('email', 'validEmail')"
              class="form-control-error">
              {{ 'GLOBAL_ERROR_EMAIL_INVALID' | translate }}
            </span>
          </div>
        </div>
      </form>
      <p *ngIf="passwordHasBeenReset">
        {{ 'SIGNIN_RESEND_CONFIRMATION_LINK_DONE' | translate }}
        <br/>
        <br/>
        <button
          (click)="goToLogin()"
          class="btn btn-hollow">
          {{'GLOBAL_LOGIN_IN_YOUR_ACCOUNT' | translate}}
        </button>
      </p>
      <div
        *ngIf="!passwordHasBeenReset"
        class="clearfix">
        <button
          (click)="resetPassword($event)"
          class="btn btn-submit">Reset
        </button>
        <button
          (click)="goToLogin()"
          class="btn btn-cancel">Cancel
        </button>
      </div>
      <div class="clearfix"></div>
    </div>
    <p class="signup-text">
      {{ 'LOGIN_DONT_HAVE_AN_ACCOUNT' | translate }}
      <a [routerLink]="'/auth/signup' | localize" data-id="signup-link">
        {{ 'GLOBAL_BUTTON_SIGNUP' | translate }}
      </a>
    </p>
  </div>
  <div class="clearfix"></div>
</div>
