import { Component, OnInit } from '@angular/core';
import { LanguageService }   from '@services/i18n.service';
import { SiteConfigService } from '@services/site-config.service';
import { UtilsService }      from '@services/utils.service';

@Component({
  selector: 'app-block-branding-common',
  templateUrl: './block-branding-common.component.html',
  styleUrls: ['./block-branding-common.component.scss']
})
export class BlockBrandingCommonComponent implements OnInit {

  constructor(
    public languageService: LanguageService,
    public siteConfigService: SiteConfigService,
    public utils: UtilsService
  ) {
  }

  get siteName() {
    return this.siteConfigService.settings ? this.siteConfigService.settings[this.utils.appType()].website.name : 'Pentalog';
  }

  ngOnInit() {
  }
}
