import { CommonModule, registerLocaleData } from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_ID, ErrorHandler, Inject, NgModule, PLATFORM_ID, LOCALE_ID} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BizAdminModule} from '@app/biz-admin/biz-admin.module';
import {B2BModule} from '@b2b/b2b.module';
import {MaterialAlertComponent} from '@common/cpts/material-alert.component';
import {RequestInterceptor} from '@common/helpers/request-interceptor';
import {SharedModule} from '@common/shared.module';
import {RouterUrlService} from '@services/router-url.service';
import {SiteConfigService} from '@services/site-config.service';

import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {LandingPageDefaultComponent} from './route.landing-page-default/landing-page-default.component';
import {RouteNotFoundComponent} from './route.not-found/route-not-found.component';
import {RouteLogoutComponent} from '@app/route.logout/route.logout.component';

const isNotLocal = window.location.href.indexOf('.xlocal') === -1;

export function getLocale(){
  return window.location.href.search('/fr') > 0  ? 'fr': 'en';
}

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEn, 'en');

@NgModule({
  imports: [
    BrowserModule.withServerTransition({appId: 'pentalogfreelancersfrontend'}),
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    SharedModule.forRoot(),
    B2BModule,
    BizAdminModule
  ],
  declarations: [
    AppComponent,
    LandingPageDefaultComponent,
    RouteNotFoundComponent,
    RouteLogoutComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true},
    {
      provide: LOCALE_ID,
      useValue: getLocale()
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    MaterialAlertComponent
  ]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string,
    siteConfigService: SiteConfigService,
    routerUrlService: RouterUrlService,
  ) {
    siteConfigService.initSettings();
    routerUrlService.initUrlSavingMechanism();
  }
}
