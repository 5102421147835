import { CommonModule }                from '@angular/common';
import { NgModule }                    from '@angular/core';
import { CheckboxDirective }           from '@common/global-directives/checkbox.directive';
import { InfiniteScrollDirective }     from '@common/global-directives/infinite-scroll.directive';
import { ToggleFeatureDirective }      from '@common/global-directives/toggle-feature.directive';
import { ValidatorEmailDirective }     from '@common/global-directives/validator-email.directive';
import { PipesModule }                 from '@common/pipes/pipes.module';
import { DisableIfNoLicenseDirective } from './disable-if-no-license.directive';
import { LevelDirective }              from './level.directive';
import { PageHeaderDirective }         from './page-header.directive';
import { RatingDirective }             from './rating.directive';
import { EqualToDirective }   from './equal-to.directive';
import { CountdownComponent } from './countdown.component';
import { TimerComponent }     from './timer.component';

@NgModule({
  declarations: [
    CheckboxDirective,
    ToggleFeatureDirective,
    DisableIfNoLicenseDirective,
    LevelDirective,
    InfiniteScrollDirective,
    PageHeaderDirective,
    ValidatorEmailDirective,
    RatingDirective,
    EqualToDirective,
    CountdownComponent,
    TimerComponent,
  ],
  exports: [
    CheckboxDirective,
    ToggleFeatureDirective,
    DisableIfNoLicenseDirective,
    LevelDirective,
    InfiniteScrollDirective,
    PageHeaderDirective,
    ValidatorEmailDirective,
    RatingDirective,
    EqualToDirective,
    CountdownComponent,
    TimerComponent,
  ],
  imports: [
    CommonModule,
    PipesModule
  ]
})
export class GlobalDirectivesModule {
}
