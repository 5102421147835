import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router }  from '@angular/router';
import { LicenseService }                         from '@b2b/@common/modules/license/services/license.service';
import { StripeModalService }                     from '@b2b/@common/modules/stripe/services/stripe-modal.service';
import { B2bUserType }                            from '@common/auth/enums/b2b-user-type.enum';
import { AuthService }                            from '@common/auth/services/auth.service';
import { LanguageService }                        from '@services/i18n.service';
import { UtilsService }                           from '@services/utils.service';
import { Cookie }                                 from 'ng2-cookies';
import { filter }                                 from 'rxjs/operators';
import { MediaQueries }                           from '@app/app.constants';


@Component({
  selector: 'app-b2b-sidebar',
  templateUrl: './b2b-sidebar.component.html',
  styleUrls: ['./b2b-sidebar.component.scss']
})
export class B2bSidebarComponent implements OnInit {
  disableTooltip = true;
  isOpenSubmenu = false;
  isSidebarCollapsedMobile = false;
  deletedTabActive: boolean;

  @Input() type = B2bUserType[B2bUserType.assessment];
  favoritesStr = '';
  needToBeHighlighted;
  showUpgradeButton;

  constructor(
    public authService: AuthService,
    public languageService: LanguageService,
    public stripeModalService: StripeModalService,
    public licenseService: LicenseService,
    public activatedRoute: ActivatedRoute,
    public utils: UtilsService,
    public router: Router
  ) {
  }

  public ngOnInit() {
    this.onResize();
    this.renderUpgradeButtonVisibility();

    const filterPipe = filter((event) => {
      return event instanceof NavigationEnd;
    });
    this.router.events
      .pipe(filterPipe)
      .subscribe((event: NavigationEnd) => {
        this.needToBeHighlighted = window.location.href.indexOf('/projects') > 0;
      });

    this.disableTooltip = Cookie.get('b2b_app_sidebar_collapsed')
      ? Cookie.get('b2b_app_sidebar_collapsed') !== 'true'
      : this.disableTooltip;

    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (!queryParams.hasNoFavorites
        && Object.keys(queryParams).length > 0
        && window.location.href.indexOf('freelancers') === -1) {
        const favoritesParams = this.utils.createQueryParams(
          {favorites__in: 'favorite'});
      }
      this.deletedTabActive = window.location.href.indexOf('/deleted') > -1;
    });
  }

  openDeletedItemsTab() {
    this.isOpenSubmenu = !this.isOpenSubmenu;
  }

  upgradeAction() {
    this.stripeModalService.buy();
  }

  renderUpgradeButtonVisibility() {
    this.showUpgradeButton = this.licenseService.showUpgradeButton();
  }
  // ugly
  // TODO: Refactor
  @HostListener('document:click', ['$event.target'])
  public onMouseClick(target) {
    if (target.id === 'collapseSidebar') {
      this.disableTooltip = false;
    } else if (target.id === 'expandSidebar') {
      this.disableTooltip = true;
    }
  }

  // Helpers
  // ==============================

  public get isSidebarCollapsed() {
    return Cookie.get('b2b_app_sidebar_collapsed') === 'true';
  }

  @HostListener('window:resize')
  public onResize() {
    this.isSidebarCollapsedMobile = window.innerWidth <= MediaQueries.screenDesktop;
  }
}
