import { Component, OnInit }  from '@angular/core';
import { FADE_OUT_ANIMATION } from '@common/core/core.animations';
import { NavigationService }  from '@services/navigation.service';

@Component({
  selector: 'app-layout-hero-banner',
  templateUrl: './layout-hero-banner.component.html',
  styleUrls: ['./layout-hero-banner.component.scss'],
  animations: [ FADE_OUT_ANIMATION ]
})
export class LayoutHeroBannerComponent {

  constructor(
    public navigationService: NavigationService
  ) { }

}
