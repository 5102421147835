import { Component, OnInit } from '@angular/core';
import { ActivatedRoute }    from '@angular/router';
import { LanguageService }   from '@services/i18n.service';
import { take }              from 'rxjs/operators';

// import { MessageService } from '../message.service';

@Component({
  selector: 'app-route-not-found',
  templateUrl: './route-not-found.component.html',
  styleUrls: ['./route-not-found.component.css']
})
export class RouteNotFoundComponent implements OnInit {
  path = '';

  constructor(
    public languageService: LanguageService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.activatedRoute.data.pipe(take(1))
      .subscribe((data: { path: string }) => {
        this.path = data.path;
      });
  }

  // public ngOnInit() {
  //   this.activatedRoute.data.subscribe((data: any) => {
  //     this._setRouteMeta();
  //   });
  // }
  //
  // private _setRouteMeta() {
  //   this.routeMetaService.getRouteMeta('not-found')
  //     .subscribe((routeMeta: RouteMeta) => {
  //       this.routeMetaService.setRouteMeta(routeMeta);
  //     });
  //
  //   this._metaService.addTags([
  //     { name: 'prerender-status-code', content: '404' }
  //   ]);
  // }
}
