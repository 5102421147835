import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {UtilsService} from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class RouterUrlService {

  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  private wpUrl: string = undefined;
  private redirectUrl: string = undefined;

  constructor(
    private router: Router,
    private utilsService: UtilsService
  ) {
  }

  public getRedirectUrl() {
    return this.utilsService.localStorage('REDIRECT_URL').get();
  }

  public getPreviousUrl() {
    return this.utilsService.localStorage('PREVIOUS_URL').get();
  }

  public getUrlFromWP() {
    return this.utilsService.localStorage('WP_URL').get();
  }

  public deleteUrlFromWP() {
    this.utilsService.localStorage('WP_URL').remove();
  }

  public deleteRedirectUrl() {
    this.utilsService.localStorage('REDIRECT_URL').remove();
  }

  public setRedirectUrl() {
    this.redirectUrl = window.location.href.split('redirectTo=')[1];
    if (this.redirectUrl) {
      this.utilsService.localStorage('REDIRECT_URL').add(this.redirectUrl);
    }
  }

  public initUrlSavingMechanism() {
    if (window.location.search.split('?')[2]) {
      this.saveUrlFromWP();
    }
    this.currentUrl = this.router.url;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.previousUrl !== '/' || this.currentUrl !== '/') {
          this.previousUrl = this.currentUrl;
          this.currentUrl = event.url;

          this.utilsService.localStorage('PREVIOUS_URL').add(this.previousUrl);
        }
      }
    });
  }

  public saveUrlFromWP() {
    this.wpUrl = window.location.search.split('redirectTo=')[1];
    if (this.wpUrl) {
      this.utilsService.localStorage('WP_URL').add(this.wpUrl);
    }
  }

}
