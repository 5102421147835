import { Directive, Input }               from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appEqualTo]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: EqualToDirective, multi: true}
  ]
})
export class EqualToDirective {
  @Input() public equalTo: string;
  @Input() public reverse: string;

  private get isReverse() {
    if (!this.reverse) {
      return false;
    }

    return this.reverse === 'true';
  }

  public validate(formControl: AbstractControl): any {
    const equalToControl = formControl.root.get(this.equalTo);

    if (formControl.value && equalToControl && formControl.value !== equalToControl.value && !this.isReverse) {
      return {equalTo: true};
    }

    if (equalToControl.errors && equalToControl && formControl.value === equalToControl.value && this.isReverse) {
      delete equalToControl.errors.equalTo;
      if (!Object.keys(equalToControl.errors).length) {
        equalToControl.setErrors(null);
      }
    }

    if (equalToControl && formControl.value !== equalToControl.value && this.isReverse) {
      equalToControl.setErrors({equalTo: true});
    }

    return null;
  }

}
