import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup }                                             from '@angular/forms';
import { ActivatedRoute, Params }                                             from '@angular/router';
import { B2cUserType }                                                        from '@common/auth/enums/b2c-user-type.enum';
import { UserRoutesService }                                                  from '@common/auth/services/user-routes.service';
import { LanguageService }                                                    from '@services/i18n.service';
import { NavigationService }                                                  from '@services/navigation.service';
import { RouteMeta, RouteMetaService }                                        from '@services/route-meta.service';
import { SiteConfigService }                                                  from '@services/site-config.service';
import { UtilsService }                                                       from '@services/utils.service';
import { Subscription }                                                       from 'rxjs';
import { finalize }                                                           from 'rxjs/operators';
import { AuthenticationEventType, AuthService }                               from '../../services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  public B2cUserType = B2cUserType;
  public SignInFormGroup: FormGroup;

  public redirectTo: string;
  public isB2C = false;

  public formData: any = {
    email: null,
    password1: null
  };
  public SignInForm: any;
  public STATUS_LOADING: boolean;
  public queryParams: Params;
  public params: Params;
  public activeSubscriptions: Subscription[] = [];

  //
  @ViewChild('emailField') private _inputElement: ElementRef;
  @ViewChild('passwordField') private _passwordElement: ElementRef;

  constructor(
    public navigationService: NavigationService,
    public siteConfigService: SiteConfigService,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public languageService: LanguageService,
    public userRoutesService: UserRoutesService,
    public utilsService: UtilsService,
    private routeMetaService: RouteMetaService,
  ) {
    //
  }

  get email() {
    return this.SignInFormGroup.get('email');
  }

  get password() {
    return this.SignInFormGroup.get('password');
  }

  public ngOnInit() {
    this.authService.isNewLogin = null;
    if (this.activatedRoute.snapshot.queryParams.loginRequired === 'true') {
      this.forceLogoutUser();
    } else {
      this.skipIfLoggedIn();
    }

    this._initSignupFormData();
    this._initSignUpForm();

    const paramsSubscription = this.activatedRoute.params.subscribe((params) => {
      this.params = params;
      if (params.redirectTo) {
        this.redirectTo = decodeURIComponent(params.redirectTo);
      }
      this._setRouteMeta();
    });

    const queryParamsSubscription = this.activatedRoute.queryParams.subscribe((params) => {
      this.queryParams = params;
      if (params.redirectTo) {
        this.redirectTo = decodeURIComponent(params.redirectTo);
      }
      this._setRouteMeta();
    });

    this.activeSubscriptions = [
      ...this.activeSubscriptions,
      ...[paramsSubscription, queryParamsSubscription]
    ];
  }

  public ngOnDestroy(): void {
    for (const subscription of this.activeSubscriptions) {
      subscription.unsubscribe();
    }
    this.activeSubscriptions = [];
  }

  public forceLogoutUser(): void {
    this.authService.currentUserDetails = null;
    this.authService.authTokenB2C = null;
    this.authService.authTokenB2B = null;
    this.authService.events.next({type: AuthenticationEventType.Logout});
  }

  public skipIfLoggedIn() {
    if (this.authService.isAuthenticatedB2C || this.authService.isAuthenticatedB2B) {
      const b2btoken = localStorage.getItem(AuthService.authTokenB2BKey);
      if (b2btoken) {
        this.authService.isB2B = true;
        this.authService.isB2C = false;
        this.authService.authTokenB2B = localStorage.getItem(AuthService.authTokenB2BKey);
        this.authService.authToken = this.authService.authTokenB2B;
        this.userRoutesService.handleB2BLogin();
      } else {
        this.authService.isB2B = false;
        this.authService.isB2C = true;
        this.userRoutesService.handleB2CLogin();
      }
    }
  }

  public _initSignupFormData() {
    if (this.activatedRoute.snapshot.queryParams.loginEmail) {
      this.formData.email = this.activatedRoute.snapshot.queryParams.loginEmail;
    }
  }

  public _initSignUpForm() {
    this._createForm();
    this._setValueChangesSubscription();
  }

  public routeWithRedirect(route: string) {
    const routeElements = [route];
    if (this.redirectTo) {
      routeElements.push(this.redirectTo);
    }
    return routeElements;
  }

  public ngAfterViewInit(): void {
    if (!this.formData.email) {
      this._inputElement.nativeElement.focus();
    } else {
      this._passwordElement.nativeElement.focus();
    }
  }

  public signIn(event: Event) {
    const isNecessaryToDisplayErrors = this.SignInFormGroup.pristine || this.SignInFormGroup.dirty;
    if (isNecessaryToDisplayErrors) {
      Object
        .keys(this.SignInFormGroup.controls)
        .forEach((formControlKey) => {
          if (typeof formControlKey !== 'undefined') {
            const hasAnyValuelessFields = this.SignInFormGroup.controls[formControlKey].value == null
              && this.SignInFormGroup.controls.hasOwnProperty(formControlKey);
            if (hasAnyValuelessFields) {
              this.SignInFormGroup.controls[formControlKey].markAsDirty();
            }
          }
        });
    }
    if (this.SignInFormGroup.valid) {
      this._doSignIn();
    }
  }

  public _doSignIn() {
    const signUpRequestBody = {
      username: this.SignInForm.email,
      password: this.SignInForm.password
    };
    this.STATUS_LOADING = true;
    this.authService.signIn(signUpRequestBody)
      .pipe(finalize(() => {
        this.STATUS_LOADING = false;
      }))
      .subscribe(
        (response) => {
          if ( response && response.type === 'b2c' ) {
            this.isB2C = true;
          } else {
            this.authService._setLocalStorageKeys();
            this._onSignInSuccess(response);
            this._resetForm();
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this._handleErrors(errorResponse);
          this.STATUS_LOADING = false;
        }
      );
  }

  goToSV() {
    window.location.href = this.authService.logoutFromADAndRedirectToSV;
  }

  public _resetForm() {
    this.SignInFormGroup.reset();
  }

  public _handleErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error) {
      this._handlePasswordError(errorResponse.error);
    }
  }

  public _onSignInSuccess(response) {
    const hasToGoToMyP5 = this.hasToGoToMyPentalogCVOnSuccess(response);
    const hasToGoToMVPFreelancerCV = this.hasToGoToFreelancerCVOnSuccess(response);

    const IsB2B = response.type === 'b2b';
    const hasAccessToRedirectUrl = this.userRoutesService.hasAccessToRedirectUrl();

    if (IsB2B && !hasAccessToRedirectUrl) {
      this.forceLogoutUser();
      this.userRoutesService.displayB2CAuthenticationRequired();
    }

    if (hasToGoToMyP5 || hasToGoToMVPFreelancerCV || IsB2B) {
      this.userRoutesService.handleB2BLogin();
    } else {
      this.userRoutesService.handleB2CLogin();
    }
  }

  public goToHomePage() {
    window.location.href = this.languageService.currentLang;
  }

  public _handlePasswordError(error) {
    let errors = {}
    if (error.password1) {
      errors = {
        invalid: {
          wrongPassword: error.password[0]
        }
      }
    } else {
      const spamError = this.languageService.getValue('USERS_TOO_MANY_FAILED_LOGINS_FE');
      errors[error.non_field_errors === spamError ? 'spam' : 'generic'] = error.non_field_errors || error.detail
    }
    this.SignInFormGroup.get('password').setErrors(errors);
  }

  public _createForm() {
    this.SignInFormGroup = new FormGroup({
      email: new FormControl(this.formData.email),
      password: new FormControl(this.formData.password)
    });
  }

  public _setValueChangesSubscription() {
    const subscription = this.SignInFormGroup.valueChanges.subscribe((formWithLastValues) => this.onFormValueChanged(formWithLastValues));
    this.activeSubscriptions.push(subscription);
  }

  public onFormValueChanged(formWithLastValues?: any) {
    if (!this.SignInFormGroup) {
      return;
    }
    this.SignInForm = formWithLastValues;
  }

  private _setRouteMeta() {
    const subscription = this.routeMetaService.getRouteMeta('auth/login')
      .subscribe((routeMeta: RouteMeta) => {
        this.routeMetaService.setRouteMeta(routeMeta);
      });
    this.activeSubscriptions.push(subscription);
  }

  private hasToGoToMyPentalogCVOnSuccess(response): boolean {
    const redirectTo = this.redirectTo;
    let hasToGoToMyP5 = redirectTo && redirectTo.indexOf('verify') > -1 && redirectTo.indexOf('cv') > -1;
    hasToGoToMyP5 = hasToGoToMyP5 && redirectTo.indexOf('freelancer_cv') === -1;
    return hasToGoToMyP5;
  }

  private hasToGoToFreelancerCVOnSuccess(response): boolean {
    const redirectTo = this.redirectTo;
    let hasToGoToMVP = redirectTo && redirectTo.indexOf('verify') > -1 && redirectTo.indexOf('freelancer_cv') > -1;
    hasToGoToMVP = hasToGoToMVP && response.type === 'b2b';
    return hasToGoToMVP;
  }

}
