import { Component, Input, OnInit }           from '@angular/core';
import { Router }                             from '@angular/router';
import { AuthService }                        from '@common/auth/services/auth.service';
import { LanguageService }                    from '@services/i18n.service';
import { B2BAppType, PlatformsAccessService } from '@services/platforms-access.service';

@Component({
  selector: 'app-b2b-sidebar-app-switcher',
  templateUrl: './b2b-sidebar-app-switcher.component.html',
  styleUrls: ['./b2b-sidebar-app-switcher.component.scss']
})
export class B2bSidebarAppSwitcherComponent implements OnInit {

  @Input() public showTooltips = false;
  @Input() set collapsed(value: boolean) {
    if (this._collapsed !== value) {
      this._collapsed = value;
      this._isDropdownMenuOpen = !this._collapsed;
    }
  }

  private _isDropdownMenuOpen = false;
  private _collapsed = false;

  constructor(
    public authService: AuthService,
    public languageService: LanguageService,
    public platformsAccessService: PlatformsAccessService,
    private router: Router,
  ) {}

  ngOnInit(): void {

  }

  // Event Handlers
  // ==============================

  handleDropdownClick() {
    this._isDropdownMenuOpen = !this._isDropdownMenuOpen;
  }

  async setAccessPlatform(platformType) {
    if (platformType === 'access_assessment') {
      await this.platformsAccessService.createAccessPlatform({access: platformType}).toPromise().then((data) => {
        this.router.navigate(
          [`/${this.languageService.currentLang}/app/${this.authService.currentUserDetails.company_slug}`]
        );
      })
    }

    if (platformType === 'access_freelancers') {
      await this.platformsAccessService.createAccessPlatform({access: platformType}).toPromise().then((data) => {
        this.router.navigate(
          [`/${this.languageService.currentLang}/business/freelancers/dashboard`]
        );
      })
    }

    if(platformType === 'access_outsourcing'){
      window.open('https://pentalog.com', '_blank');
    }
  }

  openExternalLink(target){
    this.handleDropdownClick();
    window.open(target, '_blank');
  }
  // Helpers
  // ==============================

  get isActiveB2BAssessmentTab() {
    return this.platformsAccessService.getB2BAppType() === B2BAppType[B2BAppType.assessment];
  }

  get isActiveB2BFreelancersTab() {
    return !this.isActiveB2BAssessmentTab;
  }

  get isCollapsed() {
    return this._collapsed;
  }

  get isDropdownMenuOpen() {
    return this._isDropdownMenuOpen;
  }
}
