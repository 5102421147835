<div class="color-overlay">
  <div class="main-wrapper">
    <div class="logo">
      <a
        [attr.title]="'GLOBAL_LABEL_BRANDING_PENTALOG' | translate"
        href="/{{languageService.currentLang}}">
        <img
          [attr.alt]="siteConfigService?.settings ? siteConfigService?.settings[utilsService.appType()]?.website.name : ''"
          class="logo"
          src="/assets/img/logos/pentalog.svg"
        />
      </a>
    </div>
    <div>
      <h1
        class="account-created-message">
        {{ 'SIGNUP_SUCCESS_LABEL' | translate }}  <br/>
        {{ 'SIGNUP_SUCCESS_ACTIVATE_ACCOUNT' | translate }}
      </h1>
    </div>
  </div>
  <div class="clearfix"></div>
</div>
