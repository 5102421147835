import { Injectable }             from '@angular/core';
import { ApiEndpoints }           from '@app/app.constants';
import { AuthService }            from '@common/auth/services/auth.service';
import { HttpErrorService }       from '@services/http-error.service';
import { HttpService }            from '@services/http.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map }        from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AutologinService {

  public constructor(
    private _http: HttpService,
    private _auth: AuthService,
    private _httpErrorService: HttpErrorService,
  ) {}

  public getToken(token: string = '') {

    return this._http.get(ApiEndpoints.thirdParty.autologin + token + '/', this._auth.authRequestOptions)
      .pipe(map((response: any) => {
        return response;
      }))
      .pipe(catchError((error: HttpErrorResponse) => {
        return throwError(this._httpErrorService.parseError(error));
      }));
  }

}
