import { AfterViewChecked, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef }                          from '@angular/material/dialog';
import { SlideshowGuideService }                                  from '@b2b/@common/modules/tutorial/slideshow-guide.service';
import { NgxSiemaOptions, NgxSiemaService }                       from 'ngx-siema';
import { finalize }                                               from 'rxjs/operators';

export interface SlideData {
  selector: string;
  currentSlide: number;
}

export interface SlideClassInterface {
  title: string;
  description: string;
  image: string;
  category: string;
  position: number;
}

@Component({
  selector: 'app-slideshow-guide-carousel',
  templateUrl: './slideshow-guide-carousel.component.html',
  styleUrls: ['./slideshow-guide-carousel.component.scss']
})
export class SlideshowGuideCarouselComponent implements OnInit, OnDestroy, AfterViewChecked {

  public slides: SlideClassInterface[] = [];

  public loadingTheSlides = true;

  public currentSlide = 0;

  public subscriber: any;

  public showControls = false;

  public options: NgxSiemaOptions = {
    selector: '.siema',
    duration: 200,
    easing: 'ease-out',
    perPage: 1,
    startIndex: 0,
    draggable: true,
    threshold: 20,
    loop: false,
    onInit: () => {
      // do something when this initializes
    },
    onChange: () => {
      this._persistCurrentSlide();
    },
  };

  constructor(
    public MatDialogINSTANCE: MatDialogRef<SlideshowGuideCarouselComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    public CarouselSiemaService: NgxSiemaService,
    public slideshowGuideService: SlideshowGuideService
  ) {
  }

  public ngOnInit(): void {
    this.getSlides();
  }

  public ngAfterViewChecked(): void {
    this.showControls = true;
  }

  public ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  public getSlides() {
    this.showControls = false;
    this.subscriber = this.slideshowGuideService.getSlides('b2b_assessment_carousel')
      .pipe(finalize(() => {
        this.loadingTheSlides = false;
        this.subscriber.unsubscribe();
      }))
      .subscribe((result) => {
        this.slides = result;
      });
  }

  public previousSlide() {
    this.CarouselSiemaService.prev(1)
      .subscribe((data: any) => {
        this._setCurrentSlide(data);
      });
  }

  public nextSlide() {
    this.CarouselSiemaService.next(1)
      .subscribe((data: any) => {
        this._setCurrentSlide(data);
      });
  }

  public closeCarousel() {
    this.MatDialogINSTANCE.close();
  }

  public goToSlide(slideNumber: number) {
    this.CarouselSiemaService.goTo(slideNumber)
      .subscribe((data: any) => this._setCurrentSlide(data));
  }

  private _setCurrentSlide(slideData: SlideData) {
    this.currentSlide = slideData.currentSlide;
  }

  private _persistCurrentSlide() {
    this.CarouselSiemaService.currentSlide('.siema').subscribe(
      (data) => {
        this._setCurrentSlide(data);
      }
    );
  }

}
