import { Component, OnInit }      from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppDomain }              from '@app/app.constants';
import { AuthService }            from '@common/auth/services/auth.service';
import { MaterialAlertService }   from '@services/material-alert.service';
import { NavigationService }      from '@services/navigation.service';

@Component({
  selector: 'app-login-token',
  templateUrl: './login-token.component.html',
  styleUrls: ['./login-token.component.scss']
})
export class LoginTokenComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private authService: AuthService,
    private _alertMessage: MaterialAlertService,
  ) {
  }

  public ngOnInit() {
    this._alertMessage.open({
      buttons: [],
      title: '',
      content: 'GLOBAL_WAITING_FOR_AUTOLOGIN',
      closeAfter: false
    }, {blur: false});
    this.activatedRoute.params.subscribe((params) => {
      if (!params.token) {
        if (window.location.href.indexOf(AppDomain) >= 0) {
          return this.navigationService.navigateByUrl('/404');
        }
      }
      this.checkToken(params.token);
    });
  }

  private checkToken(token) {
    this.authService.authTokenB2B = token;
    this.authService.isB2B = true;
    this.authService.getUserDetails().subscribe((result) => {
      if (window.location.href.indexOf(AppDomain) >= 0) {
        return this.navigationService.navigateByUrl(`/app/${result.company_slug}/profile`);
      }
    });
  }
}
