import { UserDetails } from '@common/auth/classes/user-details';
import { Cookie }      from 'ng2-cookies';

export const cookieNames = {
  jobFinishedData: 'jobApplyData',
};

export class LocalStorageHelper {
  public supported = true;

  constructor() {
    if (typeof Storage === undefined) {
      this.supported = false;
    }
  }

  public get(key: string) {
    if (this.supported) {
      try {
        return JSON.parse(localStorage.getItem(key));
      } catch (err) {
        return localStorage.getItem(key);
      }
    } else {
      return Cookie.get(key);
    }
  }

  public set(key, item) {
    if (this.supported) {
      item = JSON.stringify(item);
      return localStorage.setItem(key, item);
    } else {
      return Cookie.set(key, item, null, '/');
    }
  }

  public delete(key) {
    if (this.supported) {
      return localStorage.removeItem(key);
    } else {
      return Cookie.delete(key, '/');
    }
  }

  public getJobFinishedData(): JobLocalStorageData {
    let data = {} as JobLocalStorageData;
    try {
      data = JSON.parse(this.get(cookieNames.jobFinishedData));
    } catch (err) {
      // Do nothing
    }
    return data || {} as JobLocalStorageData;
  }

  public setJobFinishedData(data: JobLocalStorageData) {
    let dataToSave = '{}';
    if (typeof data !== 'string') {
      try {
        dataToSave = JSON.stringify(data);
      } catch (err) {
        // Do nothing
      }
    }
    this.set(cookieNames.jobFinishedData, dataToSave);
  }

  public deleteJobFinishedData() {
    this.delete(cookieNames.jobFinishedData);
  }
}

export interface JobLocalStorageData extends UserDetails {
  jobName?: string;
  href?: string;
}
