import { Injectable }      from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum LoaderState {
  Pending,
  Done
}

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public isLoading = new BehaviorSubject(true);
  public state: LoaderState;
  public pending: boolean;

  constructor() {
  }

  public setState(loaderState: LoaderState) {
    this.state = null;
    if (loaderState === LoaderState.Pending) {
      setTimeout(() => {
        if (this.state == null) {
          this.state = loaderState;
          this.pending = true;
        }
      }, 100);
    } else {
      this.state = loaderState;
      this.pending = false;
    }
  }

}
