import { Injectable } from '@angular/core';
import { Cookie } from 'ng2-cookies';
import { HttpParams } from '@angular/common/http';

export const MARKETO_UTM_PARAMS_PREFIX = 'utm_';
export const MARKETO_UTM_COOKIE_PREFIX = 'p5_marketo_';

export const MARKETO_COOKIES_MAX_AGE_MILLISECONDS = 30 * 60 * 1000; // 30min

@Injectable({
  providedIn: 'root'
})
export class MarketoTrackingCookiesService {

  constructor(

  ) { }

  public updateMarketoTrackingCookies(queryParams: HttpParams) {
    const utmQueryParams = {};

    for (const key of queryParams.keys()) {
      if (key.startsWith(MARKETO_UTM_PARAMS_PREFIX)) {
        utmQueryParams[key] = queryParams.get(key);
      }
    }

    if (!Object.keys(utmQueryParams).length) {
      return;
    }

    this.setMarketoCookies(utmQueryParams);
    this.setMarketoUpdateDateCookies();
  }

  public setMarketoUpdateDateCookies() {
    const cookieFirstVisit = this.prefixMarketoCookie('first_visit');
    const cookieLastVisit = this.prefixMarketoCookie('last_visit');
    const cookieValue = new Date().toISOString().split('T')[0].split('-').reverse().join('-');

    const domain = '.' + this.getDomain(window.location.hostname, 2); // available for all subdomains
    const expiresAt = new Date();
    expiresAt.setTime(expiresAt.getTime() + MARKETO_COOKIES_MAX_AGE_MILLISECONDS);

    if (!Cookie.check(cookieFirstVisit)) {
      Cookie.set(cookieFirstVisit, cookieValue, expiresAt, '/', domain, false);
    }

    Cookie.set(cookieLastVisit, cookieValue, expiresAt, '/', domain, false);
  }

  public setMarketoCookies(queryParams: object = {}) {
    Object.keys(queryParams).forEach((paramKey) => {
      const cookieValue = queryParams[paramKey];
      const cookieName = this.unprefixMarketoCookie(paramKey, MARKETO_UTM_PARAMS_PREFIX);
      this.setMarketoCookie(cookieName, cookieValue);
    });
  }

  public setMarketoCookie(cookieName: string, cookieValue: string) {
    switch (cookieName) {
      case 'source':
      case 'medium':
      case 'campaign':
      case 'content':
      case 'terms':
        const cookieKey = this.prefixMarketoCookie(cookieName, MARKETO_UTM_PARAMS_PREFIX);
        const [cookieFirst, cookieLast] = this.createMarketoFirstLastCookieKeysPair(cookieKey);

        const domain = '.' + this.getDomain(window.location.hostname, 2); // available for all subdomains
        const expiresAt = new Date();
        expiresAt.setTime(expiresAt.getTime() + MARKETO_COOKIES_MAX_AGE_MILLISECONDS);

        if (!Cookie.check(cookieFirst)) {
          Cookie.set(cookieFirst, cookieValue, expiresAt, '/', domain, false);
        }

        Cookie.set(cookieLast, cookieValue, expiresAt, '/', domain, false);
        break;
      default:
        break;
    }
  }

  private createMarketoFirstLastCookieKeysPair(cookieName) {
    cookieName = this.prefixMarketoCookie(cookieName, MARKETO_UTM_COOKIE_PREFIX);
    return [`${cookieName}_first`, `${cookieName}_last`];
  }

  private unprefixMarketoCookie(cookieName, prefix = MARKETO_UTM_COOKIE_PREFIX) {
    return cookieName.substring(prefix.length, cookieName.length);
  }

  private prefixMarketoCookie(cookieName, prefix = MARKETO_UTM_COOKIE_PREFIX) {
    return `${prefix}${cookieName}`;
  }

  // Helpers
  // ==============================

  private getDomain(domain, domainLevel) {
    if (domainLevel != null && domainLevel >= 2) {
      let splitedDomain;
      for (splitedDomain = domain.split('.'); splitedDomain.length > domainLevel && splitedDomain.length > 2; ) {
        splitedDomain.shift();
      }
      return splitedDomain.join('.');
    }

    return domain;
  }
}
