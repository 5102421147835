import { Injectable }                    from '@angular/core';
import { environment }                   from '@app/app.constants';
import { AuthEndpoints }                 from '@common/auth/auth.constants';
import { EndpointService }               from '@services/endpoint.service';
import { HttpError, HttpErrorService }   from '@services/http-error.service';
import { HttpService }                   from '@services/http.service';
import { LanguageService }               from '@services/i18n.service';
import { LoaderService, LoaderState }    from '@services/loader-service.service';
import { UtilsService }                  from '@services/utils.service';
import { Observable, throwError }        from 'rxjs';
import { catchError, finalize, flatMap } from 'rxjs/operators';
import {HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GithubService {

  constructor(
    protected _http: HttpService,
    protected _httpErrorService: HttpErrorService,
    protected _utils: UtilsService,
    protected _langService: LanguageService,
    protected _loaderService: LoaderService,
    protected _EndpointService: EndpointService,
    protected utilsService: UtilsService
  ) {
  }

  public setHeaders(code) {
    const headers = new HttpHeaders({
      Authorization: 'token ' + code,
    });
    return {withCredentials: true, headers};
  }

  public signIn(params): Observable<any> {
    return this.getLoginCode()
      .pipe(flatMap((code: string) => {
        if (!code) {
          return throwError(this._langService.getValue('SOCIAL_AUTHORIZATION_ERROR'));
        }

        const correctEndpoint = this._EndpointService.checkIfRouteIs('signup') ? AuthEndpoints.githubAuth : AuthEndpoints.githubLogin;
        const appType = this.utilsService.appType();
        const apiEndpointUserLogin = this._EndpointService.getEndpoint(correctEndpoint, appType);
        const body = {
          code,
          ...this.utilsService.socialSignupBodyParams,
          ...params
        };
        return this._http.post(apiEndpointUserLogin, body, this.setHeaders(code));
      }))
      .pipe(catchError((error) => {

        if (typeof error === 'string') {
          return throwError({detail: error});
        }

        const httpError: HttpError = this._httpErrorService.parseError(error);
        if (httpError.status === 400) {
          httpError.detail = httpError.json.non_field_errors;
        }

        return throwError(httpError);
      }))
      .pipe(finalize(() => this._loaderService.setState(LoaderState.Done)));
  }

  private getLoginCode(): Observable<string> {
    const url = this._utils.createUrl('https://github.com/login/oauth/authorize', {
      client_id: environment.github.client_id,
      scope: 'read:user,user:email',
    });

    const data = window.open(url, '', 'width=400,height=500');
    this._loaderService.setState(LoaderState.Pending);

    return new Observable((observer) => {
      const checkUrlInterval = setInterval(() => {
        if (data.closed) {
          observer.next('');
          observer.complete();
          clearInterval(checkUrlInterval);
        }
        try {
          if (data.location.href !== 'about:blank' && data.location.href !== url) {
            const code = this._utils.getUrlParameter(data.location.search, 'code');
            data.onload = () => data.close();
            observer.next(code);
            observer.complete();
            clearInterval(checkUrlInterval);
          }
        } catch (e) {
          // security error (CORS)
        }

      }, 100);

    });
  }
}
