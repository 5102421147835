import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { MediaQueries }                                           from '@app/app.constants';


@Directive({
  selector: '[appSectionFullPage]'
})
export class SectionFullPageDirective implements OnInit {

  private previousScrollY: number;
  private isInViewport: boolean;

  constructor(
    private element: ElementRef<any>,
    private renderer: Renderer2
  ) {
    this.previousScrollY = 0;
    this.isInViewport = false;
  }

  private get fullPageAllowed(): boolean {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const ratio = windowWidth / windowHeight;

    return !((ratio < 1) && (windowWidth >= MediaQueries.screenDesktop));
  }

  public ngOnInit() {
    this.setSectionHeight();
  }

  @HostListener('window:resize', ['$event'])
  public onWindowResize(event: any) {
    this.setSectionHeight();
  }

  private setSectionHeight() {
    if (this.fullPageAllowed) {
      this.renderer.setStyle(this.element.nativeElement, 'height', window.innerHeight + 'px');
      // setTimeout(() => this._triggerElementsReflow(), 100);
    }
  }

  private _triggerElementsReflow(): void {
    // Apply transform: scale on the element to trigger the CSS engine layout/reflow.
    this.renderer.setStyle(this.element.nativeElement, 'transform', 'scale(1)');
    setTimeout(() => this.renderer.removeStyle(this.element.nativeElement, 'transform'), 350);
  }


}
