import { Injectable }             from '@angular/core';
import { MatDialog }              from '@angular/material/dialog';
import { ApiEndpoints }           from '@app/app.constants';
import { ChildAccount }           from '@b2b/@common/modules/company-accounts/cpts/child-account';
import { AuthService }            from '@common/auth/services/auth.service';
import { HttpService }            from '@services/http.service';
import { UtilsService }           from '@services/utils.service';
import { Observable, throwError } from 'rxjs';
import { catchError }             from 'rxjs/operators';

@Injectable(
  // {
  //   providedIn: 'root'
  // }
)
export class CompanyAccountsService {

  constructor(
    private _commonService: UtilsService,
    private _authService: AuthService,
    private _materialDialog: MatDialog,
    private _http: HttpService,
    // private _httpErrorService: HttpErrorService
  ) {
  }

  public getCompanyAccounts(): Observable<any> {
    return this._commonService.resolveRequest(ApiEndpoints.companyAccounts,
      null);
  }

  public createCompanyAccount(newCompanyAccount: ChildAccount): Observable<object> {
    return this._http.post(
      ApiEndpoints.companyAccounts
      , newCompanyAccount
      , this._authService.authRequestOptions
    ).pipe(catchError((error: any) => {
      // if (error.detail) {
      return throwError(error);
      // }
      // return Observable.throw(this._httpErrorService.parseError(error));
    }));
  }

  public updateCompanyAccount(existingCompanyAccount: ChildAccount, existingCompanyAccountId: number): Observable<object> {
    return this._http.put(
      ApiEndpoints.companyAccounts + existingCompanyAccountId + '/'
      , existingCompanyAccount
      , this._authService.authRequestOptions
    ).pipe(catchError((error: any) => {
      // if (error.detail) {
      return throwError(error);
      // }
      // return Observable.throw(this._httpErrorService.parseError(error));
    }));
  }

  public requestCompanyAssessmentToken(password: string) {
    const payload = {
      password
    };
    return this._http.post(ApiEndpoints.companyAssessmentToken, payload)
      .pipe(catchError((error: any) => {
        return throwError(error);
      }))
      .toPromise();
  }
}

export interface CompanyAccountChild {
  email: string;
  first_name: string;
  id: number;
  is_enabled: boolean;
  job_title: string;
  last_name: string;
  test_result_notifications: string;
  is_company_admin: any;
}
