import { Directive, ElementRef, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Directive(
  {selector: '[appModal]'}
)

export class ModalDirective implements OnInit, OnDestroy {

  constructor(
    private element: ElementRef,
    private renderer: Renderer2
  ) {
  }

  public ngOnInit() {
    setTimeout(() => this.toggleScroll(), 100);

    this.disableDocumentScroll();
  }

  public ngOnDestroy() {
    this.enableDocumentScroll();
  }

  @HostListener('window:resize', ['$event'])
  public onWindowResize(event: any) {
    this.toggleScroll();
  }

  private enableDocumentScroll() {
    this.renderer.removeStyle(document.body, 'overflow');
    this.renderer.removeStyle(document.body, 'height');
  }

  private disableDocumentScroll() {
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
    this.renderer.setStyle(document.body, 'height', '100%');
  }

  private toggleScroll() {
    const nativeElement = this.element.nativeElement;

    const elementHeight = nativeElement.offsetHeight;
    const windowHeight = window.innerHeight;

    if ((windowHeight - 80) < elementHeight) {
      this.renderer.addClass(nativeElement, 'scroll');
    } else {
      this.renderer.removeClass(nativeElement, 'scroll');
    }
  }

}
