export const assessment = {
  extern: false,
  b2bMenuRoutes: {
    B2B_MENU_MY_PROFILE: 'app/{company}/profile',
    B2B_MENU_MY_COMPANY_ACCOUNTS: 'app/{company}/accounts',
    B2B_USER_LABEL_DASHBOARD: 'app/{company}/',
    B2B_USER_LABEL_MY_CAMPAIGNS: 'app/{company}/campaigns',
    B2B_USER_LABEL_MY_TESTS: 'app/{company}/tests',
    B2B_USER_LABEL_MY_CANDIDATES: 'app/{company}/candidates',
    B2B_USER_LABEL_MY_REPORTS: 'app/{company}/results',
    // B2B_MENU_SWITCH_TO_FREELANCERS: '/business/freelancers/dashboard',
  },
  denyAccessForRoutes: [],
  goTo: 'app/{company}'
};
