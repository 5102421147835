import { NgModule }             from '@angular/core';
import { RouterModule }         from '@angular/router';
import { BIZADMIN_ROUTES }      from '@app/biz-admin/biz-admin.routes';
import { LocalizeRouterModule } from 'localize-router';


@NgModule({
  imports: [
    RouterModule.forChild(BIZADMIN_ROUTES),
    LocalizeRouterModule.forChild(BIZADMIN_ROUTES)
  ],
  exports: [RouterModule, LocalizeRouterModule]
})
export class BizAdminRoutingModule {
}
