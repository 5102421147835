import { CommonModule }              from '@angular/common';
import { NgModule }                  from '@angular/core';
import { BrowserAnimationsModule }   from '@angular/platform-browser/animations';
import { NavigationEnd, Router }     from '@angular/router';
import { StripeModule }              from '@b2b/@common/modules/stripe/stripe.module';
import { AuthStorageWatcherService } from '@common/auth/services/auth-storage-watcher.service';
import { AuthService }               from '@common/auth/services/auth.service';
import { SharedModule }              from '@common/shared.module';
import { UserModule }                from '@common/user/user.module';
import { filter }                    from 'rxjs/operators';

import { B2bRoutingModule }                   from './b2b-routing.module';
import { B2BComponent }                       from './b2b.component';
import { PlatformsAutologinComponent }        from './route.autologin/platforms-autologin.component';
import { RouteForceLoginComponent }           from './route.forcelogin/route-force-login.component';
import { LoginTokenComponent }                from './route.login_token/login-token.component';
import { RouteRegistrationConfirmComponent }  from './route.registation-confirm/route-registration-confirm.component';
import { RouteResetPasswordConfirmComponent } from './route.reset-password-confirm/route-reset-password-confirm.component';

@NgModule({
  declarations: [
    B2BComponent,
    RouteResetPasswordConfirmComponent,
    LoginTokenComponent,
    PlatformsAutologinComponent,
    RouteRegistrationConfirmComponent,
    RouteForceLoginComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    StripeModule,
    B2bRoutingModule,
    UserModule,
  ],
  entryComponents: [
    PlatformsAutologinComponent,
  ],
  exports: [],
})
export class B2BModule {
  constructor(
    router: Router,
    authStorageWatcherService: AuthStorageWatcherService,
    authService: AuthService
  ) {
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      authStorageWatcherService.initLocalStorageAuthKeyEvents(AuthService.authTokenB2BKey);
      authService.isB2B = true;
      authService.isB2C = false;
      if (!authService.initialized && window.location.href.indexOf('register/confirm') === -1) {
        // console.log('Init User');
        authService._init();
      }
    });
  }
}
