import { Injectable, NgZone }                              from '@angular/core';
import { AuthService }                                     from '@common/auth/services/auth.service';
import { LocalStorageHelper }                              from '@common/helpers/local-storage-helper';
import { LanguageService }                                 from '@services/i18n.service';
import { UtilsService }                                    from '@services/utils.service';
import { fromEvent, Observable, Subscriber, Subscription } from 'rxjs';
import { filter, publish, refCount }                       from 'rxjs/operators';

const localStorageHelper = new LocalStorageHelper();

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class AuthStorageWatcherService {

  public isInitialized = false;

  public readonly _storageEvents$: Observable<StorageEvent> = new Observable((subscriber: Subscriber<StorageEvent>) => {
    const {next, error, complete} = subscriber;
    return this.ngZone.runOutsideAngular(() => {
      fromEvent(window, 'storage').pipe(
        filter((event: StorageEvent) => this.isAuthTokenStorageKey(event.key)),
        publish(),
        refCount(),
      ).subscribe(
        (value: Event) => next.call(subscriber, value),
        (msg) => error.call(subscriber, msg),
        () => complete.call(subscriber),
      );
    });
  });

  private _storageEventsSubscription: Subscription;

  constructor(
    protected authService: AuthService,
    protected languageService: LanguageService,
    protected utilsService: UtilsService,
    protected ngZone: NgZone,
  ) {
  }

  public initLocalStorageAuthKeyEvents(storageKey) {
    if (!localStorageHelper.supported) {
      throw new Error('Storage not supported by browser user agent');
    }

    if (!this.isAuthTokenStorageKey(storageKey)) {
      throw new Error('Incorrect auth token storage key');
    }

    this.isInitialized = true;

    switch (storageKey) {
      case AuthService.authTokenB2BKey:
        this._storageEventsSubscription = this._storageEvents$.pipe(
          filter((event: StorageEvent) => event.key === storageKey),
        ).subscribe(async (event: StorageEvent) => {
          if (this.authService.authTokenB2B == null && window.location.href.indexOf('.com') >= 0) {
            await this.authService.signOutB2B().toPromise();
            window.location.href = '/' + this.languageService.currentLang + '/auth/login';
          }
        });
        break;
      case AuthService.authTokenB2CKey:
        // TODO: Should subscribe to the changes of cookies (or listen to socket messages?) so we know user logged out.
        // TODO: We could also set sessionId in localStorage so that we know user is logged in/out and track only localStorage events.
        // if (this.authService.authTokenB2C == null) {
        //   await this.authService.signOutB2C().toPromise();
        //   localStorageHelper.deleteJobFinishedData();
        //   window.location.href = '/' + this.languageService.currentLang + '/auth/login';
        // }
        break;
      default:
        break;
    }
  }

  public deinit() {
    if (this._storageEventsSubscription) {
      this._storageEventsSubscription.unsubscribe();
    }
    this.isInitialized = false;
  }

  private isAuthTokenStorageKey(key) {
    const authTokenStorageKeys = [AuthService.authTokenB2BKey, AuthService.authTokenB2CKey];
    // tslint:disable-next-line:no-bitwise
    return !!~authTokenStorageKeys.indexOf(key);
  }
}
