<div [ngClass]="{'page-top-banner--hidden': !isTopBannerShown}" class="page-top-banner">
  <router-outlet
    (activate)="showTopBanner($event)"
    (deactivate)="hideTopBanner($event)"
    name="page-top-banner">
  </router-outlet>

  <span (click)="hideTopBanner($event)" class="page-top-banner__close">
    <mat-icon>close</mat-icon>
  </span>
</div>

<div [class.collapsed]="isSidebarCollapsed || isSidebarCollapsedMobile"
     [class.hidden-sidebar]="isTestResultPage || !showSidebar"
     class="page-sidebar-container">
  <router-outlet name="page-sidebar"></router-outlet>

  <div class="page-sidebar-toggle-wrap">
    <button
      (click)="collapseSidebar($event)"
      *ngIf="!isSidebarCollapsedMobile"
      class="btn btn-collapse"
      id="collapseSidebar"
      type="button">
    </button>
    <button
      (click)="expandSidebar($event)"
      *ngIf="!isSidebarCollapsedMobile"
      class="btn btn-expand"
      id="expandSidebar"
      type="button">
    </button>
  </div>
</div>

<div class="main-container-wrapper" [class.hide-sidebar] = "isTestResultPage || !showSidebar">
  <div class="page-header-container" [class.prioritize-view]="isMobileMenuOpen">
    <router-outlet name="page-header"></router-outlet>
  </div>

  <div class="router-outlet-container">
    <div [@fadeOutAnimation] *ngIf="navigationService.pending" class="router-outlet-loader"></div>
    <div *ngIf="showResolutionWarning" class="alert-box warning">
      <mat-icon>warning</mat-icon>
      {{ 'B2B_RESOLUTION_WARNING' | translate }}
    </div>
    <div class="stripe-payment-message" *ngIf="successPayment !== null && showStripeMessage">
      <div class="stripe-payment-success" *ngIf="successPayment === 'true'">
        <mat-icon>payment</mat-icon> {{ 'B2B_STRIPE_PAYMENT_SUCCESS' | translate }}
      </div>
      <div class="stripe-payment-failed" *ngIf="successPayment === 'false'">
        <mat-icon>payment</mat-icon> {{ 'B2B_STRIPE_PAYMENT_FAILED' | translate }}
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>

  <div class="page-footer-container">
    <router-outlet name="page-footer"></router-outlet>
  </div>
</div>
