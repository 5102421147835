import { Injectable }              from '@angular/core';
import { AppDomain }               from '@app/app.constants';
import { FormGroup }               from '@angular/forms';
import { LanguageService }         from '@services/i18n.service';
import { MaterialSnackBarService } from '@services/material-snack-bar.service';
import { HttpErrorResponse }       from '@angular/common/http';

export interface HttpError {
  status: number;
  detail: string;
  json: any;
  handled: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class HttpErrorService {

  constructor(
    private _languageService: LanguageService,
    private _matSnackBarService: MaterialSnackBarService
  ) {
  }

  public setErrors(helper, errors) {
    if (window.location.href.indexOf(AppDomain) >= 0) {
      Object.keys(errors.json).forEach((key) => {
        const message = errors.json[key][0];
        key = this._toCamelCase(key);
        if (helper.getField(key)) {
          helper.getField(key).setErrors({invalid: message});
        }
      });
    }
  }

  public setErrorsGroup(form: FormGroup, errors) {
    if (this.isServerError(errors)) {
      return false;
    }
    Object.keys(errors.json).forEach((key) => {
      const message = errors.json[key][0];
      const form_errors = [];
      if (form.get(key)) {
        form.get(key).setErrors({invalid: message});
      } else {
        if (typeof message === 'string') {
          form_errors.push(message);
        }
      }
      form.setErrors(form_errors);
    });
  }

  public parseError(errorResponse: HttpErrorResponse): HttpError {
    const error = errorResponse.error;
    try {
      const httpError: HttpError = {
        status: errorResponse.status,
        detail: null,
        json: error,
        handled: false
      };

      if (window.location.href.indexOf(AppDomain) >= 0) {
        if (error.detail) {
          httpError.detail = error.detail;
        } else {
          httpError.detail = this._languageService.getValue('GLOBAL_ERROR_TECHNICAL');
        }
      }

      return httpError;
    } catch (exception) {
      return {
        status: errorResponse.status,
        detail: errorResponse.statusText,
        json: null,
        handled: false
      };
    }
  }

  private _toCamelCase(text: string = '') {
    // Lower cases the string
    return text.toLowerCase()
      // Replaces any - or _ characters with a space
      .replace(/[-_]+/g, ' ')
      // Removes any non alphanumeric characters
      .replace(/[^\w\s]/g, '')
      // Uppercases the first character in each group immediately following a space
      // (delimited by spaces)
      .replace(/ (.)/g, ($1) => $1.toUpperCase())
      // Removes spaces
      .replace(/ /g, '');
  }

  private isServerError(error) {
    // We should not let errors from statuses of 500 be displayed on forms.
    // Status 0 can be when the Option Method Call fails.
    if (error.status >= 500 || !error.status) {
      this._matSnackBarService.openTop('Something went wrong! Please try again later.', {panelClass: ['error']});
    }
    return (error.status >= 500);
  }
}
