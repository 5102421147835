import { EventEmitter, Injectable }        from '@angular/core';
import { NavigationStart, Router, Routes } from '@angular/router';
import { ModalComponent }                  from '@common/cpts/modal.component';
import { Observable, Subject }             from 'rxjs';
import { filter }                          from 'rxjs/operators';


export interface ModalEvent {
  sender: ModalComponent;
  type: ModalEventType;
}

export enum ModalEventType {
  Open,
  Close
}

export enum ModalStatus {
  Success
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  public activeModal: ModalComponent;
  public events: Subject<ModalEvent>;

  public pendingModalId: string;
  public currentModal: EventEmitter<any> = new EventEmitter();

  constructor(
    protected _router: Router
  ) {
    this.events = new Subject();
  }

  public initModal(modal: ModalComponent): void {
    this.activeModal = modal;
    this.activeModal.show().subscribe(() => {
      this.events.next({
        sender: this.activeModal,
        type: ModalEventType.Open
      });
    });
  }

  public open(modalId: string): Observable<ModalEvent> {
    this.pendingModalId = modalId;
    if (this.activeModal) {
      this.activeModal.hide().subscribe(() => {
        this._openModalRoute(modalId);
      });
    } else {
      this._openModalRoute(modalId);
    }

    return this.events.pipe(filter((event: ModalEvent) => event.sender.modalId === modalId));
  }

  public close(modalId: string): void {
    this.pendingModalId = null;

    if (this.activeModal && this.activeModal.modalId !== modalId) {
      return;
    }

    this.currentModal.emit({
      type: 'close',
      id: modalId
    });

    this.activeModal.hide().subscribe(() => {
      this._router.navigate([
          {outlets: {modal: null}}],
        {skipLocationChange: true, preserveQueryParams: true}
      ).then(() => {
        this.events.next({
          sender: this.activeModal,
          type: ModalEventType.Close
        });

        this.activeModal = null;
      });
    });
  }

  public modal(modalId: string): ModalComponent {
    if (this.activeModal && this.activeModal.modalId !== modalId) {
      return;
    }

    return this.activeModal;
  }

  public registerModalRoutes(routes: Routes) {
    const subscriber = this._router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        this._router.resetConfig([
          ...routes,
          ...this._router.config
        ]);
      }

      subscriber.unsubscribe();
    });
  }

  protected _openModalRoute(modalId: string) {
    this.currentModal.emit({
      type: 'open',
      id: modalId
    });

    this._router.navigate(
      [
        {outlets: {modal: [modalId]}}
      ],
      {
        skipLocationChange: true,
        queryParamsHandling: 'merge'
      });
  }
}
