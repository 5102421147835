import { Injectable }                 from '@angular/core';
import { ApiEndpoints }               from '@app/app.constants';
import { UserDetails }                from '@common/auth/classes/user-details';
import { AuthService }                from '@common/auth/services/auth.service';
import { EndpointService }            from '@services/endpoint.service';
import { HttpService }                from '@services/http.service';
import { UtilsService }               from '@services/utils.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map }            from 'rxjs/operators';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class UserService {

  constructor(
    public authService: AuthService,
    public endpointService: EndpointService,
    public utilsService: UtilsService,
    public httpService: HttpService
  ) {
  }

  get currentUserDetails() {
    return this.authService.currentUserDetails;
  }

  set currentUserDetails(value) {
    this.authService.currentUserDetails = value;
  }

  public getUserDetails(params?: any, refresh: boolean = false, options: any = {}): Observable<any> {
    const hasParams = params && params.type;
    const typeByNavigation = hasParams ?
      params.type : this.utilsService.appType();
    const userDetailsEndpoint = this.endpointService.getEndpoint(ApiEndpoints.userDetails, typeByNavigation);

    if (this.authService.currentUserDetails && !refresh) {
      return of(this.authService.currentUserDetails);
    }
    if (!hasParams && !refresh) {
      return of({} as any);
    }
    return this.httpService.get(
      userDetailsEndpoint,
      this.authService.authRequestOptions
    )
      .pipe(map((response: any) => {
        const userDetails = response;
        const date_joined = userDetails ? new Date(userDetails.date_joined) : null;
        const existingDateJoined = this.utilsService.localStorage('date_joined').get();
        if (!existingDateJoined) {
          this.utilsService.localStorage('date_joined').add(date_joined);
        }
        if (params) {
          userDetails.type = params.type;
        }
        this.authService.setGoogleTagManagerDatalayer(userDetails);
        return userDetails as UserDetails;
      }))
      .pipe(catchError((error: any) => {
        if (!options.ignoreErrors) {
          if ((error.status === 401 || error.status === 403) && this.authService.authToken) {
            this.authService.authToken = null;
            this.authService.authTokenB2C = null;
            this.authService.authTokenB2B = null;
          }
          return throwError(error);
        } else {
          throwError(error);
        }
      }));
  }
}
