<div
  [attr.lang]="language.currentLang"
  [ngClass]="routeName"
  class="router-outlet-container-wrapper">
  <div class="router-outlet-container">
    <div
      [@fadeOutAnimation]
      *ngIf="appLoading"
      class="router-outlet-loader"></div>
    <router-outlet
      (activate)="onActivate()">
    </router-outlet>
  </div>
  <app-loader></app-loader>
</div>
