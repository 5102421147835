import { Component, OnInit } from '@angular/core';
import { AuthService }       from '@common/auth/services/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    public authService: AuthService
  ) {
  }

  get shouldShowCurrency() {
    return window.location.href.indexOf('business/freelancers') !== -1;
  }

  ngOnInit() {
  }
}
