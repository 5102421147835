import { Component, OnInit }      from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AppDomain }              from '@app/app.constants';
import { AuthService }            from '@common/auth/services/auth.service';
import { UserRoutesService }      from '@common/auth/services/user-routes.service';
import { LanguageService }        from '@services/i18n.service';
import { NavigationService }      from '@services/navigation.service';

@Component({
  selector: 'app-route-registration-confirm',
  templateUrl: './route-registration-confirm.component.html',
  styleUrls: ['./route-registration-confirm.component.scss']
})
export class RouteRegistrationConfirmComponent implements OnInit {
  public status = 'confirming';
  public message = '';
  public redirectTo = '';
  public queryParams: Params;

  constructor(
    public authService: AuthService,
    public navigationService: NavigationService,
    public languageService: LanguageService,
    private _activatedRoute: ActivatedRoute,
    private _userRoutesService: UserRoutesService
  ) {
  }

  public goToApplicationB2B() {
    let url = '';
    const lang = this.languageService.currentLang;

    if (this.queryParams.redirectTo) {
      return window.location.href = this.queryParams.redirectTo;
    }

    if (this.queryParams.redirect_to_project_id) {
      url = `${lang}/business/freelancers/projects/${this.queryParams.redirect_to_project_id}/details`;

      if (window.location.href.indexOf(AppDomain) >= 0) {
        window.location.href = url;
      }
      return;
    }

    return this._userRoutesService.redirectToApp(true);
  }

  public ngOnInit(): void {
    this.queryParams = this._activatedRoute.snapshot.queryParams;
    if (this.queryParams.redirectTo) {
      this.redirectTo = decodeURIComponent(this.queryParams.redirectTo);
    }
    this._activatedRoute.params.subscribe((params) => {

      this.authService.confirmRegistrationB2B(params.token)
        .subscribe(() => {
          this.status = 'success';

          // redirect directly if it's not a MyPentalog link, which requries the autologin middleware
          if (this.redirectTo && this.redirectTo.indexOf('freelancers.skillvalue') === -1) {
            let redirect = '';
            this.redirectTo.split('?').forEach((el, key) => {
              if (key > 1) {
                redirect += encodeURIComponent('?' + el);
              } else {
                redirect += ((key === 1) ? '?' : '') + el;
              }
            });
            if (window.location.href.indexOf(AppDomain) >= 0) {
              return window.location.href = redirect;
            }
            return;
          }

          // TODO: Could we below redirect in case of a user's inactivity? Waiting 3s is boring, we even have a button for user ...
          setTimeout(() => this.goToApplicationB2B(), 3000);
        }, (e) => {
          this.status = 'error';
          if (e.status === 400 && e.error.detail) {
            this.message = e.error.detail;
          }

          setTimeout(() => this.goToApplicationB2B(), 3000);
        });
    });
  }
}
