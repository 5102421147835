import { Injectable }                           from '@angular/core';
import { MatDialog }                            from '@angular/material/dialog';
import { LicenseUpgradePromptModalComponent }   from '@b2b/@common/modules/license/cpts/license-upgrade-prompt-modal.component';
import { StripeModalService }                   from '@b2b/@common/modules/stripe/services/stripe-modal.service';
import { AuthenticationEventType, AuthService } from '@common/auth/services/auth.service';
import { UtilsService }                         from '@services/utils.service';
import moment                                   from 'moment';
import { filter }                               from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  constructor(
    public _commonService: UtilsService,
    public _authService: AuthService,
    public _utilsService: UtilsService,
    public _matDialog: MatDialog,
    public _stripeModalService: StripeModalService
  ) {
  }

  initLicenseChecks() {
    this._authService.events
      .pipe(filter((event) => event.type === AuthenticationEventType.StateUpdated))
      .subscribe((event) => {
        if (this._hasOneWeekSubscription(event.user) || this._hasFewCreditsRemaining(event.user)) {
          this._openLicenseUpgradeModal(event.user);
        }
      });
  }

  showUpgradeButton() {
    const isAssessment = window.location.href.indexOf(this._authService.currentUserDetails.company_slug) > -1;
    return isAssessment && this._isEligibleForUpgradeAction();
  }

  _isEligibleForUpgradeAction(
    user = this._authService.currentUserDetails,
    today = moment()
  ) {
    const licenseInfo = user.license_info;
    const isPayingCustomer = licenseInfo.is_paying;
    // const isExpiringInLessThan30Days = moment().diff(licenseInfo.expire_date, 'days') >= -30;
    const todaysDate = today ? moment(today) : moment();
    const isExpiringInLessThan30Days = this._remainingDays(user, todaysDate) <= 30;

    const isNotPayingCustomer = !isPayingCustomer;
    const isOnAPayedSubscriptionThatExpiresIn30Days = this._userIsOnPayedSubscription(user) && isExpiringInLessThan30Days;

    const showButton =
      isNotPayingCustomer ||
      this._userIsOnPayedCredits(user) ||
      isOnAPayedSubscriptionThatExpiresIn30Days;

    return showButton;
  }

  _hasOneWeekSubscription(user = this._authService.currentUserDetails, today = moment()) {
    const todaysDate = today ? moment(today) : moment();
    const isExpiringInLessThan7Days = this._remainingDays(user, todaysDate) <= 7;

    return this._userIsOnPayedSubscription(user) && isExpiringInLessThan7Days;
  }

  _hasFewCreditsRemaining(user = this._authService.currentUserDetails) {
    const licenseInfo = user.license_info;
    const hasLessOrEqualThan10Credits = licenseInfo.credits_remaining <= 10;

    return this._userIsOnPayedCredits(user) && hasLessOrEqualThan10Credits;
  }

  _remainingDays(user = this._authService.currentUserDetails, today = moment()) {
    const licenseInfo = user.license_info;
    const eventdate = moment(licenseInfo.expire_date);
    const todaysDate = today ? moment(today) : moment();
    const timeDifference = eventdate.diff(todaysDate, 'days');

    return timeDifference;
  }

  _remainingCredits(user = this._authService.currentUserDetails) {
    return user.license_info.credits_remaining;
  }

  _userIsOnPayedSubscription(user = this._authService.currentUserDetails) {
    const licenseInfo = user.license_info;
    const isOnSubscription = licenseInfo.is_on_subscription;
    const isPayingCustomer = licenseInfo.is_paying;
    const notTrial = !licenseInfo.is_trial;
    const userIsOnPayedSubscription = (notTrial || isPayingCustomer) && isOnSubscription;

    return userIsOnPayedSubscription;
  }

  _userIsOnPayedCredits(user = this._authService.currentUserDetails) {
    const licenseInfo = user.license_info;
    const isOnCredits = licenseInfo.is_on_credit_pack;
    const isPayingCustomer = licenseInfo.is_paying;
    const notTrial = !licenseInfo.is_trial;

    return (notTrial || isPayingCustomer) && isOnCredits;
  }

  _openLicenseUpgradeModal(user) {
    const credits_remaining = this._remainingCredits(user);
    const days_remaining = this._remainingDays(user);
    const subscription = this._hasOneWeekSubscription(user);
    const credits = this._hasFewCreditsRemaining(user);
    this._matDialog.open(LicenseUpgradePromptModalComponent, {
      disableClose: false,
      panelClass: 'pretty-dialog',
      data: {
        credits_remaining,
        days_remaining,
        subscription,
        credits,
        user
      }
    }).afterClosed().toPromise().then((response) => {
      if (response) {
        this._stripeModalService.buy();
      }
    });
  }
}
