import {HttpErrorResponse}                        from '@angular/common/http';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup}                   from '@angular/forms';
import {MatDialog, MatDialogRef}                  from '@angular/material/dialog';
import {ActivatedRoute, Params, Router}           from '@angular/router';
import { AppDomain }                              from '@app/app.constants';
import {
  SignupTermsModalComponent,
  SignupTermsModalData
}                                                 from '@common/auth/cpts/signup-terms-modal/signup-terms-modal.component';
import {B2cUserType}                              from '@common/auth/enums/b2c-user-type.enum';
import {AuthService, B2CTermsTypesDictionary}     from '@common/auth/services/auth.service';
import {UserRoutesService}                        from '@common/auth/services/user-routes.service';
import {LanguageService}                          from '@services/i18n.service';
import {NavigationService}                        from '@services/navigation.service';
import {AccessTypesB2B}                           from '@services/platforms-access.service';
import {RouteMetaService} from '@services/route-meta.service';
import {SiteConfigService} from '@services/site-config.service';
import {UtilsService} from '@services/utils.service';
import {finalize} from 'rxjs/operators';
import {PasswordValidator} from '@common/auth/helpers/password.validator';
import {RouterUrlService} from '@services/router-url.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  @ViewChild('mainWrapper') public mainWrapper: ElementRef;

  public B2cUserType = B2cUserType;
  public appType: string;
  public isB2C: boolean;
  public isB2F: boolean;
  public isB2B: boolean;
  public redirectTo: string;

  public SignUpFormGroup: FormGroup;
  public SignUpForm: any = {};
  public sendingForm = false;
  public submittedWithNoErrors = false;

  public data: any = {};
  public applicationTypeOptions: any = {
    b2bTypes: [{
        title: 'B2B_REGISTER_TYPE_FREELANCER_LABEL',
        val: 'freelancers'
      }]
  };
  public selectedApplicationTypeOption = this.applicationTypeOptions.b2bTypes[0];

  public checkTermsAndConditions = false;
  public checkPrivacyPolicy = false;
  public noB2BSignup = false;
  public decodedRedirectTo: string;

  public hasChosenType: boolean;
  public isApplicationFromJobs: boolean;

  public response: any;
  public typeRequired: boolean;

  public formData: any = {
    email: '',
    first_name: '',
    last_name: '',
    password1: '',
    phone: '',
    type: 'freelancers',
    company_name: '',
    privacy_policy: false,
    terms: false,
    newsletter: false,
  };

  public genericErrorMessages: string[];

  public queryParams: Params;

  public isLoading = true;

  constructor(
    public siteConfigService: SiteConfigService,
    public utilsService: UtilsService,
    public authService: AuthService,
    public navigationService: NavigationService,
    public languageService: LanguageService,
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public userRoutesService: UserRoutesService,
    private routeMetaService: RouteMetaService,
    private router: Router,
    private routerUrlService: RouterUrlService
  ) {
    //
  }

  public get getB2bApiParam() {
    return (!this.isB2B)
      ? undefined
      : this.SignUpFormGroup.get('type').value && this.SignUpFormGroup.get('type').value === 'freelancers'
        ? AccessTypesB2B[AccessTypesB2B.access_freelancers]
        : AccessTypesB2B[AccessTypesB2B.access_assessment];
  }

  public get buildSocialAuthSignupApiParams() {
    const params = {} as any;
    if (!this.isB2B) {
      return params;
    }

    params.access = this.SignUpFormGroup.get('type').value && this.SignUpFormGroup.get('type').value.val === 'freelancers'
      ? AccessTypesB2B[AccessTypesB2B.access_freelancers]
      : AccessTypesB2B[AccessTypesB2B.access_assessment];

    return params;
  }

  get email() {
    return this.SignUpFormGroup.get('email');
  }

  get first_name() {
    return this.SignUpFormGroup.get('first_name');
  }

  get last_name() {
    return this.SignUpFormGroup.get('last_name');
  }

  get password1() {
    return this.SignUpFormGroup.get('password1');
  }

  get password2() {
    return this.SignUpFormGroup.get('password2');
  }

  get phone() {
    return this.SignUpFormGroup.get('phone');
  }

  get type() {
    return this.SignUpFormGroup.get('type');
  }

  get company_name() {
    return this.SignUpFormGroup.get('company_name');
  }

  get terms() {
    return this.SignUpFormGroup.get('terms');
  }

  get privacy_policy() {
    return this.SignUpFormGroup.get('privacy_policy');
  }

  get newsletter() {
    return this.SignUpFormGroup.get('newsletter');
  }

  public ngOnInit() {
    if (window.location.href.indexOf('redirectTo') > 0) {
      this.routerUrlService.setRedirectUrl();
    }

    this.navigateToB2b();
    this._setDefaults();
    this._initSignUpForm();
    this._saveQueryStrings();
    this.isLoading = false;
  }

  public navigateToB2b() {
    this.navigationService.navigateByUrl(`/${this.languageService.currentLang}/auth/business/signup`);
  }

  public _setFormMode() {
    const hasJobsQS = typeof this.decodedRedirectTo !== 'undefined' && this.decodedRedirectTo.indexOf('job/apply/') > -1;
    const hasCompetitionQS = typeof this.decodedRedirectTo !== 'undefined' && this.decodedRedirectTo.indexOf('competition') > -1;
    if (hasJobsQS || hasCompetitionQS) {
      this.noB2BSignup = !!this.decodedRedirectTo;
    }
  }

  public _saveQueryStrings() {
    this.activatedRoute.params
      .subscribe((params) => {
        if (params.redirectTo) {
          this.decodedRedirectTo = params.redirectTo;
          this.redirectTo = decodeURIComponent(params.redirectTo);
          this._setFormMode();
        }
        // this._setRouteMeta();
      });

    this.activatedRoute.queryParams
      .subscribe((queryParams) => {
        if (queryParams && queryParams.type === 'company') {
          if (!this.applicationTypeOptions.b2bTypes[0].val) {
            this.applicationTypeOptions.b2bTypes.splice(0, 1);
          }
          this.typeRequired = false;
          this.selectedApplicationTypeOption = this.applicationTypeOptions.b2bTypes.filter((item) => {
            return item.val === 'freelancers';
          })[0];
        }
        if (queryParams && queryParams.type === 'assessment') {
          if (!this.applicationTypeOptions.b2bTypes[0].val) {
            this.applicationTypeOptions.b2bTypes.splice(0, 1);
          }
          this.typeRequired = false;
          this.selectedApplicationTypeOption = this.applicationTypeOptions.b2bTypes.filter((item) => {
            return item.val === 'assessment';
          })[0];
        }

        this.queryParams = queryParams;
        this.isApplicationFromJobs = !!this.queryParams.action && !!this.queryParams.redirectTo;
        this.hasChosenType = !!this.queryParams.type;
        if (queryParams && queryParams.redirectTo) {
          this.decodedRedirectTo = queryParams.redirectTo;
          this.redirectTo = decodeURIComponent(queryParams.redirectTo);
          this._setFormMode();
        }
        // this._setRouteMeta();
        this.data.type = queryParams.type;
        this.data.access_type = queryParams.access;

        if (this.data.access_type != null) {
          const accessTypeOption = this.applicationTypeOptions.b2bTypes.filter((option) => option.val === this.data.access_type)[0];
          this.SignUpFormGroup.get('type').setValue(accessTypeOption);
          setTimeout(() => {
            this.onAccessTypeChange(accessTypeOption);
          });
        }

        this._setDefaults(queryParams.type);
        if (Object.keys(queryParams).length === 0 && window.location.href.indexOf('business') === -1) {
          if (window.location.href.indexOf(AppDomain) >= 0) {
            this.navigationService.navigateByUrl(
              `/${this.languageService.currentLang}/auth/signup`,
              {
                queryParams: {
                  type: B2cUserType[B2cUserType.coder]
                },
                queryParamsHandling: 'merge'
              }
            );
          }
        }

        if (Object.keys(queryParams).length > 0 &&
          !queryParams.hasOwnProperty('type') &&
          !queryParams.hasOwnProperty('action') &&
          window.location.href.indexOf('business') === -1) {

          if (window.location.href.indexOf(AppDomain) >= 0) {
            this.navigationService.navigateByUrl(
              `/${this.languageService.currentLang}/auth/signup`,
              {
                queryParams: {
                  type: B2cUserType[B2cUserType.coder]
                },
                queryParamsHandling: 'merge'
              }
            );
          }
        }

        // const prevUrl = this.utilsService.localStorage('PREVIOUS_URL').get();
        // const businessSignupURLFragment = 'auth/business/signup';
        // const coderAndFreelancerSignupURLFragment = 'auth/signup';
        // if (prevUrl.indexOf(businessSignupURLFragment) > -1 &&
        //   window.location.href.indexOf(coderAndFreelancerSignupURLFragment) > -1) {
        //   location.reload();
        //   return;
        // }
      });
  }

  public goToHomePage() {
    if (window.location.href.indexOf(AppDomain) >= 0) {
        window.location.href = this.languageService.currentLang;
    }
  }

  public routeWithRedirect(route: string) {
    const routeElements = [route];
    if (this.redirectTo) {
      routeElements.push(this.redirectTo);
    }
    return routeElements;
  }

  public routeWithRedirectB2C(route: string) {
    const routeElements = [route];
    if (this.redirectTo) {
      routeElements.push(this.redirectTo);
    }
    return route;
  }

  public _setDefaults(type?: string) {
    this.appType = this.utilsService.appType();
    this.isB2C = this.appType === 'b2c' && type === B2cUserType[B2cUserType.coder];
    this.isB2F = this.appType === 'b2c' && type === B2cUserType[B2cUserType.freelancer];
    this.isB2B = this.appType === 'b2b';
  }

  public _initSignUpForm() {
    this._createForm();
    this._setValueChangesSubscription();
  }

  public _createForm() {
    this.SignUpFormGroup = new FormGroup({
      email: new FormControl(this.formData.email),
      first_name: new FormControl(this.formData.first_name),
      last_name: new FormControl(this.formData.last_name),
      password1: new FormControl(this.formData.password1, [PasswordValidator.strong]),
      phone: new FormControl({
        value: this.formData.phone,
        disabled: !this.isB2B
      }),
      type: new FormControl({
        value: this.formData.type,
        disabled: !this.isB2B
      }),
      company_name: new FormControl({
        value: this.formData.company_name,
        disabled: !this.isB2B
      }),
      privacy_policy : new FormControl(this.formData.privacy_policy ),
      terms: new FormControl(this.formData.terms),
      newsletter: new FormControl(this.formData.newsletter)
    });
  }

  public _setValueChangesSubscription() {
    this.SignUpFormGroup.valueChanges.subscribe((formWithLastValues) => this.onFormValueChanged(formWithLastValues));
  }

  public onFormValueChanged(formWithLastValues?: any) {
    if (!this.SignUpFormGroup) {
      return;
    }
    this.SignUpForm = formWithLastValues;
  }

  public _handleErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error) {
      this._handleGenericError(errorResponse.error);
      this._handleDuplicateEmailError(errorResponse.error);
      this._handlePasswordError(errorResponse.error);
      this._handleFirstAndLastNameError(errorResponse.error);
    }
  }

  public _handleGenericError(error) {
    if (error.constructor === Array) {
      this.genericErrorMessages = error[0];
      setTimeout(() => {
        const errorWrapper: HTMLElement = document.querySelector('.generic-error') as HTMLElement;
        if (errorWrapper != null) {
          this.mainWrapper.nativeElement.scrollTo(0, errorWrapper.offsetTop / 2);
        }
      }, 50);
    }
  }

  public _clearGenericError() {
    this.genericErrorMessages = null;
  }

  public _handleDuplicateEmailError(error) {
    if (error.email || error.username) {
      this.SignUpFormGroup.get('email').setErrors(
        {
          duplicate: error.email || error.username
        });
      setTimeout(() => {
        if (window.location.href.indexOf(AppDomain) >= 0) {
          this.navigationService.navigateByUrl(
            this.languageService.currentLang + '/auth/login',
            {
              queryParams: {
                redirectTo: this.queryParams && this.queryParams.redirectTo
              }
            }
          );
        }
      }, 5000);
    }
  }

  public _handleFirstAndLastNameError(error) {
    if (error.first_name) {
      this.SignUpFormGroup.get('first_name').setErrors({
        invalid: error.first_name
      });
    }
    if (error.last_name) {
      this.SignUpFormGroup.get('last_name').setErrors({
        invalid: error.last_name
      });
    }
  }

  public _handlePasswordError(error) {
    if (error.password1) {
      this.SignUpFormGroup.get('password1').setErrors({
        invalid: {
          isCommonText: error.password1[0],
          isNumericText: error.password1[0] ? error.password1[1] : null
        }
      });
    }
  }

  public markTermsWithError() {
    Object
      .keys(this.SignUpFormGroup.controls)
      .forEach((formControlKey) => {
        if (typeof formControlKey !== 'undefined' && formControlKey === 'terms') {
          this.SignUpFormGroup.controls[formControlKey].markAsDirty();
        }
      });
    this.SignUpFormGroup.controls.terms.markAsDirty();
    this.SignUpFormGroup.controls.terms.setErrors({required: true});
    this.SignUpFormGroup.controls.terms.markAsTouched();
    this.checkTermsAndConditions = true;
  }

  public markPrivacyWithError() {
    Object
      .keys(this.SignUpFormGroup.controls)
      .forEach((formControlKey) => {
        if (typeof formControlKey !== 'undefined' && formControlKey === 'privacy_policy') {
          this.SignUpFormGroup.controls[formControlKey].markAsDirty();
        }
      });
    this.SignUpFormGroup.controls.privacy_policy.markAsDirty();
    this.SignUpFormGroup.controls.privacy_policy.setErrors({required: true});
    this.SignUpFormGroup.controls.privacy_policy.markAsTouched();
    this.checkPrivacyPolicy = true;
  }

  public markB2bTypeWithError() {
    Object
      .keys(this.SignUpFormGroup.controls)
      .forEach((formControlKey) => {
        if (typeof formControlKey !== 'undefined' && formControlKey === 'type') {
          this.SignUpFormGroup.controls[formControlKey].markAsDirty();
        }
      });
    this.SignUpFormGroup.controls.type.markAsDirty();
    this.SignUpFormGroup.controls.type.setErrors({required: true});
    this.SignUpFormGroup.controls.type.markAsTouched();
    // this.typeRequired = this.SignUpForm.type ? !this.SignUpForm.type.val : true;
    this.typeRequired = this.SignUpForm.type ? !this.SignUpForm.type : true;
  }

  public createAccount() {
    const isNecessaryToDisplayErrors = this.SignUpFormGroup.pristine || this.SignUpFormGroup.touched;
    if (isNecessaryToDisplayErrors) {
      this._markValuelessInputs();
    }
    if (!this.SignUpFormGroup.controls.terms.valid) {
      this.checkTermsAndConditions = true;
      return;
    }
    if (!this.SignUpFormGroup.controls.privacy_policy.valid) {
      this.checkPrivacyPolicy = true;
      return;
    }
    if (this.SignUpFormGroup.valid && !this.typeRequired) {
      this._clearGenericError();
      this._doSignUp();
    }
  }

  public _markValuelessInputs() {
    Object
      .keys(this.SignUpFormGroup.controls)
      .forEach((formControlKey) => {
        const definedFormControl = typeof formControlKey !== 'undefined';
        if (definedFormControl) {
          const formControlKeyExists = this.SignUpFormGroup.controls.hasOwnProperty(formControlKey);
          const formControlValueExists =
            !this.SignUpFormGroup.controls[formControlKey].value || this.SignUpFormGroup.controls[formControlKey].value.length === 0;
          const isAnyValuelessField = formControlKeyExists && formControlValueExists;
          if (isAnyValuelessField) {
            this.SignUpFormGroup.controls[formControlKey].markAsDirty();
            this.SignUpFormGroup.controls[formControlKey].markAsTouched();
          }
        }
      });
    // this.typeRequired = this.SignUpForm.type ? !this.SignUpForm.type.val : void 0;
    this.typeRequired = this.SignUpForm.type ? !this.SignUpForm.type : void 0;
  }

  public onSocialSignInAttempt(resolveAttemptPromise: (result: boolean) => any) {
    const isTermsAccepted = this.terms.value;
    const selectedB2BApplicationType = this.selectedApplicationTypeOption;
    const isB2BServiceTypeSelectionRequired = this.isB2B ? selectedB2BApplicationType.val == null : false;
    if (isTermsAccepted && !isB2BServiceTypeSelectionRequired) {
      return resolveAttemptPromise(true);
    }

    const modalData: SignupTermsModalData = {
      formData: {
        terms: this.terms.value,
        newsletter: this.newsletter.value,
        type: selectedB2BApplicationType,
      },
      type: this.queryParams.type
    };

    const modalRef: MatDialogRef<SignupTermsModalComponent> = this.dialog.open(SignupTermsModalComponent, {
      width: '600px',
      disableClose: false,
      panelClass: 'pretty-dialog',
      data: modalData
    });

    const modalCloseSubscription = modalRef.afterClosed().subscribe((formData) => {
      resolveAttemptPromise(formData != null);
      modalCloseSubscription.unsubscribe();
    });

    const modalBeforeCloseSubscription = modalRef.beforeClosed().subscribe(() => {
      const formData = modalRef.componentInstance.termsForm.value;

      this.newsletter.patchValue(formData.newsletter);

      if (formData.terms) {
        this.terms.patchValue(formData.terms);
      } else {
        this.markTermsWithError();
      }

      formData.type.val = 'freelancers';
      if (this.isB2B && formData.type.val) {
        this.selectedApplicationTypeOption = this.applicationTypeOptions.b2bTypes.filter((item) => item.val === formData.type.val)[0];
        this.type.patchValue(this.selectedApplicationTypeOption);
      } else if (this.isB2B) {
        this.markB2bTypeWithError();
      }

      modalBeforeCloseSubscription.unsubscribe();
    });
  }

  public onAccessTypeChange(event) {
    const switchedToAssessment = event && event.val === 'assessment';
    const switchedToFreelancers = event && event.val === 'freelancers';
    if (switchedToFreelancers || switchedToAssessment) {
      this.typeRequired = false;
      if (!this.applicationTypeOptions.b2bTypes[0].val) {
        this.applicationTypeOptions.b2bTypes.splice(0, 1);
      }

      if (window.location.href.indexOf(AppDomain) >= 0) {
        if (switchedToFreelancers) {
          this.router.navigate([], {
            queryParams: {
              type: 'company',
            },
            queryParamsHandling: 'merge'
          });
        }
        if (switchedToAssessment) {
          this.router.navigate([], {
            queryParams: {
              type: 'assessment',
            },
            queryParamsHandling: 'merge'
          });
        }
      }
    }
  }

  public _setAccountCreatedFlags() {
    this.submittedWithNoErrors = true;
  }

  public _goToConfirmPage() {
    let businessString;
    if (this.utilsService.appType() === 'b2b') {
      businessString = 'business/';
    } else {
      businessString = '';
    }
    const qs = {
      queryParams: {
        action: this.queryParams.action,
        type: this.queryParams.type
      }
    };

    if (window.location.href.indexOf(AppDomain) >= 0) {
      this.navigationService.navigateByUrl('/auth/' + businessString + 'signup-confirm', qs);
    }
  }

  public _doSignUp() {
    const redirect = this.isB2B ?
      this.userRoutesService.getRedirectForSignup(this.appType, this.SignUpForm.company_name, this.SignUpForm.type) :
      this.userRoutesService.getRedirectForSignup(this.appType);
    let signUpRequestBody: any = {
      username: this.SignUpForm.email
      , first_name: (this.isB2B) ? this.SignUpForm.first_name : undefined
      , last_name: (this.isB2B) ? this.SignUpForm.last_name : undefined
      , password1: this.SignUpForm.password1
      , password2: this.SignUpForm.password1
      , phone: this.SignUpForm.phone
      , company_name: this.SignUpForm.company_name
      , source: (this.isB2F) ? B2cUserType[B2cUserType.freelancer] : undefined
      , newsletter: this.SignUpForm.newsletter ? this.SignUpForm.newsletter : false
      , ...((this.isB2C || this.isB2F || this.isB2B) || typeof this.redirectTo !== 'undefined') && {
        redirect_to: this.redirectTo ? encodeURIComponent(this.redirectTo) : encodeURIComponent(redirect)
      }
      , access: this.getB2bApiParam
      , privacy_policy: this.SignUpForm.privacy_policy
    };
    this.applyTerms(signUpRequestBody);
    signUpRequestBody = this.authService.setIfFreelancerRegistrationParams(signUpRequestBody);
    this.sendingForm = true;
    this.authService.signUp(signUpRequestBody)
      .pipe(finalize(
        () => {
          this.sendingForm = false;
        }
      ))
      .subscribe(
        (response) => {
          this.authService._setLocalStorageKeys();
          this.SignUpFormGroup.reset();
          const responseObj = response;
          if (responseObj.hasOwnProperty('key') && responseObj.key.length > 0) {
            this.authService.isB2B = true;
            this.authService.isB2C = false;
            this.authService.authTokenB2B = response.key;
            this.authService.authToken = this.authService.authTokenB2B;
            this.userRoutesService.handleB2BLogin();
          } else {
            this.authService.clearSession();
          }
          this._setAccountCreatedFlags();
          this._goToConfirmPage();
        },
        (errorResponse: HttpErrorResponse) => {
          this._handleErrors(errorResponse);
        }
      );
  }

  public onSocialSignUpSuccess() {
    if (window.location.href.indexOf(AppDomain) >= 0) {
      let redirectTo = this.redirectTo || '/';
      if (this.isB2C || this.isB2F) {
        redirectTo = this.userRoutesService.getRedirectForSignup();
      } else if (this.isB2B && window.location.href.indexOf('signup') > -1) {
        this.userRoutesService.handleB2BLogin();
        return;
      }
      window.location.href = redirectTo;
    }
  }

  private applyTerms(values) {
    const termsKey = (this.isB2C) ? B2CTermsTypesDictionary.coder : B2CTermsTypesDictionary.freelancer;
    if (!this.isB2B) {
      values[termsKey] = true;
    }
    values.terms = true; // To be removed;
  }

}
