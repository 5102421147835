import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { PageHeaderService }                                      from '@services/page-header.service';
import { Subscription }                                           from 'rxjs';

@Component({
  selector: 'app-block-buttons',
  templateUrl: './block-buttons.component.html',
  styleUrls: ['./block-buttons.component.scss']
})
export class BlockButtonsComponent implements AfterViewInit, OnDestroy {

  public buttonChangeSubscription: Subscription;

  constructor(
    public cdr: ChangeDetectorRef,
    public pageHeaderService: PageHeaderService,
  ) {
  }

  public ngAfterViewInit(): void {
    this.subscribePageHeaderChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribePageHeaderChanges();
  }

  // Page Header
  // ==============================

  private subscribePageHeaderChanges() {
    this.pageHeaderService.button$.toPromise().then(() => {
    });
    this.buttonChangeSubscription = this.pageHeaderService.button$.subscribe((value) => {
      this.cdr.detectChanges();
    });
  }

  private unsubscribePageHeaderChanges() {
    if (this.buttonChangeSubscription) {
      this.buttonChangeSubscription.unsubscribe();
      this.buttonChangeSubscription = null;
    }
  }
}
