<div
  [class.is-loading-overlay]="showLoader"
  class="color-overlay">
  <div class="main-wrapper">
    <div class="logo">
      <a
        (click)="goToHomePage()"
        [attr.title]="'GLOBAL_LABEL_BRANDING_PENTALOG' | translate">
        <img
          [attr.alt]="siteConfigService?.settings ? siteConfigService?.settings[utilsService.appType()]?.website.name : ''"
          class="logo"
          src="/assets/img/logos/pentalog.svg"
        />
      </a>
    </div>
    <div
      [class.is-loading]="STATUS_LOADING"
      class="login-content">
      <h4
        class="thin">{{ 'LOGIN_WITH_PENTALOG' | translate }}
      </h4>
      <div *ngIf="isCallbackUri">
        <span *ngIf="isB2c">{{ 'LOGIN_ERROR_B2C_PENTALOG' | translate }}
          <a (click)="goToSV()">skillvalue.com</a>
        </span>
        <span *ngIf="!isB2c">{{ 'WAIT_FOR_VALIDATION_PENTALOG' | translate }}</span>
      </div>
      <form
        *ngIf="!isCallbackUri"
        (ngSubmit)="signIn($event)"
        [formGroup]="SignInFormGroup"
        class="sign-in-form"
        novalidate>
        <div class="form-group">
          <div>
            <span class="input-with-icon">
              <mat-icon>email</mat-icon>
              <!--              <i class="fa fa-envelope"></i>-->
              <input
                #emailField
                [attr.placeholder]="'' + 'GLOBAL_LABEL_EMAIL' | translate"
                appValidatorEmail
                class="form-control"
                formControlName="email"
                name="email"
                required
                tabindex="1"
                type="email"
                data-id="login-email"
              >
            </span>
          </div>
          <div
            *ngIf="email.errors && email.dirty"
            class="alert alert-danger">
            <span
              *ngIf="email.errors.required"
              class="form-control-error">
              {{ 'GLOBAL_ERROR_EMAIL_REQUIRED' | translate }}
            </span>
            <span
              *ngIf="errorMessage"
              class="form-control-error">
              {{ errorMessage }}
            </span>
            <span
              *ngIf="email.errors.validEmail"
              class="form-control-error">
              {{ 'GLOBAL_ERROR_EMAIL_INVALID' | translate }}
            </span>
          </div>
        </div>
        <div class="form-actions">
          <button
            class="btn btn-submit"
            type="submit"
          >
            {{ 'GLOBAL_BUTTON_SIGNIN' | translate }}
          </button>
        </div>
      </form>
    </div>
    <p class="signup-text" *ngIf="!isCallbackUri">
      {{ 'LOGIN_DONT_HAVE_AN_ACCOUNT' | translate }}
      <!--      <a [routerLink]="routeWithRedirect('/' + languageService.currentLang + '/auth/signup')"-->
      <a [queryParams]="{ type: B2cUserType[B2cUserType.coder] }"
         [routerLink]="'/' + languageService.currentLang + '/auth/signup'"
         data-id="signup-link"
         queryParamsHandling="merge">
        {{ 'GLOBAL_BUTTON_SIGNUP' | translate }}
      </a>
    </p>
  </div>
  <div class="clearfix"></div>
</div>
