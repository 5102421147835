import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams }        from '@angular/common/http';
import { Injectable }                                                    from '@angular/core';
import { MatDialog }                                                     from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { ApiEndpoints, AppDomain }                                 from '@app/app.constants';
import { AuthEndpoints }                                           from '@common/auth/auth.constants';
import { B2bUserDetails }                                                from '@common/auth/classes/b2b-user-details';
import { UserDetails }                                                   from '@common/auth/classes/user-details';
import { STEPNAMES }                                                     from '@common/auth/constants/stepnames';
import { B2cUserType }                                                   from '@common/auth/enums/b2c-user-type.enum';
import { EndpointService }                                               from '@services/endpoint.service';
import { FacebookService }                                               from '@services/facebook.service';
import { GithubService }                                                 from '@services/github.service';
import { GoogleService }                                                 from '@services/google.service';
import { HttpErrorService }                                              from '@services/http-error.service';
import { LanguageService }                                               from '@services/i18n.service';
import { LinkedinService }                                               from '@services/linkedin.service';
import { LoaderService, LoaderState }                                    from '@services/loader-service.service';
import { MaterialSnackBarService }                                       from '@services/material-snack-bar.service';
import { ModalService }                                                  from '@services/modal.service';
import { NavigationService }                                             from '@services/navigation.service';
import { EndpointType, UtilsService }                                    from '@services/utils.service';
import { Cookie }                                                        from 'ng2-cookies';
import { from, Observable, of, Subject, throwError }                     from 'rxjs';
import { fromPromise }                                                   from 'rxjs/internal-compatibility';
import { catchError, delay, filter, finalize, first, flatMap, map, tap } from 'rxjs/operators';

export interface AuthenticationEvent {
  type: AuthenticationEventType;
  user?: B2bUserDetails & B2CUserDetails
}

export enum AuthenticationEventType {
  Login,
  Logout,
  StateUpdated
}

export const B2CTermsTypesDictionary = {
  coder: 'terms_coder',
  freelancer: 'terms_freelancer'
};

export const B2CTermsTypesTranslationsKeysDictionary = {
  coder: 'SIGNUP_FORM_LABEL_TERMS_AND_COND',
  freelancer: 'SIGNUP_FORM_LABEL_TERMS_AND_COND_FREELANCE'
};

export const LogoutFromADUrl = 'https://myaccount-test.pentalog.com/p5businesstoconsumer.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1A_SV_20210312_SignIn&post_logout_redirect_uri='
export const LogoutFromADUrlProd = 'https://myaccount.pentalog.com/mypentalog.onmicrosoft.com/oauth2/v2.0/logout?p=B2C_1A_SV_20210312_SignIn&post_logout_redirect_uri=';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public static authTokenB2CKey = 'sessionid';
  public static cookieB2CKey = 'ad_access_token';
  public static authTokenB2BKey = 'auth_token';
  public static refreshTokenB2BKey = 'refresh_token_b2b';
  public static refreshTokenB2CKey = 'refresh_token_b2c';
  public static isNewLoginKey = 'is_new_login';
  public readonly authTokenB2CKeyFor3rdPartyApps = 'auth_token_b2c';
  public readonly authTokenB2BKeyFor3rdPartyApps = 'auth_token_b2b';
  public isProd = window.location.hostname === 'pentalog-freelancers.com';
  public logoutFromADAndRedirectToSV = `https://www.skillvalue.com/${this.languageService.currentLang}/auth/login`;

  public events: Subject<AuthenticationEvent> = new Subject();
  public authToken: string;
  public currentUserDetails: any;
  public isB2B: boolean = this.utilsService.appType() === EndpointType[EndpointType.b2b];
  public isB2C: boolean = this.utilsService.appType() === EndpointType[EndpointType.b2c];
  public pathToRedirect: string;
  public initialized = false;
  public logoutURL: string;

  private genericErrorPipe = catchError((response: HttpErrorResponse) => {
    if (response.error.detail) {
      return throwError(response);
    }
    return throwError(response);
  });
  private confirmRegistrationPipeline = {
    handleAuthResponse: map((response: any) => {
      this._handleAuthResponse(response);
    }),
    getUserDetails: flatMap(() => {
      return this.getUserDetails();
    }),
    saveUserDetails: tap((response: B2CUserDetails & B2bUserDetails) => {
      this.currentUserDetails = response;
      this.events.next({type: AuthenticationEventType.Login, user: response});
    }),
    onError: this.genericErrorPipe
  };

  constructor(
    public httpService: HttpClient,
    public languageService: LanguageService,
    public navigationService: NavigationService,
    public endpointService: EndpointService,
    public utilsService: UtilsService,
    public modalService: ModalService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public matDialog: MatDialog,
    public matSnackBarService: MaterialSnackBarService,
    protected facebookService: FacebookService,
    protected googleService: GoogleService,
    protected linkedinService: LinkedinService,
    protected githubService: GithubService,
    protected httpErrorService: HttpErrorService,
    protected loaderService: LoaderService
  ) {
  }

  public get domain(): string {
    return '.' + window.location.hostname; // available for all subdomains
  }

  public get refreshToken(): string {
    return localStorage.getItem(this.isB2B ? AuthService.refreshTokenB2BKey : AuthService.refreshTokenB2CKey);
  }

  public set refreshToken(value: string) {
    if (value) {
      localStorage.setItem(this.isB2B ? AuthService.refreshTokenB2BKey : AuthService.refreshTokenB2CKey, value);
    } else {
      localStorage.removeItem(AuthService.refreshTokenB2BKey);
      localStorage.removeItem(AuthService.refreshTokenB2CKey);
    }
  }

  public get isNewLogin(): string {
    return localStorage.getItem(AuthService.isNewLoginKey);
  }

  public set isNewLogin(value: string) {
    if (value) {
      localStorage.setItem(AuthService.isNewLoginKey, value);
      // Cookie added for WP to distinguish between new login with AD and old implementation
      Cookie.set(AuthService.isNewLoginKey, value, this.cookieExpirationDate, '/', this.domain);
    } else {
      localStorage.removeItem(AuthService.isNewLoginKey);
      Cookie.delete(AuthService.isNewLoginKey, '/', this.domain);
    }
  }

  public get authRequestOptions(): any {
    if (this.isB2C) {
        return {
          withCredentials: true,
          // setHeaders: {
          //   'Set-Cookie': 'ad_access_token=' + Cookie.get('ad_access_token'),
          // }
        }
    }
    if (this.isB2B) {
      const headers = new HttpHeaders();
      if (this.authTokenB2B) {
        if (this.isNewLogin) {
          headers.set('Authorization', 'Bearer ' + this.authTokenB2B);
        } else {
          headers.set('Authorization', 'Token ' + this.authTokenB2B);
        }
      }
      return headers;
    }
  }

  public get cookieExpirationDate() {
    const now = new Date();
    return new Date(now.getFullYear() + 5, now.getMonth(), now.getDate());
  }

  public get authTokenB2B(): string {
    return localStorage.getItem(AuthService.authTokenB2BKey);
  }

  public set authTokenB2B(value: string) {
    Cookie.delete('force_login', '/');
    if (value) {
      this.authToken = value;
      localStorage.setItem(AuthService.authTokenB2BKey, value);
      Cookie.set(this.authTokenB2BKeyFor3rdPartyApps, value, this.cookieExpirationDate, '/', this.domain);
    } else {
      localStorage.removeItem(AuthService.authTokenB2BKey);
      Cookie.delete(this.authTokenB2BKeyFor3rdPartyApps, '/', this.domain);
    }
  }

  public get isForcedLogin() {
    return Cookie.get('force_login') === 'true' ? 'true' : 'false';
  }

  public set authTokenB2C(value: string) {
    if (value) {
      this.authToken = value;
      if (this.isNewLogin) {
        Cookie.set(AuthService.cookieB2CKey, value, this.cookieExpirationDate, '/', this.domain);
      }
      const cookie = Cookie.get(this.isNewLogin ? AuthService.cookieB2CKey : AuthService.authTokenB2CKey);
      Cookie.set(this.authTokenB2CKeyFor3rdPartyApps, cookie || value, this.cookieExpirationDate, '/', this.domain);
    } else {
      localStorage.removeItem(this.isNewLogin ? AuthService.cookieB2CKey : AuthService.authTokenB2CKey);
      Cookie.delete(AuthService.cookieB2CKey, '/', this.domain);
      Cookie.delete(AuthService.authTokenB2CKey, '/');
      Cookie.delete(AuthService.authTokenB2CKey, '/', this.domain);
      Cookie.delete(this.authTokenB2CKeyFor3rdPartyApps, '/', this.domain);
    }
  }

  public get isAuthenticatedB2C(): boolean {
    const isAuthenticatedInB2C = Cookie.get(this.isNewLogin ? AuthService.cookieB2CKey : AuthService.authTokenB2CKey);
    return !!isAuthenticatedInB2C;
  }

  public get isAuthenticatedB2B(): boolean {
    const isAuthenticatedInB2B = localStorage.getItem(AuthService.authTokenB2BKey);
    return !!isAuthenticatedInB2B;
  }

  public get businessProfileUrl() {
    if (!this.currentUserDetails) {
      return '';
    }

    return '/app/' + this.currentUserDetails.company_slug;
  }

  get isVisitingFreelancersRecruiter() {
    const url = window.location.href;
    return url.indexOf('freelancers') > 0 && url.indexOf('business') > 0;
  }

  get isVisitingAssessmentRecruiter() {
    return !this.isVisitingFreelancersRecruiter;
  }

  get isCandidateProfilePublishedInB2B() {
    const user = this.currentUserDetails;
    const userProfileNotHidden = !user.is_hidden;
    const userProfileIsComplete = user.complete_profile;
    const userAgreedAsFreelancerToAcceptData = user.freelancer_accept_share_data;

    return userProfileNotHidden && userProfileIsComplete && userAgreedAsFreelancerToAcceptData;
  }

  public requireAuth(): Observable<boolean> {
    if (this.isAuthenticatedB2C) {
      return of(true);
    }

    return fromPromise(this.router.navigateByUrl('/' + this.languageService.currentLang + '/auth/login'));
  }

  public async _init() {
    this.events = new Subject();
    const isAuthenticated = this.isAuthenticatedB2B || this.isAuthenticatedB2C;
    if (window.location.pathname.indexOf('auth') === -1 && !this.currentUserDetails && isAuthenticated) {
      // console.log('auth refresh');
      await this.refreshUserState();
      this.initialized = true;
    }
    // this.watchRouteChanges();
  }

  public watchRouteChanges() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (this.isB2B && window.location.href.indexOf('business/freelancers') === -1) {
          this.setURLParamsForB2B();
        }
      });
  }

  public setURLParamsForB2B() {
    const hasUserLicenseInfo = !!this.currentUserDetails && !!this.currentUserDetails.license_info;
    if (hasUserLicenseInfo) {
      const subscription = this.getUserDetails(null, true) // todo: add socket implementation as this is quite dirty
        .pipe(finalize(() => {
          subscription.unsubscribe();
        }))
        .subscribe((data) => {
          const routeDataOptions: NavigationExtras = {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge',
            queryParams: {}
          };
          const licenseUpdated = data.license_info.license_type_changed;
          let licenseName = this.navigationService.cleanStringAsQueryParam(data.license_info.name);
          const licenseId = data.license_info.license_type_id;

          if (licenseUpdated) {
            licenseName = 'licenseType___' + licenseName;
            routeDataOptions.queryParams = {
              [licenseName]: licenseId
            };
          }

          const firstLevelReached = data.company_stats.first_level_reached;

          if (firstLevelReached) {
            routeDataOptions.queryParams.firstLevelReached = firstLevelReached;
          }

          const firstCampaignSent = data.company_stats.first_campaign_sent;

          if (firstCampaignSent) {
            routeDataOptions.queryParams.firstCampaignSent = firstCampaignSent;
          }

          this.router.navigate([], routeDataOptions);
        });
    }
  }

  public callbackApim(apimParams?: HttpParams) {
    if (!apimParams) {
      apimParams = new HttpParams({fromString: ''});
    }

    return this.httpService.get(ApiEndpoints.auth.callbackApim, {params: apimParams})
      .pipe(tap((response: Response) => {
        return this._handleAuthResponse(response);
      }))
      .pipe(map((response) => {
        return response;
      }))
      .pipe(tap((response: any) => {
        this.events.next({type: AuthenticationEventType.Login});
        return response;
      }))
      .pipe(catchError((error: any) => {
        if (error.detail) {
          return throwError(error);
        }

        if (error.status === 400) {
          error.detail = error.non_field_errors;
        }

        return throwError(error);
      }));
  }

  public signInApim(input: any): Observable<any> {
    return this.httpService.post(ApiEndpoints.auth.loginApim, input)
      .pipe(tap((response: Response) => {
        return response;
      }))
      .pipe(map((response) => {
        return response;
      }))
      .pipe(tap((response: any) => {
        this.events.next({type: AuthenticationEventType.Login});
        return response;
      }))
      .pipe(catchError((error: any) => {
        if (error.detail) {
          return throwError(error);
        }

        if (error.status === 400) {
          error.detail = error.non_field_errors;
        }

        return throwError(error);
      }));
  }

  public signIn(input: any): Observable<any> {
    const loginEndpoint = this.endpointService.getEndpoint(ApiEndpoints.auth.login, 'b2c');
    return this.httpService.post(loginEndpoint, input)
      .pipe(tap((response: Response) => {
        return this._handleAuthResponse(response);
      }))
      .pipe(map((response) => {
        return response;
      }))
      .pipe(tap((response: any) => {
        this.events.next({type: AuthenticationEventType.Login});
        return response;
      }))
      .pipe(catchError((error: any) => {
        if (error.detail) {
          return throwError(error);
        }

        if (error.status === 400) {
          error.detail = error.non_field_errors;
        }

        return throwError(error);
      }));
  }

  public signUp(input: any): Observable<any> {
    const type = this.utilsService.appType();
    const signUpEndpoint = this.endpointService.getEndpoint(ApiEndpoints.auth.registration, type);

    const requestOptions = {
      withCredentials: true,
    };

    return this.httpService.post(signUpEndpoint, input, requestOptions)
      .pipe(this.genericErrorPipe);
  }

  public async refreshUserState() {
    const hasCredentialsForB2CCalls = this.isB2C && this.isAuthenticatedB2C;
    const hasCredentialsForB2BCalls = this.isB2B && this.isAuthenticatedB2B;
    const canRefreshUserState = hasCredentialsForB2BCalls || hasCredentialsForB2CCalls;
    if (canRefreshUserState) {
      return this.getUserDetails(null, true)
        .pipe(first()).toPromise()
        .then((response: UserDetails) => {
          this.currentUserDetails = response;
          this.events.next({type: AuthenticationEventType.StateUpdated});
          return response;
        });
    } else {
      return of(this.currentUserDetails = null).toPromise();
    }
  }

  public getUserDetails(params?: any, refresh: boolean = false, options: any = {}): Observable<any> {
    const hasParams = params && params.type;
    const typeByNavigation = hasParams ?
      params.type : this.utilsService.appType();
    const userDetailsEndpoint = this.endpointService.getEndpoint(ApiEndpoints.auth.userDetails, typeByNavigation);
    if (this.currentUserDetails && this.currentUserDetails.pk && !refresh) {
      return of(this.currentUserDetails);
    }
    if (!hasParams && !refresh) {
      return of({} as any);
    }
    return this.httpService.get(
      userDetailsEndpoint,
      this.authRequestOptions
    )
      .pipe(map((response: any) => {
        const userDetails = response;
        this.currentUserDetails = userDetails;
        this.events.next({type: AuthenticationEventType.StateUpdated, user: userDetails});
        const date_joined = userDetails ? new Date(userDetails.date_joined) : null;
        const existingDateJoined = this.utilsService.localStorage('date_joined').get();
        if (!existingDateJoined) {
          this.utilsService.localStorage('date_joined').add(date_joined);
        }
        if (params) {
          userDetails.type = params.type;
        }
        this.setGoogleTagManagerDatalayer(userDetails);
        return userDetails as UserDetails;
      }))
      .pipe(catchError((error: any) => {
        if (!options.ignoreErrors) {
          if ((error.status === 401 || error.status === 403) && this.authToken) {
            this.clearSession();
          }
          return throwError(error);
        } else {
          throwError(error);
        }
      }));
  }

  public getB2CUser(uid): Observable<any> {
    const typeByNavigation = this.utilsService.appType();
    const userDetailsEndpoint = this.endpointService.getEndpoint(ApiEndpoints.auth.userDetails, typeByNavigation);

    return this.httpService.get(userDetailsEndpoint + '?user_id=' + uid, this.authRequestOptions)
      .pipe(map((response: any) => {
        const userDetails = response;
        this.currentUserDetails = response;
        return userDetails as UserDetails;
      }))
      .pipe(catchError((error: any) => {
        if (error.status === 401 || error.status === 403) {
          this.clearSession();
        }
        return throwError(error);
      }));
  }

  public getB2BUser(): Observable<B2bUserDetails> {
    const userDetailsEndpoint = this.endpointService.getEndpoint(ApiEndpoints.auth.userDetails, 'b2b');
    return this.httpService.get(userDetailsEndpoint, this.b2bRequestHeaders())
      .pipe(catchError(this.genericErrorPipe))
      .pipe(map((response: B2bUserDetails) => {
        const userDetails = response;
        this.currentUserDetails = response;
        return userDetails;
      }));
  }

  public b2bRequestHeaders() {
    const headers = new HttpHeaders();
    if (this.authTokenB2B) {
      if (this.isNewLogin) {
        headers.set('Authorization', 'Bearer ' + this.authTokenB2B);
      } else {
        headers.set('Authorization', 'Token ' + this.authTokenB2B);
      }
    } else {
      headers.delete('Authorization');
    }
    return {headers};
  }

  public setGoogleTagManagerDatalayer(user: B2bUserDetails & UserDetails = this.currentUserDetails) {
    (window as any).dataLayer = (window as any).dataLayer || [];
    if (this.isB2B) {
      let obj = {};
      const isB2BFreelanceRecruiterAccount = window.location.href.indexOf('business/freelancers') > -1 && user.platforms_access.freelancers;
      if (isB2BFreelanceRecruiterAccount) {
        const subscription = this.router.events
          .pipe(finalize(() => {
            subscription.unsubscribe();
          }))
          .pipe(filter((event) => event instanceof NavigationEnd))
          .subscribe(() => {
            this.getGTMFlagsForB2BFreelancers().toPromise().then((response: any) => {
              const stages = response;
              Object.keys(stages.stage).forEach((value) => {
                if (stages.stage[value]) {
                  const freelanceRecruiterDataLayerObj = {
                    user: user.pk,
                    userType: 'freelance recruiter',
                    company: user.company_name,
                    stage: value.replace('_', ' ')
                  };
                  (window as any).dataLayer.push(freelanceRecruiterDataLayerObj);
                }
              });
            });
          });
      } else if (this.utilsService.appType() !== 'b2c') {
        obj = {
          companyID: user.company_id,
          companyName: user.company_name,
          licenceType: user.license_info.name,
          userID: user.pk
        };
        (window as any).dataLayer.push(obj);
      }
    }
    if (this.isB2C) {
      (window as any).dataLayer.push({
        userID: user.pk,
        userType: user.is_freelancer ?
          B2cUserType[B2cUserType.freelancer] :
          B2cUserType[B2cUserType.coder]
      });
    }
  }

  public getGTMFlagsForB2BFreelancers() {
    return this.httpService.get(ApiEndpoints.gtm.freelancers)
      .pipe(catchError(this.genericErrorPipe));
  }

  public saveUserDetails(input: any, triggerEvent: boolean = true): Observable<UserDetails> {
    const endpoint = this.endpointService.getEndpoint(AuthEndpoints.userDetails, this.utilsService.appType());
    return this.httpService.patch(
      endpoint,
      input
    )
      .pipe(tap((response: Response) => {
        this.currentUserDetails = response;
        if (triggerEvent === true) {
          this.events.next({type: AuthenticationEventType.StateUpdated});
        }
        return response;
      }))
      .pipe(this.genericErrorPipe);

  }

  public async clearSession(): Promise<any> {
    return new Promise(async (resolve) => {
      this.currentUserDetails = null;
      this.authToken = null;
      this.authTokenB2C = null;
      this.authTokenB2B = null;
      this.refreshToken = null;
      this.isNewLogin = null;

      localStorage.clear();
      Cookie.deleteAll();
      // await this.clearAuthCookies();
      resolve(true);
    });
  }

  public async clearAuthCookies() {
    const cookies = Cookie.getAll();
    for (const cookie of cookies) {
      if (cookie.startWith(AuthService.authTokenB2CKey) ||
        cookie.startWith(AuthService.authTokenB2BKey) ||
        cookie.startWith(AuthService.cookieB2CKey)) {
        Cookie.delete(cookie, '/', this.domain);
      }
    }
  }

  public logoutUserFromAD(b2bURL: string): Observable<any> {
    const logoutEndpoint = this.endpointService.getEndpoint(ApiEndpoints.auth.logout, b2bURL);
    const body = {
      redirect_uri: (window.location.href.indexOf('localhost') >= 0 ? 'http://' : 'https://') +
        window.location.host + '/' + this.languageService.currentLang + '/auth/login'
    };

    return this.httpService.post(logoutEndpoint, body, this.authRequestOptions)
      .pipe(tap((response: any) => {
        this.logoutURL = response.redirect_uri;
        return of(null);
      }))
      .pipe(finalize(() => {
        this.loaderService.setState(LoaderState.Done);
        this.clearSession().then(r => {
          if(r) {
            if (this.logoutURL) {
              window.location.href = this.logoutURL;
            } else {
              window.location.href = (this.isProd ? LogoutFromADUrlProd : LogoutFromADUrl) + 'https://' +
                window.location.host + '/' + this.languageService.currentLang + '/auth/login';
            }
          }
        });

        return of(null);
      }))
      .pipe(catchError(this.genericErrorPipe));
  }

  public signOutB2B(): Observable<boolean> {
    this.loaderService.setState(LoaderState.Pending);

    if (this.isNewLogin) {
      this.logoutUserFromAD('b2b').subscribe();
    } else {
      this.clearSession();
      this.events.next({
        type: AuthenticationEventType.Logout
      });
      return of(true).pipe(delay(1000)).pipe(tap(() => {
        this.loaderService.setState(LoaderState.Done);
        const location = window.location.href;
        if (window.location.href.indexOf(AppDomain) >= 0 || location.indexOf('localhost') >= 0) {
            window.location.href = `${this.languageService.currentLang}/auth/login`;
        }
      }));
    }
  }

  public signOutB2C(): Observable<boolean> {
    this.loaderService.setState(LoaderState.Pending);

    if (this.isNewLogin) {
      this.logoutUserFromAD('').subscribe();
    } else {
      const logoutEndpoint = this.endpointService.getEndpoint(ApiEndpoints.auth.logout, 'b2c');
      return this.httpService.get(logoutEndpoint)
        .pipe(tap((response: any) => {
          this.clearSession();
          window.location.href = `${this.languageService.currentLang}/auth/login`;
          this.events.next({
            type: AuthenticationEventType.Logout
          });

          return of(null);
        }))
        .pipe(finalize(() => {
          this.loaderService.setState(LoaderState.Done);

          return of(null);
        }))
        .pipe(catchError(this.genericErrorPipe));
    }
  }

  public signInFacebook(params): Observable<UserDetails> {
    return this.facebookService.signIn(params)
      .pipe(tap((response: any) => {
        return this._handleAuthResponse(response);
      }));
  }

  public signInGoogle(params): Observable<UserDetails> {
    return this.googleService.signIn(params)
      .pipe(tap((response: any) => {
        return this._handleAuthResponse(response);
      }));
  }

  public signInLinkedin(params): Observable<UserDetails> {
    return this.linkedinService.signIn(params)
      .pipe(tap((response: any) => {
        return this._handleAuthResponse(response);
      }));
  }

  public signInGithub(params): Observable<UserDetails> {
    return this.githubService.signIn(params)
      .pipe(tap((response: any) => {
        return this._handleAuthResponse(response);
      }));
  }

  public resetPassword(email?: string): Observable<string> {
    email = email || this.currentUserDetails.email;

    return this.httpService.post(AuthEndpoints.passwordReset, {email})
      .pipe(catchError(this.genericErrorPipe))
      .pipe(map((response: string) => response));
  }

  public goBackToApplication(accountType: string) {
    if (window.location.href.indexOf(AppDomain) >= 0) {
      const lang = this.languageService.currentLang;
      accountType === EndpointType[EndpointType.b2c] ?
        window.location.href = '/' + lang + '' :
        EndpointType[EndpointType.b2c] ?
          window.location.href = '/companies/' + lang + '/business' :
          window.location.href = '/' + lang + '/user/profile';
    }
  }

  public requireAuthB2C(redirectToUrl: string): Observable<boolean> {
    if (redirectToUrl) {
      this.pathToRedirect = encodeURIComponent(redirectToUrl);
    }
    if (this.isAuthenticatedB2C) {
      return of(true);
    } else {
      const authenticationUrl = '/' + this.languageService.currentLang + '/auth/login/';
      const authenticationPathNavigationExtras = {
        queryParams: {
          redirectTo: this.pathToRedirect || '',
          type: B2cUserType[B2cUserType.coder]
        }
      };
      return from(this.navigationService.navigateByUrl(authenticationUrl, authenticationPathNavigationExtras));
    }
  }

  public confirmRegistrationB2C(token: string): Observable<UserDetails> {
    const registrationEndpoint = this.endpointService.getEndpoint(ApiEndpoints.auth.registrationConfirm, this.utilsService.appType());
    return this.httpService.put(registrationEndpoint, {key: token})
      .pipe(
        map(() => {
          return {key: token, type: 'b2c'};
        }),
        // this.confirmRegistrationPipeline.handleAuthResponse,
        this.confirmRegistrationPipeline.onError
      );
  }

  public confirmRegistrationB2B(token: string): Observable<UserDetails> {
    const registrationEndpoint = this.endpointService.getEndpoint(ApiEndpoints.auth.registrationConfirm, this.utilsService.appType());
    return this.httpService.put(registrationEndpoint, {key: token})
      .pipe(
        this.confirmRegistrationPipeline.handleAuthResponse,
        this.confirmRegistrationPipeline.getUserDetails,
        this.confirmRegistrationPipeline.saveUserDetails,
        this.confirmRegistrationPipeline.onError
      );
  }

  public async requestDeletion() {
    try {
      await this.httpService.post(ApiEndpoints.delete_company, {}).toPromise();
      this.currentUserDetails.requested_delete = true;
      this.matSnackBarService.open(
        this.languageService.getValue('B2B_REQUEST_DELETE_OK'),
        null,
        {
          duration: 3000,
          panelClass: ['success']
        });

    } catch (error) {
      throwError(error)
    }
  }

  public B2CrequestAccountDeletion(): Observable<any> {
    return this.httpService.delete(
      ApiEndpoints.users.requestDeletion, this.authRequestOptions)
      .pipe(catchError(this.genericErrorPipe));
  }

  public _setLocalStorageKeys() {
    this._setAuthTimesCountPerDay();
  }

  public _setAuthTimesCountPerDay() {
    const today = new Date();
    this._handleAuthTimesCount(today);
  }

  public isValidUserB2C(user: B2CUserDetails): boolean {
    return user.complete_profile;
  }

  public canViewMyProfile() {
    const userDetails = this.currentUserDetails;
    const hasUserProfileSaved = !!userDetails.profile;
    const canViewProfile =
      !this.currentUserDetails.is_freelancer ||
      (this.isValidUserB2C(userDetails) &&
        hasUserProfileSaved);
    if (!canViewProfile) {
      this.matSnackBarService.openTop('B2C_MUST_COMPLETE_PROFILE', {panelClass: ['error'], duration: 5000});
    }
    const typeString = this.currentUserDetails.is_freelancer ? B2cUserType[B2cUserType.freelancer] : B2cUserType[B2cUserType.coder];
    const isOnOtherPageThanCompleteProfile = window.location.href.indexOf(`user/profile/complete/${STEPNAMES.step1}`);
    if (isOnOtherPageThanCompleteProfile && this.currentUserDetails.is_freelancer && !hasUserProfileSaved) {
      this.router.navigate([this.languageService.currentLang + `/user/profile/complete/${STEPNAMES.step1}`], {
        queryParams: {
          type: typeString
        }
      });
    }

    if (hasUserProfileSaved) {
      return hasUserProfileSaved;
    }
    return canViewProfile;
  }

  public setIfFreelancerRegistrationParams(signupBody) {
    if (signupBody.source === B2cUserType[B2cUserType.freelancer]) {
      signupBody.is_freelancer = true;
      signupBody.freelancer_accept_share_data = true;
    }
    return signupBody;
  }

  public redirectUserForCompleteProfile() {
    const queryParams = {
      onContinue: window.location.pathname + encodeURIComponent(window.location.search),
      type: (this.currentUserDetails && this.currentUserDetails.is_freelancer)
        ? B2cUserType[B2cUserType.freelancer]
        : B2cUserType[B2cUserType.coder],
      invalid: true,
    };
    this.navigationService.navigateByUrl(`user/profile/complete/${STEPNAMES.step1}`, {queryParams});
  }

  public checkResponseIncompleteProfileError(error) {
    return ((error.status === 403) && (error.json.detail === 'incomplete_profile'));
  }

  public hasAssessmentAccess(userDetails) {
    return userDetails.platforms_access.assessment;
  }

  public hasFreelancersAccess(userDetails) {
    return userDetails.platforms_access.freelancers;
  }

  // private getUrlType(url: window.location)

  public _handleAuthResponse(response: any) {
    const type = response.type || this.utilsService.appType();
    switch (type) {
      case 'b2b':
        this.isB2B = true;
        this.isB2C = false;
        this.authTokenB2B = this.isNewLogin ? response.access_token : response.key;
        this.refreshToken = response.refresh_token;
        break;
      case 'b2c':
        this.isB2C = true;
        this.isB2B = false;
        // this.authTokenB2C = this.isNewLogin ? response.access_token : response.key;
        // this.refreshToken = response.refresh_token;
        break;
      default:
        break;
    }

    return response;
  }

  public hasAccess(feature: string): boolean {
    // check if a b2b user can access a specific feature
    if (!this.currentUserDetails) {
      return false;
    }
    return this.currentUserDetails.license_info[`access_${feature}`];
  }

  public redirectToApp() {
    if (window.location.href.indexOf(AppDomain) >= 0) {
      if (this.currentUserDetails.company_has_campaigns) {
        window.location.href = '/app/' + this.currentUserDetails.company_slug;
      } else {
        window.location.href = '/app/' + this.currentUserDetails.company_slug + '/tests';
      }
    }
  }

  private _handleAuthTimesCount(today) {
    const existingTimesAuth = this.utilsService.localStorage('times_authenticated').get();
    this.utilsService.localStorage('last_authentication').add(today);
    if (!existingTimesAuth) {
      this.utilsService.localStorage('times_authenticated').add(1);
    } else {
      this.utilsService.localStorage('times_authenticated').add(existingTimesAuth + 1);
    }
  }
}

export interface HMACValues {
  freelancers: string;
  assessment: string;
}

export interface B2BLicenseInfo {
  name: string;
  number_of_reports: number;
  number_of_users: number;
  consumed_credits: number;
  credits_remaining: number;
  has_unlimited_credits: boolean;
  start_date: string;
  expire_date: string;
  tested_candidates_count: number;
  succes_rate: number;
  license_type_changed: boolean;
  license_type_id: number;
  is_valid: boolean;
  is_trial: boolean;
  is_paying: boolean;
  // toggle features
  access_anonymous_candidates: boolean;
  access_custom_email_template: boolean;
  access_share_campaign: boolean;
  is_on_credit_pack: boolean;
  is_on_subscription: boolean;
}

export interface B2BCompanyStats {
  campaigns_draft_count: number;
  campaigns_active_count: number;
  campaigns_finished_count: number;
  candidates_count: number;
  tests_sent_count: number;
  tests_result_count: number;
  first_campaign_sent: number;
  first_level_reached: number;
}

export interface B2CUserDetails {
  pk: number;
  activity_domain?: any;
  activity_domain_name?: any;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  avatar?: any;
  full_name: string;
  profile?: number;
  profile_name?: string;
  technology?: number;
  technology_name?: string;
  country?: number;
  city?: number;
  years_of_exp?: number;
  use_personal_data?: boolean;
  newsletter?: boolean;
  date_joined?: Date;
  is_freelancer?: boolean;
  saved_freelancer_val?: boolean;
  freelancer_accept_share_data?: boolean;
  freelance_availability?: boolean | string;
  freelance_rate_amount?: number;
  freelance_rate_currency?: string;
  freelance_rate_frequency?: string;
  experience_years?: any;
  freelance_work_location_availability?: any;
  freelance_work_time_availability?: any;
  freelance_ready?: any;
  freelance_additional_comments?: any;
  linkedin_profile_url?: string;
  university?: any;
  is_student?: boolean;
  experience?: any;
  terms?: boolean;
  type?: string;
  phone?: string;
  counters?: any;
  date_of_birth?: string;
  main_technologies?: string[];

  title?: string;
  description?: string;

  agree_terms?: boolean;

  terms_coder?: boolean;
  terms_freelancer?: boolean;

  requested_delete?: boolean;

  complete_profile?: boolean; // The server will sent this in order to redirect the user to complete profile if set on false.
  // Make sure that the profile/complete has the same validations as on the backend!

  // These are for form Freelancer View Mode
  freelanceRateExperienceName?: any;
  freelanceWorkLocationAvailabilityName?: any;
  freelanceWorkTimeAvailabilityName?: any;
  freelanceRateCurrencyName?: any;
  freelanceRateFrequencyName?: any;
  resume?: File;
  document?: File;
}

export enum B2CUserType {
  coder = 0,
  freelancer = 1
}

export enum B2BUserType {
  assessment = 0,
  freelancer = 1
}

export enum UserActionType {
  application = 0
}
