import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef }      from '@angular/material/dialog';
import { MatSnackBar }                        from '@angular/material/snack-bar';
import { AppDomain }                          from '@app/app.constants';
import { CompanyFormLocation }                from '@b2b/@common/modules/company-profile/cpts/company-edit.component';
import { SellableItem, StripeService }        from '@b2b/@common/modules/stripe/services/stripe.service';
import { BuyStatus, BuyStatusService }        from '@b2b/@common/services/buy-status.service';
import { AuthService }                        from '@common/auth/services/auth.service';
import { FADE_IN_ANIMATION }                  from '@common/core/core.animations';
import { CurrencyService }                    from '@services/currency.service';
import { LanguageService }                    from '@services/i18n.service';
import { MaterialAlertService }               from '@services/material-alert.service';
import { CountryInfo }                        from '@services/utils.service';
import { Subject, throwError }                from 'rxjs';
import { takeUntil }                            from 'rxjs/operators';

export enum ProductType {
  credits = 'credits',
  subscription = 'subscription',
}

@Component({
  selector: 'app-modal-stripe',
  templateUrl: './modal-stripe.component.html',
  styleUrls: ['./modal-stripe.component.scss'],
  animations: [FADE_IN_ANIMATION]
})
export class ModalStripeComponent implements OnInit, OnDestroy {
  activeTabIndex = this.data.activeTabIndex || 0;
  allSubscriptions: SellableItem[];
  allCreditPacks: SellableItem[];
  isLoading = false;
  companyFormEnum = CompanyFormLocation;
  productTypeEnum = ProductType;
  showCheckout = false;
  selectedPaymentOptions = {
    productType: null,
    productId: null,
    currency: 'eur',
    numberOfUsers: null,
    numberOfTests: null,
    calculated_price_eur: null,
    calculated_price_usd: null,
  };
  selectedProduct: SellableItem;
  eventsSubject: Subject<void> = new Subject<void>();
  isEditingCompanyDetails = false;
  savingData = false;
  vatValue = 0;

  selectedSubscriptionOptions: SubscriptionOption[] = [];
  maxNumberOfAccounts = 30;
  companyAccountUsers: number;
  private _destroyed$: Subject<any> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SellableItems,
    private _dialogRef: MatDialogRef<ModalStripeComponent>,
    public materialAlertService: MaterialAlertService,
    public currencyService: CurrencyService,
    public stripeService: StripeService,
    public authService: AuthService,
    public languageService: LanguageService,
    public matSnackBar: MatSnackBar,
    public buyStatusService: BuyStatusService
  ) {
  }

  ngOnInit(): void {
    this.markUserAsInterestedInPrices();
    this.initialiseModalData();
    this._registerOpenStripeModal();
    this.initCurrencyChangeSubscription();
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  markUserAsInterestedInPrices() {
    this.buyStatusService.updateUserShoppingStatus(BuyStatus.interested_prices).toPromise().then();
  }

  initialiseModalData() {
    this.allSubscriptions = this.data.subscriptions;
    this.allCreditPacks = this.data.creditPacks;
    this.companyAccountUsers = this.authService.currentUserDetails.company_accounts_count;
    this.allSubscriptions.map((data, key) => {
      this.selectedSubscriptionOptions[key] = {
        numberOfUsers: this.companyAccountUsers,
        numberOfTests: null,
        calculated_price_eur: null,
        calculated_price_usd: null,
        minHit: true,
        maxHit: false,
        price_per_unit_eur: data.price_per_unit_eur,
        price_per_unit_usd: data.price_per_unit_usd,
        number_of_units: data.number_of_units,
      };

      this._calculatePrices(this.currencyService.selectedCurrency.toLowerCase(), key);
      this._calculateTest(key);
    });
    this.isEditingCompanyDetails = this.setDefaultCompanyDetailsFormVisibility();
    if (window.location.href.indexOf(AppDomain) >= 0) {
      this.computeVatValue(this.authService.currentUserDetails.company_country_id);
    }
  }

  initCurrencyChangeSubscription() {
    this.currencyService.currencyChanges.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      this.recalculatePriceOnCurrencyChange();
    });
  }

  setDefaultCompanyDetailsFormVisibility() {
    if (this.authService.currentUserDetails) {
      return !this.authService.currentUserDetails.company_country ||
        !this.authService.currentUserDetails.company_name ||
        !this.authService.currentUserDetails.company_address;
    }
  }

  proceedToCheckout(productType, productId, currency = 'eur', calculated_price_eur, calculated_price_usd, numberOfUsers, numberOfTests) {
    this.isLoading = true;
    const calculatedPrice = currency === 'eur' ? calculated_price_eur : calculated_price_usd;

    this.stripeService.buyProduct(productType, productId, currency, calculatedPrice, numberOfUsers, numberOfTests)
      .then(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        this.materialAlertService.messageAndButton('GLOBAL_BUY_STRIPE_SERVICE_ERROR_TRY_AGAIN_LATER');
        return throwError(error);
      });
  }

  proceedToInformationConfirmation(productType, productId, currency = 'eur', index: number = null) {
    if (index !== null) {
      this.selectedPaymentOptions = {
        productType, productId, currency,
        numberOfUsers: this.selectedSubscriptionOptions[index].numberOfUsers,
        numberOfTests: this.selectedSubscriptionOptions[index].numberOfTests,
        calculated_price_eur: this.selectedSubscriptionOptions[index].calculated_price_eur,
        calculated_price_usd: this.selectedSubscriptionOptions[index].calculated_price_usd,
      };

      this.selectedProduct = this.getSelectedProductById(productId, productType);
      this.selectedProduct.numberOfUsers = this.selectedSubscriptionOptions[index].numberOfUsers;
      this.selectedProduct.numberOfTests = this.selectedSubscriptionOptions[index].numberOfTests;
      this.selectedProduct.calculated_price_eur = this.selectedSubscriptionOptions[index].calculated_price_eur;
      this.selectedProduct.calculated_price_usd = this.selectedSubscriptionOptions[index].calculated_price_usd;

    } else {
      this.selectedPaymentOptions = {
        productType, productId, currency,
        numberOfUsers: null, numberOfTests: null, calculated_price_eur: null, calculated_price_usd: null
      };
      this.selectedProduct = this.getSelectedProductById(productId, productType);
      this.selectedProduct.calculated_price_eur = this.selectedProduct['total_price_' + currency];
      this.selectedProduct.calculated_price_usd = this.selectedProduct['total_price_' + currency];
    }
    this.toggleCheckout();
  }

  getSelectedProductById(id, type) {
    if (type === ProductType.credits) {
      return this.allCreditPacks.find((product) => product.id === id);
    }
    if (type === ProductType.subscription) {
      return this.allSubscriptions.find((product) => product.id === id);
    }
  }

  toggleCheckout() {
    this.showCheckout = !this.showCheckout;
  }

  editCompany() {
    this._companyFormState(true);
  }

  validate() {
    this.savingData = true;
    this.buyStatusService.updateUserShoppingStatus(BuyStatus.interested_buying).toPromise().then(() => {
      if (this.isEditingCompanyDetails) {
        this.eventsSubject.next();
      } else {
        this.onValidatedForCheckout();
      }
    });
  }

  onCompanyFormSave() {
    this._companyFormState(false);
    this.onValidatedForCheckout();
  }

  onCompanyFormCancel() {
    this._companyFormState(false);
  }

  onCountryChange(country: CountryInfo) {
    this.computeVatValue(country.id);
  }

  computeVatValue(countryId) {
    const FR_CODE = 23;
    switch (countryId) {
      case FR_CODE:
        this.vatValue = 0.20;
        break;
      default:
        this.vatValue = 0;
        break;
    }
  }

  onValidatedForCheckout() {
    setTimeout(() => {
      this.proceedToCheckout(
        this.selectedPaymentOptions.productType,
        this.selectedPaymentOptions.productId,
        this.currencyService.selectedCurrency.toLowerCase(),
        this.selectedPaymentOptions.calculated_price_eur,
        this.selectedPaymentOptions.calculated_price_usd,
        this.selectedPaymentOptions.numberOfUsers,
        this.selectedPaymentOptions.numberOfTests,
      );
      this.savingData = false;
    }, 3000);
  }

  onFormError() {
    this.savingData = false;
    this.matSnackBar.open(
      this.languageService.getValue('B2B_STRIPE_INVALID_COMPANY_DETAILS'),
      null,
      {
        duration: 3000,
        panelClass: ['error']
      });
  }

  increaseUserCounter(index: number, numberOfMonths: number, pricePerUnit: number) {
    const increasedNumberOfUsers = this.selectedSubscriptionOptions[index].numberOfUsers + 1;
    this.selectedSubscriptionOptions[index].numberOfUsers = increasedNumberOfUsers;
    this.selectedSubscriptionOptions[index].maxHit = increasedNumberOfUsers >= this.maxNumberOfAccounts;
    this.selectedSubscriptionOptions[index].minHit = increasedNumberOfUsers <= this.companyAccountUsers;

    this._calculatePrices(this.currencyService.selectedCurrency.toLowerCase(), index, increasedNumberOfUsers);
    this._calculateTest(index, increasedNumberOfUsers);
  }

  decreaseUserCounter(index: number, numberOfMonths: number, pricePerUnit: number) {
    const decreasedNumberOfUsers = this.selectedSubscriptionOptions[index].numberOfUsers - 1;
    this.selectedSubscriptionOptions[index].numberOfUsers = decreasedNumberOfUsers;
    this.selectedSubscriptionOptions[index].maxHit = decreasedNumberOfUsers >= this.maxNumberOfAccounts;
    this.selectedSubscriptionOptions[index].minHit = decreasedNumberOfUsers <= this.companyAccountUsers;

    this._calculatePrices(this.currencyService.selectedCurrency.toLowerCase(), index, decreasedNumberOfUsers);
    this._calculateTest(index, decreasedNumberOfUsers);
  }

  recalculatePriceOnCurrencyChange() {
    this.selectedSubscriptionOptions.map((data, key) => {
      return this._calculatePrices(this.currencyService.selectedCurrency.toLowerCase(), key);
    });
  }

  private _calculatePrices(currency: string, key: number, customNumberOfUser = null) {
    const option = this.selectedSubscriptionOptions[key];
    const numberOfUsers = customNumberOfUser !== null ? customNumberOfUser : option.numberOfUsers;

    if (option.number_of_units >= 12) {

      if (option.numberOfUsers > 5) {
        option.calculated_price_eur = (option.price_per_unit_eur + numberOfUsers * 29) * option.number_of_units;
        option.calculated_price_usd = (option.price_per_unit_usd + numberOfUsers * 39) * option.number_of_units;
      } else {
        option.calculated_price_eur = (option.price_per_unit_eur + (numberOfUsers - 1) * 40) * option.number_of_units;
        option.calculated_price_usd = (option.price_per_unit_usd + (numberOfUsers - 1) * 49) * option.number_of_units;
      }
    } else {
      if (option.numberOfUsers > 5) {
        option.calculated_price_eur = option.price_per_unit_eur + numberOfUsers * 39;
        option.calculated_price_usd = option.price_per_unit_usd + numberOfUsers * 49;
      } else {
        option.calculated_price_eur = option.price_per_unit_eur + (numberOfUsers - 1) * 50;
        option.calculated_price_usd = option.price_per_unit_usd + (numberOfUsers - 1) * 59;
      }
    }
  }

  private _calculateTest(key: number, customNumberOfUser = null) {
    const option = this.selectedSubscriptionOptions[key];
    const numberOfUsers = customNumberOfUser !== null ? customNumberOfUser : option.numberOfUsers;

    if (option.number_of_units >= 12) {
      option.numberOfTests = numberOfUsers > 5 ? null : 500;
    } else {
      option.numberOfTests = numberOfUsers > 5 ? 100 : 50;
    }
  }

  private _companyFormState(open) {
    setTimeout(() => {
      this.isEditingCompanyDetails = open;
    }, 150);
  }

  private _registerOpenStripeModal() {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: 'B2B_Assessment_Dashboard_Price_Popup_Opened',
      userType: 'b2b',
      source: 'dashboard'
    });
  }

}

export interface SellableItems {
  subscriptions: SellableItem[];
  creditPacks: SellableItem[];
  activeTabIndex: number;
}

export interface SubscriptionOption {
  numberOfUsers: number,
  numberOfTests: number,
  calculated_price_eur: number,
  calculated_price_usd: number,
  minHit: boolean,
  maxHit: boolean,
  price_per_unit_eur: number,
  price_per_unit_usd: number,
  number_of_units: number,
}

