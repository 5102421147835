<section [class.is-loading]="loadingTheSlides" class="carousel">
  <mat-dialog-content>
    <!--  <mat-dialog-content flex layout="row" layout-align="center">-->
    <!--    <mat-dialog-content flex="50">-->
    <mat-dialog-content>
      <ngx-siema *ngIf="!loadingTheSlides && slides && slides.length > 0" [options]="options" class="siema-container">
        <ngx-siema-slide *ngFor="let slide of slides" class="ngx-siema-slide">
          <div class="carousel-slide">
            <div class="carousel-image">
              <img [src]="slide.image" alt=""/>
            </div>
            <div class="carousel-text">
              <h2 class="carousel-title">{{ slide.title }}</h2>
              <p>{{ slide.description }}</p>
            </div>
          </div>
        </ngx-siema-slide>
      </ngx-siema>
      <div *ngIf="!loadingTheSlides && slides && slides.length > 0" class="carousel-controls">
        <a (click)="closeCarousel()" *ngIf="showControls" class="close">
          <mat-icon>close</mat-icon>
        </a>
        <div class="carousel-column">
          <a (click)="previousSlide()"
             [class.disabled]="currentSlide === 0"
             class="carousel-prev">
            <mat-icon>arrow_left</mat-icon>
          </a>
        </div>
        <div class="carousel-column">
          <a (click)="nextSlide()"
             [class.disabled]="slides.length === currentSlide + 1"
             class="carousel-next">
            <mat-icon>arrow_right</mat-icon>
          </a>
        </div>
        <!--<button type="button" (click)="goTo()">go to first slide</button>-->
      </div>
    </mat-dialog-content>
  </mat-dialog-content>
  <div class="carousel-dots">
    <ul>
      <li *ngFor="let slide of slides; let i = index">
        <a (click)="goToSlide(i)" [ngClass]="{'current': slide.position === currentSlide + 1}">{{i + 1}}</a>
      </li>
    </ul>
  </div>
</section>
