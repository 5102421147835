import { Injectable }                     from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ApiEndpoints }                   from '@app/app.constants';
import { HttpErrorService }               from '@services/http-error.service';
import { HttpService }                    from '@services/http.service';
import { Observable, of, throwError }     from 'rxjs';
import { catchError }                     from 'rxjs/operators';
import { LanguageService }                from './i18n.service';

export enum AccessTypesB2B {
  // access_my_pentalog,
  access_freelancers,
  access_assessment,
  freelancer
}

export enum B2BAppType {
  assessment,
  freelancers
}


@Injectable({
  providedIn: 'root'
})
export class PlatformsAccessService {
  currentAccess = {};

  constructor(
    public router: Router,
    public lang: LanguageService,
    public httpService: HttpService,
    protected httpErrorService: HttpErrorService,
  ) {
  }

  public redirectAfterLogin(user, paramSuffix = '', firstTimeSignup?: boolean,) {
    const platforms = user.platforms_access;
    const isLegacyUser = !!platforms.b2b_old_apps;
    // const hasMyPentalogAccess = !!platforms.my_pentalog;
    const hasAssessmentAccess = !!platforms.assessment;
    const hasFreelancersAccess = !!platforms.freelancers;
    const isAssessmentOrFreelanceRecruiter = hasFreelancersAccess || hasAssessmentAccess;

    if (isLegacyUser && !isAssessmentOrFreelanceRecruiter) {
      // this.redirectToMagento().toPromise();
    } else {
      const url = this.getPlatformDefaultUrl(user.default_platform_access);

      const lang = this.lang.currentLang;
      // if(firstTimeSignup && user.default_platform_access === 'assessment') {
      //   return window.location.href = '/' + lang + '/app/' + user.company_slug + '/tests' +
      //     '?language__in=en&favorites__in=&page=1&page_size=10&signup_success=true';
      // }

      if (url.length > 0) {
        // const newUrl = url.replace('{company}', user.company_slug);
        const newUrl = 'business/freelancers/dashboard';
        return window.location.href = '/' + lang + '/' + newUrl + paramSuffix;
      }
      return window.location.href = `/companies/${lang}/business${paramSuffix}`;
    }
  }

  public getPlatformDefaultUrl(platform) {
    const data = require('../access_map/' + platform);
    return data[platform].goTo ? data[platform].goTo : '';
  }

  public hasUserPlatformAccess(user, platform = '') {
    const userAccessiblePlatforms = this._getPlatforms(user);
    if (!platform || !platform.length || !userAccessiblePlatforms.length) {
      return false;
    }

    return userAccessiblePlatforms.indexOf(platform) >= 0;
  }

  public switchDefaultPlatform(platform: B2BAppType, user) {
    if (this.shouldGiveAccess(platform, user)) {
      const patch = {platforms_access: {[platform]: true}};
      return this.httpService.patch(ApiEndpoints.auth.userDetails, patch);
    } else {
      return of(undefined);
    }
  }

  public grantB2BFreelancerPlatformAccess(requestOptions) {
    return this.httpService.get(ApiEndpoints.grantFreelancersAccess, requestOptions);
  }

  private checkIfUrlIsFreelancersRecruitersApp(url) {
    // tslint:disable-next-line:no-bitwise
    return ~url.indexOf('business/freelancers');
  }

  public getB2BAppType(url = window.location.href) {
    return this.checkIfUrlIsFreelancersRecruitersApp(url) ?
      B2BAppType[B2BAppType.freelancers] :
      B2BAppType[B2BAppType.assessment];
  }

  public createAccessPlatform(body): Observable<any> {
    return this.httpService.put(ApiEndpoints.marketo.accessPlatform, body)
      .pipe(catchError((error) => {
        return throwError(this.httpErrorService.parseError(error));
      }));
  }

  private shouldGiveAccess(platform, user) {
    const doesntHaveAccess = !user.platforms_access[platform];
    return doesntHaveAccess;
  }

  private _createListOfUrls(platform, companySlug) {
    const data = require('../access_map/' + platform);
    const infos = data[platform] || {};
    const b2bMenuRoutes = infos !== undefined && infos.b2bMenuRoutes
      ? infos.b2bMenuRoutes
      : {};

    return Object.keys(b2bMenuRoutes).map((item) => {
      return {
        translateKey: item,
        url: b2bMenuRoutes[item].replace('{company}', companySlug),
        extern: infos.extern === true
      };
    });
  }

  public getB2BApplicationTypeFromRoute(snapshot: ActivatedRouteSnapshot) {
    let route = snapshot;
    try {
      while (route !== null) {
        if (
          route.data.type &&
          (
            route.data.type === B2BAppType[B2BAppType.freelancers] ||
            route.data.type === B2BAppType[B2BAppType.assessment]
          )
        ) {
          return route.data.type;
        } else {
          route = route.parent;
        }
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  private _getPlatforms(user) {
    if (!user.platforms_access) {
      return [];
    }
    const elem = Object.keys(user.platforms_access).filter((item) => {
      return user.platforms_access[item] === true;
    });
    const freelancersPlatform = user.platforms_access.freelancers ? ['freelancers'] : null;
    // const my_pentalogPlatform = user.platforms_access.my_pentalog ? ['my_pentalog'] : [''];
    // const assessmentPlatform = user.platforms_access.assessment ? ['assessment'] : [''];
    const platform = freelancersPlatform || elem;
    return platform;
  }

}
