<div class="slideshow-guide-youtube">
  <a (click)="close()" class="close">
    <mat-icon>close</mat-icon>
  </a>
  <div class="guide-youtube">
    <div class="guide-youtube__title">
      <h2>{{ guideTitle }}</h2>
    </div>
    <div class="guide-youtube__video">
      <youtube-player *ngIf="youtubeVideoId !== ''"
        [videoId]="youtubeVideoId"
        suggestedQuality="highres"
        [height]="450"
        [width]="850">
      </youtube-player>
    </div>
    <div class="guide-youtube__description">
      {{ guideInfo }}
    </div>
  </div>
</div>
