import { CommonModule }         from '@angular/common';
import { NgModule }             from '@angular/core';
import { CommonB2bModule }      from '@b2b/@common/common-b2b.module';
import { LayoutsModule }        from '@common/layouts/layouts.module';
import { ModalStripeComponent } from './cpts/modal-stripe.component';
import { MaterialModule }       from '@common/material/material.module';
import { TranslateModule }      from '@ngx-translate/core';


@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    CommonB2bModule,
    LayoutsModule,
  ],
  declarations: [
    ModalStripeComponent
  ],
  entryComponents: [
    ModalStripeComponent
  ]
})
export class StripeModule { }
