import { Injectable }   from '@angular/core';
import { Subject }      from 'rxjs';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  public currencies: Currency[] = window.location.href.indexOf('business/freelancers') > -1 ? [
    {
      id: 'EUR',
      symbol: '€',
      selected: true
    },
    {
      id: 'GBP',
      symbol: '£',
      selected: false
    },
    {
      id: 'USD',
      symbol: '$',
      selected: false
    }
  ] : [
    {
      id: 'EUR',
      symbol: '€',
      selected: true
    },
    {
      id: 'USD',
      symbol: '$',
      selected: false
    }
  ];
  public currencyChanges: Subject<Currency> = new Subject();

  constructor(
    public utilsService: UtilsService
  ) {
  }

  public get defaultCurrencyId() {
    return this.currencies.filter((currency) => {
      return currency.selected;
    })[0].id;
  }

  public get activeCurrencyId() {
    const selectedCurrency = this.selectedCurrency;
    if (selectedCurrency && selectedCurrency !== 'undefined') {
      return selectedCurrency;
    } else {
      return this.currencies.filter((currency) => {
        return currency.selected;
      })[0].id || 'EUR';
    }
  }

  public get activeCurrencySymbol() {
    const activeCurrencyId = this.activeCurrencyId;
    return this.currencies.filter((currency: Currency) => {
      return currency.id === activeCurrencyId;
    })[0].symbol;
  }

  public get selectedCurrency() {
    const currencyFromLocalStorage = this.utilsService.localStorage('freelancers_currency').get();
    const currencyFallback = this.currencies.filter((currency) => {
      return 'EUR' === currency.id;
    })[0].id;
    return currencyFromLocalStorage || currencyFallback;
  }

  public setCurrencyLocalStorage(newCurrencyId: string = this.defaultCurrencyId) {
    if (this.selectedCurrency) {
      this.utilsService.localStorage('freelancers_currency').add(newCurrencyId);
    } else {
      this.utilsService.localStorage('freelancers_currency').add(this.defaultCurrencyId);
    }
  }

  public setCurrencySwitcherValue(newCurrencyId: string = this.selectedCurrency) {
    this.currencies.forEach((currency) => {
      currency.selected = currency.id === newCurrencyId;
    });
  }

}

export interface Currency {
  id: string;
  symbol: string;
  selected?: boolean;
}

