import { Injectable }                      from '@angular/core';
import { ApiEndpoints }                    from '@app/app.constants';
import { HttpErrorService }                from '@services/http-error.service';
// import { Response } from '@angular/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, tap }            from 'rxjs/operators';
import { HttpService }                     from './http.service';
import { UtilsService }                    from './utils.service';
import { HttpErrorResponse } from '@angular/common/http';

// import { ApiEndpoints } from '~/app/@core/core.constants';

// import { HttpService } from './http.service';
// import { HttpErrorService } from './http-error.service';

export interface SiteSettings {
  b2b: {
    social_auth: SocialSiteSettings;
    website: {
      name: string;
      about_us: string;
    };
    social_links: {
      facebook: string;
      linkedin: string;
      twitter: string;
    };
    contact_info: {
      emails: string[];
      phone_numbers: string[];
      address: string;
    };
    activate_new_app?: boolean;
    enable_candidate_my_stack_section?: boolean;
  };
  b2c: {
    website: {
      name: string;
      about_us: string;
    };
  };
  social_auth: {
    facebook: string;
    github: string;
    linkedin: string;
    google: string;
  };
}

export interface SocialSiteSettings {
  facebook: string;
  google: string;
  github: string;
  linkedin_oauth2: string;
}

@Injectable({
  providedIn: 'root'
})
export class SiteConfigService {

  public siteConfigLoaded: Subject<SiteSettings> = new Subject();
  public siteConfigSocialSettingsLoaded = new Subject<SocialSiteSettings>();
  public appType = 'b2c';

  constructor(
    protected _http: HttpService,
    protected _httpErrorService: HttpErrorService,
    protected _utils: UtilsService
  ) {
  }

  protected _settings: SiteSettings;

  public get settings(): any | SiteSettings {
    return this._settings;
  }

  public initSettings() {
    this.appType = this._utils.appType();
    this._getSiteSettings().subscribe((response: any) => {
      this._settings = response;
    });
  }

  public parseSiteSettings(response) {
    return response;
  }

  private _getSiteSettings(): Observable<SiteSettings> {
    return this._http.get(ApiEndpoints.common.siteConfig)
      .pipe(tap((val: any) => {
        this.siteConfigSocialSettingsLoaded.next(val.social_auth);
      }))
      .pipe(map((response: Response) => {
        this.siteConfigLoaded.next();
        this.siteConfigLoaded.complete();

        return this.parseSiteSettings(response);
      }))
      .pipe(catchError((error: HttpErrorResponse) => {
        return throwError(this._httpErrorService.parseError(error));
      }));
  }

}
