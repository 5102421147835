<h2
  *ngIf="companyFormEnum.default === formLocation"
  class="app-page--subtitle">
  {{ 'B2B_COMPANY_PROFILE_LABEL_COMPANY_DETAILS' | translate }}
  <button
    *ngIf="companyFormEnum.default === formLocation"
    (click)="onCancelClick($event)" class="btn btn-hollow">
    {{ 'B2B_GLOBAL_BUTTON_CANCEL' | translate }}
    <mat-icon>close</mat-icon>
  </button>
</h2>
<form
  (ngSubmit)="onSubmit()"
  *ngIf="data"
  [class.is-loading]="pending"
  [formGroup]="formHelper.formGroup"
  [class.has-padding]="companyFormEnum.default === formLocation"
  class="mat-form">
  <app-form-alert [formGroupAlert]="formHelper.formGroupAlert"></app-form-alert>
  <mat-form-field class="form-group">
    <input
      [placeholder]="'GLOBAL_LABEL_COMPANY' | translate"
      formControlName="name"
      matInput
      minlength="2"
      required>
    <span
      *ngIf="formHelper.fieldHasError('name', 'required')"
      class="form-control-error">
      {{ 'GLOBAL_ERROR_COMPANY_REQUIRED' | translate }}
    </span>
    <span
      *ngIf="formHelper.fieldHasError('name', 'minlength')"
      class="form-control-error">
      {{ 'GLOBAL_ERROR_FIELD_MIN_LENGTH' | translate: {minlength: 2} }}
    </span>
    <span
      *ngIf="formHelper.fieldHasError('name', 'invalid')"
      class="form-control-error">
      {{ formHelper.getField('name').getError('invalid') }}
    </span>
  </mat-form-field>

  <mat-form-field
    *ngIf="companyFormEnum.default === formLocation"
    class="form-group">
    <input
      [placeholder]="'GLOBAL_LABEL_WEBSITE' | translate"
      formControlName="website"
      matInput
      required>
    <span
      *ngIf="formHelper.fieldHasError('website', 'invalid')"
      class="form-control-error">
      {{ formHelper.getField('website').getError('invalid') }}
    </span>
  </mat-form-field>

  <mat-form-field
    *ngIf="companyFormEnum.default === formLocation"
    class="form-group">
    <input
      [placeholder]="'GLOBAL_LABEL_COMPANY_ACTIVITY' | translate"
      formControlName="activity"
      matInput
      required>
    <span
      *ngIf="formHelper.fieldHasError('activity', 'required')"
      class="form-control-error">
      {{ 'GLOBAL_ERROR_COMPANY_ACTIVITY_REQUIRED' | translate }}
    </span>
  </mat-form-field>

  <mat-form-field class="form-group">
    <input
      [matAutocomplete]="fieldCountry"
      [placeholder]="'B2B_COMPANY_LABEL_COUNTRY' | translate"
      (blur)="onBlurCountry($event)"
      formControlName="country"
      matInput
      required
      minlength="2">
    <mat-autocomplete
      #fieldCountry="matAutocomplete"
      [displayWith]="fieldCountryDisplay">
      <mat-option
        *ngFor="let country of filteredCountryList | async"
        (click)="emitSelectedCountry(country)"
        [value]="country">
        {{ country.name }}
      </mat-option>
    </mat-autocomplete>

    <span
      *ngIf="formHelper.fieldHasError('country', 'invalid')"
      class="form-control-error">
      {{ formHelper.getField('country').getError('invalid') }}
    </span>
    <span
      *ngIf="formHelper.fieldHasError('country', 'required')"
      class="form-control-error">
      {{ 'GLOBAL_ERROR_REQUIRED' | translate }}
    </span>
    <span *ngIf="formHelper.fieldHasError('country', 'minlength')"
      class="form-control-error">
      {{ 'GLOBAL_ERROR_FIELD_MIN_LENGTH' | translate: {minlength: 2} }}
    </span>
  </mat-form-field>

  <mat-form-field class="form-group">
    <input
      [placeholder]="'B2B_COMPANY_LABEL_CITY' | translate"
      formControlName="company_city"
      matInput>

    <span
      *ngIf="formHelper.fieldHasError('company_city', 'required')"
      class="form-control-error">
      {{ 'GLOBAL_ERROR_REQUIRED' | translate }}
    </span>
  </mat-form-field>

  <mat-form-field class="form-group">
    <textarea
      [placeholder]="'B2B_COMPANY_LABEL_ADDRESS' | translate"
      formControlName="address"
      matInput
      rows="5">
    </textarea>
    <span
      *ngIf="formHelper.fieldHasError('address', 'invalid')"
      class="form-control-error">
      {{ formHelper.getField('address').getError('invalid') }}
    </span>
    <span
      *ngIf="formHelper.fieldHasError('address', 'required')"
      class="form-control-error">
      {{ 'GLOBAL_ERROR_REQUIRED' | translate }}
    </span>
  </mat-form-field>

  <mat-form-field class="form-group">
    <input
      [placeholder]="'LEGAL_FORM__VAT_NUMBER' | translate"
      (keydown.space)="$event.preventDefault();"
      formControlName="company_vat_number"
      matInput
      minlength="8"
      required
      type="text">
    <span
      *ngIf="formHelper.fieldHasError('company_vat_number', 'required')"
      class="form-control-error">
      {{ 'GLOBAL_ERROR_VAT_NUMBER_REQUIRED' | translate }}
    </span>
    <span
      *ngIf="formHelper.fieldHasError('company_vat_number', 'minlength')"
      class="form-control-error">
      {{ 'GLOBAL_ERROR_FIELD_MIN_LENGTH' | translate: {minlength: 8} }}
    </span>
    <span
      *ngIf="formHelper.fieldHasError('company_vat_number', 'whitespace')"
      class="form-control-error">
      {{ 'GLOBAL_ERROR_NO_SPACES' | translate }}
    </span>
    <span
      *ngIf="formHelper.fieldHasError('company_vat_number', 'invalid')"
      class="form-control-error">
      {{ formHelper.getField('company_vat_number').getError('invalid') }}
    </span>
  </mat-form-field>

  <div
    *ngIf="companyFormEnum.default === formLocation"
    class="form-group">
    <label
      class="mat-input-file-element"
      for="company-logo-preview">
      <mat-icon>file_upload</mat-icon>
      {{ companyLogoPreview ? companyLogoPreview : ('B2B_COMPANY_LABEL_LOGO' | translate) }}
    </label>
    <input
      (change)="onCompanyLogoChange($event)"
      accept=".jpg,.png,.jpeg"
      class="hidden"
      id="company-logo-preview"
      type="file"/>

    <span
      *ngIf="formHelper.fieldHasError('logo', 'invalid')"
      class="form-control-error">
      {{ formHelper.getField('logo').getError('invalid') }}
    </span>
  </div>

  <div class="form-actions">
    <button
      *ngIf="companyFormEnum.default === formLocation"
      class="btn btn-save"
      type="submit">
      <span>
        {{ 'B2B_GLOBAL_BUTTON_SAVE_CHANGES' | translate }}
        <mat-icon>check</mat-icon>
      </span>
    </button>
  </div>
</form>
