import { Component, OnInit }  from '@angular/core';
import { FADE_OUT_ANIMATION } from '@common/core/core.animations';
import { LanguageService }    from '@services/i18n.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './landing-page-default.component.html',
  styleUrls: ['./landing-page-default.component.css'],
  animations: [FADE_OUT_ANIMATION]
})
export class LandingPageDefaultComponent implements OnInit {
  constructor(
    public languageService: LanguageService
  ) {
  }

  ngOnInit() {
    const location = window.location.href;
    if (location.indexOf('xtest') >= 0 || location.indexOf('xlocal') >= 0) {
      window.location.href = this.languageService.currentLang + '/auth/login';
    } else if (location.indexOf('.com') > 0){
      window.location.href = this.languageService.currentLang + '/';
    }
  }
}
