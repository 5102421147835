export const freelancers = {
  extern: false,
  b2bMenuRoutes: {
    B2B_MENU_MY_PROFILE: 'business/freelancers/profile',
    B2B_MENU_MY_COMPANY_ACCOUNTS: 'business/freelancers/accounts',
    B2B_MENU_DASHBOARD: 'business/freelancers/dashboard',
    B2B_MENU_PROJECTS: 'business/freelancers/projects/list',
    B2B_MENU_CANDIDATE_PROFILES: 'business/freelancers/candidate-profiles',
    // B2B_MENU_SWITCH_TO_ASSESSMENT: '/app/{company}/',
    // B2B_MENU_MY_SETTINGS: 'business/freelancers/my-settings',
  },
  denyAccessForRoutes: [],
  // denyAccessForRoutes: ['/app/'],
  goTo: 'business/freelancers/dashboard'
};
