import { Component, DoCheck, OnInit } from '@angular/core';
import { ActivatedRoute }             from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { LicenseService }             from '@b2b/@common/modules/license/services/license.service';
import { StripeModalService }         from '@b2b/@common/modules/stripe/services/stripe-modal.service';
import { AuthService }                from '@common/auth/services/auth.service';
import { LanguageService }            from '@services/i18n.service';
import { PlatformsAccessService }     from '@services/platforms-access.service';
import { UtilsService }               from '@services/utils.service';
import { Subscription }               from 'rxjs';


@Component({
  selector: 'app-block-menu-header',
  templateUrl: './block-menu-header.component.html',
  styleUrls: ['./block-menu-header.component.scss']
})
export class BlockMenuHeaderComponent implements DoCheck, OnInit {
  public profileImage: string;
  public activeSubscriptions: Subscription[] = [];
  public showUpgradeButton;
  public hideCreditsAndTarifs = false;

  constructor(
    public authService: AuthService,
    // public userService: UserServiceB2B,
    public userService: AuthService,
    public language: LanguageService,
    public utilsService: UtilsService,
    public stripeModalService: StripeModalService,
    public activatedRoute: ActivatedRoute,
    public platformAccessService: PlatformsAccessService,
    public licenseService: LicenseService,
    public url: LocationStrategy
  ) {
  }

  get isCurrentlyVisitingFreelancers() {
    return window.location.href.indexOf('freelancers') > -1 && window.location.href.indexOf('business') > -1;
  }
  public get licenseInfo() {
    if (this.userService.currentUserDetails) {
      return this.userService.currentUserDetails.license_info;
    }

    return null;
  }
  get isCurrentlyVisitingAssessment() {
    return (window.location.href.indexOf('app') > -1 || window.location.href.indexOf('business') > -1)
      && !this.isCurrentlyVisitingFreelancers;
  }

  public ngOnInit() {
    this.renderUpgradeButtonVisibility();
    if(this.url.path().search('freelancers') > -1){
      this.hideCreditsAndTarifs = true;
    } else {
      this.hideCreditsAndTarifs = false;
    }
  }

  public ngDoCheck() {
    if (!this.authService.currentUserDetails) {
      return false;
    }

    if (this.authService.currentUserDetails.company_logo) {
      this.profileImage = this.authService.currentUserDetails.company_logo;
    } else {
      this.profileImage = '/assets/img/b2b/app/profile.img.default.png';
    }
  }

  upgradeAction() {
    this.stripeModalService.buy();
  }

  renderUpgradeButtonVisibility() {
    this.showUpgradeButton = this.licenseService.showUpgradeButton();
  }

  public async onSignOutClick(event: any) {
    event.preventDefault();
    await this.authService.signOutB2B().toPromise();
  }

}
