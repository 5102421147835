import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalStripeComponent } from '@b2b/@common/modules/stripe/cpts/modal-stripe.component';
import {
  SellableCreditPacks,
  SellableItem,
  SellableSubscriptions,
  StripeService,
} from '@b2b/@common/modules/stripe/services/stripe.service';
import { forkJoin, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StripeModalService {

  constructor(
    private stripeService: StripeService,
    private matDialog: MatDialog,
  ) {
  }

  buy(activeTabIndex?: number) {
    this.openModalWithProductData(activeTabIndex);
  }

  openModalWithProductData(activeTabIndex?: number) {
    forkJoin([
      this.stripeService.getSellableSubscriptions(),
      this.stripeService.getSellableCreditPacks()
    ]).pipe(
      catchError((err => throwError(err)))
    ).subscribe((values: [SellableSubscriptions, SellableCreditPacks]) => {
      const sellableItemsValues = {subscriptions: values[0].results, creditPacks: values[1].results, activeTabIndex};
      this.openLicenseUpgradeModal(sellableItemsValues);
    });
  }

  openLicenseUpgradeModal(items: { creditPacks: SellableItem[]; subscriptions: SellableItem[]; activeTabIndex: number }) {
    const dialogRef = this.matDialog.open(ModalStripeComponent, {
      width: 'auto',
      panelClass: 'stripe-modal',
      data: items
    });

    dialogRef.afterClosed().subscribe(() => {
      // refresh user data show payment status fail/success
    });
  }
}
