import { HttpParams }                      from '@angular/common/http';
import { Injectable }                      from '@angular/core';
import { Meta, Title }                     from '@angular/platform-browser';
import { ApiEndpoints }                    from '@app/app.constants';
import { Observable, throwError }          from 'rxjs';
import { catchError, map }                 from 'rxjs/operators';
import { HttpService }                     from './http.service';
import { SiteConfigService, SiteSettings } from './site-config.service';


@Injectable({
  providedIn: 'root'
})
export class RouteMetaService {

  private _routeHomeMeta: RouteMeta;
  private _siteUrl: string = window.location.protocol + '//' + window.location.hostname;

  constructor(
    private _systemConfig: SiteConfigService,
    private _titleService: Title,
    private _metaService: Meta,
    private _http: HttpService,
    // private _httpErrorService: HttpErrorService
  ) {
  }

  public getTitle(): string {
    return this._titleService.getTitle();
  }

  public setTitle(title: string, force?: boolean) {
    if (force) {
      this._titleService.setTitle(title);
    } else {
      if (this._systemConfig.settings) {
        this._titleService.setTitle(title);
        // this._titleService.setTitle(title + ' | ' + this._systemConfig.settings.website.name );
      } else {
        this._systemConfig.siteConfigLoaded.subscribe((settings: SiteSettings) => {
          this._titleService.setTitle(title);
          // this._titleService.setTitle(title + ' | ' + settings.website.name);
        });
      }
    }
  }

  public getRouteMeta(routeUrl: string, fullUrl?: string): Observable<RouteMeta> {
    if (!routeUrl.startsWith('/')) {
      routeUrl = '/' + routeUrl;
    }

    let searchParams: HttpParams = new HttpParams({fromString: ''});
    searchParams = searchParams.set('route', routeUrl);

    if (fullUrl) {
      searchParams = searchParams.set('full_url', fullUrl);
    }

    let options;

    if (searchParams) {
      options = {params: {search: searchParams}};
    }

    return this._http.get(ApiEndpoints.seo.routeMeta, options)
      .pipe(map((response: any) => response))
      .pipe(catchError((error) => {
        // return Observable.throw(this._httpErrorService.parseError(error));
        return throwError(error);
      }));
  }

  public setRouteMeta(routeMeta: RouteMeta) {
    if (!this._routeHomeMeta) {
      this.getRouteMeta('').subscribe((homeRouteMeta: any) => {
        this._routeHomeMeta = homeRouteMeta;

        this._renderRouteMeta(routeMeta);
      });
    } else {
      this._renderRouteMeta(routeMeta);
    }
  }

  public addRedirectTags(path: string) {
    this.removeRedirectTags();

    this._metaService.addTags([
      {
        name: 'prerender-status-code',
        content: '301'
      },
      {
        name: 'prerender-header',
        content: 'Location: ' + this._siteUrl + path
      }
    ]);
  }

  public removeRedirectTags() {
    this._metaService.removeTag('name="prerender-status-code"');
    this._metaService.removeTag('name="prerender-header"');
  }

  private _renderRouteMeta(routeMeta: RouteMeta) {
    this._setRouteTitle(routeMeta);
    this._setRouteDescription(routeMeta);
    this._setRouteImage(routeMeta);
    this._setRouteNoIndexNoFollow(routeMeta);
  }

  private _setRouteNoIndexNoFollow(routeMeta) {
    const isAuthenticationPage = routeMeta.route.indexOf('auth') > -1;
    if (isAuthenticationPage) {
      this._metaService.removeTag('name="robots"');
      this._metaService.addTags([
        {name: 'robots', content: 'noindex, nofollow'}
      ]);
    }
  }

  private _setRouteTitle(routeMeta: RouteMeta) {
    if (!routeMeta.title) {
      routeMeta.title = this._routeHomeMeta.title;
    }

    routeMeta.title = this._stripMetaTitle(routeMeta.title);

    this.setTitle(routeMeta.title);

    this._metaService.removeTag('name="twitter:title"');
    this._metaService.removeTag('property="og:title"');

    this._metaService.addTags([
      {name: 'twitter:title', content: routeMeta.title},
      {property: 'og:title', content: routeMeta.title}
    ]);
  }

  private _setRouteDescription(routeMeta: RouteMeta) {
    if (!routeMeta.description) {
      routeMeta.description = this._routeHomeMeta.description;
    }
    routeMeta.description = this._stripMetaDescription(routeMeta.description);

    this._metaService.removeTag('name="description"');
    this._metaService.removeTag('name="twitter:description"');
    this._metaService.removeTag('property="og:description"');

    this._metaService.addTags([
      {name: 'description', content: routeMeta.description},
      {name: 'twitter:description', content: routeMeta.description},
      {property: 'og:description', content: routeMeta.description}
    ]);
  }

  private _setRouteImage(routeMeta: RouteMeta) {
    if (!routeMeta.image) {
      routeMeta.image = this._routeHomeMeta.image;
    }

    this._metaService.removeTag('name="twitter:image"');
    this._metaService.removeTag('property="og:image"');

    this._metaService.addTags([
      {name: 'twitter:image', content: routeMeta.image},
      {property: 'og:image', content: routeMeta.image}
    ]);
  }

  private _stripMetaTitle(value: string): string {
    // let strippedMeta = String(value).replace(/<[^>]+>/gm, '');
    // if (strippedMeta.length > 60) {
    //   strippedMeta = strippedMeta.substr(0, 57) + '...';
    // }
    return String(value).replace(/<[^>]+>/gm, '');
  }

  private _stripMetaDescription(value: string): string {
    // let strippedMeta =
    // if (strippedMeta.length > 160) {
    //   strippedMeta = strippedMeta.substr(0, 157) + '...';
    // }
    return String(value).replace(/<[^>]+>/gm, '');
  }

}

export interface RouteMeta {
  title: string;
  description: string;
  image: string;
  route: string;
}
