import { environment } from '@app/app.constants';

const ApiEndpointsBase = environment.baseUrl + 'api/';

export const AuthEndpoints: any = {
  base: ApiEndpointsBase + 'auth/',
  login: ApiEndpointsBase + 'auth/login/',
  logginCommon: ApiEndpointsBase + 'auth/common-login/',
  logout: ApiEndpointsBase + 'auth/logout/',
  registration: ApiEndpointsBase + 'auth/registration/',
  registrationConfirm: ApiEndpointsBase + 'auth/registration/verify-email/',
  resendConfirmationEmail: ApiEndpointsBase + 'auth/resend-confirmation-email/',
  userDetails: ApiEndpointsBase + 'auth/user/',
  passwordReset: ApiEndpointsBase + 'auth/password/reset/',
  passwordResetConfirm: ApiEndpointsBase + 'auth/password/reset/confirm/',
  adPasswordResetConfirm: ApiEndpointsBase + 'auth/ad/authorization-url/',

  googleLogin: ApiEndpointsBase + 'auth/google-login/',
  facebookLogin: ApiEndpointsBase + 'auth/facebook-login/',
  linkedinLogin: ApiEndpointsBase + 'auth/linkedin-login/',
  githubLogin: ApiEndpointsBase + 'auth/github-login/',

  googleAuth: ApiEndpointsBase + 'auth/google/',
  facebookAuth: ApiEndpointsBase + 'auth/facebook/',
  linkedinAuth: ApiEndpointsBase + 'auth/linkedin/',
  githubAuth: ApiEndpointsBase + 'auth/github/'
};

